<template>
  <v-tooltip
    :bottom="positionBottom"
    :class="ExportEnum.NO_EXPORT_CLASS"
    :color="themeColorRGBA('synthesis-brand-dark-blue', 0.9)"
    :left="positionLeft"
    :max-width="maxWidth"
    :right="positionRight"
    :top="positionTop"
    content-class="py-2 px-4 text-break"
  >
    <template #activator="{on, attrs}">
      <slot
        name="content"
        v-bind="{on, attrs}"
      >
        <v-icon
          :class="[iconClass, ExportEnum.NO_EXPORT_CLASS]"
          :style="iconStyle"
          v-bind="attrs"
          x-small
          v-on="on"
        >
          mdi-information
        </v-icon>
      </slot>
    </template>
    <span>
      <slot />
    </span>
  </v-tooltip>
</template>

<script>

import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'
import ExportEnum       from '@/api/enums/ExportEnum'

export default {
  name      : 'Infotip',
  components: {},
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    maxWidth: {
      type   : [Number, String],
      default: 350
    },
    iconClass: {
      type   : String,
      default: ''
    },
    iconStyle: {
      type   : String,
      default: ''
    },
    position: {
      type   : String,
      default: 'top'
    }
  },
  dataStore: {},
  enums    : { ExportEnum },
  dataModel: null,
  data () {
    return {
      positionTop   : this.position === 'top',
      positionBottom: this.position === 'bottom',
      positionLeft  : this.position === 'left',
      positionRight : this.position === 'right'
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>
