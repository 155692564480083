<template>
  <v-menu
    v-model="filtersMenu"
    :close-on-content-click="false"
    :nudge-top="-9"
    offset-y
  >
    <template #activator="{on: menu}">
      <v-badge
        :value="activeFiltersCount"
        color="purple"
        overlap
      >
        <template #badge>
          <span>{{ activeFiltersCount }}</span>
        </template>

        <v-btn
          :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
          :disabled="allFiltersCount <= 0"
          class="pa-0 mr-0 ml-1"
          depressed
          style="width: 48px; min-width: 48px; height: 48px;"
          v-on="menu"
        >
          <v-icon v-text="'mdi-filter-menu-outline'" />
        </v-btn>
      </v-badge>
    </template>

    <v-card :max-width="maxWidth">
      <v-card-text
        :style="`max-height: ${maxHeight}px;`"
        class="overflow-y-auto"
      >
        <v-container fluid>
          <v-row>
            <template v-for="(obj, objKey, objIndex) in filters">
              <v-col
                :key="`filter-${objKey}-${objIndex}`"
                cols="auto"
              >
                <p
                  class="pb-0 mb-1"
                  v-text="obj.Label"
                />
                <template v-if="obj.Type === 'v-btn-toggle'">
                  <v-btn-toggle
                    v-model="dataTable[obj.CustomFilter ? 'customFilter' : 'filter'][objKey]"
                    class="elevation-0"
                    mandatory
                    style="min-width: 268px"
                  >
                    <template v-for="(item, itemIndex) in obj.Items">
                      <v-btn
                        :key="`filter-${objKey}-${objIndex}-btn-toggle-${itemIndex}`"
                        :value="item[item.ItemValue || 'Value']"
                        height="40"
                        text
                      >
                        {{ item[item.ItemText || 'Name'] }}
                      </v-btn>
                    </template>
                  </v-btn-toggle>
                </template>

                <template v-else-if="obj.Type === 'v-select'">
                  <v-select
                    v-model="dataTable[obj.CustomFilter ? 'customFilter' : 'filter'][objKey]"
                    :item-text="obj.ItemText || 'Name'"
                    :item-value="obj.ItemValue || 'Value'"
                    :items="obj.Items"
                    :multiple="!!obj.Multiple"
                    :placeholder="obj.PlaceHolder"
                    :small-chips="!!obj.Chips"
                    :style="$vuetify.breakpoint.smAndUp ?'max-width: 220px;' : ''"
                    clearable
                    dense
                    flat
                    hide-details
                    outlined
                    single-line
                    style="min-width: 268px"
                  >
                    <template #item="{on, attrs, item}">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item[item.ItemText || 'Name']" />
                          <v-list-item-subtitle v-text="item[item.ItemDesc || 'Description']" />
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </template>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />

        <v-btn
          text
          @click="resetFilters"
        >
          {{ $t('Common.Button.Reset') }}
        </v-btn>

        <v-btn
          color="primary"
          depressed
          @click="closeMenu"
        >
          {{ $t('Common.Button.Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>

import { isArray, isPlainObject } from '@/lib/utils/type'
import { arrayEquals }            from '@/lib/utils/array'
import { clone }                  from '@/lib/utils/helper'

let initialDataTableFilters = {}

export default {
  name      : 'DataTableFilter',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type    : Object,
      required: true
    },
    filters: {
      type    : Object,
      required: true
    },
    maxWidth: {
      type   : [Number, String],
      default: 320
    },
    maxHeight: {
      type   : [Number, String],
      default: 500
    }
  },
  enums: {},
  data () {
    return {
      filtersMenu: false
    }
  },
  computed: {
    dataTable: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    allFiltersCount () {
      const filters = isPlainObject(this.filters) ? Object.keys(this.filters) : []
      return filters.length
    },

    activeFiltersCount () {
      const langFilters = isPlainObject(this.filters) ? Object.keys(this.filters) : []
      const dataTableFilters = isPlainObject(this.dataTable.filter) && isPlainObject(this.dataTable.customFilter) ? { ...this.dataTable.filter, ...this.dataTable.customFilter } : {}
      const filteredFilters = Object.keys(dataTableFilters).filter(key => langFilters.includes(key)).reduce((obj, key) => {
        obj[key] = dataTableFilters[key]
        return obj
      }, {})
      const retVal = Object.keys(filteredFilters).filter(key => filteredFilters[key] !== initialDataTableFilters[key] && filteredFilters[key] !== 'null' && (isArray(initialDataTableFilters[key]) ? !arrayEquals(initialDataTableFilters[key], filteredFilters[key]) : true)).length
      return retVal
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    initialDataTableFilters = clone({ ...this.dataTable.filter ?? {}, ...this.dataTable.customFilter ?? {} })
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    closeMenu () {
      this.filtersMenu = false
    },

    resetFilters () {
      Object.keys(this.dataTable.filter).forEach(key => { this.dataTable.filter[key] = initialDataTableFilters[key] })
      Object.keys(this.dataTable.customFilter).forEach(key => { this.dataTable.customFilter[key] = initialDataTableFilters[key] })
      this.closeMenu()
    }
  }
}
</script>

<style scoped>

</style>
