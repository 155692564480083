import BaseResource                    from '@/lib/data/resource/BaseResource'
import i18n                            from '@/lang/lang'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import ChartsStyleEnum                 from '@/api/enums/ChartsEnum'
import Common                          from '@/lang/locale/en/Common'
import { collect }                     from '@/lib/utils/array'
import AssetNameResource               from '@/api/resources/asset/AssetNameResource'
import AssetCodeNameResource           from '@/api/resources/asset/AssetCodeNameResource'

export default class RiskHistoricalSpectrumResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const assetValue = payload?.itemCode || payload?.itemName || ''

    this.Asset = payload.itemName ? (payload.itemName === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.itemName) : ''
    this.AssetValue = assetValue.toLocaleLowerCase(i18n.code)

    this.Seasonality = {
      user: [],
      max : [],
      no  : []
    }
    if (payload.seasonalityData) {
      if (payload.seasonalityData.length) {
        payload.seasonalityData.forEach(item => {
          const seasonalityName = Common.Seasonality.Map[item.seasonality]

          this.Seasonality[seasonalityName] = {
            name      : seasonalityName,
            values    : item.cashFlows ?? [],
            color     : ChartsStyleEnum.Colors.LineChartSeasonality[seasonalityName],
            risk      : item.risk ? toNumberFormatNotationShort(item.risk) : '',
            lineSource: item.finalIndexP1 || '',
            lineTarget: item.initialIndexP1 || '',
            lineColor : 'synthesis-ui-green-00'
            // lineColor : ChartsStyleEnum.Colors.LineChartSeasonalityGraphLine[seasonalityName]
          }
        })
      }
    }
    this.AggregateItem = payload.aggregateItem || false
    this.PerCashflowCodes = collect(payload?.perCashflowCodes || [], AssetNameResource)
    this.PerCashflowItems = collect(payload?.perCashflowItems || [], RiskHistoricalSpectrumResource)

    this.PerCashflowNames = payload.perCashflowNames ?? []
    this.PerCashflowCodeNames = collect(payload?.perCashflowCodeNames || [], AssetCodeNameResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
