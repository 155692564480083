export const Risks = {
  BaseCurrency: {
    Data: {}
  },

  Active: {
    Data: null
  },

  Archive: {
    Data: {}
  },

  Summary: {
    Data: {}
  },

  Exposures: {
    Data: {}
  },

  ValueAtRisk: {
    Data: {}
  },

  ForwardSimulations: {
    Data: {}
  },

  HistoricalSpectrum: {
    Data: {}
  },

  ExtremeEvents: {
    Data: {}
  },

  Correlations: {
    Data: {}
  },

  Seasonality: {
    Data: {}
  },

  Forecasts: {
    Data: {}
  },

  Debt: {
    Data: {}
  },

  ForecastFlow: {
    Data: {}
  },

  SupportedCurrencies: {
    Data: []
  }
}
