import BaseResource from '@/lib/data/resource/BaseResource'

export default class ComparisonCompanyTableResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.shortName ?? ''
    this.Industry = payload.industry?.name || ''
  }

  /* PROPERTIES */
  get TableRow () {
    return {
      Id      : this.Id,
      Name    : this.Name,
      Industry: this.Industry
    }
  }

  /* METHODS */
}
