import BaseResource                    from '@/lib/data/resource/BaseResource'
import { first }                       from '@/lib/utils/array'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskForecastPricesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Dates = payload.dates ?? []
    this.Prices = payload.prices ?? []
    this.LastUpdated = payload.lastUpdated ?? ''
    this.Institution = payload.institution ?? ''
  }

  /* PROPERTIES */
  get Price () {
    const price = first(this.Prices) || ''
    if (price) {
      return toNumberFormatNotationShort(price)
    }

    return ''
  }

  /* METHODS */
}
