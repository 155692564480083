<template>
  <v-text-field
    ref="inputRef"
    :value="formattedValue"
    v-bind="attrs"
  >
    <template
      v-for="(index, name) in $slots"
      #[name]
    >
      <slot :name="name" />
    </template>
  </v-text-field>
</template>

<script>

import useCurrencyInput from 'vue-currency-input'
import { watch }        from '@vue/composition-api'

const defaults = {
  // A ISO 4217 currency code, for example "USD" or "EUR". This option is required.
  currency                          : 'GBP',
  // A BCP 47 language tag (for example "en" or "de-DE"). Default is undefined (use the default locale of the Browser).
  locale                            : 'en-GB',
  /*
    How to display the currency in currency formatting. Possible values are:

    "symbol" to use a localized currency symbol such as "€" (default value)
    "narrowSymbol" to use a narrow format symbol ("$100" rather than "US$100")
    "code" to use the ISO currency code
    "name" to use a localized currency name such as "dollar"
    "hidden" to hide the currency
  */
  currencyDisplay                   : 'hidden',
  // Whether to use accounting sign formatting, for example wrapping negative values with parentheses instead of prepending a minus sign.
  accountingSign                    : false,
  // Whether the decimal symbol is inserted automatically, using the last inputted digits as decimal digits. Default is false (the decimal symbol needs to be inserted manually).
  autoDecimalDigits                 : false,
  /*
    The number of displayed decimal digits. Default is "undefined" (use the currency's default,
    decimal digits will be hidden for integer numbers). Must be between 0 and 15 and can only
    be applied for currencies that support decimal digits. You can also pass an object {min, max}
    to use a precision range.
  */
  precision                         : undefined,
  // Whether to hide the currency symbol on focus. Default is true.
  hideCurrencySymbolOnFocus         : false,
  // Whether to hide the grouping separator on focus. Default is true.
  hideGroupingSeparatorOnFocus      : false,
  // Whether to hide negligible decimal digits on focus. Default is true.
  hideNegligibleDecimalDigitsOnFocus: false,
  /*
    Applies a scaling to the exported value. Possible values are:

    undefined for no scaling at all and use of float numbers
    "precision" for scaling float values automatically to integers depending on the current precision, for example 1.23 -> 123
    "thousands" for using a scaling factor of 1,000
    "millions"" for using scaling factor of 1,000,000
    "billions" for using a scaling factor of 1,000,000,000
  */
  valueScaling                      : 'precision',
  // The range of accepted values as object {min, max}. Default is undefined (no value range). The validation is triggered on blur and automatically sets the respective threshold if out of range.
  valueRange                        : undefined,
  // Whether the minus symbol is automatically inserted or prevented to be inputted depending on the configured valueRange. Default is true.
  autoSign                          : true,
  // Whether to use grouping separators such as thousands/lakh/crore separators.
  useGrouping                       : true
}

export default {
  name : 'VTextFieldCurrencyDirective',
  props: {
    value: {
      type   : [Number, String],
      default: () => ''
    },

    options: {
      type   : Object,
      default: () => defaults
    }
  },

  setup (props) {
    const {
      inputRef,
      formattedValue,
      setValue
    } = useCurrencyInput({ ...defaults, ...props.options })

    watch(() => props.value, (value) => {
      setValue(value)
    })

    return {
      inputRef,
      formattedValue
    }
  },

  computed: {
    attrs () {
      const {
        value,
              ...attrs
      } = this.$attrs // all but input event
      return attrs
    }
  }
}
</script>

<style scoped>

</style>
