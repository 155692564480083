import BaseResource      from '@/lib/data/resource/BaseResource'
import SortDirectionEnum from '@/api/enums/SortDirectionEnum'

export default class CompanySortResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.SortBy = payload?.sortBy || []
    this.SortDesc = payload?.sortDesc || []
  }

  /* PROPERTIES */
  get Sortable () {
    let sortable = []

    if (this.SortBy && !!this.SortBy.length) {
      sortable = this.SortBy.map((column, i) => {
        return {
          sortColumn: column,
          direction : this.SortDesc[i] ? SortDirectionEnum.Descending : SortDirectionEnum.Ascending
        }
      })
    }

    return sortable
  }

  /* METHODS */
}
