<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <v-icon
              color="orange"
              size="24"
            >
              {{ isEditMode ? 'mdi-pencil-circle-outline' : 'mdi-plus-circle-outline' }}
            </v-icon>

            <div class="mt-4 mb-4">
              <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                {{ isEditMode ? $t('Risks.Section.DebtProfile.Edit.Form.Button.EditAmortisation.Title') : $t('Risks.Section.DebtProfile.Edit.Form.Button.AddAmortisation.Title') }}
              </div>
              <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                <!--{{ $t(`Risks.AddExposure.Subtitle`) }}-->
              </div>
            </div>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          class="pt-2"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <div cols="12">
              <label class="field-label">
                Type
              </label>

              <group-item-radio
                v-model="model.amortisationType"
                :items="amortisationTypes"
                mandatory
                class="pa-0"
                @change="onAmortisationTypeChange"
              />
            </div>

            <div class="mt-4">
              <v-container
                fluid
                pa-0
              >
                <v-row>
                  <template v-for="(item, index) in model.amortisation">
                    <v-col
                      :key="`amortisation-${index}`"
                      cols="6"
                    >
                      <label class="field-label">{{ item.label }}</label>
                      <v-text-field-currency-directive
                        v-model="item.amount"
                        :options="{precision: 0, locale: $i18n.code, currency: baseCurrency.symbol}"
                        :error-messages="decrementValuesError(item)"
                        :prefix="baseCurrency.displayName"
                        :rules="item.validator.vuetifyFormFieldRules('amount')"
                        class="add-amortisation-input"
                        dense
                        hide-details="auto"
                        outlined
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </div>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="pb-0">
          <v-btn
            :disabled="isFormSaving"
            :loading="isFormSaving"
            block
            class="action-button"
            color="synthesis-ui-grey-03"
            depressed
            large
            @click="saveFromData(true)"
          >
            {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.ClearAmortisation.Title') }}
          </v-btn>
        </v-card-actions>
        <v-card-actions class="pb-2">
          <v-btn
            :disabled="isFormSaving || !isModelValid"
            :loading="isFormSaving"
            block
            class="action-button white--text"
            color="synthesis-ui-green-02"
            depressed
            large
            @click="saveFromData(false)"
          >
            {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.SaveAmortisation.Title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                       from '@/mixins/dialog/dialogData'
import dialogVisible                    from '@/mixins/dialog/dialogVisible'
import VTextFieldCurrencyDirective      from '@/theme/default/components/form/VTextFieldCurrencyDirective'
import RiskDebtAmortisationModel        from '@/api/models/risks/RiskDebtAmortisationModel'
import GroupItemRadio                   from '@/theme/default/components/form/GroupItemRadio.vue'
import RiskDebtAmortisationTypeEnumEnum from '@/api/enums/RiskDebtAmortisationTypeEnumEnum'

export default {
  name      : 'AddEditRiskDebtAmortisationDialog',
  components: {
    GroupItemRadio,
    VTextFieldCurrencyDirective
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    baseCurrency: {
      type    : Object,
      required: true
    },
    editMode: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : { RiskDebtAmortisationTypeEnumEnum },
  dataModel: RiskDebtAmortisationModel,
  data () {
    return {}
  },
  computed: {
    amortisationTypes () {
      return [{
        Label: 'Annually',
        Value: RiskDebtAmortisationTypeEnumEnum.ANNUALLY
      }, {
        Label: 'Quarterly',
        Value: RiskDebtAmortisationTypeEnumEnum.QUARTERLY
      }]
    },

    isEditMode () {
      return !!this.editMode
    }
  },
  watch: {
    item () {
      this.initModel(false)
    },

    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    decrementValuesError (item) {
      let retVal = []
      const arr = [...[{ amount: this.model?.notional || 0 }], ...this.model.amortisation]
      const itemIndex = arr.findIndex(o => o.key === item.key) || 1
      const prevIndex = itemIndex - 1

      const isValid = prevIndex >= 0 ? arr[prevIndex]?.amount >= item.amount : true
      if (!isValid) retVal = [itemIndex === 1 ? 'Must be smaller or equal to notional' : `Must be smaller or equal to previous ${ this.model.amortisationType === RiskDebtAmortisationTypeEnumEnum.ANNUALLY ? 'year' : 'quarter' }`]

      return retVal
    },

    onAmortisationTypeChange (val) {
      this.$emit('amortisation-type-change', this.model, val)
    },

    /**
     * saveFromData
     *
     * Locally persist entered amortisations
     */
    saveFromData (clearData) {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const data = this.model.clone()
      if (clearData) {
        data.amortisation = []
        this.formSaveSuccess(data)
      } else {
        data.amortisation = data.amortisation.map(item => {
          if (!item.amount) item.amount = 0
          return item
        })
        this.validateForm() && this.formSaveSuccess(data)
      }
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items     : start;
  justify-content : end;
  height          : 100%;
}

/deep/ .v-dialog {
  border-radius : 4px;
  margin        : 0px !important;
  max-height    : 100% !important;
}

/deep/ .add-amortisation-input .v-input__slot {
  padding : 0 !important;
}

/deep/ .add-amortisation-input .v-text-field__slot .v-text-field__prefix, /deep/ .add-amortisation-input .v-text-field__slot .v-text-field__suffix {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
  display       : flex;
  align-items   : center;
  font-weight   : 400;
}

/deep/ .add-amortisation-input .v-text-field__slot input {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
}
</style>
