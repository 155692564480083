<template>
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    :close-on-content-click="false"
    :disabled="disabled"
    :return-value.sync="selectedDate"
    max-width="286"
    nudge-bottom="52"
    transition="scale-transition"
  >
    <template #activator="{on, attrs}">
      <v-text-field
        :background-color="disabled ? 'grey lighten-4' : ''"
        :disabled="disabled"
        :height="height"
        :label="label"
        :placeholder="placeholder"
        :style="`width: ${width}px;`"
        :value="selectedDateFormattedText"
        :clearable="clearable"
        :dense="dense"
        :rules="rules"
        :hide-details="hideDetails"
        outlined
        prepend-inner-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="onDateClear"
      />
    </template>

    <v-card flat>
      <v-date-picker
        v-model="selectedDate"
        :allowed-dates="allowedDatesEval"
        :disabled="disabled"
        :locale="$i18n.locale"
        :max="max"
        :min="min"
        color="primary"
        no-title
        scrollable
        width="286"
      />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="onDateDialogCancel"
        >
          {{ $t('Common.Button.Cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onDateDialogOk"
        >
          {{ $t('Common.Button.Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { isFunction } from '@/lib/utils/type'

export default {
  name      : 'SelectDate',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : String,
      default: ''
    },

    placeholder: {
      type   : String,
      default: ''
    },

    label: {
      type   : String,
      default: ''
    },

    disabled: {
      type   : Boolean,
      default: false
    },

    clearable: {
      type   : Boolean,
      default: false
    },

    dense: {
      type   : Boolean,
      default: false
    },

    allowedDates: {
      type   : Function,
      default: null
    },

    min: {
      type   : String,
      default: undefined
    },

    max: {
      type   : String,
      default: undefined
    },

    rules: {
      type   : Array,
      default: () => []
    },

    hideDetails: {
      type   : [Boolean, String],
      default: 'auto'
    },

    dateFormatDisplay: {
      type   : String,
      default: 'ddd DD MMM YYYY'
    },

    dateFormatModel: {
      type   : String,
      default: 'YYYY-MM-DD'
    },

    width: {
      type   : [String, Number],
      default: ''
    },

    height: {
      type   : [String, Number],
      default: ''
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {
      dateMenu    : false,
      selectedDate: null
    }
  },
  computed: {
    selectedDateFormattedText () {
      return this.formatDisplayDate(this.selectedDate)
    },

    model: {
      get () {
        return this.formatValueDate(this.value)
      },
      set (val) {
        this.$emit('input', this.formatModelDate(val))
        this.$emit('change', this.formatModelDate(val))
      }
    }
  },
  watch: {
    model: {
      handler (newVal) {
        this.selectedDate = newVal
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    allowedDatesEval (val) {
      return isFunction(this.allowedDates) ? this.allowedDates(val) : true
    },

    onDateDialogCancel () {
      this.dateMenu = false
    },

    onDateDialogOk () {
      this.$refs.dateMenu.save(this.selectedDate)
      this.model = this.selectedDate
    },

    onDateClear () {
      this.model = null
    },

    formatDisplayDate (date) {
      if (!date) return ''

      return this.$dayjs(date).format(this.dateFormatDisplay)
    },

    formatValueDate (date) {
      if (!date) return ''

      return this.$dayjs(date).format('YYYY-MM-DD')
    },

    formatModelDate (date) {
      if (!date) return null

      return this.$dayjs(date).format(this.dateFormatModel)
    }
  }
}
</script>

<style scoped>

</style>
