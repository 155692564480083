import Vue from 'vue'
import Storage from 'vue-web-storage'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import App from '@/App'
import vuetify from '@/plugins/vuetify'
import { resetRouter, router } from '@/router'
import { i18n, setI18nLanguage } from '@/lang/lang'
import DataStorePlugin from '@/lib/data/dataStore/plugin/DataStorePlugin'
import EventBus from '@/lib/events/eventBus'
import Enums from '@/lib/enums/plugin'
import { themeUtils } from '@/lib/utils'
import DayjsPlugin from '@/lib/date/plugin'
import EnvMixin from '@/mixins/env'
import AuthMixin from '@/mixins/auth'
import LangMiddleware from '@/router/middleware/LangMiddleware'
import StoreRouteMiddleware from '@/router/middleware/StoreRouteMiddleware'
import RouteMiddleware from '@/lib/middleware/RouteMiddleware'
import AuthMiddleware from '@/router/middleware/AuthMiddleware'
import RouterReadyMiddleware from '@/router/middleware/RouterReadyMiddleware'
import Snotify, { SnotifyPosition } from '@/theme/default/components/common/vue-snotify'
import DataStore from '@/lib/data/dataStore/DataStore'
import VChart from 'vue-echarts'
import VueBlobJsonCsv from 'vue-blob-json-csv'
import '@mdi/font/css/materialdesignicons.css'
import '@openfonts/roboto_greek'
import './theme/default/components/common/vue-snotify/styles/synthesis.css'
import './App.css'
import { setupGoogleAnalytics } from '@/lib/services/GoogleAnalytics'
import { setupHotJar } from '@/lib/services/HotJar'

const LoginLayout = () => themeUtils.importThemeComponent('layouts/LoginLayout')
const DefaultLayout = () => themeUtils.importThemeComponent('layouts/DefaultLayout')

const initVue = () => {
  resetRouter()

  Vue.component('DefaultLayout', DefaultLayout)
  Vue.component('LoginLayout', LoginLayout)
  Vue.component('EChart', VChart)

  Vue.mixin(EnvMixin)
  Vue.mixin(AuthMixin)

  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(DayjsPlugin)
  Vue.use(Enums)
  Vue.use(VueMask)
  Vue.use(Storage, {
    prefix : `${process.env.VUE_APP_STORAGE_PREFIX}_`,
    drivers: ['session', 'local']
  })
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

  Vue.use(Snotify, {
    config: {
      timeout        : 3000,
      showProgressBar: true,
      pauseOnHover   : true,
      titleMaxLength : 28,
      bodyMaxLength  : 150,
      backdrop       : -1
    },
    options: {
      maxOnScreen  : 8,
      maxAtPosition: 8,
      maxHeight    : 500,
      newOnTop     : true,
      position     : SnotifyPosition.centerTop
    }
  })

  Vue.use(VueBlobJsonCsv)

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],

    data () {
      return {}
    },

    beforeMount () {
      setI18nLanguage(process.env.VUE_APP_I18N_LOCALE)
      this.$router.onReady(RouterReadyMiddleware)
      router.beforeEach(RouteMiddleware({
        LangMiddleware,
        AuthMiddleware
      }, true, this))
      router.afterEach(RouteMiddleware({ StoreRouteMiddleware }, true, this))
    },

    beforeCreate () {
    },
    created () {
      if (this.userIsAuthenticated) this.onInitAnalytics(this.user)
    },

    async mounted () {
      this.$bus.$on('app:logout', this.logout)
      this.$bus.$on('Auth:Login:Success', this.onInitAnalytics)
    },

    beforeDestroy () {
      this.$bus.$off('app:logout', this.logout)
      this.$bus.$off('Auth:Login:Success', this.onInitAnalytics)
    },

    methods: {
      onInitAnalytics (user) {
        if (!user?.userAnalyticsSettings?.enabled) return

        const defaultEnvironments = ['production', 'staging']

        setupGoogleAnalytics({
          key         : user?.userAnalyticsSettings?.gaId,
          enabled     : user?.userAnalyticsSettings?.gaEnabled,
          environments: defaultEnvironments
        }, router)

        // ?hjDebug=1
        // ?hjVerifyInstall=2926700
        setupHotJar({
          key         : user?.userAnalyticsSettings?.hjId,
          enabled     : user?.userAnalyticsSettings?.hjEnabled,
          environments: defaultEnvironments
        })
      }
    },
    i18n,
    router,
    vuetify,
    render: h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const init = (data = {}) => {
  DataStore.App.Config = { ...(DataStore?.App?.Config ?? {}), ...(data?.data ?? {}) }
  initVue()
}

init()
