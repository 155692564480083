export const ValidatorMessages = {
  el: {
    type: {
      alpha                    : 'Πρέπει να είναι μόνο γράμματα',
      alphaNum                 : 'Πρέπει να είναι αλφαριθμητικό',
      numeric                  : 'Πρέπει να είναι μόνο αριθμοί',
      array                    : 'Πρέπει να είναι σειρά αντικειμένων [{rule}]',
      object                   : 'Πρέπει να είναι αντικείμενο [{rule}]',
      function                 : 'Πρέπει να είναι λειτουργία [{rule}]',
      promise                  : 'Πρέπει να είναι [{rule}]',
      boolean                  : 'Πρέπει να είναι [{rule}]',
      number                   : 'Πρέπει να είναι έγκυρος αριθμός',
      integer                  : 'Πρέπει να είναι έγκυρος ακέραιος',
      float                    : 'Πρέπει να είναι έγκυρος δεκαδικός',
      string                   : 'Πρέπει να είναι κείμενο',
      url                      : 'Πρέπει να είναι έγκυρο {rule}',
      domain                   : 'Πρέπει να είναι έγκυρο {rule}',
      email                    : 'Πρέπει να είναι έγκυρο {rule}',
      date                     : 'Πρέπει να είναι έγκυρη ημερομηνία',
      mysqlDateTimeString      : 'Πρέπει να είναι έγκυρη ημερομηνία και ώρα της μορφής YYYY-MM-DD HH:mm:ss',
      mysqlDateOrDateTimeString: 'Πρέπει να είναι έγκυρη ημερομηνία ή ημερομηνία και ώρα της μορφής YYYY-MM-DD HH:mm:ss',
      mysqlDateString          : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής YYYY-MM-DD',
      mysqlTimeString          : 'Πρέπει να είναι έγκυρη ώρα της μορφής HH:mm:ss',
      timeString               : 'Πρέπει να είναι έγκυρη ώρα της μορφής HH:mm',
      dateStringDash           : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής DD-MM-YYYY',
      dateStringSlash          : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής DD/MM/YYYY',
      undefined                : 'Πρέπει να είναι [{rule}]'
    },
    required     : 'Το πεδίο είναι υποχρεωτικό',
    requiredIf   : 'Το πεδίο είναι υποχρεωτικό',
    requiredIfNot: 'Το πεδίο είναι υποχρεωτικό',
    regexp       : 'Μη αποδεκτή τιμή',
    min          : 'Πρέπει να είναι από {rule}',
    max          : 'Πρέπει να είναι έως {rule}',
    between      : 'Πρέπει να είναι από {rule} έως {rule}',
    minLen       : 'Πρέπει να είναι από {rule} χαρακτήρες',
    maxLen       : 'Πρέπει να είναι έως {rule} χαρακτήρες',
    betweenLen   : 'Πρέπει να είναι από {rule} έως {rule}',
    length       : 'Πρέπει να είναι {rule} χαρακτήρες',
    equals       : 'Πρέπει να είναι ίδιο με το πεδίο {rule}',
    is           : 'Πρέπει να είναι {rule}',
    isNot        : 'Δεν πρέπει να είναι {rule}',
    isIn         : 'Πρέπει να είναι ένα από {rule}',
    dateFormat   : 'Πρέπει να είναι έγκυρη ημερομηνία της μορφής {rule}',
    timeFormat   : 'Πρέπει να είναι έγκυρη ώρα της μορφής {rule}',
    undefined    : 'Γενικό λάθος'
  },
  en: {
    type: {
      alpha                    : 'Must be only letters',
      alphaNum                 : 'Must be alphanumeric',
      numeric                  : 'Must be only numbers',
      array                    : 'Must be a valid {rule}',
      object                   : 'Must be a valid {rule}',
      function                 : 'Must be a valid {rule}',
      promise                  : 'Must be a valid {rule}',
      boolean                  : 'Must be a valid {rule}',
      number                   : 'Must be a valid {rule}',
      integer                  : 'Must be a valid {rule}',
      float                    : 'Must be a valid {rule}',
      string                   : 'Must be a valid {rule}',
      url                      : 'Must be a valid {rule}',
      domain                   : 'Must be a valid {rule}',
      email                    : 'Must be a valid {rule}',
      date                     : 'Must be a valid {rule}',
      mysqlDateTimeString      : 'Must be a valid date and time formatted as YYYY-MM-DD HH:mm:ss',
      mysqlDateOrDateTimeString: 'Must be a valid date or date and time formatted as YYYY-MM-DD HH:mm:ss',
      mysqlDateString          : 'Must be a valid date formatted as YYYY-MM-DD',
      mysqlTimeString          : 'Must be a valid time formatted as HH:mm:ss or HH:mm',
      dateStringDash           : 'Must be a valid date formatted as DD-MM-YYYY',
      dateStringSlash          : 'Must be a valid date formatted as DD/MM/YYYY',
      undefined                : 'This is not a valid [{rule}]'
    },
    required     : 'This field is required',
    requiredIf   : 'This field is required',
    requiredIfNot: 'This field is required',
    regexp       : 'This is not a valid value',
    min          : 'Minimum value is {rule}',
    max          : 'Maximum value is {rule}',
    between      : 'Must be Between {rule} and {rule}',
    minLen       : 'Minimum length is {rule}',
    maxLen       : 'Maximum length is {rule}',
    betweenLen   : 'Length must be Between {rule} and {rule}',
    length       : 'Length must be {rule}',
    equals       : 'Must be equal to the field {rule}',
    is           : 'Must be {rule}',
    isNot        : 'Must not be {rule}',
    isIn         : 'Must be one of {rule}',
    dateFormat   : 'Must be a valid date formatted as {rule}',
    timeFormat   : 'Must be a valid time formatted as {rule}',
    undefined    : 'General error'
  }
}

export default ValidatorMessages
