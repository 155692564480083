export default {

  Title: 'Χρήστες',

  Login: {
    Title: 'Welcome Back!',
    Email: {
      Label      : 'Email',
      Placeholder: 'Enter your email address'
    },
    Password: {
      Label      : 'Password',
      Placeholder: 'Enter password'
    },
    Error: {
      Auth      : 'Wrong username or password',
      Connection: 'Connection to server failed'
    }
  },

  ForgotPassword: {
    Title   : 'Forgot Password',
    SubTitle: 'Please enter your email and you will receive instructions how to reset your password',
    Email   : {
      Label      : 'Email',
      Placeholder: 'Enter your email address'
    },
    Success: {
      Message: 'Shortly you will receive an email with instructions on how to reset your password.'
    }
  },

  ResetPassword: {
    Title      : 'Reset Password',
    SubTitle   : 'To reset your password, please enter and confirm your new password',
    NewPassword: {
      Label      : 'New Password',
      Placeholder: ''
    },
    ConfirmPassword: {
      Label      : 'Confirm Password',
      Placeholder: ''
    },
    Success: {
      Message: 'Your password has changed successfully.'
    }
  },

  Logout: {
    Title: 'Logout'
  }

}
