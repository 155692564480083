import { EventBus } from '@/lib/events/eventBus'

const IS_DEV = process.env.NODE_ENV === 'development'

const DataEventService = {
  Emit: (Event, Payload) => {
    // eslint-disable-next-line no-console
    IS_DEV && console.info('%c%s%c%s', 'color: MediumPurple', 'DataEventService.', 'color: DodgerBlue', 'Emit:', Payload || '')
    EventBus.$emit(Event, Payload)
  },

  On: (Event, Callback) => {
    // eslint-disable-next-line no-console
    IS_DEV && console.info('%c%s%c%s', 'color: MediumPurple', 'DataEventService.', 'color: SpringGreen', 'On:', Callback?.name || '')
    EventBus.$on(Event, Callback)
  },

  Off: (Event, Callback) => {
    // eslint-disable-next-line no-console
    IS_DEV && console.info('%c%s%c%s', 'color: MediumPurple', 'DataEventService.', 'color: OrangeRed', 'Off:', Callback?.name || '')
    EventBus.$off(Event, Callback)
  }
}

export default DataEventService
