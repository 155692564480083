import BaseResource                from '@/lib/data/resource/BaseResource'
import HedgingHistoricalWindowEnum from '@/api/enums/HedgingHistoricalWindowEnum'
import HedgingModeEnum             from '@/api/enums/HedgingModeEnum'
import { toDecimalMark }           from '@/lib/utils/helper'
import i18n                        from '@/lang/lang'
import AssetEnum                   from '@/api/enums/AssetEnum'
import { sum }                     from '@/lib/utils/array'

export default class HedgingPolicyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.accountId = payload.accountId ?? ''
    this.policyId = payload.policyId ?? null
    this.activeAnalysisUsed = payload.activeAnalysisUsed ?? true
    this.activeForwards = payload.activeForwards ?? true
    this.activeOptions = payload.activeOptions ?? false
    this.activeRiskReversal = payload.activeRiskReversal ?? false
    this.name = payload.name ?? ''
    this.mode = payload.mode ?? HedgingModeEnum.MonteCarlo
    this.baseCurrencyCode = payload.baseCurrencyCode ?? ''
    this.exposures = payload.exposures ?? []
    this.marketRatesUsed = payload.marketRatesUsed ?? true
    this.carry = payload.carry ?? []
    this.strikeMoneynessOption = payload.strikeMoneynessOption ?? ''
    this.strikeMoneynessRiskReversal = payload.strikeMoneynessRiskReversal ?? ''
    this.historicalWindow = payload.historicalWindow ?? HedgingHistoricalWindowEnum.All
  }

  /* PROPERTIES */
  get exposuresList () {
    const retVal = []

    this.exposures.forEach(exposure => {
      const asset = exposure?.selectedItem?.pair?.base?.displayName || ''
      const notional = exposure?.selectedItem?.pair?.base?.type === AssetEnum.CURRENCY
        ? `${ toDecimalMark(parseFloat(exposure.notional), 0) }`
        : `${ toDecimalMark(parseFloat(exposure.notional), 0) } ${ exposure?.selectedItem?.quotation?.displayUnit ?? '' }`
      const notionalClass = parseFloat(exposure.notional) < 0 ? 'error--text' : ''
      const notionalBase = exposure?.selectedItem?.pair?.base?.type === AssetEnum.CURRENCY
        ? `${ exposure?.selectedItem?.pair?.quote?.displaySymbol } ${ this.activeAnalysisUsed ? toDecimalMark(exposure?.selectedItem?.price || 0, 0) : toDecimalMark(parseFloat(exposure.notional) * (exposure?.selectedItem?.price || 0), 0) }`
        : `${ exposure?.selectedItem?.pair?.quote?.displaySymbol } ${ this.activeAnalysisUsed ? toDecimalMark(exposure?.selectedItem?.price || 0, 0) : toDecimalMark(parseFloat(exposure.notional) * (exposure?.selectedItem?.price || 0), 0) }`
      const notionalBaseClass = this.activeAnalysisUsed ? parseFloat(exposure?.selectedItem?.price) < 0 ? 'error--text' : '' : parseFloat(exposure.notional) * parseFloat(exposure?.selectedItem?.price) < 0 ? 'error--text' : ''
      const seasonality = exposure.userProvidedSeasonality ? i18n.t('Common.Button.Yes') : i18n.t('Common.Button.No')
      const label = exposure?.label || ''
      const name = exposure?.name || ''

      const showExposureName = this.exposures.filter(e => e.assetCode === exposure.assetCode).length > 1

      retVal.push({
        Exposure       : exposure,
        Excluded       : exposure.userProvidedSeasonality && sum(exposure.seasonality) === 0,
        ExcludedMessage: 'Asset will be excluded as net exposure is zero.',
        Items          : [
          {
            Title: i18n.t('Hedging.Edit.Form.Fields.Exposures.Asset'),
            Value: asset
          },
          {
            Title     : i18n.t('Hedging.Edit.Form.Fields.Exposures.AnnualExposure'),
            Value     : notional,
            ValueClass: notionalClass
          },
          {
            Title     : i18n.t('Hedging.Edit.Form.Fields.Exposures.AnnualExposureInBaseCurrency'),
            Value     : notionalBase,
            ValueClass: notionalBaseClass
          },
          {
            Title: i18n.t('Hedging.Edit.Form.Fields.Exposures.Seasonality'),
            Value: seasonality
          },
          {
            Title: i18n.t('Hedging.AddExposure.Form.Fields.Label.Label'),
            Value: label
          },
          ...[this.activeAnalysisUsed && showExposureName && {
            Title: i18n.t('Risks.AddExposure.Form.Fields.Code.Label'),
            Value: name
          }]
        ]
      })
    })

    return retVal
  }

  /* METHODS */
}
