<template>
  <v-navigation-drawer
    v-model="navigationDrawerVisible"
    :expand-on-hover="navigationDrawerMini"
    :mini-variant="navigationDrawerMini"
    app
    color="synthesis"
    dark
    floating
    permanent
    width="260"
  >
    <template #prepend>
      <v-list
        dark
        dense
      >
        <v-list-item class="px-4">
          <img
            class="my-4"
            height="28"
            src="/img/logo/logo-light.png"
          >

          <v-spacer />

          <v-btn
            class="pa-0"
            icon
            plain
            @click.stop="toggleNavigationDrawerMini"
          >
            <v-icon v-if="!navigationDrawerMini">
              mdi-chevron-double-left
            </v-icon>

            <v-icon
              v-else
              size="20"
            >
              mdi-pin
            </v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </template>

    <v-list dense>
      <template v-for="(item, i) in topItems">
        <v-row
          v-if="item.heading"
          :key="`heading-${i}`"
          align="center"
        >
          <v-col
            class="text-left py-0 my-0"
            cols="6"
          >
            <v-subheader>
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
            class="text-right py-0 my-0"
            cols="6"
          >
            <v-btn
              v-if="item.text && item.route"
              :exact="item.route.exact"
              :to="item.route"
              small
              text
            >
              {{ item.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider
          v-else-if="item.divider"
          :key="`divider-${i}`"
          class="my-2"
        />

        <v-list-group
          v-else-if="item.children"
          :key="i"
          v-model="item.model"
          no-action
        >
          <template #appendIcon>
            <div style="opacity: 0.6;">
              <v-icon color="#ffffff">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>

          <template #activator>
            <v-list-item
              :class="{'active-list-item': item.model}"
              active-class="active-list-item"
              class="pl-0 normal-list-item"
              style="height: 40px;"
            >
              <template #default="{active}">
                <v-list-item-action
                  v-if="item.icon"
                  class="mr-5"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-action
                  v-else-if="item.synthesisIcon"
                  class="mr-5"
                >
                  <synthesis-icon
                    :active="active || item.model"
                    :name="item.synthesisIcon"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>

          <v-list-item
            v-for="(child, c) in item.children"
            :key="`child-${i}-${c}`"
            :exact="child.route.exact"
            :to="child.route"
            active-class="active-list-item"
            class="normal-list-item"
            style="padding-left: 70px;"
          >
            <template #default="{active}">
              <v-list-item-action
                v-if="child.icon"
                class="mr-5"
              >
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-action
                v-else-if="child.synthesisIcon"
                class="mr-5"
              >
                <synthesis-icon
                  :active="active || child.model"
                  :name="child.synthesisIcon"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :key="`item-${i}`"
          :exact="item.route.exact"
          :to="item.route"
          active-class="active-list-item"
          class="normal-list-item"
        >
          <template #default="{active}">
            <v-list-item-action
              v-if="item.icon"
              class="mr-5"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-action
              v-else-if="item.synthesisIcon"
              class="mr-5"
            >
              <synthesis-icon
                :active="active || item.model"
                :name="item.synthesisIcon"
              />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-list>

    <template #append>
      <v-list dense>
        <template v-for="(item, i) in bottomItems">
          <v-row
            v-if="item.heading"
            :key="`heading-${i}`"
            align="center"
          >
            <v-col
              class="text-left py-0 my-0"
              cols="6"
            >
              <v-subheader>
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col
              class="text-right py-0 my-0"
              cols="6"
            >
              <v-btn
                v-if="item.text && item.route"
                :exact="item.route.exact"
                :to="item.route"
                small
                text
              >
                {{ item.text }}
              </v-btn>
            </v-col>
          </v-row>

          <v-divider
            v-else-if="item.divider"
            :key="`divider-${i}`"
            class="my-2"
          />

          <v-list-group
            v-else-if="item.children"
            :key="i"
            v-model="item.model"
            no-action
          >
            <template #appendIcon>
              <div style="opacity: 0.6;">
                <v-icon color="#ffffff">
                  mdi-chevron-down
                </v-icon>
              </div>
            </template>

            <template #activator>
              <v-list-item
                :class="{'active-list-item': item.model}"
                active-class="active-list-item"
                class="pl-0 normal-list-item"
                style="height: 40px;"
              >
                <template #default="{active}">
                  <v-list-item-action
                    v-if="item.icon"
                    class="mr-5"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-action
                    v-else-if="item.synthesisIcon"
                    class="mr-5"
                  >
                    <synthesis-icon
                      :active="active || item.model"
                      :name="item.synthesisIcon"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>

            <v-list-item
              v-for="(child, c) in item.children"
              :key="`child-${i}-${c}`"
              :exact="child.route.exact"
              :to="child.route"
              active-class="active-list-item"
              class="normal-list-item"
              style="padding-left: 70px;"
            >
              <template #default="{active}">
                <v-list-item-action
                  v-if="child.icon"
                  class="mr-5"
                >
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-action
                  v-else-if="child.synthesisIcon"
                  class="mr-5"
                >
                  <synthesis-icon
                    :active="active || child.model"
                    :name="child.synthesisIcon"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ child.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :exact="item.route.exact"
            :to="item.route"
            active-class="active-list-item"
            class="normal-list-item"
          >
            <template #default="{active}">
              <v-list-item-action
                v-if="item.icon"
                class="mr-5"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-action
                v-else-if="item.synthesisIcon"
                class="mr-5"
              >
                <synthesis-icon
                  :active="active || item.model"
                  :name="item.synthesisIcon"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-list>

      <v-divider />

      <v-menu
        class="synthesis-card-border"
        max-width="175"
        nudge-right="125"
        nudge-top="15"
        rounded="sm"
        top
      >
        <template #activator="{on, attrs}">
          <v-list
            dark
            dense
          >
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-avatar
                color="orange"
                size="25"
              >
                <span class="text-size-16 font-weight-bold">
                  {{ userAvatarLetter }}
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="normal-list-item">
                  {{ userCompanyName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <v-list
          class="pa-0 ma-0"
          dense
        >
          <template v-for="(accountItem, accountIndex) in accountItems">
            <v-list-item
              :key="`account-item-${accountIndex}`"
              :to="accountItem.route"
            >
              <v-list-item-title class="text-size-14">
                {{ accountItem.text }}
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item @click="onLogoutBtnClick">
            <v-list-item-title class="text-size-14">
              {{ $t('Common.Button.Logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-navigation-drawer>
</template>

<script>

import { HmacMD5 }               from '@/lib/crypto/crypto'
import appNavigationDrawerCommon from '@/theme/default/components/app/mixins/appNavigationDrawerCommon'
import SynthesisIcon             from '@/theme/default/components/common/icons/SynthesisIcon'

const navDrawerMiniKey = HmacMD5.hash('nav-drawer-mini')

export default {
  name      : 'AppNavigationDrawer',
  components: { SynthesisIcon },
  directives: {},
  mixins    : [appNavigationDrawerCommon],
  props     : {
    drawerMini: {
      type    : Boolean,
      required: true
    }
  },
  dataStore: {
    appToolbarExtended: 'App.Config.AppToolbarExtended'
  },
  data () {
    return {}
  },
  computed: {
    navigationDrawerMini: {
      get () {
        return this.drawerMini
      },
      set (val) {
        this.$emit('update:drawerMini', val)
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.initNavigationDrawerMini()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    initNavigationDrawerMini () {
      this.navigationDrawerMini = this.$localStorage.get(navDrawerMiniKey, this.drawerMini)

      // Set to datastore
      this.appToolbarExtended = this.navigationDrawerMini
    },

    toggleNavigationDrawerMini () {
      this.$localStorage.set(navDrawerMiniKey, !this.navigationDrawerMini)
      this.navigationDrawerMini = !this.navigationDrawerMini

      // Set to datastore
      this.appToolbarExtended = this.navigationDrawerMini
    }
  }
}
</script>

<style scoped>
/deep/ .v-list-item__title {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.1rem !important;
}

/deep/ .normal-list-item {
  color: #FFFFFF;
  opacity: 0.6;
}

/deep/ .active-list-item {
  color: #FFFFFF;
  opacity: 1;
}
</style>
