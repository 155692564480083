<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-btn
              v-if="model.selectedItem && !isEditMode"
              class="pa-2"
              text
              @click="onBackToListClick"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              {{ $t('Common.Button.Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <template v-if="!model.selectedItem">
              <v-icon
                color="orange"
                size="24"
              >
                mdi-poll
              </v-icon>

              <div class="mt-4">
                <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                  {{ $t(`${ componentLang }.AddTrigger.Title`) }}
                  <infotip
                    v-if="$t(`${ componentLang }.AddTrigger.InfoTip`)"
                    icon-style="margin-top: -26px;"
                  >
                    {{ $t(`${ componentLang }.AddTrigger.InfoTip`) }}
                  </infotip>
                </div>
                <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                  {{ subtitle }}
                </div>
              </div>

              <div class="mt-4">
                <v-text-field
                  v-model="searchTerms"
                  :placeholder="$t('Common.Search.Title')"
                  append-icon="mdi-magnify"
                  outlined
                  single-line
                />
              </div>
            </template>

            <template v-else>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                      {{ selectedItemBaseDisplayName }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                      <template
                        v-if="isPortfolioMTM && !!selectedItemTags"
                      >
                        <v-chip
                          v-for="(tag, index) in selectedItemTags"
                          :key="`tag-${index}`"
                          :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
                          class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
                          label
                          x-small
                        >
                          {{ tag }}
                        </v-chip>
                      </template>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action-text class="text-right">
                    <v-chip
                      class="font-weight-medium"
                      color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                      label
                      small
                    >
                      {{ selectedItemValue }}
                    </v-chip>
                    <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                      {{ model.selectedItem.description }}
                    </div>
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </template>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          :class="[{'pa-0': !model.selectedItem}]"
          class="pt-5"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <template v-if="!model.selectedItem">
              <v-list>
                <template v-for="(item, index) in items">
                  <v-list-item
                    :key="`list-item-${index}`"
                    :class="[{'grey lighten-4': isAssetDisabled(item)}]"
                    :disabled="isAssetDisabled(item)"
                    class="px-6 py-1"
                    @click="onListItemClick(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="item.title"
                        class="synthesis-text text-size-12 synthesis-ui-grey-01--text font-weight-medium"
                      >
                        {{ item.title }}
                      </v-list-item-title>

                      <v-list-item-title
                        :class="isAssetDisabled(item) ? 'synthesis-ui-grey-01--text' : 'synthesis-brand-dark-blue--text'"
                        class="synthesis-text text-size-16 font-weight-medium"
                      >
                        <template
                          v-if="isPortfolioMTM && !!getItemBaseDisplayName(item)"
                        >
                          <v-chip
                            v-for="(tag, index) in getItemBaseDisplayName(item)"
                            :key="`tag-${index}`"
                            :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
                            class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
                            label
                            x-small
                          >
                            {{ tag }}
                          </v-chip>
                        </template>
                        <template v-else>
                          {{ getItemBaseDisplayName(item) }}
                        </template>
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium"
                      >
                        {{ getItemQuoteDisplayName(item) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action-text class="text-right">
                      <v-chip
                        :color="isAssetDisabled(item) ? 'synthesis-ui-grey-07 synthesis-ui-grey-00--text' : 'synthesis-ui-green-07 synthesis-ui-green-00--text'"
                        class="font-weight-medium"
                        label
                        small
                      >
                        {{ getItemValue(item) }}
                      </v-chip>
                      <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                        {{ getItemDescription(item) }}
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-divider :key="`list-item-divider-${index}`" />
                </template>
              </v-list>
            </template>

            <template v-else>
              <v-container
                fluid
                pa-0
              >
                <v-row>
                  <v-col cols="12">
                    <base-e-chart-line-area
                      v-if="tradeHistoricalMtmChartData || historicalDailyQuotesChartData || portfolioHistoricalMtmChartData"
                      :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-purple-06')"
                      :axis-pointer-line-style-width="2"
                      :axis-x-label-padding="[0, 2, 0, 0]"
                      :axis-x-label-value-date-format="dateValueFormat"
                      :axis-y-label-inside="false"
                      :axis-y-label-margin="10"
                      :axis-y-max-value="chartValuesYMinMax.max"
                      :axis-y-min-value="chartValuesYMinMax.min"
                      :axis-y-split-line-color="themeColorRGBA('synthesis-ui-grey-03', 1)"
                      :axis-y-split-line-dash-offset="4"
                      :axis-y-split-line-opacity="1"
                      :axis-y-split-line-type="[8, 8]"
                      :axis-y-tick-prefix="selectedItemQuoteSymbol"
                      :chart-animation-duration="800"
                      :chart-area-fill-color="[themeColorRGBA('synthesis-ui-purple-01', 0.4), themeColorRGBA('synthesis-ui-purple-01', 0.03)]"
                      :chart-data-options="chartValues"
                      :chart-grid-margin="{left: 10, right: 0, top: 10, bottom: 10, containLabel: true}"
                      :chart-height="400"
                      :chart-line-color="themeColorRGBA('synthesis-ui-purple-01')"
                      :chart-line-show-symbol="false"
                      :chart-line-symbol-offset="[0, 0]"
                      :chart-line-symbol-size="10"
                      :chart-scale="true"
                      :mark-line-animation-duration="300"
                      :mark-line-horizontal="true"
                      :mark-line-label-background-color="themeColorRGBA(model.conditionType === AlertTriggerConditionThresholdTypeEnum.UpperLimit ? 'synthesis-ui-green-01' : 'synthesis-ui-red-01')"
                      :mark-line-label-show="true"
                      :mark-line-label-suffix="markLineLabelSuffix"
                      :mark-line-line-style-color="themeColorRGBA(model.conditionType === AlertTriggerConditionThresholdTypeEnum.UpperLimit ? 'synthesis-ui-green-01' : 'synthesis-ui-red-01')"
                      :mark-line-value="model.threshold"
                      :mark-line-value-label="chartMarkLineValue"
                      :mark-line-value-label-format="markLineValueLabelFormat"
                      axis-x-label-align="right"
                      chart-line-symbol="circle"
                    />
                    <div
                      v-else
                      style="min-height: 400px"
                    />
                  </v-col>

                  <v-col
                    class="text-right"
                    cols="12"
                  >
                    <div class="d-inline-flex">
                      <asset-button-group
                        :disabled="!tradeHistoricalMtmChartData && !historicalDailyQuotesChartData && !portfolioHistoricalMtmChartData"
                        :height="26"
                        :items="periodItems"
                        :selected.sync="selectedPeriod"
                        disable-dropdown-on-mobile
                      />
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddTrigger.Form.Fields.Threshold.Label`) }}
                    </label>

                    <v-text-field-currency-directive
                      v-model="model.threshold"
                      :class="{'negative-value-input-warning': isNegativeValue(model.threshold), 'asset-symbol-prefix-suffix': isPositionMTM}"
                      :options="{precision: {min: 0, max: 5}, valueScaling: undefined, locale: $i18n.code, currency: selectedItemCurrencySymbol}"
                      :placeholder="$t(`${componentLang}.AddTrigger.Form.Fields.Threshold.Placeholder`)"
                      :prefix="selectedItemCurrencyPrefix"
                      :suffix="selectedItemCurrencySuffix"
                      hide-details="auto"
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-form>
        </v-card-text>

        <template v-if="model.selectedItem">
          <v-divider />

          <v-card-actions class="py-3">
            <v-btn
              :disabled="isFormSaving || !isModelValid"
              :loading="isFormSaving"
              block
              class="action-button white--text"
              color="synthesis-ui-green-02"
              depressed
              large
              @click="saveFromData"
            >
              {{ isEditMode ? $t(`${ componentLang }.AddTrigger.Form.Button.Update.Title`) : $t(`${ componentLang }.AddTrigger.Form.Button.Save.Title`) }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                                                                from '@/mixins/dialog/dialogData'
import dialogVisible                                                             from '@/mixins/dialog/dialogVisible'
import API                                                                       from '@/api/Api'
import { clone, toDecimalMark, toDecimalMarkRange, toNumberFormatNotationShort } from '@/lib/utils/helper'
import InstrumentTypeEnum                                                        from '@/api/enums/InstrumentTypeEnum'
import VTextFieldCurrencyDirective                                               from '@/theme/default/components/form/VTextFieldCurrencyDirective'
import TriggerInputModel                                                         from '@/api/models/alerts/TriggerInputModel'
import AlertTypeEnum                                                             from '@/api/enums/AlertTypeEnum'
import chartsColorMixin                                                          from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BaseEChartLineArea                                                        from '@/theme/default/components/charts/echarts/BaseEChartLineArea'
import AlertTriggerConditionThresholdTypeEnum                                    from '@/api/enums/AlertTriggerConditionThresholdTypeEnum'
import AssetButtonGroup                                                          from '@/theme/default/components/common/AssetButtonGroup'
import { collect, first }                                                        from '@/lib/utils/array'
import AddTriggerAssetResource                                                   from '@/api/resources/alerts/AddTriggerAssetResource'
import { UUIDGeneratorBrowser }                                                  from '@/lib/utils/browser'
import AssetEnum                                                                 from '@/api/enums/AssetEnum'
import Infotip                                                                   from '@/theme/default/components/common/Infotip'
import FeatureFilterEnum                                                         from '@/api/enums/FeatureFilterEnum'

export default {
  name      : 'AddAlertTriggerDialog',
  components: {
    Infotip,
    AssetButtonGroup,
    BaseEChartLineArea,
    VTextFieldCurrencyDirective
  },
  directives: {},
  mixins    : [dialogVisible, dialogData, chartsColorMixin],
  props     : {
    componentLang: {
      type   : String,
      default: 'Alerts'
    },

    baseCurrencyCode: {
      type   : String,
      default: ''
    },

    triggersList: {
      type   : Array,
      default: () => []
    },

    alertType: {
      type   : String,
      default: AlertTypeEnum.AssetPrice
    }
  },
  dataStore: {
    SupportedAssets: 'Assets.All'
  },
  data () {
    return {
      assets                         : [],
      trades                         : [],
      tradeItems                     : [],
      positions                      : [],
      searchTerms                    : '',
      searchDebounceTimeout          : null,
      selectedBaseCurrency           : null,
      tradeHistoricalMtmChartData    : null,
      portfolioHistoricalMtmChartData: null,
      historicalDailyQuotesChartData : null,
      selectedPeriod                 : this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      dateValueFormat                : 'D MMM',
      periodItems                    : this.$t('Common.Date.PeriodItems')
    }
  },
  computed: {
    isAssetPrice () {
      return this.alertType === AlertTypeEnum.AssetPrice
    },
    isPositionMTM () {
      return this.alertType === AlertTypeEnum.PositionMTM
    },
    isPortfolioMTM () {
      return this.alertType === AlertTypeEnum.PortfolioMTM
    },
    isEditMode () {
      return this.isEditTriggerNewAlertMode || this.isEditTriggerEditAlertMode
    },
    isEditTriggerNewAlertMode () {
      return !!this.item && this.isNewAlertMode
    },
    isEditTriggerEditAlertMode () {
      return !!this.item && this.isEditAlertMode
    },
    isNewAlertMode () {
      return this.$route.name === 'AlertNew'
    },
    isEditAlertMode () {
      return this.$route.name === 'AlertEdit'
    },

    baseCurrency: {
      get () {
        return this.baseCurrencyCode
      },

      set (value) {
        this.$emit('update:baseCurrencyCode', value)
      }
    },

    subtitle () {
      if (this.isAssetPrice) {
        return this.$i18n.t(`${ this.componentLang }.AddTrigger.Subtitle`)
      } else if (this.isPositionMTM) {
        return this.$i18n.t(`${ this.componentLang }.AddTrigger.SubtitlePositionMTM`)
      } else {
        return this.$i18n.t(`${ this.componentLang }.AddTrigger.SubtitlePortfolioMTM`)
      }
    },

    items () {
      if (this.isAssetPrice) {
        return this.assets
      } else if (this.isPositionMTM) {
        return this.tradeItems
      } else {
        return this.positions
      }
    },

    selectedItemBaseDisplayName () {
      if (this.isAssetPrice ||
        this.isPositionMTM) {
        return this.model.selectedItem.pair.base.displayName || ''
      } else {
        return this.model.selectedItem.title || ''
      }
    },

    selectedItemTags () {
      return (this.isPortfolioMTM) ? this.model.selectedItem.tags : []
    },

    selectedItemValue () {
      if (this.isAssetPrice) {
        return this.model.selectedItem.value
      } else if (this.isPositionMTM) {
        return toNumberFormatNotationShort(this.model.selectedItem.value)
      } else {
        return this.baseCurrencySymbolSingle(this.model.selectedItem?.baseCurrency?.displaySymbol || '') + toNumberFormatNotationShort(this.model.selectedItem.value)
      }
    },

    selectedItemQuoteSymbol () {
      if (this.isAssetPrice ||
        this.isPositionMTM) {
        return this.model.selectedItem?.pair?.quote?.displaySymbol || ''
      } else {
        return this.model.selectedItem?.baseCurrency?.displaySymbol || ''
      }
    },

    selectedItemCurrencySymbol () {
      if (this.isAssetPrice ||
        this.isPositionMTM) {
        return this.model.selectedItem?.pair?.quote?.symbol || ''
      } else {
        return this.model.selectedItem?.baseCurrency?.symbol || ''
      }
    },

    selectedItemCurrencyPrefix () {
      if (this.isAssetPrice) {
        return ''
      } else if (this.isPositionMTM) {
        return (this.model.selectedItem.pair.quote.type === AssetEnum.CURRENCY) ? this.model.selectedItem.pair.quote.displayName : ''
      } else {
        return ''
      }
    },

    selectedItemCurrencySuffix () {
      if (this.isAssetPrice) {
        return ''
      } else if (this.isPositionMTM) {
        return (this.model.selectedItem.pair.quote.type === AssetEnum.COMMODITY) ? this.model.selectedItem.pair.quote.displayName : ''
      } else {
        return ''
      }
    },

    markLineValueLabelFormat () {
      return this.isAssetPrice ? 'percent' : 'number'
    },

    markLineLabelSuffix () {
      return this.isAssetPrice ? this.chartMarkLineValue === 0 ? '0%' : '%' : ''
    },

    chartMarkLineValue () {
      const threshold = this.model?.threshold || 0
      const assetValue = this.model?.selectedItem?.value || 0
      const percent = ((threshold / assetValue) * 100) - 100
      return this.isAssetPrice ? percent : threshold
    },

    chartValuesYMinMax () {
      const threshold = this.model?.threshold || 0
      return {
        min: Math.min(threshold, ...first(this.chartValues).y),
        max: Math.max(threshold, ...first(this.chartValues).y)
      }
    },

    chartValues () {
      if (this.isAssetPrice) {
        return this.historicalDailyQuotesChartValues
      } else if (this.isPositionMTM) {
        return this.tradeHistoricalMtmChartValues
      } else {
        return this.positionTradeHistoricalMtmChartValues
      }
    },

    tradeHistoricalMtmChartValues () {
      return [{
        x: this.tradeHistoricalMtmChartData?.dates || [],
        y: this.tradeHistoricalMtmChartData?.prices || []
      }]
    },

    positionTradeHistoricalMtmChartValues () {
      return [{
        x: this.portfolioHistoricalMtmChartData?.dates || [],
        y: this.portfolioHistoricalMtmChartData?.prices || []
      }]
    },

    historicalDailyQuotesChartValues () {
      return [{
        x: this.historicalDailyQuotesChartData?.dates || [],
        y: this.historicalDailyQuotesChartData?.prices || []
      }]
    }
  },
  enums: {
    AlertTriggerConditionThresholdTypeEnum,
    InstrumentTypeEnum,
    AlertTypeEnum,
    AssetEnum
  },
  dataModel: TriggerInputModel,
  watch    : {
    alertType () {
      this.selectedBaseCurrency = null
      this.getData()
    },

    'model.tradeId': function (newVal) {
      if (newVal) {
        this.getTradeHistoricalMtm()
      }
    },

    'model.portfolioId': function (newVal) {
      if (newVal) {
        this.getPortfolioHistoricalMtm()
      }
    },

    selectedPeriod (val) {
      this.setDateValueFormat(val)

      if (this.isAssetPrice) {
        this.getHistoricalDailyQuotes()
      } else if (this.isPositionMTM) {
        this.getTradeHistoricalMtm()
      } else {
        this.getPortfolioHistoricalMtm()
      }
    },

    'model.selectedItem': function (newVal) {
      if (!newVal) return

      if (this.isAssetPrice) {
        this.getHistoricalDailyQuotes()
      } else if (this.isPositionMTM) {
        this.getTradeHistoricalMtm()
      } else {
        this.getPortfolioHistoricalMtm()
      }
    },

    baseCurrency: {
      handler () {
        this.getData()
      },
      immediate: true
    },

    searchTerms (searchTerm) {
      if (this.isAssetPrice) {
        this.searchAssets(searchTerm)
      } else if (this.isPositionMTM) {
        this.searchTrades(searchTerm)
      } else {
        this.searchPositions(searchTerm)
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
        this.model.selectedItem = null
        this.model.assetCode = ''
        this.model.tradeId = ''
        this.tradeHistoricalMtmChartData = null
        this.historicalDailyQuotesChartData = null
        const tmpId = UUIDGeneratorBrowser()
        this.$nextTick(() => {
          if (this.item && !this.item.id) this.item.id = tmpId
          if (this.model && !this.model.id) this.model.id = tmpId
          if (this.isEditAlertMode && this.isPositionMTM) {
            this.selectedBaseCurrency = this.baseCurrency
          }
        })
      } else {
        this.searchTerms = ''
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toNumberFormatNotationShort,
    toDecimalMark,

    setDateValueFormat (value) {
      const o = clone(this.periodItems)
      const index = o.findIndex(item => item.Value === value)
      if (index > -1) {
        const period = o[index]
        switch (period.Title) {
          case this.$t('Common.Date.Periods.Day'):
            this.dateValueFormat = 'H'
            break
          case this.$t('Common.Date.Periods.Week'):
            this.dateValueFormat = 'ddd D'
            break
          case this.$t('Common.Date.Periods.Month'):
            this.dateValueFormat = 'D MMM'
            break
          case this.$t('Common.Date.Periods.Year'):
          case this.$t('Common.Date.Periods.All'):
            this.dateValueFormat = 'MMM YY'
        }
      }
    },

    /**
     * getData
     *
     * Get latest quotes from api
     */
    getData () {
      if (this.isAssetPrice) {
        this.getLatestQuotes()
      } else if (this.isPositionMTM) {
        this.getTrades()
      } else {
        this.getPositions()
      }
    },

    /**
     * searchAssets
     *
     * Search in supported assets locally, create pairs
     * for the result and request latest quotes with
     * 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchAssets (searchTerm) {
      clearTimeout(this.searchDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      if (!searchTerm) {
        this.getLatestQuotes()
        return
      }

      this.searchDebounceTimeout = setTimeout(() => {
        const results = this.SupportedAssets.filter(asset => asset.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.symbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.displaySymbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm))
        const resultPairs = []

        results.forEach(asset => {
          if (this.baseCurrency && asset.symbol !== this.baseCurrency) {
            resultPairs.push({
              baseSymbol : asset.symbol,
              quoteSymbol: this.baseCurrency
            })
          }
        })

        this.getLatestQuotes(resultPairs)
      }, 500)
    },

    /**
     * searchTrades
     *
     * Search in trades locally with 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchTrades (searchTerm) {
      clearTimeout(this.searchDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      if (!searchTerm) {
        this.tradeItems = this.trades
        return
      }

      this.searchDebounceTimeout = setTimeout(() => {
        const results = this.trades.filter(trade =>
          trade.title.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) ||
          trade.pair.base.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) ||
          trade.pair.base.displayName.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) ||
          trade.pair.quote.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) ||
          trade.pair.quote.displayName.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) ||
          trade.assets.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm)
        )

        this.tradeItems = results
      }, 500)
    },

    /**
     * searchPositions
     *
     * Search in positions locally with 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchPositions (searchTerm) {
      clearTimeout(this.searchDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      if (!searchTerm) {
        this.positions = this.trades
        return
      }

      this.searchDebounceTimeout = setTimeout(() => {
        const results = this.trades.filter(trade =>
          trade.title.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) ||
          trade.assets.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm)
        )

        this.positions = results
      }, 500)
    },

    /**
     * getLatestQuotes
     *
     * Request latest quotes for passed asset pairs
     *
     * @param assetPairs {Array<Object>}
     */
    getLatestQuotes (assetPairs = this.user.getLivePricesAssetPairs(this.baseCurrencyCode)) {
      if (!Array.isArray(assetPairs) || assetPairs.length <= 0 || !this.baseCurrency) return

      const resultPairs = []
      assetPairs.forEach(asset => {
        if (this.baseCurrency && asset.baseSymbol !== this.baseCurrency) {
          resultPairs.push({
            baseSymbol : asset.baseSymbol,
            quoteSymbol: this.baseCurrency
          })
        }
      })

      API.Resource.Assets.LatestQuotes(resultPairs, FeatureFilterEnum.Alerting)
        .then(response => {
          this.handleLatestQuotesResponse(response)
        })
        .catch(e => {
          this.handleLatestQuotesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleLatestQuotesResponse
     *
     * @param response <Object>
     */
    handleLatestQuotesResponse (response) {
      const data = API.responseData(response)?.latestQuotes || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.assets = collect(data, AddTriggerAssetResource).filter(pair => !!pair)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * getTrades
     *
     * Request positions trades for account
     */
    getTrades () {
      API.Resource.Alerts.Trades(this.user.selectedAccountId)
        .then(response => {
          this.handleTradesResponse(response)
        })
        .catch(e => {
          this.handleTradesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleTradesResponse
     *
     * @param response <Object>
     */
    handleTradesResponse (response) {
      const data = API.responseData(response)?.listTrades || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const collectedData = collect(data, AddTriggerAssetResource)
        this.trades = collectedData
        this.tradeItems = collectedData
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * getPositions
     *
     * Request positions for account
     */
    getPositions () {
      API.Resource.Alerts.PositionsWithActiveTrades(this.user.selectedAccountId)
        .then(response => {
          this.handlePositionsResponse(response)
        })
        .catch(e => {
          // this.handlePositionsResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handlePositionsResponse
     *
     * @param response <Object>
     */
    handlePositionsResponse (response) {
      const data = API.responseData(response)?.listPositionsWithActiveTrades || []
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)

        if (o) {
          const v = collect(o, AddTriggerAssetResource)

          this.trades = v
          this.tradeItems = v
          this.positions = v
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getTradeHistoricalMtm (period = this.selectedPeriod) {
      if (!this.model.tradeId) return

      API.Resource.Positions.TradeHistoricalMtm(this.model.tradeId, period)
        .then(response => {
          this.handleTradeHistoricalMtmResponse(response)
        })
        .catch(e => {
          this.handleTradeHistoricalMtmResponse(e.response)
        })
        .finally(() => {})
    },

    handleTradeHistoricalMtmResponse (response) {
      const data = API.responseData(response)?.tradeHistoricalMtm || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.tradeHistoricalMtmChartData = data
        if (this.isEditMode) {
          this.model.selectedItem.value = this.tradeHistoricalMtmChartData?.trade?.mtmValue ?? 0
          this.model.selectedItem.description = this.tradeHistoricalMtmChartData?.trade?.instrument ?? ''
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getPortfolioHistoricalMtm (period = this.selectedPeriod) {
      if (!this.model.portfolioId) return

      API.Resource.Positions.PositionHistoricalMtm(this.model.portfolioId, period)
        .then(response => {
          this.handlePortfolioHistoricalMtmResponse(response)
        })
        .catch(e => {
          this.handlePortfolioHistoricalMtmResponse(e.response)
        })
        .finally(() => {})
    },

    handlePortfolioHistoricalMtmResponse (response) {
      const data = API.responseData(response)?.portfolioHistoricalMTM || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.portfolioHistoricalMtmChartData = data

        if (this.isEditMode) {
          this.model.selectedItem.value = this.portfolioHistoricalMtmChartData?.position?.aggregateMtm ?? 0
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getHistoricalDailyQuotes (pair = this.model.selectedItem?.pair, period = this.selectedPeriod) {
      if (!pair) return
      API.Resource.Assets.HistoricalDailyQuotes([{
        baseSymbol : pair.base.symbol,
        quoteSymbol: pair.quote.symbol
      }], period)
        .then(response => {
          this.handleHistoricalDailyQuotesResponse(response)
        })
        .catch(e => {
          this.handleHistoricalDailyQuotesResponse(e.response)
        })
        .finally(() => {})
    },

    handleHistoricalDailyQuotesResponse (response) {
      const data = API.responseData(response)?.historicalDailyQuotes || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.historicalDailyQuotesChartData = first(data)
        if (this.isEditMode) {
          this.model.selectedItem.value = this.historicalDailyQuotesChartData?.pair?.latestQuote?.price ?? 0
          this.model.selectedItem.description = this.historicalDailyQuotesChartData?.pair?.latestQuote?.originalQuotation?.description ?? ''
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * onListItemClick
     *
     * @param item {Object}
     */
    onListItemClick (item) {
      this.model.selectedItem = item

      if (this.isAssetPrice ||
        this.isPositionMTM) {
        this.model.tradeId = item.id ?? ''
        this.model.assetCode = item?.pair?.base?.symbol || ''
      } else {
        this.model.portfolioId = item.id ?? ''
      }

      this.model.threshold = item.value ?? 0
    },

    /**
     * onBackToListClick
     *
     * Handle back to asset list button click
     */
    onBackToListClick () {
      this.model.selectedItem = null
      this.model.tradeId = ''
      this.model.portfolioId = ''
      this.model.assetCode = ''
      this.model.snapshotValue = 0
    },

    /**
     * saveFromData
     *
     * Locally persist entered triggers
     */
    saveFromData () {
      if (this.isFormSaving) return

      if (!this.selectedBaseCurrency) {
        this.baseCurrency = this.selectedBaseCurrency = this.selectedItemCurrencySymbol
      }

      this.isFormSaving = true
      this.model.displayThreshold = toDecimalMarkRange(this.model.threshold, 1, 5)
      this.model.snapshotValue = 0
      const data = new TriggerInputModel(clone(this.model))
      this.validateForm() && this.formSaveSuccess(data)
    },

    isAssetInTriggersList (symbol) {
      return !!this.triggersList.find(e => e.assetCode === symbol)
    },

    isAssetDisabled (item) {
      if (this.isAssetPrice) {
        return this.isAssetInTriggersList(item.pair.base.symbol) || (this.selectedBaseCurrency && item.pair.quote.symbol !== this.selectedBaseCurrency)
      }

      // TODO:: Fix disabled asset for PortfolioMTM

      return false
    },

    isNegativeValue (val) {
      return parseInt(val) < 0
    },

    isLargeValue (val) {
      return parseInt(val) >= 1_000_000_000
    },

    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    },

    getItemBaseDisplayName (item) {
      if (this.isAssetPrice ||
        this.isPositionMTM) {
        return item?.pair?.base?.displayName || ''
      } else {
        return item.tags || []
      }
    },
    getItemQuoteDisplayName (item) {
      if (this.isAssetPrice ||
        this.isPositionMTM) {
        return item?.pair?.quote?.displayName || ''
      } else {
        return item.createdAt
      }
    },

    getItemValue (item) {
      if (this.isAssetPrice) {
        return item.value
      } else if (this.isPositionMTM) {
        return toNumberFormatNotationShort(item.value)
      } else {
        return this.baseCurrencySymbolSingle(item.baseCurrency.displaySymbol) + toNumberFormatNotationShort(item.value)
      }
    },

    getItemDescription (item) {
      if (this.isAssetPrice ||
        this.isPositionMTM) {
        return item?.description || ''
      } else {
        return item?.baseCurrency?.displayName || ''
      }
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items: start;
  justify-content: end;
  height: 100%;
}

/deep/ .v-dialog {
  border-radius: 4px;
  margin: 0px !important;
  max-height: 100% !important;
}

/deep/ .asset-symbol-prefix-suffix .v-input__slot {
  padding: 0 !important;
}

/deep/ .asset-symbol-prefix-suffix .v-text-field__slot .v-text-field__prefix {
  padding-left: 8px;
  padding-right: 4px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

/deep/ .asset-symbol-prefix-suffix .v-text-field__slot .v-text-field__suffix {
  padding-left: 4px;
  padding-right: 8px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

/deep/ .asset-symbol-prefix-suffix .v-text-field__slot input {
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 2px;
  margin-right: 2px;
}
</style>
