import BaseResource        from '@/lib/data/resource/BaseResource'
import RiskSummaryResource from '@/api/resources/risks/RiskSummaryResource'
import dayjs               from 'dayjs'

export default class RiskActiveAnalysisResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(this.CreatedAt)
    this.Summary = payload ? new RiskSummaryResource(payload) : null
  }

  /* PROPERTIES */
  get TotalRiskPerAsset () {
    return this.Summary?.TotalRiskPerAsset || []
  }

  get AnnualRisk () {
    return this.Summary?.AnnualRisk || ''
  }

  get PieValues () {
    return this.Summary?.PieValues || []
  }

  get MethodologyContribution () {
    return this.Summary?.MethodologyContribution || []
  }

  /* METHODS */
  getDateFmt (date) {
    if (date === '') {
      return ''
    }

    return dayjs(date).format('DD MMM YYYY')
  }
}
