import BaseResource from '@/lib/data/resource/BaseResource'
import i18n         from '@/lang/lang'

export default class AssetNameResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload ? (payload === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload) : ''
    this.Value = payload ? payload.toLocaleLowerCase(i18n.code) : ''
  }

  /* PROPERTIES */

  /* METHODS */
}
