import BaseModel                        from '@/lib/data/model/BaseModel'
import AlertInputValidator              from '@/api/validations/alerts/AlertInputValidator'
import AlertInputResource               from '@/api/resources/alerts/AlertInputResource'
import API                              from '@/api/Api'
import AlertTypeEnum                    from '@/api/enums/AlertTypeEnum'
import { collect }                      from '@/lib/utils/array'
import AssetPriceTriggerInputResource   from '@/api/resources/alerts/AssetPriceTriggerInputResource'
import PositionMtmTriggerInputResource  from '@/api/resources/alerts/PositionMtmTriggerInputResource'
import PortfolioMtmTriggerInputResource from '@/api/resources/alerts/PortfolioMtmTriggerInputResource'

export default class AlertInputModel extends BaseModel {
  constructor (payload = {}, resource = AlertInputResource, validator = AlertInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['type', 'triggersList']
  }

  /* PROPERTIES */

  /* METHODS */
  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)

    if (this.type === AlertTypeEnum.AssetPrice) {
      data.triggers = collect(data.triggers ?? [], AssetPriceTriggerInputResource)
    } else if (this.type === AlertTypeEnum.PositionMTM) {
      data.triggers = collect(data.triggers ?? [], PositionMtmTriggerInputResource)
    } else {
      data.triggers = collect(data.triggers ?? [], PortfolioMtmTriggerInputResource)
    }

    return data
  }

  async CreateAlert () {
    if (this.type === AlertTypeEnum.AssetPrice) {
      return await API.Resource.Alerts.CreateAlertAssetPrice(this.clone())
    } else if (this.type === AlertTypeEnum.PositionMTM) {
      return await API.Resource.Alerts.CreateAlertTradeMTM(this.clone())
    } else {
      return await API.Resource.Alerts.CreateAlertPortfolioMTM(this.clone())
    }
  }
}
