import BaseResource from '@/lib/data/resource/BaseResource'

export default class DashboardLivePriceCategoryItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.categoryName ?? ''
    this.Category = payload.category ?? ''
    this.Show = payload.display ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
