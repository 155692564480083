import BaseResource                  from '@/lib/data/resource/BaseResource'
import DirectionEnum                 from '@/api/enums/DirectionEnum'
import InstrumentTypeEnum            from '@/api/enums/InstrumentTypeEnum'
import ForwardInputResource          from '@/api/resources/positions/ForwardInputResource'
import OptionInputResource           from '@/api/resources/positions/OptionInputResource'
import CollarInputResource           from '@/api/resources/positions/CollarInputResource'
import { UUIDGeneratorBrowser }      from '@/lib/utils/browser'
import InterestRateSwapInputResource from '@/api/resources/positions/InterestRateSwapInputResource'
import CrossCurrencySwapInputResource from '@/api/resources/positions/CrossCurrencySwapInputResource'

export default class TradeInputEditResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.Id ?? null
    this.uuid = payload.uuid ?? UUIDGeneratorBrowser()
    this.asset = {
      displayName  : payload.AssetPair?.Base?.displayName ?? '',
      displaySymbol: payload.AssetPair?.Base?.displaySymbol ?? '',
      name         : payload.AssetPair?.Base?.name ?? '',
      symbol       : payload.AssetPair?.Base?.symbol ?? '',
      type         : payload.AssetPair?.Base?.type ?? ''
    }
    this.assetCode = payload.AssetPair?.Base?.symbol ?? ''
    this.forward = new ForwardInputResource({ rate: payload.Rate ?? 0 })
    this.option = new OptionInputResource({
      strike: payload.Rate ?? 0,
      type  : payload.OptionType ?? ''
    })
    this.interestRateSwap = new InterestRateSwapInputResource({
      rate: payload.Rate ?? 0
    })
    this.crossCurrencySwap = new CrossCurrencySwapInputResource(
      {
        ccsStrike            : payload.CcsStrike ?? 0,
        assetFixedRate       : payload.Rate ?? 0,
        counterAssetFixedRate: payload.CounterRate ?? '',
        ccsFrequency         : payload.CcsFrequency ?? ''
      }
    )
    const strikeValues = payload.Rate?.split('-')
    this.collar = new CollarInputResource({
      lowStrike : InstrumentTypeEnum.COLLAR ? strikeValues[0] ?? 0 : 0,
      highStrike: InstrumentTypeEnum.COLLAR ? strikeValues[1] ?? 0 : 0
    })
    this.direction = payload.Direction ?? DirectionEnum.Long
    this.instrument = payload.Instrument ?? InstrumentTypeEnum.FORWARD
    this.displayInstrument = payload.DisplayInstrument ?? InstrumentTypeEnum.FORWARD
    this.tradeDate = payload.TradeDate ?? ''
    this.maturityDate = payload.MaturityDate ?? ''
    this.notional = payload.Notional ?? ''
    this.selectedItem = {
      price       : 0,
      displayPrice: 0,
      pair        : {
        base                     : payload.AssetPair?.Base ?? {},
        quote                    : payload.AssetPair?.Quote ?? {},
        supportedTradeInstruments: []
      },
      quotation: {
        currency          : '',
        currencyUnitFactor: 1,
        description       : '',
        displayUnit       : '',
        unit              : ''
      }
    }
  }

  /* PROPERTIES */

  /* METHODS */
}
