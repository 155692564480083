import BaseResource from '@/lib/data/resource/BaseResource'
import AssetPairResource from '@/api/resources/asset/AssetPairResource'
import { collect } from '@/lib/utils/array'
import CrossCurrencySwapFrequencyItemResource from '@/api/resources/positions/CrossCurrencySwapFrequencyItemResource'

export default class CrossCurrencySwapOptionItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.key = new AssetPairResource(payload.key ?? null)
    this.options = collect(payload.options ?? [], CrossCurrencySwapFrequencyItemResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
