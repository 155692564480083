<template>
  <v-row>
    <v-col cols="12">
      <block-item-card
        :min-height="170"
      >
        <v-card-title
          class="synthesis-text text-size-16 font-weight-medium synthesis-brand-dark-blue--text"
          v-text="$t('Hedging.Table.Costs.Titles.Asset')"
        />
        <v-card-subtitle
          class="pt-1 synthesis-text text-size-14 synthesis-ui-grey-01--text"
          v-text="$t('Hedging.Table.Costs.Titles.SelectAsset')"
        />

        <v-card-text>
          <v-row>
            <v-col
              :lg="appToolbarExtended ? 6 : 4"
              :md="appToolbarExtended ? 8 : 6"
              cols="12"
              xl="4"
            >
              <v-select
                v-model="asset"
                :items="assets"
                hide-details="auto"
                item-text="Title"
                item-value="Value"
                outlined
              />
            </v-col>
          </v-row>
        </v-card-text>
      </block-item-card>
    </v-col>
    <v-col cols="12">
      <hedging-costs-forwards-interbank-rates-table-block
        :policy-id="id"
        :selected-asset="asset"
      />
      <hedging-costs-options-interbank-rates-table-block
        :policy-id="id"
        :selected-asset="asset"
      />
      <hedging-costs-collar-interbank-rates-table-block
        :policy-id="id"
        :selected-asset="asset"
      />
      <hedging-costs-estimated-bank-fees-table-block
        :policy-id="id"
        :selected-asset="asset"
      />
    </v-col>
  </v-row>
</template>

<script>
import { first }                                    from '@/lib/utils/array'
import policyId                                     from '@/theme/default/views/hedging/mixins/policyId'
import BlockItemCard                                from '@/theme/default/components/common/BlockItemCard'
import HedgingCostsForwardsInterbankRatesTableBlock from '@/theme/default/views/hedging/blocks/costs/HedgingCostsForwardsInterbankRatesTableBlock'
import HedgingCostsOptionsInterbankRatesTableBlock  from '@/theme/default/views/hedging/blocks/costs/HedgingCostsOptionsInterbankRatesTableBlock'
import HedgingCostsEstimatedBankFeesTableBlock      from '@/theme/default/views/hedging/blocks/costs/HedgingCostsEstimatedBankFeesTableBlock'
import HedgingCostsCollarInterbankRatesTableBlock   from '@/theme/default/views/hedging/blocks/costs/HedgingCostsCollarInterbankRatesTableBlock'

export default {
  name      : 'HedgingCostsTablesBlock',
  components: {
    BlockItemCard,
    HedgingCostsEstimatedBankFeesTableBlock,
    HedgingCostsOptionsInterbankRatesTableBlock,
    HedgingCostsCollarInterbankRatesTableBlock,
    HedgingCostsForwardsInterbankRatesTableBlock
  },
  directives: {},
  mixins    : [policyId],
  props     : {},
  dataStore : {
    appToolbarExtended: 'App.Config.AppToolbarExtended',
    hedgingCostsData  : 'Hedging.Costs.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      selectedAsset: null
    }
  },
  computed: {
    costs () {
      return this.hedgingCostsData[this.id] ?? null
    },

    assets () {
      return this.costs?.Assets || []
    },

    asset: {
      get () {
        return this.selectedAsset || first(this.assets)?.Value
      },

      set (val) {
        this.selectedAsset = val
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
