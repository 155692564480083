import BaseResource      from '@/lib/data/resource/BaseResource'
import { toDecimalMark } from '@/lib/utils/helper'

export default class HedgingCostsForwardsInterbankRatesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Maturity = payload.maturity ?? ''
    this.ForwardRate = payload.forwardRate ?? ''
    // this.ForwardRateFmt = payload.forwardRate ? toDecimalMark(payload.forwardRate) : ''
    this.Carry = payload.carry ?? ''
    this.CarryFmt = payload.carry ? `${ toDecimalMark(payload.carry) }%` : '-'
    this.CarryColor = (this.Carry > 0 || this.Carry === '' ? 'synthesis-ui-green-07' : 'synthesis-ui-red-07')
    this.CarryTextColor = (this.Carry > 0 || this.Carry === '' ? 'synthesis-ui-green-00' : 'synthesis-ui-red-01')
  }

  /* PROPERTIES */

  /* METHODS */
}
