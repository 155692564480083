import { clone } from '@/lib/utils/helper'

export default class BaseResource {
  /**
   *
   * @param {Object} [payload={}] the object to resource.
   * @return {BaseResource} A BaseResource object.
   */
  constructor (payload = {}) {
    return this
  }

  /* PROPERTIES */

  /* METHODS */

  /**
   * Defines a private non enumerable property on an object
   *
   * @param {String} propName the property name
   * @param {any} propValue the propert value
   */
  definePrivateProperty (propName, propValue = null) {
    if (!propName) return
    Object.defineProperty(this, `_${ propName }`, {
      enumerable  : false,
      configurable: false,
      writable    : true,
      value       : propValue
    })
  }

  /**
   * Gets a serialized version of the resource.
   *
   * @returns {JSON | Object} The serialized resource
   */
  clone () {
    return clone(this)
  }

  /**
   * stringifies the resource using JSON.stringify API.
   *
   * @return {string} The stringified resource.
   */
  toString () {
    return JSON.stringify(this)
  }
}
