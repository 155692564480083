import { API } from '@/api/Api'

import { AttributesQuery, CompaniesQuery, ComparisonArchiveQuery, ComparisonSummaryQuery, CreateComparisonMutation, DeleteComparisonMutation, IndustriesListQuery, SaveComparisonMutation } from '@/api/query/Industry.graphql'

export default {
  // Queries
  async Archive (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(ComparisonArchiveQuery, Data)
  },

  async Summary (data) {
    const Data = { ...data }

    return await API.GQLRequest(ComparisonSummaryQuery, Data)
  },

  async Companies (data) {
    const Data = { ...data }

    return await API.GQLRequest(CompaniesQuery, Data)
  },

  async IndustriesList () {
    const Data = {}

    return await API.GQLRequest(IndustriesListQuery, Data)
  },

  async Attributes () {
    const Data = {}

    return await API.GQLRequest(AttributesQuery, Data)
  },

  // Mutations
  async CreateComparison (data) {
    const Data = { ...data }

    return await API.GQLRequest(CreateComparisonMutation, Data)
  },

  async SaveComparison (data) {
    const Data = { ...data }

    return await API.GQLRequest(SaveComparisonMutation, Data)
  },

  async DeleteComparison (comparisonId) {
    const Data = { comparisonId: comparisonId }

    return await API.GQLRequest(DeleteComparisonMutation, Data)
  }
}
