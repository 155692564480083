<template>
  <v-col
    cols="12"
  >
    <block-item-card
      v-if="maturities"
      ref="debtMaturitiesCard"
      :export-element="elementDebtMaturities"
      :title="$t('Risks.Section.DebtProfile.Block.Maturities.Title')"
      :tooltip="$t('Risks.Section.DebtProfile.Block.Maturities.InfoTip')"
    >
      <v-row class="ma-0">
        <v-col
          class="ma-0 pa-1"
          cols="12"
        >
          <v-row
            class="ma-0"
          >
            <v-col
              cols="12"
              lg="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-green-07')"
                :title="$t('Risks.Section.DebtProfile.Block.Maturities.Floating.Title')"
                :title-size="16"
                class="pa-5 pt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <base-e-chart-bar
                  :show-background="true"
                  :chart-animation-duration="2000"
                  :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
                  :axis-x-label-show="true"
                  :axis-y-label-inside="false"
                  :axis-y-label-margin="0"
                  :axis-y-label-show="true"
                  :axis-y-split-line-dash-offset="4"
                  :axis-y-split-line-opacity="1"
                  :axis-y-split-line-type="[8, 8]"
                  :axis-y-split-number="2"
                  :chart-data-options="maturityFloating"
                  :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 10,containLabel: true}"
                  :tooltip-show="false"
                  :tooltip-show-content="false"
                  axis-x-label-value-format="number"
                  :axis-x-tick-prefix="baseCurrencySymbol"
                  axis-y-label-value-format="number"
                  :axis-y-tick-prefix="baseCurrencySymbol"
                  chart-animation-easing="elasticOut"
                  :chart-animation="chartAnimation"
                  :chart-height="$vuetify.breakpoint.lgAndUp ? 250 : 180"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.1)"
                  :bar-color="themeColorRGBA('synthesis-ui-green-02')"
                />
              </v-lazy>
            </v-col>

            <v-col
              cols="12"
              lg="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-red-07')"
                :title="$t('Risks.Section.DebtProfile.Block.Maturities.Fixed.Title')"
                :title-size="16"
                class="pa-5 pt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <base-e-chart-bar
                  :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
                  :axis-x-label-show="true"
                  :axis-y-label-inside="false"
                  :axis-y-label-margin="0"
                  :axis-y-label-show="true"
                  :axis-y-split-line-dash-offset="4"
                  :axis-y-split-line-opacity="1"
                  :axis-y-split-line-type="[8, 8]"
                  :axis-y-split-number="2"
                  :tooltip-show="false"
                  :tooltip-show-content="false"
                  axis-x-label-value-format="number"
                  axis-y-label-value-format="number"
                  :axis-x-tick-prefix="baseCurrencySymbol"
                  :axis-y-tick-prefix="baseCurrencySymbol"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.1)"
                  :bar-color="themeColorRGBA('synthesis-ui-red-02')"
                  :chart-data-options="maturityFixed"
                  :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 10,containLabel: true}"
                  :chart-height="$vuetify.breakpoint.lgAndUp ? 250 : 180"
                  :show-background="true"
                  :chart-animation-duration="2000"
                  :chart-animation="chartAnimation"
                  chart-animation-easing="elasticOut"
                />
              </v-lazy>
            </v-col>

            <v-col
              cols="12"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-blue-07')"
                :title="$t('Risks.Section.DebtProfile.Block.Maturities.Total.Title')"
                :title-size="16"
                class="pa-5 pt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <base-e-chart-bar-stack
                  :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
                  :axis-pointer-line-style-opacity="0"
                  :axis-x-label-show="true"
                  :axis-y-label-inside="false"
                  :axis-y-label-margin="0"
                  :axis-y-label-show="true"
                  :axis-y-split-line-dash-offset="4"
                  :axis-y-split-line-opacity="1"
                  :axis-y-split-line-show="true"
                  :axis-y-split-line-type="[8, 8]"
                  :axis-y-split-number="2"
                  axis-x-label-value-format="number"
                  axis-y-label-value-format="number"
                  :axis-x-tick-prefix="baseCurrencySymbol"
                  :axis-y-tick-prefix="baseCurrencySymbol"
                  :chart-colors="maturityTotalChartColors"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-06', 0.1)"
                  bar-category-gap="-1"
                  bar-min-height="1"
                  :chart-data-options="maturityTotal"
                  :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 10,containLabel: true}"
                  :chart-height="$vuetify.breakpoint.lgAndUp ? 250 : 180"
                  :show-background="true"
                  :chart-animation-duration="2000"
                  :chart-animation="chartAnimation"
                  chart-animation-easing="quinticOut"
                />
              </v-lazy>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>
import chartsColorMixin    from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BlockItemCard       from '@/theme/default/components/common/BlockItemCard'
import riskAnalysisId      from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency    from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import BaseEChartBar       from '@/theme/default/components/charts/echarts/BaseEChartBar.vue'
import ItemChartLegend     from '@/theme/default/components/common/ItemChartLegend.vue'
import useExportAdditional from '@/mixins/useExportAdditional'
import BaseEChartBarStack  from '@/theme/default/components/charts/echarts/BaseEChartBarStack.vue'

export default {
  name      : 'MaturitiesBlock',
  components: {
    BaseEChartBarStack,
    ItemChartLegend,
    BaseEChartBar,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId, useExportAdditional],
  props     : {},
  dataStore : {
    riskDebtData        : 'Risks.Debt.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data',
    appToolbarExtended  : 'App.Config.AppToolbarExtended'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementDebtMaturities: null
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id] || null
    },

    baseCurrencySymbol () {
      return this.debt?.BaseSymbol || ''
    },

    maturities () {
      return this.debt?.Maturities || null
    },

    maturityFloating () {
      return this.maturities?.Floating || []
    },

    maturityFixed () {
      return this.maturities?.Fixed || []
    },

    maturityTotal () {
      return this.maturities?.TotalStack || []
    },

    maturityTotalChartColors () {
      return [
        this.themeColorRGBA('synthesis-ui-green-02'),
        this.themeColorRGBA('synthesis-ui-red-02')
      ]
    }
  },
  watch: {
    debt: {
      handler () {
        this.setExportElements()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    setExportElements () {
      this.$nextTick(() => {
        this.elementDebtMaturities = this.$refs?.debtMaturitiesCard?.$el
      })
    }
  }
}
</script>

<style scoped>

</style>
