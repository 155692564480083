import BaseResource from '@/lib/data/resource/BaseResource.js'
import AssetModel from '@/api/models/asset/AssetModel.js'

export default class RiskDebtSupportedCurrencyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.displayName = payload.displayName ?? ''
    this.baseCurrency = new AssetModel(payload.baseCurrency ?? null)
  }
}
