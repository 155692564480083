import BaseResource                   from '@/lib/data/resource/BaseResource'
import { collect }                    from '@/lib/utils/array'
import RiskExtremeEventsResource      from '@/api/resources/risks/RiskExtremeEventsResource'
import RiskExtremeEventOptionResource from '@/api/resources/risks/RiskExtremeEventOptionResource'

export default class RiskExtremeEventsBaseResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const extremeEvents = payload?.result?.extremeEvents || payload
    const extremePeriods = payload?.result?.extremePeriods || payload

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Events = collect(extremeEvents?.events || [], RiskExtremeEventsResource)
    this.CoreEvents = collect(extremeEvents?.coreEvents || [], RiskExtremeEventsResource)
    this.ExtraEvents = collect(extremeEvents?.extraEvents || [], RiskExtremeEventsResource)
    this.SelectedEvents = collect(extremeEvents?.selectedEvents || [], RiskExtremeEventOptionResource)
    this.SelectedPeriod = new RiskExtremeEventsResource(extremePeriods?.selectedPeriod || [])
    this.AllSupportedEvents = collect(extremeEvents?.allSupportedEvents || [], RiskExtremeEventOptionResource)
    this.EventsSelectionLocked = extremeEvents?.eventsSelectionLocked ?? false
    this.EventsSelectionSupported = extremeEvents?.eventsSelectionSupported ?? false
  }

  /* PROPERTIES */
  get Visible () {
    return this.EventsSelectionSupported === true
  }

  get Disabled () {
    return this.EventsSelectionLocked === true
  }

  /* METHODS */
}
