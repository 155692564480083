import BaseResource                     from '@/lib/data/resource/BaseResource'
import AssetModel                       from '@/api/models/asset/AssetModel'
import DashboardRiskSummaryItemResource from '@/api/resources/dashboard/DashboardRiskSummaryItemResource'
import { collect }                      from '@/lib/utils/array'
import { toNumberFormatNotationShort }  from '@/lib/utils/helper'
import i18n                             from '@/lang/lang'

export default class DashboardRiskSummaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.IsDummy = payload.dummy ?? false
    this.TitleValue = payload.aggregateRisk ?? ''
    this.TitleValueChange = payload.aggregateChange ?? ''
    this.AggregateHedgedRisk = payload.aggregateHedgedRisk ?? ''
    this.AggregateUnhedgedRisk = payload.aggregateUnhedgedRisk ?? ''
    this.ActiveAnalysisExists = payload.activeAnalysisExists ?? false
    this.Assets = payload.assets ?? []
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Items = payload.summaryItems ? collect(payload.summaryItems, DashboardRiskSummaryItemResource) : []
  }

  /* PROPERTIES */
  get BaseCurrencyTitle () {
    return this.baseCurrencySymbolSingle() ? this.BaseCurrencyDisplayName : this.BaseCurrencyDisplayName + ' '
  }

  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get TitleValueFmt () {
    return this.TitleValue ? toNumberFormatNotationShort(this.TitleValue) : ''
  }

  get TitleValueChangeFmt () {
    return this.TitleValueChange ? this.BaseCurrencyTitle + toNumberFormatNotationShort(this.TitleValueChange) : ''
  }

  get PieValues () {
    return [{
      name : i18n.t('Dashboard.Block.LiveRisk.Label.HedgedRisk'),
      type : 'number',
      value: this.AggregateUnhedgedRisk
    }, {
      name : i18n.t('Hedging.Card.HedgedRisk.Title'),
      type : 'number',
      value: this.AggregateHedgedRisk
    }]
  }

  /* METHODS */
  baseCurrencySymbolSingle () {
    return this.BaseCurrencyDisplayName.length === 1
  }
}
