<template>
  <v-row
    :style="`flex: ${$vuetify.breakpoint.mdAndUp ? 'initial' : '1 1 auto'};`"
    class="ma-0 pa-0"
    :class="[{'justify-start': (alignLeft && !alignCenter), 'justify-end': (!alignLeft && !alignCenter), 'justify-center': alignCenter}]"
  >
    <v-col
      :class="[{'flex-grow-1': showDropdown}, {'flex-grow-0': !showDropdown}]"
      class="ma-0 pa-0"
    >
      <slot name="title" />
      <template v-if="showDropdown">
        <v-select
          v-model="model"
          :disabled="disabled"
          :items="items"
          :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 150px': ''"
          class="text-size-12 font-weight-600"
          dense
          flat
          hide-details="auto"
          item-text="Title"
          item-value="Value"
          outlined
          :placeholder="$t('Common.Message.Select.Title')"
        />
      </template>
      <template v-else>
        <v-btn-toggle
          v-model="model"
          :disabled="disabled"
          active-class="toggle-active"
          borderless
          class="d-flex pa-1 rounded synthesis-ui-grey-03"
          dense
          group
          :mandatory="mandatory"
          style="padding: 0 !important; border-radius: 8px !important"
          :style="minWidth ? `min-width: ${minWidth}px` : ''"
        >
          <template v-for="(item, i) in items">
            <v-btn
              :key="`asset-btn-item-${i}`"
              :disabled="disabled || item.Disabled"
              :height="height"
              :style="`margin: ${buttonMargin}px; border-radius: ${buttonBorderRadius}px !important;`"
              :value="item.Value"
              class="grow rounded"
              text
            >
              {{ item.Title }}
            </v-btn>
          </template>
        </v-btn-toggle>
      </template>
    </v-col>
    <slot name="extra" />
  </v-row>
</template>
<script>

export default {
  name      : 'AssetButtonGroup',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    items: {
      type   : Array,
      default: () => []
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    mandatory: {
      type   : Boolean,
      default: true
    },
    selected: {
      type   : [String, Number, Object],
      default: ''
    },
    buttonBorderRadius: {
      type   : [String, Number],
      default: 6
    },
    buttonMargin: {
      type   : [String, Number],
      default: 2
    },
    height: {
      type   : [String, Number],
      default: 28
    },
    minWidth: {
      type   : [String, Number],
      default: undefined
    },
    alignLeft: {
      type   : Boolean,
      default: false
    },
    alignCenter: {
      type   : Boolean,
      default: false
    },
    disableDropdownOnMobile: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      showDropdownItemsBiggerThan: 6
    }
  },
  computed: {
    model: {
      get () {
        return this.selected
      },
      set (val) {
        this.$emit('update:selected', val)
      }
    },

    showDropdown () {
      return !this.disableDropdownOnMobile && (this.items.length > (this.$vuetify.breakpoint.xl ? 11 : this.showDropdownItemsBiggerThan) || this.$vuetify.breakpoint.smAndDown)
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .theme--light.toggle-active.v-btn--active::before,
.theme--light.toggle-active.v-btn--active:hover::before,
.theme--light.toggle-active.v-btn--active {
  opacity: 1 !important;
  background-color: #FFFFFF !important;
}

/deep/ .theme--light.v-btn::before {
  background-color: #FFFFFF !important;
  opacity: 0;
}

/deep/ .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  color: var(--v-synthesis-ui-blue-04-base);
}

/deep/ .v-btn-toggle .v-btn > span.v-btn__content {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: var(--v-synthesis-ui-grey-01-base) !important;
}

/deep/ .theme--light.toggle-active.v-btn--active > span.v-btn__content {
  color: var(--v-synthesis-ui-blue-04-base) !important;
  font-weight: 600 !important;
}

</style>
