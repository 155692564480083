import TermsAndPrivacy from './TermsAndPrivacy'
import MyDetails       from './MyDetails'
import Help            from './Help'
import KnowledgeCenter from './KnowledgeCenter'
import Positions       from './Positions'
import Industry        from './Industry'
import Hedging         from './Hedging'
import Risks           from './Risks'
import Common          from './Common'
import Route           from './Route'
import Dashboard       from './Dashboard'
import Users           from './Users'
import Alerts          from './Alerts'
import Assets          from './Assets'

export default {
  MyDetails      : MyDetails,
  Help           : Help,
  KnowledgeCenter: KnowledgeCenter,
  TermsAndPrivacy: TermsAndPrivacy,
  Positions      : Positions,
  Industry       : Industry,
  Hedging        : Hedging,
  Risks          : Risks,
  Common         : Common,
  Route          : Route,
  Dashboard      : Dashboard,
  Users          : Users,
  Alerts         : Alerts,
  Assets         : Assets
}
