<script>
import baseEChartColors from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

import { first } from '@/lib/utils/array'

import { toDecimalMark, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import dayjs                                                                from 'dayjs'
import BaseEChartBar                                                        from '@/theme/default/components/charts/echarts/BaseEChartBar'

export default {
  name    : 'EChartHedgingOptimalHedgeRatioBar',
  extends : BaseEChartBar,
  mixins  : [baseEChartColors],
  computed: {
    getChartDataOptions () {
      const o = this.series

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          show           : this.tooltipShow,
          trigger        : 'item',
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          showContent    : this.tooltipShowContent,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          axisPointer: {
            show     : false,
            lineStyle: {
              opacity: 0
            }
          },
          formatter: (params) => {
            return `${ params.marker } ${ params.name }<br>${ params.seriesName }`
          }
        },
        legend   : { show: this.legendShow },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          data     : o.xAxis,
          type     : 'category',
          axisLabel: {
            show      : this.axisXLabelShow,
            inside    : this.axisXLabelInside,
            margin    : this.axisXLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter :
              value => {
                if (this.axisXLabelValueFormat === 'number') {
                  value = toNumberFormatNotationShort(parseFloat(value))
                }
                if (this.axisXLabelValueFormat === 'percent') {
                  value = toDecimalMark(parseFloat(value))
                }
                if (this.axisXLabelValueFormat === 'date') {
                  value = dayjs(value).format('MMM YY')
                }
                return (this.axisXTickPrefix || '') + value + (this.axisXTickSuffix || '')
              },
            interval: this.axisXLabelInterval
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitArea: {
            areaStyle: {
              color     : ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
              shadowBlur: 0.5
            }
          },
          boundaryGap: true
        },
        yAxis: [
          {
            type     : 'value',
            position : 'left',
            axisLabel: {
              show      : this.axisYLabelShow,
              inside    : this.axisYLabelInside,
              margin    : this.axisYLabelMargin,
              padding   : [0, 0, 0, 0], // [top, right, bottom, left]
              fontFamily: this.fontFamily,
              fontSize  : this.axisLabelFontSize,
              color     : this.axisLabelFontColor,
              formatter : value => {
                if (this.axisYLabelValueFormat === 'number') {
                  value = toNumberFormatNotationShort(parseFloat(value))
                }
                if (this.axisYLabelValueFormat === 'percent') {
                  value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
                }
                if (this.axisYLabelValueFormat === 'date') {
                  value = dayjs(value).format('MMM YY')
                }
                return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
              },
              interval     : this.axisYLabelInterval,
              verticalAlign: this.axisYLabelVerticalAlign
            },
            splitLine: {
              show     : this.axisYSplitLineShow,
              lineStyle: {
                type   : this.axisYSplitLineType,
                color  : this.axisYSplitLineColor,
                opacity: this.axisYSplitLineOpacity,
                width  : this.axisYSplitLineWidth
              }
            },
            splitNumber: this.axisYSplitNumber,
            axisLine   : {
              show: false
            },
            axisTick: {
              show: false
            },
            silent: true
          }],
        color : this.colors,
        series: o.series
      }
    },

    series () {
      let o = {
        x         : [],
        y         : [],
        instrument: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const barMaxWidth = (o.x.length < 4 ? (this.$vuetify.breakpoint.xs ? '100%' : '25%') : '100%')

      const series = o.x.map((name, index) => {
        const values = new Array(o.y.length).fill('')
        values[index] = parseFloat(o.y[index]) || 0

        return {
          name        : o?.instrument ? o.instrument[index] : name,
          type        : 'bar',
          stack       : 'chart',
          barMinHeight: 0.5,
          barMaxWidth : barMaxWidth,
          label       : {
            show          : true,
            silent        : true,
            position      : 'top',
            fontSize      : 12,
            fontWeight    : 600,
            valueAnimation: true,
            formatter     : params => {
              const val = params.value === 0 ? params.value : toNumberFormatNotationShort(params.value)

              return (this.axisYTickPrefix + ' ' || '') + val + (this.axisYTickSuffix || '')
            }
          },
          showBackground : true,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius]
          },
          color : this.colors[index],
          cursor: this.cursor,
          data  : values
        }
      })

      return {
        xAxis : o.x,
        series: series
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
