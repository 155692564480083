import BaseResource                               from '@/lib/data/resource/BaseResource'
import RiskDebtCreditMetricsResource              from '@/api/resources/risks/RiskDebtCreditMetricsResource'
import RiskDebtMaturitiesResource                 from '@/api/resources/risks/RiskDebtMaturitiesResource'
import RiskDebtFixedFloatingMixResource           from '@/api/resources/risks/RiskDebtFixedFloatingMixResource'
import RiskDebtInterestExpenseVariabilityResource from '@/api/resources/risks/RiskDebtInterestExpenseVariabilityResource'
import RiskDebtOptimalFixedRatioResource          from '@/api/resources/risks/RiskDebtOptimalFixedRatioResource'
import AssetModel                                 from '@/api/models/asset/AssetModel'

export default class RiskDebtResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.debtProfile || null

    this.Id = o?.input.id ?? null
    this.Name = o?.input.name ?? null

    this.Base = new AssetModel(o?.input?.base ?? null)
    this.BaseAsset = this.Base?.displayName || ''
    this.BaseSymbol = this.Base?.displaySymbol || ''

    this.Maturities = new RiskDebtMaturitiesResource(o?.debtMaturities ?? [])
    this.FixedFloatingMix = new RiskDebtFixedFloatingMixResource(o?.debtFixedFloatingMix ?? [])
    this.InterestExpenseVariability = new RiskDebtInterestExpenseVariabilityResource(o?.debtInterestExpenseVariability ?? [])
    this.CreditMetrics = new RiskDebtCreditMetricsResource(o?.debtCreditMetrics ?? [])
    this.OptimalFixedRatio = new RiskDebtOptimalFixedRatioResource(o?.optimalFixedRatio ?? [])
    this.Input = o?.input ?? null
  }

  /* PROPERTIES */

  /* METHODS */
}
