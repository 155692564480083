<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
      />
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="6"
        md="8"
        offset-lg="3"
        offset-md="2"
        offset-sm="1"
        offset-xl="3"
        sm="10"
        xl="6"
      >
        <block-item-card
          class="pa-md-16 pt-md-12"
        >
          <v-card-title
            class="synthesis-text text-size-28 synthesis-brand-dark-blue--text"
            v-html="$t('TermsAndPrivacy.TermsOfService.Title')"
          />
          <v-card-text
            class="synthesis-text text-size-14 synthesis-brand-dark-blue--text text-start"
            v-html="$t('TermsAndPrivacy.TermsOfService.Text')"
          />
        </block-item-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader from '@/theme/default/components/common/SectionHeader'
import BlockItemCard from '@/theme/default/components/common/BlockItemCard'

export default {
  name      : 'TermsAndPrivacy',
  components: {
    BlockItemCard,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('TermsAndPrivacy.Title'),
        disabled: true
      }]
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
