import BaseResource                  from '@/lib/data/resource/BaseResource'
import DirectionEnum                 from '@/api/enums/DirectionEnum'
import ForwardInputResource          from '@/api/resources/positions/ForwardInputResource'
import OptionInputResource           from '@/api/resources/positions/OptionInputResource'
import CollarInputResource           from '@/api/resources/positions/CollarInputResource'
import InterestRateSwapInputResource from '@/api/resources/positions/InterestRateSwapInputResource'
import { UUIDGeneratorBrowser }      from '@/lib/utils/browser'
import CrossCurrencySwapInputResource from '@/api/resources/positions/CrossCurrencySwapInputResource'

export default class TradeInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? null
    this.uuid = payload.uuid ?? UUIDGeneratorBrowser()
    this.asset = {
      displayName  : payload?.selectedItem?.pair?.base?.displayName ?? '',
      displaySymbol: payload?.selectedItem?.pair?.base?.displaySymbol ?? '',
      name         : payload?.selectedItem?.pair?.base?.name ?? '',
      symbol       : payload?.selectedItem?.pair?.base?.symbol ?? '',
      type         : payload?.selectedItem?.pair?.base?.type ?? ''
    }
    this.assetCode = payload.assetCode ?? this.asset?.symbol ?? ''
    this.forward = new ForwardInputResource(payload.forward ?? {})
    this.option = new OptionInputResource(payload.option ?? {})
    this.collar = new CollarInputResource(payload.collar ?? {})
    this.interestRateSwap = new InterestRateSwapInputResource(payload.interestRateSwap ?? {})
    this.crossCurrencySwap = new CrossCurrencySwapInputResource(payload.crossCurrencySwap ?? {})
    this.direction = payload.direction ?? DirectionEnum.Long
    this.label = payload.label ?? ''
    this.instrument = payload.instrument ?? null
    this.displayInstrument = payload.displayInstrument ?? null
    this.tradeDate = payload.tradeDate ?? ''
    this.maturityDate = payload.maturityDate ?? ''
    this.notional = payload.notional ?? ''
    this.selectedItem = payload.selectedItem ?? null
  }

  /* PROPERTIES */

  /* METHODS */
}
