import BaseModel                      from '@/lib/data/model/BaseModel'
import RiskDeptFloatingInputValidator from '@/api/validations/risks/RiskDeptFloatingInputValidator'
import RiskDeptFloatingInputResource  from '@/api/resources/risks/RiskDeptFloatingInputResource'

export default class RiskDeptFloatingInputModel extends BaseModel {
  constructor (payload = {}, resource = RiskDeptFloatingInputResource, validator = RiskDeptFloatingInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['amortisationAnnually', 'amortisationQuarterly']
  }

  /* PROPERTIES */

  /* METHODS */
}
