<template>
  <v-row class="ma-0">
    <v-col
      class="pa-0 pb-2"
      cols="12"
    >
      <block-item-card
        v-ripple
        :min-height="110"
        :style="`border-radius: 8px !important; border: ${themeColorRGBA('synthesis-ui-grey-02')} 1px solid; cursor: pointer;`"
      >
        <v-container
          class="synthesis-brand-white d-block"
          fill-height
          pa-0
          style="height: 110px;"
          @click="item.Id ? $router.push({
            name: 'PositionsShow',
            params: {
              id: item.Id,
              positionId: item.Id,
              positionName: item.Name
            }
          }): ''"
        >
          <v-row class="ma-0 py-2">
            <v-col
              class="pa-0 px-3"
              cols="6"
            >
              <div class="d-inline-block text-no-wrap">
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.Asset }}
                </div>
                <div class="synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-grey-01--text">
                  {{ item.DisplayInstrument }}<span
                    v-if="item.InstrumentType && $vuetify.breakpoint.lgAndUp"
                  ><v-icon small>
                    mdi-circle-small
                  </v-icon>{{ item.Date }}</span>
                </div>
              </div>
            </v-col>
            <v-col
              class="pa-0 px-3 text-right"
              cols="6"
            >
              <div class="d-inline-block text-no-wrap">
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.TitleValueFmt }}
                </div>
                <div :class="`synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-${item.DailyChangeValueColor}-01--text`">
                  {{ item.TitleValueChangeFmt }}
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row
            class="ma-0"
          >
            <v-col
              class="pa-0"
              cols="12"
            >
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                style="pointer-events: none;"
                transition="fade-transition"
              >
                <base-e-chart-line-area
                  :axis-x-pointer-show="false"
                  :axis-x-show="false"
                  :axis-y-label-show="false"
                  :axis-y-pointer-show="false"
                  :axis-y-show="false"
                  :axis-y-split-line-show="false"
                  :chart-animation="!disableLazy"
                  :chart-animation-duration="400"
                  :chart-area-fill-color="[themeColorRGBA(`synthesis-ui-${item.MtmValueColor}-01`, 0.4), themeColorRGBA(`synthesis-ui-${item.MtmValueColor}-01`, 0.03)]"
                  :chart-data-options="item.ChartValues"
                  :chart-grid-margin="{left: 0, right: 0, top: 0, bottom: 0, containLabel: false}"
                  :chart-height="58"
                  :chart-line-color="themeColorRGBA(`synthesis-ui-${item.MtmValueColor}-01`)"
                  :chart-scale="true"
                />
              </v-lazy>
            </v-col>
          </v-row>
        </v-container>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>
import BlockItemCard      from '@/theme/default/components/common/BlockItemCard'
import BaseEChartLineArea from '@/theme/default/components/charts/echarts/BaseEChartLineArea'
import chartsColorMixin   from '@/theme/default/components/charts/mixins/chartsColorMixin'

export default {
  name      : 'LiveHedgingListCard',
  components: {
    BaseEChartLineArea,
    BlockItemCard
  },
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    item: {
      type    : Object,
      required: true
    },
    disableLazy: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
