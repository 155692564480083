import BaseResource    from '@/lib/data/resource/BaseResource'
import ArticleResource from '@/api/resources/knowledgeCenter/ArticleResource'
import { clone }       from '@/lib/utils/helper'

export default class ArticleSummaryResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Content = payload?.content?.html || ''
    this.Summary = payload.summary ? new ArticleResource(payload.summary) : ''
  }

  /* PROPERTIES */
  get Article () {
    const article = clone(this.Summary)
    article.Content = this.Content

    return article
  }

  /* METHODS */
}
