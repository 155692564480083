<template>
  <view-data-container
    :loading="isLoadingData"
    :style="`${hasDebt ? '' : 'min-height: calc(100vh - 160px);'}`"
    class="pt-0"
  >
    <v-row
      v-if="hasDebt"
    >
      <v-col cols="12">
        <risk-debt-edit-block
          v-if="isEditMode"
          :item="debtInput"
          @form:save:success="onSaveSuccess"
          @form:edit:cancel="onEditCancel"
        />

        <risk-debt-show-block
          v-else
          :id="id"
          @click:button:edit="onEditDebtProfileClick"
        />
      </v-col>
    </v-row>
  </view-data-container>
</template>

<script>

import i18nRouteMeta     from '@/mixins/i18n/i18nRouteMeta'
import riskAnalysisId    from '@/theme/default/views/risks/mixins/riskAnalysisId'
import RiskDebtResource  from '@/api/resources/risks/RiskDebtResource'
import API               from '@/api/Api'
import { clone }         from '@/lib/utils/helper'
import ViewDataContainer from '@/theme/default/components/common/ViewDataContainer'
import RiskDebtShowBlock from '@/theme/default/views/risks/blocks/RiskDebtShowBlock.vue'
import RiskDebtEditBlock from '@/theme/default/views/risks/blocks/RiskDebtEditBlock.vue'

export default {
  name      : 'RiskDebtBlock',
  components: {
    RiskDebtEditBlock,
    RiskDebtShowBlock,
    ViewDataContainer
  },
  directives: {},
  mixins    : [i18nRouteMeta, riskAnalysisId],
  props     : {},
  dataStore : {
    riskDebtData: 'Risks.Debt.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      isLoadingData: true,
      isEditMode   : false
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id]
    },

    hasDebt () {
      return !!this.debt
    },

    debtInput () {
      return this.debt?.Input || null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onEditDebtProfileClick () {
      this.isEditMode = true
    },

    onSaveSuccess (data) {
      this.$set(this.riskDebtData[this.id], 'Input', data)
      this.$set(this.riskDebtData[this.id], 'Name', data.name)
      this.getData()
      this.onEditCancel()
    },

    onEditCancel () {
      this.isEditMode = false
      this.$vuetify.goTo(0)
    },

    getData () {
      API.Resource.Risks.RiskDebt(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },

    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskDebtResource(o)

        this.$set(this.riskDebtData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
