import DataStore from '@/lib/data/dataStore/DataStore'

const DataStorePlugin = {

  install (Vue, options) {
    // Register a helper prototype property for store access.
    Object.defineProperty(Vue.prototype, '$dataStore', {
      get () {
        return DataStore
      }
    })

    // Register a global mixin to manage the getters/setters for our store.
    // DataStore: ['StoreProperty', ...]
    // DataStore: {ComputedPropertyName: 'StoreProperty.Prop.Prop', ...}
    Vue.mixin({
      beforeCreate () {
        registerStore(this)
      }
    })
  }

}

export default DataStorePlugin

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(DataStorePlugin)
}

function registerStore (vm) {
  // 1.) Check for a DataStore "option" on the component.
  // 2.) Check for a DataStore "object" on the plugin.

  if (typeof vm.$options.dataStore !== 'undefined' && typeof DataStore !== 'undefined') {
    // Initialize the computed option if it hasn't already been initialized.
    if (typeof vm.$options.computed === 'undefined') {
      vm.$options.computed = {}
    }

    // Check if the store option is a non-empty array.
    if (Array.isArray(vm.$options.dataStore)) {
      // Loop through the elements of the "store" option.
      vm.$options.dataStore.forEach(property => {
        // Create a computed property using our DataStoreAccessor helper class.
        vm.$options.computed[property] = dataStoreAccessor(property)
      })
    } else {
      // Loop through the store options.
      for (const key in vm.$options.dataStore) {
        if (typeof vm.$options.dataStore[key] === 'function') {
          // Handle a function
          vm.$options.computed[key] = dataStoreAccessor(vm.$options.dataStore[key]())
        } else if (typeof vm.$options.dataStore[key] === 'string') {
          // Handle a string
          vm.$options.computed[key] = dataStoreAccessor(vm.$options.dataStore[key], typeof vm.$options.dataStore[key].set === 'function')
        }
      }
    }
  }
}

function dataStoreAccessor (key) {
  return {
    get () {
      return key.split('.').reduce((pValue, cValue) => pValue[cValue], DataStore)
    },

    set (value) {
      const path = key.split('.')
      const length = path.length - 1
      let store = DataStore
      let i = 0

      for (i = 0; i < length; i++) {
        if (store.hasOwnProperty(path[i])) {
          store = store[path[i]]
        }
      }

      store[path[i]] = value
    }
  }
}
