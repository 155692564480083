<template>
  <v-container
    ref="hedgingCompareBlock"
    fluid
  >
    <v-row v-if="strategies">
      <v-col cols="12">
        <v-row>
          <template v-for="(item, i) in strategies">
            <v-col
              :key="`hedging-instrument-compare-card-${i}`"
              cols="12"
              md="6"
            >
              <v-lazy
                :options="{threshold: .5}"
                transition="fade-transition"
              >
                <hedging-instrument-compare-card
                  :chart-animation="chartAnimation"
                  :disable-lazy="disableLazy"
                  :strategy="item"
                />
              </v-lazy>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { clone }                    from '@/lib/utils/helper'
import HedgingCompareResource       from '@/api/resources/hedging/HedgingCompareResource'
import HedgingInstrumentCompareCard from '@/theme/default/components/common/HedgingInstrumentCompareCard'
import policyId                     from '@/theme/default/views/hedging/mixins/policyId'
import API                          from '@/api/Api'
import useExportAdditional          from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingCompareBlock',
  components: { HedgingInstrumentCompareCard },
  directives: {},
  mixins    : [policyId, useExportAdditional],
  props     : {},
  dataStore : {
    hedgingCompareData: 'Hedging.Compare.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    compare () {
      return this.hedgingCompareData[this.id] ?? null
    },

    strategies () {
      return this.compare?.Data ?? null
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Hedging.Compare(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response)?.findPolicy || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const v = new HedgingCompareResource(o)

        // Store data to DataStore
        this.$set(this.hedgingCompareData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>
