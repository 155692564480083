import BaseValidator from '@/lib/data/validator/BaseValidator'
import IndustryEnum  from '@/api/enums/IndustryEnum'

export default class IndustryComparisonInputValidator extends BaseValidator {
  validatorRules = {
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    comparisonAttributeIds: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1
    },
    companyIds: {
      required: true,
      type    : BaseValidator.Type.Array,
      minLen  : 1,
      maxLen  : IndustryEnum.MaxSelectedCompanies
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
