<template>
  <table-header-gradient-blue v-if="tableValues">
    <template #table>
      <v-data-table
        :headers="table.headers"
        :items="tableValues"
        class="font-weight-medium synthesis-card-border"
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template #header.Forwards="{header}">
          {{ header.text }}
          <infotip
            v-if="header.tip"
            icon-style="margin-top: -12px;"
          >
            {{ header.tip }}
          </infotip>
        </template>

        <template #header.Options="{header}">
          {{ header.text }}
          <infotip
            v-if="header.tip"
            icon-style="margin-top: -12px;"
          >
            {{ header.tip }}
          </infotip>
        </template>

        <template #header.Collars="{header}">
          {{ header.text }}
          <infotip
            v-if="header.tip"
            icon-style="margin-top: -12px;"
          >
            {{ header.tip }}
          </infotip>
        </template>

        <template #header.BestInstrument="{header}">
          {{ header.text }}
          <infotip
            v-if="header.tip"
            icon-style="margin-top: -12px;"
          >
            {{ header.tip }}
          </infotip>
        </template>

        <template #item.Forwards="{item}">
          <span
            v-if="item.HasForwards && item.BaseCurrency && !isBaseCurrencySymbolSingle(item.BaseCurrency)"
            class="pr-1 text-size-10 synthesis-brand-dark-blue--text"
            style="margin-top: 2px;"
            v-text="item.BaseCurrency"
          />{{ item.HasForwards ? baseCurrencySymbolSingle(item.BaseCurrency) + item.Forwards : '-' }}
        </template>

        <template #item.Options="{item}">
          <span
            v-if="item.HasOptions && item.BaseCurrency && !isBaseCurrencySymbolSingle(item.BaseCurrency)"
            class="pr-1 text-size-10 synthesis-brand-dark-blue--text"
            style="margin-top: 2px;"
            v-text="item.BaseCurrency"
          />{{ item.HasOptions ? baseCurrencySymbolSingle(item.BaseCurrency) + item.Options : '-' }}
        </template>

        <template #item.Collars="{item}">
          <span
            v-if="item.HasCollars && item.BaseCurrency && !isBaseCurrencySymbolSingle(item.BaseCurrency)"
            class="pr-1 text-size-10 synthesis-brand-dark-blue--text"
            style="margin-top: 2px;"
            v-text="item.BaseCurrency"
          />{{ item.HasCollars ? baseCurrencySymbolSingle(item.BaseCurrency) + item.Collars : '-' }}
        </template>

        <template #item.BestInstrument="{item}">
          <v-chip
            v-if="item.BestInstrument"
            :color="item.BestInstrumentColor"
            :text-color="item.BestInstrumentTextColor"
          >
            {{ item.BestInstrument }}
          </v-chip>
        </template>
      </v-data-table>
    </template>
  </table-header-gradient-blue>
</template>

<script>
import policyId                from '@/theme/default/views/hedging/mixins/policyId'
import hedgingBaseCurrency     from '@/theme/default/views/hedging/mixins/hedgingBaseCurrency'
import TableHeaderGradientBlue from '@/theme/default/components/common/TableHeaderGradientBlue'
import Infotip                 from '@/theme/default/components/common/Infotip'

export default {
  name      : 'HedgingInstrumentResultsTableBlock',
  components: {
    Infotip,
    TableHeaderGradientBlue
  },
  mixins   : [policyId, hedgingBaseCurrency],
  dataStore: {
    hedgingSummaryData: 'Hedging.Summary.Data'
  },
  data () {
    return {
      table: {
        headers: [
          {
            text : this.$t('Hedging.Table.Results.Headers.Asset'),
            align: 'start',
            value: 'Asset',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Results.Headers.Forward.Title'),
            tip  : this.$t('Hedging.Table.Results.Headers.Forward.InfoTip'),
            value: 'Forwards',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Results.Headers.Option.Title'),
            tip  : this.$t('Hedging.Table.Results.Headers.Option.InfoTip'),
            value: 'Options',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Results.Headers.Collar.Title'),
            tip  : this.$t('Hedging.Table.Results.Headers.Collar.InfoTip'),
            value: 'Collars',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Results.Headers.BestInstrument.Title'),
            tip  : this.$t('Hedging.Table.Results.Headers.BestInstrument.InfoTip'),
            value: 'BestInstrument',
            class: 'grey lighten-5'
          }
        ]
      }
    }
  },
  computed: {
    summary () {
      return this.hedgingSummaryData[this.id] ?? null
    },

    tableValues () {
      return this.summary?.TableValues || []
    }
  }
}
</script>
