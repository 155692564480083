import BaseResource                 from '@/lib/data/resource/BaseResource'
import RiskForecastPricesResource   from '@/api/resources/risks/RiskForecastPricesResource'
import RiskHistoricalPricesResource from '@/api/resources/risks/RiskHistoricalPricesResource'
import { collect, first }           from '@/lib/utils/array'
import { clone }                    from '@/lib/utils/helper'
import ChartsEnum                   from '@/api/enums/ChartsEnum'
import i18n                         from '@/lang/lang'

export default class RiskForecastsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Pair = payload.pair ?? []
    this.BaseSymbol = payload?.pair?.base?.displayName || ''
    this.QuoteSymbol = payload?.pair?.quote?.displayName || ''
    this.HistoricalPrices = payload.historicalPrices ? new RiskHistoricalPricesResource(payload.historicalPrices) : []
    this.ForecastPrices = payload.forecastPrices ? collect(payload.forecastPrices ?? null, RiskForecastPricesResource) : []
    this.ForecastPricesMean = payload.forecastPricesMean ?? ''
  }

  /* PROPERTIES */
  get Name () {
    return this.BaseSymbol + '-' + this.QuoteSymbol
  }

  get Values () {
    return [
      first(this.HistoricalPrices.Values),
      this.getForecastValues()
    ]
  }

  get Mean () {
    if (this.ForecastPricesMean) {
      return {
        Name : i18n.t('Risks.Section.ForecastsForExposures.MeanForecast'),
        Value: String(this.ForecastPricesMean),
        Color: 'synthesis-ui-grey-01',
        Icon : false
      }
    }
    return null
  }

  get Banks () {
    const o = clone(this.ForecastPrices)

    if (o) {
      const colors = ChartsEnum.Colors.Global
      const len = colors.length
      const banks = []

      o.forEach((item, i) => {
        if (item.Institution === 'Mean') {
          banks.push(this.Mean)
        } else {
          banks.push({
            Name : item.Institution,
            Value: '',
            Color: colors[i % len],
            Icon : true
          })
        }
      })

      return banks
    }

    return []
  }

  /* METHODS */
  getBankNames () {
    const o = clone(this.ForecastPrices)

    return o.map(item => item.Institution)
  }

  getForecastValues () {
    const o = clone(this.ForecastPrices)

    if (o && !!o.length) {
      return {
        x     : first(o).Dates,
        y     : o.map(item => item.Prices),
        labels: this.getBankNames()
      }
    }

    return {
      x     : [],
      y     : [],
      labels: []
    }
  }
}
