import BaseResource from '@/lib/data/resource/BaseResource'
import dayjs        from 'dayjs'
import i18n         from '@/lang/lang'

export default class ComparisonResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.AttributesNo = payload.attributesNo ?? ''
    this.CompaniesNo = payload.companiesNo ?? ''
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? null)
  }

  /* PROPERTIES */
  get Tags () {
    return [
      i18n.t('Industry.Tag.Companies', { Count: this.CompaniesNo }),
      i18n.t('Industry.Tag.Attributes', { Count: this.AttributesNo })
    ]
  }

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
