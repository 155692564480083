<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartGaugeData            from '@/theme/default/components/charts/echarts/mixins/gauge/baseEChartGaugeData'
import baseEChartGaugeProps           from '@/theme/default/components/charts/echarts/mixins/gauge/baseEChartGaugeProps'
import baseEChartColors               from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsColorMixin               from '@/theme/default/components/charts/mixins/chartsColorMixin'
import ChartsStyleEnum                from '@/api/enums/ChartsEnum'
import chartsBaseCurrencySymbolSingle from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'

import { use }                                             from 'echarts/core'
import { GaugeChart }                                      from 'echarts/charts'
import { GridComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { LabelLayout }                                     from 'echarts/features'
import { CanvasRenderer }                                  from 'echarts/renderers'

use([
  GridComponent,
  GaugeChart,
  TooltipComponent,
  TitleComponent,
  CanvasRenderer,
  LabelLayout
])

export default {
  name  : 'BaseEChartGauge',
  enums : { ChartsStyleEnum },
  mixins: [baseEChartColors, chartsColorMixin, chartsBaseCurrencySymbolSingle, baseEChartGaugeProps, baseEChartGaugeData],
  data () {
    return {
      // echart: null
    }
  },
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 0,
        bottom      : 0,
        containLabel: false
      }
    },

    getChartDataOptions () {
      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          trigger        : 'item',
          show           : this.tooltipShow,
          confine        : this.tooltipConfine,
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          }
        },
        title: {
          show      : this.titleShow,
          text      : this.titleValue,
          left      : 'center',
          color     : this.themeColorRGBA('synthesis-ui-blue-01', 0.8),
          fontFamily: this.fontFamily,
          fontSize  : 42,
          fontWeight: 'bolder'
        },
        color    : this.defaultColors ?? this.colors,
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid  : this.getChartGridMargin,
        series: this.series
      }
    },

    series () {
      let values = []

      if (this.chartDataOptions.length) {
        values = this.chartDataOptions
      }
      return {
        type    : 'gauge',
        progress: {
          show    : false,
          roundCap: true,
          width   : 18
        },
        pointer: {
          icon        : 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
          length      : '75%',
          width       : 16,
          offsetCenter: [0, '5%']
        },
        axisLine: {
          roundCap : true,
          lineStyle: {
            width: 18
          }
        },
        axisTick: {
          splitNumber: 2,
          lineStyle  : {
            width: 2,
            color: '#999'
          }
        },
        splitLine: {
          length   : 12,
          lineStyle: {
            width: 3,
            color: '#999'
          }
        },
        axisLabel: {
          distance: 30,
          color   : '#999',
          fontSize: 20
        },
        title: {
          show: false
        },
        cursor: this.cursor,
        detail: {
          show           : false,
          backgroundColor: '#fff',
          borderColor    : '#999',
          borderWidth    : 2,
          width          : '60%',
          lineHeight     : 40,
          height         : 40,
          borderRadius   : 8,
          offsetCenter   : [0, '35%'],
          valueAnimation : true,
          formatter      : function (value) {
            return '{value|' + value.toFixed(0) + '}{unit|km/h}'
          },
          rich: {
            value: {
              fontSize  : 50,
              fontWeight: 'bolder',
              color     : '#777'
            },
            unit: {
              fontSize: 20,
              color   : '#999',
              padding : [0, 0, -20, 10]
            }
          }
        },
        data: values
      }
    }
  },
  mounted () {}
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
