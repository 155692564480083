<template>
  <v-container
    :class="[{'pa-0': removeContainerPadding}]"
    fluid
  >
    <v-row :class="[{'ma-0': removeContainerRowMargin}]">
      <v-col
        :class="[{'pa-0': removeContainerColPadding}]"
        cols="12"
      >
        <slot name="toolbar" />
        <table-header-gradient-blue>
          <template #toolbar>
            <v-toolbar
              v-if="title && !hideTitle"
              class="mb-4"
              color="transparent"
              dense
              flat
            >
              <v-toolbar-title class="pl-1 synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text">
                <div
                  v-if="createdAtFmt"
                  class="synthesis-inter-text text-size-12 font-weight-regular synthesis-ui-grey-01--text"
                >
                  {{ createdAtFmt }}
                </div>
                {{ title }}
              </v-toolbar-title>
              <slot name="toolbarActions" />
            </v-toolbar>
          </template>
          <template #table>
            <v-data-table
              :headers="headers"
              :items="values"
              class="font-weight-medium synthesis-card-border"
              disable-pagination
              hide-default-footer
              item-key="value"
            >
              <template
                v-for="key in headers"
                #[`item.${key.value}`]="{item}"
              >
                {{ truncateString((item[`${ key.value }_raw`] || item[`${ key.value }`]), truncateLength) }}
              </template>
            </v-data-table>
          </template>
        </table-header-gradient-blue>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import comparisonId            from '@/theme/default/views/industry/mixins/comparisonId'
import TableHeaderGradientBlue from '@/theme/default/components/common/TableHeaderGradientBlue'
import { truncateString }      from '@/lib/utils/string'

export default {
  name      : 'ComparisonTableSummary',
  components: {
    TableHeaderGradientBlue
  },
  directives: {},
  mixins    : [comparisonId],
  props     : {
    expandAllFields: {
      type   : Boolean,
      default: false
    },
    hideTitle: {
      type   : Boolean,
      default: false
    },
    removeContainerPadding: {
      type   : Boolean,
      default: true
    },
    removeContainerRowMargin: {
      type   : Boolean,
      default: true
    },
    removeContainerColPadding: {
      type   : Boolean,
      default: true
    }
  },
  dataStore: {
    comparisonSummaryData: 'Industry.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    summary () {
      return this.comparisonSummaryData[this.id] || null
    },

    title () {
      return this.summary?.Name || ''
    },

    headers () {
      return this.summary?.TableHeaders || []
    },

    values () {
      return this.summary?.TableValues || []
    },

    createdAt () {
      return this.summary?.CreatedAtFmt || ''
    },

    createdAtFmt () {
      if (this.createdAt) {
        return this.$t('Common.Misc.CreatedAt') + ' ' + this.createdAt
      }
      return ''
    },

    truncateLength () {
      return this.expandAllFields ? -1 : 40
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    truncateString
  }
}
</script>

<style scoped>
/deep/ .v-toolbar--dense .v-toolbar__content {
  padding-left: 4px;
  padding-right: 4px;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  box-shadow: 0 1px 0 1px #dfe3eb;
  left: 0;
  z-index: 1;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 2;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-of-type(even) > td:nth-child(1) {
  background-color: var(--v-synthesis-ui-grey-06-base);
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-of-type(odd) > td:nth-child(1) {
  background-color: var(--v-synthesis-brand-white-base);
}

</style>
