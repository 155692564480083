<template>
  <view-data-container
    :loading="isLoadingData"
    :no-data-title="$t('Risks.Message.NoActiveData.Title')"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-row>
          <table-header-gradient-blue
            :loading="loading"
            :title="title"
            has-skeleton
          >
            <template
              v-if="cardValues && cardValues.length"
              #toolbarActions
            >
              <v-spacer />

              <div class="d-flex fill-height align-end justify-end">
                <export-to-csv
                  v-if="tableValues"
                  :export-data="exportData"
                  :export-file-name="title"
                  :text="$t('Common.Button.Export')"
                  class="pr-4"
                  color="synthesis-ui-blue-04"
                  color-hover="synthesis-ui-blue-03"
                  dark
                  large
                  no-padding
                />
              </div>
            </template>

            <template #table>
              <v-data-table
                :headers="table.headers"
                :items="tableValues"
                class="row-pointer font-weight-medium synthesis-card-border"
                :item-class="itemRowBackground"
                disable-pagination
                disable-sort
                hide-default-footer
                @click:row="showHistoricalMTMValueDialog"
              >
                <template #item.DailyChange="{item}">
                  <v-icon
                    :color="getDailyChangeColor(item.DailyChange)"
                    size="14"
                    style="margin-top: -7px;"
                  >
                    {{ getDailyChangeIcon(item.DailyChange) }}
                  </v-icon>
                  <span
                    :ref="`live-daily-text-${item.Id}`"
                    :class="[getDailyChangeColor(item.DailyChange, '--text')]"
                  >
                    {{ `${ item.DailyChangeFmt === '' ? '' : item.DailyChangeFmt + '%' }` }}
                  </span>
                </template>
                <template #item.MTMValue="{item}">
                  <div :class="[`${item.Id===-1?'text-size-16':''}`, getDailyChangeColor(item.MTMValue, '--text')]">
                    <span
                      v-if="item.BaseCurrency && !isBaseCurrencySymbolSingle(item.BaseCurrency)"
                      class="pr-1 text-size-10 synthesis-brand-dark-blue--text"
                      style="margin-top: 2px;"
                      v-text="item.BaseCurrency"
                    /><span :ref="`live-mtm-text-${item.Id}`">{{ baseCurrencySymbolSingle(item.BaseCurrency) + item.MTMValueFmt }}</span>
                  </div>
                </template>
              </v-data-table>
            </template>
          </table-header-gradient-blue>
        </v-row>
        <v-row v-if="cardValues && cardValues.length">
          <v-col
            class="px-0"
            cols="12"
          >
            <v-toolbar
              color="transparent"
              dense
              flat
            >
              <v-toolbar-title
                class="pl-1 synthesis-text text-size-20 font-weight-medium synthesis-brand-dark-blue--text"
                v-text="$t('Positions.Summary.StressTest.Title')"
              />
              <infotip
                v-if="$t('Positions.Summary.StressTest.InfoTip')"
                icon-class="ml-1"
                icon-style="margin-top: -6px;"
              >
                {{ $t('Positions.Summary.StressTest.InfoTip') }}
              </infotip>
            </v-toolbar>
            <v-skeleton-loader
              :height="100"
              :loading="loading"
              :width="464"
              type="list-item-two-line"
            >
              <v-row>
                <template v-for="(item, i) in cardValues">
                  <v-col
                    :key="`positions-summary-col-${i}`"
                    cols="12"
                    md="4"
                  >
                    <positions-summary-stress-test-item-card
                      :key="`positions-summary-stress-test-item-card-${i}`"
                      :base-currency-symbol="item.BaseCurrency"
                      :caption="item.Asset"
                      :label="item.Label"
                      :maturity-date="item.MaturityDate"
                      :percent="item.Percent"
                      :price="item.Price"
                    >
                      <template #combo>
                        <position-trade-stress-test-select
                          :key="`position-trade-stress-test-select-${i}`"
                          v-model="tradeStressFactorSelected[i]"
                          :items="item.StressTestOptions"
                          :name="item.Asset"
                        />
                      </template>
                    </positions-summary-stress-test-item-card>
                  </v-col>
                </template>
              </v-row>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <historical-mtm-value-dialog
      ref="HistoricalMtmValueDialog"
      :data="historicalMTMValueDialogData"
      :visible.sync="historicalMTMValueDialogVisible"
      @selected-period-changed="handleTradeHistoricalPeriodChanged"
    />
  </view-data-container>
</template>

<script>

import positionId                         from '@/theme/default/views/positions/mixins/positionId'
import autoUpdateApiData                  from '@/mixins/api/autoUpdateApiData'
import PositionsSummaryStressTestItemCard from '@/theme/default/components/common/PositionsSummaryStressTestItemCard'
import TableHeaderGradientBlue            from '@/theme/default/components/common/TableHeaderGradientBlue'
import API                                from '@/api/Api'
import { clone }                          from '@/lib/utils/helper'
import PositionsResource                  from '@/api/resources/positions/PositionsResource'
import HistoricalMtmValueDialog           from '@/theme/default/views/positions/HistoricalMtmValueDialog'
import Infotip                            from '@/theme/default/components/common/Infotip'
import ExportToCsv                        from '@/theme/default/components/common/ExportToCsv'
import routerTabState                     from '@/mixins/routerTabState'
import ViewDataContainer                  from '@/theme/default/components/common/ViewDataContainer'
import viewHasData                        from '@/mixins/viewHasData'
import PositionTradeStressTestSelect      from '@/theme/default/components/common/PositionTradeStressTestSelect.vue'
import watchable                          from '@/mixins/watchable'

let chartInstance = null

export default {
  name      : 'PositionsShowOpenTrades',
  components: {
    PositionTradeStressTestSelect,
    ViewDataContainer,
    ExportToCsv,
    Infotip,
    HistoricalMtmValueDialog,
    TableHeaderGradientBlue,
    PositionsSummaryStressTestItemCard
  },
  directives: {},
  mixins    : [positionId, autoUpdateApiData, routerTabState, viewHasData, watchable],
  props     : {},
  dataStore : {
    positionsSummaryData: 'Positions.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      isDeactivated                         : false,
      onInit                                : true,
      tradeStressFactorSelected             : [],
      showPriceAnimation                    : true,
      historicalMTMValueDialogData          : null,
      historicalMTMValueDialogVisible       : false,
      historicalMTMValueDialogSelectedPeriod: this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      autoUpdateApiDataOptions              : {
        // 5 seconds
        timeout: 5000
      },
      table: {
        headers: [
          {
            text     : this.$t('Positions.Table.Headers.Asset'),
            align    : 'left',
            value    : 'Asset',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Label'),
            align    : 'left',
            value    : 'Label',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Instrument'),
            align    : 'left',
            value    : 'Instrument',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.TradeDate'),
            align    : 'left',
            value    : 'TradeDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.MaturityDate'),
            align    : 'left',
            value    : 'MaturityDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Rate'),
            align    : 'left',
            value    : 'Rate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Notional'),
            align    : 'left',
            value    : 'Notional',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.DailyChange'),
            align    : 'left',
            value    : 'DailyChange',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text', 'font-weight-medium']
          },
          {
            text     : this.$t('Positions.Table.Headers.Direction'),
            align    : 'left',
            value    : 'Direction',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text', 'font-weight-medium']
          },
          {
            text     : this.$t('Positions.Table.Headers.MTMValue'),
            align    : 'left',
            value    : 'MTMValue',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          }
        ]
      }
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    loading () {
      return !this.position
    },

    position () {
      return this.positionsSummaryData[this.id] || null
    },

    title () {
      return this.position?.Name || ''
    },

    aggregateMtm () {
      return this.position?.AggregateMtmValueFmt || ''
    },

    createdAt () {
      return this.position?.CreatedAtFmt || ''
    },

    cardValues () {
      return this.position?.CardValues || []
    },

    tableValues () {
      return this.position?.TableValues || []
    },

    exportData () {
      return this.position?.ExportData || []
    },

    breadcrumb () {
      return [
        {
          text    : this.$t('Positions.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Positions' }
        },
        {
          text    : this.$t('Positions.Summary.Title'),
          disabled: true
        }
      ]
    }
  },
  watch: {
    tableValues: {
      handler: function (after, before) {
        const changed = after.filter(function (p, idx) {
          return Object.keys(p).some(function (prop) {
            return p?.DailyChange !== before[idx]?.DailyChange
          })
        })

        if (changed) {
          changed.forEach(item => {
            this.animatePriceText(item)
          })
        }
      },
      deep: true
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
    this.isDeactivated = false
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    this.isDeactivated = true
    chartInstance = null
  },
  methods: {
    animatePriceText (item) {
      if (this.showPriceAnimation) {
        const ref = this.$refs[`live-daily-text-${ item.Id }`]
        const mtm = this.$refs[`live-mtm-text-${ item.Id }`]
        const all = ['blinking', ...item.DailyChangeBgColors]
        const cls = ['blinking', item.DailyChangeBgColor]

        ref && ref.classList.add(...cls)
        mtm && mtm.classList.add(...cls)

        clearTimeout(item.AnimatePriceTimeout)
        item.AnimatePriceTimeout = setTimeout(() => {
          ref && ref.classList.remove(...all)
          mtm && mtm.classList.remove(...all)
        }, 400)
      }
    },

    itemRowBackground (item) {
      return item.Id === -1 ? 'aggregate-mtm-row-bg' : ''
    },

    updateTradeStressFactors (newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }
      // Stop polling
      this.stopAutoUpdateApiData()

      this.tradeStressFactorSelected.forEach(item => {
        item.Loading = true
      })

      const values = clone(this.tradeStressFactorSelected).map(item => {
        return {
          tradeId     : item.Id,
          stressFactor: item.Value
        }
      })

      API.Resource.Positions.UpdateTradeStressFactor(this.id, values)
        .then(response => {
          this.handleUpdateTradeStressFactorResponse(response)
        })
        .catch(e => {
          this.handleUpdateTradeStressFactorResponse(e.response)
        })
    },
    handleUpdateTradeStressFactorResponse (response) {
      if (this.isDeactivated) {
        return
      }
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const p = data?.tradeStressFactors || null

        if (p) {
          const o = clone(p)
          const v = new PositionsResource(o)
          // Store data to DataStore
          this.$set(this.positionsSummaryData, v.Id, v)

          this.tradeStressFactorSelected.forEach(item => {
            item.Loading = false
          })

          // Start polling
          this.startAutoUpdateApiData(this.getData)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getData () {
      API.Resource.Positions.Summary(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        const p = data?.findPositions || null
        if (p) {
          const o = clone(p)
          const v = new PositionsResource(o)

          if (v) {
            if (v.TradeStressFactorSelected && !!v.TradeStressFactorSelected.length) {
              this.unwatchAll()

              this.tradeStressFactorSelected = v.TradeStressFactorSelected

              v.TradeStressFactorSelected.forEach((item, key) => {
                this.watch(`tradeStressFactorSelected.${ key }.Value`, this.updateTradeStressFactors, true)
              })
            }
          }

          // Store data to DataStore
          this.$set(this.positionsSummaryData, v.Id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    },

    getDailyChangeColor (v, text = '') {
      if (v === 0 || v === '') {
        return 'synthesis-brand-dark-blue' + text
      } else {
        return (v > 0 ? 'synthesis-ui-green-01' : 'synthesis-ui-red-02') + text
      }
    },

    getDailyChangeIcon (v) {
      if (v === 0 || v === '') {
        return ''
      } else {
        return (v > 0 ? 'mdi-arrow-top-right' : 'mdi-arrow-bottom-right')
      }
    },

    showHistoricalMTMValueDialog (item) {
      if (item.Id === -1) {
        return
      }

      this.historicalMTMValueDialogData = null
      this.historicalMTMValueDialogVisible = true

      this.$nextTick(() => {
        chartInstance = this.$refs?.HistoricalMtmValueDialog?.$refs?.ChartHistorical?.$refs?.echartHistoricalChart?.chart

        this.$nextTick(() => {
          if (this.historicalMTMValueDialogData === null) {
            this.showChartLoader()
          }

          API.Resource.Positions.TradeHistoricalMtm(item.Id, this.historicalMTMValueDialogSelectedPeriod)
            .then(response => {
              this.handleTradeHistoricalMtmResponse(response)
            })
            .catch(e => {
              this.handleTradeHistoricalMtmResponse(e.response)
            })
            .finally(() => {
              this.hideChartLoader()
            })
        })
      })
    },

    handleTradeHistoricalMtmResponse (response) {
      const data = API.responseData(response)?.tradeHistoricalMtm || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.historicalMTMValueDialogData = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    handleTradeHistoricalPeriodChanged (tradeId, period) {
      this.historicalMTMValueDialogSelectedPeriod = period
      this.showChartLoader()

      API.Resource.Positions.TradeHistoricalMtm(tradeId, period)
        .then(response => {
          this.handleTradeHistoricalPeriodResponse(response)
        })
        .catch(e => {
          this.handleTradeHistoricalPeriodResponse(e.response)
        })
        .finally(() => {
          this.hideChartLoader()
        })
    },
    handleTradeHistoricalPeriodResponse (response) {
      const data = API.responseData(response)?.tradeHistoricalMtm || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.historicalMTMValueDialogData = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    showChartLoader () {
      chartInstance && chartInstance.showLoading('default', {
        text         : '',
        spinnerRadius: 20,
        color        : this.$vuetify.theme.themes.light['synthesis-ui-blue-04'],
        maskColor    : 'rgba(255,255,255,0.8)',
        lineWidth    : 4
      })
    },

    hideChartLoader () {
      chartInstance && chartInstance.hideLoading()
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content, .v-toolbar__extension {
  padding: 4px 0 !important;
}

/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr.aggregate-mtm-row-bg {
  background-color: #eeeeee !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.blinking {
  -webkit-animation: 1s blink ease-in-out infinite;
  -moz-animation: 1s blink ease-in-out infinite;
  -ms-animation: 1s blink ease-in-out infinite;
  -o-animation: 1s blink ease-in-out infinite;
  animation: 1s blink ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  50% {
    opacity: 1;
  }
}

</style>
