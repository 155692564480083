import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class RiskDebtAmortisationInputValidator extends BaseValidator {
  validatorRules = {
    amount: {
      required                    : true,
      NotEmptyZeroOrPositiveNumber: (v) => parseFloat(v) >= 0 && v !== ''
    }
  }

  validatorMessages = {
    el: {
      NotEmptyZeroOrPositiveNumber: 'Enter zero or a positive value'
    },
    en: {
      NotEmptyZeroOrPositiveNumber: 'Enter zero or a positive value'
    }
  }

  constructor (data = {}) {
    super(data)
  }
}
