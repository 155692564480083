<template>
  <v-col cols="12">
    <block-item-card
      v-if="debt"
      ref="interestExpenseCard"
      :export-element="elementInterestExpense"
      :title="$t('Risks.Section.DebtProfile.Block.InterestExpense.Title')"
      :tooltip="$t('Risks.Section.DebtProfile.Block.InterestExpense.InfoTip')"
      :title-value-margin-top="titleValueMarginTop"
      :title-value-padding-left="5"
      :title-value-prefix="$t('Risks.Section.DebtProfile.Block.InterestExpense.TitleValue.Prefix')"
      title-value-suffix="%"
      :title-value="currentInterestRateValueFmt"
      :title-value-color="currentInterestRateColor"
      :title-value-color-red="false"
      :title-large="false"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #actions
      >
        <v-spacer />
        <asset-button-group
          :items="assets"
          :selected.sync="interestExpenseSelectedAsset"
          class="mt-4"
        />
      </template>
      <template
        v-else
        #actions-row
      >
        <asset-button-group
          :items="assets"
          :selected.sync="interestExpenseSelectedAsset"
          class="mx-4 mb-2"
        />
      </template>

      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #header2
      >
        <v-spacer />
        <asset-button-group
          :items="sensitivities"
          :selected.sync="interestExpenseSelectedSensitivity"
          class="mt-2 mr-4"
        />
      </template>
      <template
        v-else
        #header2
      >
        <asset-button-group
          :items="sensitivities"
          :selected.sync="interestExpenseSelectedSensitivity"
          class="mx-4"
        />
      </template>

      <v-row
        class="ma-0"
      >
        <v-col
          class="ma-0 pa-1"
          cols="12"
        >
          <v-row
            class="ma-0"
          >
            <v-col
              cols="12"
              md="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-red-08')"
                :title="$t('Risks.Section.DebtProfile.Block.InterestExpense.BeforeHedging.Title')"
                :title-size="16"
                class="pa-5 pt-4 mt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <e-chart-interest-expense-variability
                  font-weight="300"
                  :axis-label-font-size="$vuetify.breakpoint.xs ? 16 : 22"
                  :axis-label-font-color="themeColorRGBA('synthesis-brand-dark-blue')"
                  :axis-x-boundary-gap="true"
                  :axis-y-tick-prefix="baseCurrencySymbol"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-06', 0.1)"
                  :axis-x-tick-color="themeColorRGBA('synthesis-ui-grey-01',.4)"
                  :axis-x-tick-width="2"
                  :chart-line-smooth="true"
                  :chart-line-width="2"
                  :chart-line-color="themeColorRGBA('synthesis-ui-red-04')"
                  :chart-area-fill-color="[themeColorRGBA('synthesis-ui-red-06', 0.4), themeColorRGBA('synthesis-ui-red-06', 0.1)]"
                  :chart-data-options="beforeHedgingValues"
                  :chart-height="240"
                  :chart-grid-margin="{left: 5, right: 0, top: 20, bottom: 24, containLabel: true}"
                  :chart-scale="true"
                  :graph-line-show="false"
                  :has-line-bell="false"
                  :axis-x-label-show="true"
                  :axis-y-label-show="false"
                  axis-x-label-value-format=""
                  axis-y-label-value-format="percent"
                  chart-animation-easing="quarticOut"
                  :chart-animation="chartAnimation"
                  :mark-line-animation="chartAnimation"
                  :mark-line-animation-duration="1000"
                  :mark-line-animation-duration-update="1000"
                  :mark-line-label-font-size="$vuetify.breakpoint.xs ? 16 : 22"
                  :mark-line-label-font-weight="400"
                  :mark-line-label-color="themeColorRGBA('synthesis-ui-orange-01')"
                  :mark-line-line-style-color="themeColorRGBA('synthesis-ui-orange-01')"
                  :mark-line-line-style-width="2"
                  :mark-line-label-prefix="baseCurrencySymbol"
                  mark-line-label-background-color="transparent"
                  :mark-line-label-border-width="0"
                  mark-line-label-show
                />
              </v-lazy>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <item-chart-legend
                :background-color="themeColorRGBA('synthesis-ui-green-08')"
                :title="$t('Risks.Section.DebtProfile.Block.InterestExpense.AfterHedging.Title')"
                :title-size="16"
                class="pa-5 pt-4 mt-4"
              />
              <v-lazy
                :options="{threshold: .5}"
                :value="disableLazy"
                transition="fade-transition"
              >
                <e-chart-interest-expense-variability
                  font-weight="300"
                  :axis-label-font-size="$vuetify.breakpoint.xs ? 16 : 22"
                  :axis-label-font-color="themeColorRGBA('synthesis-brand-dark-blue')"
                  :axis-x-boundary-gap="true"
                  :axis-y-tick-prefix="baseCurrencySymbol"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-06', 0.1)"
                  :axis-x-tick-color="themeColorRGBA('synthesis-ui-grey-01',.4)"
                  :axis-x-tick-width="2"
                  :chart-line-smooth="true"
                  :chart-line-width="2"
                  :chart-line-color="themeColorRGBA('synthesis-ui-green-04')"
                  :chart-area-fill-color="[themeColorRGBA('synthesis-ui-green-06', 0.4), themeColorRGBA('synthesis-ui-green-06', 0.1)]"
                  :chart-data-options="afterHedgingValues"
                  :chart-height="240"
                  :chart-grid-margin="{left: 5, right: 0, top: 20, bottom: 24, containLabel: true}"
                  :chart-scale="true"
                  :graph-line-show="false"
                  :has-line-bell="false"
                  :axis-x-label-show="true"
                  :axis-y-label-show="false"
                  axis-x-label-value-format=""
                  axis-y-label-value-format="percent"
                  chart-animation-easing="quarticOut"
                  :chart-animation="chartAnimation"
                />
              </v-lazy>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>
import { isEmpty }                      from '@/lib/utils/type'
import chartsColorMixin                 from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BlockItemCard                    from '@/theme/default/components/common/BlockItemCard'
import riskAnalysisId                   from '@/theme/default/views/risks/mixins/riskAnalysisId'
import AssetButtonGroup                 from '@/theme/default/components/common/AssetButtonGroup.vue'
import ItemChartLegend                  from '@/theme/default/components/common/ItemChartLegend.vue'
import EChartInterestExpenseVariability from '@/theme/default/components/charts/echarts/EChartInterestExpenseVariability.vue'
import { first }                        from '@/lib/utils/array'
import useExportAdditional              from '@/mixins/useExportAdditional'

export default {
  name      : 'InterestExpenseBlock',
  components: {
    EChartInterestExpenseVariability,
    ItemChartLegend,
    AssetButtonGroup,
    BlockItemCard
  },
  directives: {},
  mixins    : [chartsColorMixin, riskAnalysisId, useExportAdditional],
  props     : {},
  dataStore : {
    appToolbarExtended: 'App.Config.AppToolbarExtended',
    riskDebtData      : 'Risks.Debt.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementInterestExpense            : null,
      interestExpenseSelectedAsset      : null,
      interestExpenseSelectedSensitivity: 'CURRENT'
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id] || null
    },

    baseCurrencySymbol () {
      return this.debt?.BaseSymbol || ''
    },

    interestExpenseVariability () {
      return this.debt?.InterestExpenseVariability || null
    },

    currentInterestRateValue () {
      return this.interestExpenseVariability?.CurrentInterestRate || ''
    },

    currentInterestRateColor () {
      return this.themeColorRGBA('synthesis-brand-dark-blue')
    },

    currentInterestRateValueFmt () {
      return this.interestExpenseVariability?.CurrentInterestRateFmt || ''
    },

    titleValueMarginTop () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return -50
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return 15
      } else {
        return -5
      }
    },

    assets () {
      return this.interestExpenseVariability?.Assets || []
    },

    sensitivities () {
      return this.interestExpenseVariability?.Sensitivities || []
    },

    selectedData () {
      let values = []

      const data = this.interestExpenseVariability?.ChartValues || null

      if (!isEmpty(data)) {
        values = data[this.interestExpenseSelectedAsset] ?? null

        if (values) {
          values = values[this.interestExpenseSelectedSensitivity] || []
        }
      }

      return values
    },

    beforeHedgingValues () {
      const data = this.selectedData?.Data?.BeforeHedging ?? null

      if (data) {
        return [data]
      }

      return []
    },

    afterHedgingValues () {
      const data = this.selectedData?.Data?.AfterHedging ?? null

      if (data) {
        return [data]
      }

      return []
    }
  },
  watch: {
    debt: {
      handler () {
        this.setExportElements()
        this.setSelectedAssets()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    setExportElements () {
      this.$nextTick(() => {
        this.elementInterestExpense = this.$refs?.interestExpenseCard?.$el
      })
    },

    setSelectedAssets () {
      this.$nextTick(() => {
        // Fix for dropdown
        if (this.assets && !!this.assets.length) {
          this.interestExpenseSelectedAsset = first(this.assets).Value
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
