import BaseResource from '@/lib/data/resource/BaseResource'

export default class AssetExposureNewFromExistingResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.asset = payload.asset
    this.assetCode = payload.asset?.symbol ?? ''
    this.code = payload.code ?? this.assetCode ?? ''
    this.name = payload.name ?? this.code ?? ''
    this.notional = payload.notional ?? ''
    this.label = payload.label ?? ''
    this.userProvidedSeasonality = payload.userProvidedSeasonality ?? false
    this.seasonality = this.userProvidedSeasonality ? payload.seasonality ?? [] : []
    this.selectedItem = payload.selectedItem ?? {
      price       : payload.currentPrice ?? 0,
      displayPrice: payload.currentDisplayPrice ?? 0,
      quotation   : payload.quotation,
      pair        : payload.pair
    }
  }

  /* PROPERTIES */

  /* METHODS */
}
