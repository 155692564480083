import { collect }                                from '@/lib/utils/array'
import i18n                                       from '@/lang/lang'
import BaseResource                               from '@/lib/data/resource/BaseResource'
import AssetModel                                 from '@/api/models/asset/AssetModel'
import HedgingCostsForwardsInterbankRatesResource from '@/api/resources/hedging/HedgingCostsForwardsInterbankRatesResource'
import HedgingCostsOptionsInterbankRatesResource  from '@/api/resources/hedging/HedgingCostsOptionsInterbankRatesResource'
import HedgingCostsEstimatedBankFeesResource      from '@/api/resources/hedging/HedgingCostsEstimatedBankFeesResource'
import HedgingCostsCollarInterbankRatesResource   from '@/api/resources/hedging/HedgingCostsCollarInterbankRatesResource'

export default class HedgingAssetCostResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.asset ?? ''
    this.AssetValue = payload.asset ? payload.asset.toLocaleLowerCase(i18n.code) : ''
    this.Asset = new AssetModel(payload.detailedAsset ?? {})
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.BankFeesSupported = payload.bankFeesSupported ?? false
    this.ForwardsInterbankRates = collect(payload.forwardsInterbankRates ?? [], HedgingCostsForwardsInterbankRatesResource)
    this.OptionsInterbankRates = collect(payload.optionsInterbankRates ?? [], HedgingCostsOptionsInterbankRatesResource)
    this.CollarInterbankRates = collect(payload.collarsInterbankRates ?? [], HedgingCostsCollarInterbankRatesResource)
    this.EstimatedBankFees = collect(payload.estimatedBankFees ?? [], HedgingCostsEstimatedBankFeesResource)
  }

  /* PROPERTIES */
  get IsPremium () {
    return this.BankFeesSupported === true
  }

  get IsStandard () {
    return this.BankFeesSupported === false
  }

  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get ExportForwardsInterbankRatesData () {
    return this.getExportData(this.ForwardsInterbankRates, i18n.t('Hedging.Table.Costs.Headers.ForwardsInterbankRates'))
  }

  get ExportOptionsInterbankRatesData () {
    return this.getExportData(this.OptionsInterbankRates, i18n.t('Hedging.Table.Costs.Headers.OptionsInterbankRates'))
  }

  get ExportCollarInterbankRatesData () {
    return this.getExportData(this.CollarInterbankRates, i18n.t('Hedging.Table.Costs.Headers.CollarInterbankRates'))
  }

  get ExportEstimatedBankFeesData () {
    return this.getExportData(this.EstimatedBankFees, i18n.t('Hedging.Table.Costs.Headers.EstimatedBankFees'))
  }

  /* METHODS */
  getExportData (data, headers) {
    const v = []

    if (data && data.length && headers) {
      data.forEach(item => {
        const row = {}

        for (const [key, value] of Object.entries(headers)) {
          const val = item[`${ key }Fmt`] || item[key]
          row[value] = `"${ val }"`
        }

        v.push(row)
      })
    }

    return v
  }
}
