import API              from '@/api/Api'
import UserModel        from '@/api/models/user/UserModel'
import { AES, HmacMD5 } from '@/lib/crypto/crypto'
import DataEventService from '@/lib/services/event/DataEventService'
import DataEventEnum    from '@/lib/services/enum/DataEventEnum'
import { first, last }  from '@/lib/utils/array'

export default {
  name: 'AuthGQLApi',
  data () {
    return {}
  },
  methods: {
    login (username, password) {
      this.$dataStore.User = null
      this.isAuthLoading = true

      API.Resource.User.Login(username, password)
        .then(response => {
          this.handleLoginResponse(response)
        })
        .catch(e => {
          this.handleLoginResponse(e.response)
        })
        .finally(() => {
          this.isAuthLoading = false
        })
    },

    handleLoginResponse (response) {
      const data = API.responseData(response)?.login || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data.token && data.user) {
        this.onLoginSuccess(response, data)
      } else {
        this.onLoginError(response, errors)
      }
    },

    onLoginSuccess (response, data) {
      const storageKey = HmacMD5.hash('ROUTE')
      const storedRoute = this.$sessionStorage.get(storageKey, null) ? AES.decrypt(this.$sessionStorage.get(storageKey)) : null

      this.loginError = false
      this.authorizationToken = data.token
      this.user = new UserModel(data.user)

      this.$nextTick(() => {
        this.$bus.$emit('Auth:Login:Success', this.user)
        DataEventService.Emit(DataEventEnum.EVENT, {
          Event  : DataEventEnum.LOGIN,
          Payload: { User: this.user }
        })

        if (storedRoute) {
          this.$router.replace(storedRoute)
        } else {
          this.$router.replace({ name: 'Dashboard' })
            .catch(e => {})
            .finally(() => {})
        }
      })
    },

    onLoginError (response, errors) {
      if (response) {
        this.loginErrorConnection = false
      } else {
        this.loginErrorConnection = true
      }
      this.authError = true
    },

    logout () {
      const user = this.user
      const storageKey = HmacMD5.hash('ROUTE')
      this.clearUserData()
      this.clearStorageData()

      this.$sessionStorage.remove(storageKey)

      this.$router.push({
        name : 'Login',
        query: {}
      })

      this.$bus.$emit('Auth:Logout:Success', user)
      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.LOGOUT,
        Payload: { User: user }
      })
    },

    clearUserData () {
      this.$dataStore.User = null
      this.authorizationToken = ''
      this.user = null
    },

    clearStorageData () {
      const all = [
        'All',
        'Data',
        'Currencies',
        'Commodities',
        'CommonCurrencies',
        'Forward.Rolling',
        'Option.Rolling',
        'Collar.Rolling'
      ]
      const allNull = [
        'Dashboard.RiskSummary.Data',
        'Dashboard.HedgingPositions.Data',
        'Risks.Active.Data'
      ]

      for (const root in this.$dataStore) {
        const store = this.$dataStore[root]

        if (store) {
          for (const [key, value] of Object.entries(store)) {
            all.forEach(s => {
              // Root level (e.g. Assets...)
              if (key === s) {
                store[key] = Array.isArray(store[key]) ? [] : {}
              }
              if (value.hasOwnProperty(s)) {
                const keyName = `${ root }.${ key }.${ s }`
                if (value[s]) {
                  value[s] = (allNull.find(key => key === keyName)) ? null : (Array.isArray(value[s]) ? [] : {})
                }
              } else {
                if (s.includes('.')) {
                  const arr = s.split('.')
                  const f = first(arr)
                  const l = last(arr)

                  if (value.hasOwnProperty(f)) {
                    if (value[f]) {
                      if (value[f].hasOwnProperty('Data')) {
                        value[f].Data = Array.isArray(value[f].Data) ? [] : {}
                      }
                      if (value[f].hasOwnProperty(l)) {
                        if (value[f][l]) {
                          if (value[f][l].hasOwnProperty('Data')) {
                            value[f][l].Data = Array.isArray(value[f][l].Data) ? [] : {}
                          }
                        }
                      }
                    }
                  }
                }
              }
            })
          }
        }
      }
    }
  }
}
