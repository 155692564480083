<template>
  <v-container
    ref="suggestedStrategy"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <instrument-optimal-hedge-ratio-block
          :chart-animation="chartAnimation"
          :chart-height="chartHeight"
          :disable-lazy="disableLazy"
          :grid-margin-bottom="0"
          :policy-id="id"
          tooltip-show
        />
        <hedging-summary-list-block
          :chart-animation="chartAnimation"
          :disable-lazy="disableLazy"
          :policy-id="id"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import policyId  from '@/theme/default/views/hedging/mixins/policyId'
import API       from '@/api/Api'
import { clone } from '@/lib/utils/helper'

import HedgingSummaryResource           from '@/api/resources/hedging/HedgingSummaryResource'
import HedgingSummaryListBlock          from '@/theme/default/views/hedging/blocks/HedgingSummaryListBlock'
import InstrumentChartTypeEnum          from '@/api/enums/InstrumentChartTypeEnum'
import InstrumentTypeEnum               from '@/api/enums/InstrumentTypeEnum'
import InstrumentOptimalHedgeRatioBlock from '@/theme/default/views/hedging/blocks/instrument/InstrumentOptimalHedgeRatioBlock'
import useExportAdditional              from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingSummaryBlock',
  components: {
    InstrumentOptimalHedgeRatioBlock,
    HedgingSummaryListBlock
  },
  directives: {},
  mixins    : [policyId, useExportAdditional],
  props     : {},
  dataStore : {
    hedgingSummaryData: 'Hedging.Summary.Data',
    hedgingCostsData  : 'Hedging.Costs.Data',
    hedgingInstrument : 'Hedging.Instrument'
  },
  enums: {
    InstrumentChartTypeEnum,
    InstrumentTypeEnum
  },
  dataModel: null,
  data () {
    return {
      chartHeight: 280
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Hedging.Summary(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.findPolicy || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        if (o) {
          this.$set(this.hedgingSummaryData, this.id, new HedgingSummaryResource(o))
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
