import BaseResource                           from '@/lib/data/resource/BaseResource'
import AlertTriggerConditionThresholdTypeEnum from '@/api/enums/AlertTriggerConditionThresholdTypeEnum'

export default class TriggerInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? ''
    this.tradeId = payload.tradeId ?? ''
    this.portfolioId = payload.portfolioId ?? ''
    this.assetCode = payload.assetCode ?? ''
    this.conditionType = payload.conditionType ?? AlertTriggerConditionThresholdTypeEnum.UpperLimit
    this.snapshotValue = payload.selectedItem?.value ?? payload.threshold
    this.threshold = payload.threshold ?? ''
    this.displayThreshold = payload.displayThreshold ?? ''
    this.selectedItem = payload.selectedItem ?? null
  }

  /* PROPERTIES */

  /* METHODS */
}
