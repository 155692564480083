import BaseResource                    from '@/lib/data/resource/BaseResource'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskSeasonalityAssetItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.seasonality ?? ''

    this.OnePercentRisk = payload.onePercentRisk ? toNumberFormatNotationShort(payload.onePercentRisk) : ''
    this.OnePercentRiskRaw = payload.onePercentRisk ?? ''

    this.FivePercentRisk = payload.fivePercentRisk ? toNumberFormatNotationShort(payload.fivePercentRisk) : ''
    this.FivePercentRiskRaw = payload.fivePercentRisk ?? ''

    this.Mean = payload.mean ? toNumberFormatNotationShort(payload.mean) : ''
    this.MeanRaw = payload.mean ?? ''

    this.Impact = payload.impact
    this.ImpactRaw = payload.impact
  }

  /* PROPERTIES */

  /* METHODS */
}
