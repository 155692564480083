import BaseModel              from '@/lib/data/model/BaseModel'
import HedgingPolicyValidator from '@/api/validations/hedging/HedgingPolicyValidator'
import HedgingPolicyResource  from '@/api/resources/hedging/HedgingPolicyResource'
import API                    from '@/api/Api'
import { sum }                from '@/lib/utils/array'

export default class HedgingPolicyModel extends BaseModel {
  constructor (payload = {}, resource = HedgingPolicyResource, validator = HedgingPolicyValidator) {
    super(payload, resource, validator)
  }

  /* PROPERTIES */

  /* METHODS */
  boot () {
    super.boot()

    this.guarded = ['exposuresList', 'id']
  }

  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)
    data.strikeMoneynessOption = data.strikeMoneynessOption ? data.strikeMoneynessOption : 0
    data.strikeMoneynessRiskReversal = data.strikeMoneynessRiskReversal ? data.strikeMoneynessRiskReversal : 0
    if (data.activeAnalysisUsed) {
      data.exposures = []
    } else {
      data.exposures = data.exposures.filter(exposure => (exposure.userProvidedSeasonality && sum(exposure.seasonality) !== 0) || !exposure.userProvidedSeasonality).map(exposure => {
        delete exposure.selectedItem
        delete exposure.userProvidedSeasonality
        delete exposure.asset
        return exposure
      })
    }
    data.includeNaturalHedging = data.includeNaturalHedging ?? false
    return data
  }

  hasExcludedExposureAssets () {
    return !!this.exposures.filter(exposure => exposure.userProvidedSeasonality && sum(exposure.seasonality) === 0).length > 0
  }

  async CreateAnalysis () {
    const payload = this.clone()

    return await API.Resource.Hedging.CreatePolicy(payload)
  }

  async PersistAnalysis () {
    return await API.Resource.Hedging.PersistPolicy(this.policyId)
  }
}
