import BaseResource                    from '@/lib/data/resource/BaseResource'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskSummaryAssetAggregateResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.itemName ?? ''

    this.ValueAtRisk = payload.valueAtRisk ? toNumberFormatNotationShort(payload.valueAtRisk) : ''
    this.ValueAtRiskRaw = payload.valueAtRisk ?? ''

    this.Historical = payload.historicalRisk ? toNumberFormatNotationShort(payload.historicalRisk) : ''
    this.HistoricalRaw = payload.historicalRisk ?? ''

    this.ExtremeEvent = payload.eventsRisk ? toNumberFormatNotationShort(payload.eventsRisk) : ''
    this.ExtremeEventRaw = payload.eventsRisk ?? ''

    this.Simulation = payload.simulationRisk ? toNumberFormatNotationShort(payload.simulationRisk) : ''
    this.SimulationRaw = payload.simulationRisk ?? ''

    this.AnnualRisk = payload.annualRisk ? toNumberFormatNotationShort(payload.annualRisk) : ''
    this.AnnualRiskRaw = payload.annualRisk ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
