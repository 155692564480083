import BaseResource                           from '@/lib/data/resource/BaseResource'
import AssetModel                             from '@/api/models/asset/AssetModel'
import { toNumberFormatNotationShort }        from '@/lib/utils/helper'
import i18n                                   from '@/lang/lang'
import { collect }                            from '@/lib/utils/array'
import HedgingStrategySummaryCostItemResource from '@/api/resources/hedging/HedgingStrategySummaryCostItemResource'

export default class HedgingSummaryAssetStrategyResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.asset ?? ''
    this.Asset = new AssetModel(payload.detailedAsset ?? {})
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)

    this.PreferredInstrument = payload.preferredInstrument ?? '' // 'Forward', 'Option', 'Collar'
    this.PreferredInstrumentDisplay = payload.displayPreferredInstrument ?? ''
    this.PreferredOptimalHedgeRatio = payload.optimalHedgeRatio ?? ''
    this.UnhedgedRisk = payload.unhedgedRisk ?? ''
    this.HedgedRisk = payload.hedgedRisk ?? ''
    this.Max = payload.max ?? ''
    this.Cost = payload.cost ?? ''
    this.CostBreakdown = payload.costBreakdown ? collect(payload.costBreakdown, HedgingStrategySummaryCostItemResource) : null
    this.CostFmt = payload.cost ? toNumberFormatNotationShort(payload.cost) : ''
    this.PotentialSaving = payload.potentialSaving ?? ''
    this.PotentialSavingFmt = payload.potentialSaving ? toNumberFormatNotationShort(payload.potentialSaving) : ''
    this.HedgeAmount = payload.hedgeAmount ?? ''
    this.HedgeAmountFmt = payload.hedgeAmount ? toNumberFormatNotationShort(payload.hedgeAmount) : ''

    this.ForwardsRisk = payload.forwardsRisk ?? ''
    this.ForwardsRiskFmt = payload.forwardsRisk ? toNumberFormatNotationShort(payload.forwardsRisk) : ''
    this.OptionsRisk = payload.optionsRisk ?? ''
    this.OptionsRiskFmt = payload.optionsRisk ? toNumberFormatNotationShort(payload.optionsRisk) : ''
    this.CollarsRisk = payload.collarsRisk ?? ''
    this.CollarsRiskFmt = payload.collarsRisk ? toNumberFormatNotationShort(payload.collarsRisk) : ''
  }

  /* PROPERTIES */
  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get Card () {
    return {
      Name                    : this.Name,
      Type                    : this.PreferredInstrument,
      TypeDisplay             : this.PreferredInstrumentDisplay,
      HedgeAmount             : this.HedgeAmountFmt,
      BaseCurrency            : this.BaseCurrencyDisplayName,
      HedgeRatio              : `${ this.PreferredOptimalHedgeRatio }%`,
      Cost                    : this.CostFmt,
      CostBreakdown           : this.CostBreakdown,
      PotentialSaving         : this.PotentialSavingFmt,
      PotentialSavingTextColor: (this.PotentialSaving > 0 ? 'synthesis-ui-green-01' : 'synthesis-ui-red-01'),
      BarValues               : [{
        y  : [i18n.t('Hedging.Card.HedgedRisk.Title'), i18n.t('Hedging.Card.UnhedgedRisk.Title')],
        x  : [this.HedgedRisk, this.UnhedgedRisk],
        max: this.Max
      }],
      BarValuesUnhedgedRisk: [{
        y  : [i18n.t('Hedging.Card.UnhedgedRisk.Title')],
        x  : [this.UnhedgedRisk],
        max: this.UnhedgedRisk
      }],
      BarValuesHedgedRisk: [{
        y  : [i18n.t('Hedging.Card.HedgedRisk.Title')],
        x  : [this.HedgedRisk],
        max: this.UnhedgedRisk
      }]
    }
  }

  /* METHODS */
}
