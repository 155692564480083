<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <v-row>
      <section-header :breadcrumb-items="breadcrumb" />
    </v-row>

    <v-row>
      <v-col cols="12">
        <view-data-container :loading="isLoadingData">
          <template v-if="viewHasData">
            <v-row>
              <v-col
                v-for="(article, i) in items"
                :key="i"
                cols="12"
                md="4"
                sm="6"
                xl="3"
              >
                <v-card
                  class="mx-auto"
                  elevation="0"
                >
                  <div
                    :class="[article.ImageUrl ? 'hover-wrapper' : 'center-img']"
                    :style="article.ImageUrl ? '' : `${getGradientBg(i % colorsLen)}height:250px`"
                  >
                    <template v-if="article.ImageUrl">
                      <v-img
                        :src="`${article.ImageUrl}`"
                        eager
                        height="250"
                        position="center center"
                      />
                    </template>
                    <template v-else>
                      <div
                        class="d-flex justify-center align-center"
                        style="display: flex;justify-content: center;align-items: center;"
                      >
                        <v-img
                          eager
                          src="/img/knowledgeCenter/synthesis-icon.svg"
                          width="96"
                        />
                      </div>
                    </template>
                  </div>
                  <v-card-title>
                    {{ article.Title }}
                  </v-card-title>
                  <v-card-text>
                    <p class="ellipsis-summary">
                      {{ article.Summary }}
                    </p>
                  </v-card-text>
                  <v-card-actions class="justify-space-between">
                    <v-btn
                      color="primary"
                      link
                      text
                      @click="onItemCardClick(article)"
                    >
                      {{ $t('KnowledgeCenter.Article.ReadMore.Title') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <v-row v-if="items && (items.length < options.total)">
            <v-col class="text-center">
              <v-btn
                color="primary"
                outlined
                shaped
                @click="loadMore"
              >
                {{ $t('KnowledgeCenter.Button.LoadMore.Title') }}
              </v-btn>
            </v-col>
          </v-row>
        </view-data-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ChartsStyleEnum         from '@/api/enums/ChartsEnum'
import chartsColorMixin        from '@/theme/default/components/charts/mixins/chartsColorMixin'
import i18nRouteMeta           from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader           from '@/theme/default/components/common/SectionHeader'
import ViewDataContainer       from '@/theme/default/components/common/ViewDataContainer'
import viewHasData             from '@/mixins/viewHasData'
import API                     from '@/api/Api'
import { clone }               from '@/lib/utils/helper'
import { hexToRGB, hexToRGBA } from '@/lib/utils/color'
import ArticleSummaryResource  from '@/api/resources/knowledgeCenter/ArticleSummaryResource'
import ArticleListResource     from '@/api/resources/knowledgeCenter/ArticleListResource'

export default {
  name      : 'KnowledgeCenter',
  components: {
    ViewDataContainer,
    SectionHeader
  },
  directives: {},
  mixins    : [chartsColorMixin, i18nRouteMeta, viewHasData],
  props     : {},
  dataStore : {
    items                     : 'KnowledgeCenter.List.Data',
    knowledgeCenterArticleData: 'KnowledgeCenter.Article.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      options: {
        itemsPerPage: 8,
        page        : 1,
        total       : 0
      },
      colorsLen: ChartsStyleEnum.Colors.KnowledgeCenter.length
    }
  },
  computed: {
    breadcrumb () {
      return [{
        text    : this.$t('KnowledgeCenter.Title'),
        disabled: true
      }]
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    loadMore () {
      if (this.items && (this.items.length < this.options.total)) {
        this.options.page++
        this.getData()
      }
    },

    getData () {
      this.isLoadingData = true

      API.Resource.KnowledgeCenter.List({
        page        : (this.options.page - 1),
        itemsPerPage: this.options.itemsPerPage
      })
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response)?.listArticles || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        if (data) {
          const o = clone(data)
          const v = new ArticleListResource(o)

          if (this.items && (this.items.length < this.options.total)) {
            this.items.push(...v.Items)
          } else {
            this.items = v.Items
          }
          this.options.total = v.Total
          this.options.itemsPerPage = v.ItemsPerPage
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onItemCardClick (item) {
      this.getItemData(item)

      this.$router.push({
        name  : 'KnowledgeCenterArticleShow',
        params: {
          id       : item.Id,
          articleId: item.Id
        }
      })
    },

    getItemData (item) {
      API.Resource.KnowledgeCenter.Article(item.Id)
        .then(response => {
          this.handleItemResponse(response)
        })
        .catch(e => {
          this.handleItemResponse(e.response)
        })
        .finally(() => {})
    },

    handleItemResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const p = data?.article || null
        if (p) {
          const o = clone(p)
          const v = new ArticleSummaryResource(o)

          // Store data to DataStore
          if (v?.Article?.Id) {
            this.$set(this.knowledgeCenterArticleData, v.Article.Id, v)
          }
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getColorHex (hex) {
      return this.$vuetify.theme.themes.light[hex]
    },

    getColorRGB (hex) {
      return hexToRGB(this.getColorHex(hex))
    },

    getColorRGBA (hex, opacity = 1) {
      return hexToRGBA(this.getColorHex(hex), opacity)
    },

    getGradientBg (index) {
      // https://cssgradient.io/
      const color = ChartsStyleEnum.Colors.KnowledgeCenter[index]
      const c1 = color[0]
      const c2 = color[1]
      const colorBg = this.getColorRGB(c1)
      const color1 = this.getColorRGBA(c1)
      const color2 = this.getColorRGBA(c2)
      const colorHex1 = this.getColorHex(c1)
      const colorHex2 = this.getColorHex(c2)

      return `background: ${ colorBg };
        background: -moz-linear-gradient(90deg, ${ color1 } 50%, ${ color2 } 100%);
        background: -webkit-linear-gradient(90deg, ${ color1 } 50%, ${ color2 } 100%);
        background: linear-gradient(90deg, ${ color1 } 50%, ${ color2 } 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${ colorHex1 }",endColorstr="${ colorHex2 }",GradientType=1);`
    }
  }
}
</script>

<style scoped>
.center-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-card__title {
  word-break: normal;
}

.ellipsis-summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.hover-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}

.hover-wrapper .v-image {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hover-wrapper:hover .v-image {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
