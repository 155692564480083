import BaseResource                        from '@/lib/data/resource/BaseResource'
import { collect }                         from '@/lib/utils/array'
import RiskHistoricalSpectrumAssetResource from '@/api/resources/risks/RiskHistoricalSpectrumAssetResource'
import AssetNameResource                   from '@/api/resources/asset/AssetNameResource'

export default class RiskHistoricalSpectrumResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.historicalExposure || null

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Assets = collect(o?.assets || [], AssetNameResource)
    this.Data = collect(o?.data || [], RiskHistoricalSpectrumAssetResource)
    this.Dates = o?.dates || []
  }

  /* PROPERTIES */

  /* METHODS */
}
