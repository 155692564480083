export const Industry = {
  Archive: {
    Data: {}
  },

  Summary: {
    Data: {}
  },

  Table: {
    Data: {}
  },

  List: {
    Data: []
  },

  Attributes: {
    Data: {}
  }
}
