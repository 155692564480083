<template>
  <v-col cols="12">
    <block-item-card
      ref="forwardSimulations"
      :base-currency-symbol="riskBaseCurrencyDisplayName"
      :export-element="elementForwardSimulations"
      :title="$t('Risks.Section.MonteCarloSimulation.Title')"
      :title-large="false"
      :title-value="onePercentRisk"
      :title-value-color-red="true"
      :tooltip="tooltip"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #actions
      >
        <v-spacer />
        <asset-button-group
          :items="assets"
          :selected.sync="selectedAsset"
          class="mt-4"
        />
        <button-text-toggle
          :icon="toggleChart === 'bar' ? 'mdi-chart-line-variant' : 'mdi-chart-bar'"
          class="mt-4 ml-2"
          @button-text-toggle-click="changeChart"
        />
      </template>
      <template
        v-else
        #actions-row
      >
        <v-card-title
          class="ml-3 pa-0"
          :class="`${aggregateItem ? 'mb-2' : 'mb-4'}`"
        >
          <asset-button-group
            :items="assets"
            :selected.sync="selectedAsset"
          >
            <template #extra>
              <button-text-toggle
                :height="36"
                :icon="toggleChart === 'bar' ? 'mdi-chart-line-variant' : 'mdi-chart-bar'"
                class="mx-3"
                @button-text-toggle-click="changeChart"
              />
            </template>
          </asset-button-group>
        </v-card-title>
        <v-card-title class="mx-3 mb-4 pa-0">
          <asset-button-group
            v-if="aggregateItem"
            :items="aggregateAssets"
            :selected.sync="selectedAggregateAsset"
          />
        </v-card-title>
      </template>

      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        #avatar
      >
        <v-spacer />
        <asset-button-group-orange
          v-if="aggregateItem"
          :items="aggregateAssets"
          :selected.sync="selectedAggregateAsset"
          :mandatory="false"
          class="mt-3 mr-13"
        />
      </template>

      <v-row class="ma-0 mb-5">
        <v-col
          class="ma-0"
          cols="12"
        >
          <v-row
            v-if="chartDataValues && chartDataValues.length"
            class="ma-0 flex-md-nowrap"
          >
            <v-col
              class="pa-0 flex-shrink-1"
              cols="12"
              lg="9"
              md="8"
              xl="10"
            >
              <v-lazy
                :options="{threshold: .5}"
                transition="fade-transition"
              >
                <e-chart-forward-simulations
                  :axis-x-boundary-gap="true"
                  :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
                  :bar-background-color="themeColorRGBA('synthesis-ui-grey-06', 0.1)"
                  :chart-data-options="chartDataValues"
                  :chart-height="350"
                  :chart-scale="true"
                  :graph-line-show="true"
                  :has-line-bell="false"
                  :is-bar-chart="isBarChart"
                  axis-x-label-value-format=""
                  axis-y-label-value-format="percent"
                  chart-animation-easing="quarticOut"
                  chart-ref-id="ForwardSimulations"
                />
              </v-lazy>
            </v-col>
            <v-col
              class="pa-0"
              cols="12"
              lg="3"
              md="4"
              style="min-width: 270px"
              xl="2"
            >
              <v-row
                class="ma-0"
              >
                <v-col
                  :class="[{'pr-sm-1': $vuetify.breakpoint.sm && !appToolbarExtended}]"
                  :sm="appToolbarExtended ? 12 : 6"
                  class="ma-0 pa-0"
                  cols="12"
                  md="12"
                >
                  <item-chart-legend
                    :key="`forward-simulations-legend-risk-one-pct`"
                    :background-color="themeColorRGBA('synthesis-ui-orange-08')"
                    :base-currency-symbol="riskBaseCurrencyDisplayName"
                    :icon-color="themeColorRGBA('synthesis-ui-orange-02')"
                    :title="$t('Risks.Section.MonteCarloSimulation.Block.Distribution.Risk_Pct_1.Title')"
                    :title-value="onePercentileRisk"
                    :title-value-color="themeColorRGBA('synthesis-brand-dark-blue')"
                    :title-value-size="18"
                    :tooltip="$t('Risks.Section.MonteCarloSimulation.Block.Distribution.Risk_Pct_1.InfoTip')"
                    class="pa-5 pt-6"
                    style="min-width: 250px;min-height: 70px;height: 70px"
                  />
                </v-col>
                <v-col
                  :class="[{'pl-sm-1': $vuetify.breakpoint.sm && !appToolbarExtended}]"
                  :sm="appToolbarExtended ? 12 : 6"
                  class="ma-0 pa-0"
                  cols="12"
                  md="12"
                >
                  <item-chart-legend
                    :key="`forward-simulations-legend-risk-five-pct`"
                    :background-color="themeColorRGBA('synthesis-ui-green-08')"
                    :base-currency-symbol="riskBaseCurrencyDisplayName"
                    :icon-color="themeColorRGBA('synthesis-ui-green-02')"
                    :title="$t('Risks.Section.MonteCarloSimulation.Block.Distribution.AverageCashFlow.Title')"
                    :title-value="averageCashFlow"
                    :title-value-color="themeColorRGBA('synthesis-brand-dark-blue')"
                    :title-value-size="18"
                    :tooltip="$t('Risks.Section.MonteCarloSimulation.Block.Distribution.AverageCashFlow.InfoTip')"
                    class="pa-5 pt-6"
                    style="min-width: 250px;min-height: 70px;height: 70px"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="ma-0 py-0"
          cols="12"
        >
          <seasonality-radio
            :disabled="!isBarChart"
            :selected.sync="selectedSeasonality"
            class="pt-0 mt-2"
          />
        </v-col>
      </v-row>
    </block-item-card>
  </v-col>
</template>

<script>

import chartsColorMixin               from '@/theme/default/components/charts/mixins/chartsColorMixin'
import API                            from '@/api/Api'
import { clone }                      from '@/lib/utils/helper'
import RiskForwardSimulationsResource from '@/api/resources/risks/RiskForwardSimulationsResource'
import AssetButtonGroup               from '@/theme/default/components/common/AssetButtonGroup'
import BlockItemCard                  from '@/theme/default/components/common/BlockItemCard'
import riskAnalysisId                 from '@/theme/default/views/risks/mixins/riskAnalysisId'
import SeasonalityRadio               from '@/theme/default/components/common/SeasonalityRadio'
import ItemChartLegend                from '@/theme/default/components/common/ItemChartLegend'
import EChartForwardSimulations       from '@/theme/default/components/charts/echarts/EChartForwardSimulations'
import riskBaseCurrency               from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import ButtonTextToggle               from '@/theme/default/components/common/ButtonTextToggle'
import AssetButtonGroupOrange         from '@/theme/default/components/common/AssetButtonGroupOrange.vue'

export default {
  name      : 'MethodThreeBlock',
  components: {
    AssetButtonGroupOrange,
    ButtonTextToggle,
    EChartForwardSimulations,
    ItemChartLegend,
    SeasonalityRadio,
    BlockItemCard,
    AssetButtonGroup
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId],
  props     : {},
  dataStore : {
    appToolbarExtended        : 'App.Config.AppToolbarExtended',
    riskForwardSimulationsData: 'Risks.ForwardSimulations.Data',
    riskBaseCurrencyData      : 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      toggleChart              : 'bar',
      selectedAsset            : 'portfolio',
      selectedAggregateAsset   : null,
      selectedSeasonality      : 'user',
      dates                    : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      elementForwardSimulations: null
    }
  },
  computed: {
    isBarChart () {
      return (this.toggleChart === 'bar')
    },

    tooltip () {
      return this.isBarChart ? this.$t('Risks.Section.MonteCarloSimulation.InfoTip') : this.$t('Risks.Section.MonteCarloSimulation.Block.SimulatedPaths.InfoTip')
    },

    forwardSimulations () {
      return this.riskForwardSimulationsData[this.id] || null
    },

    assets () {
      return this.forwardSimulations?.Assets || []
    },

    forwardSimulationsData () {
      return this.forwardSimulations?.Data || null
    },

    selectedItem () {
      return this.forwardSimulationsData?.find(item => item.AssetValue === this.selectedAsset) || null
    },

    aggregateItem () {
      return this.selectedItem?.AggregateItem || false
    },

    aggregateAssets () {
      return this.selectedItem?.PerCashflowCodeNames || []
    },

    selectedAggregateItem () {
      return this.selectedItem?.PerCashflowItems?.find(item => item.AssetValue === this.selectedAggregateAsset) || null
    },

    selectedFinalItem () {
      return this.aggregateItem && this.selectedAggregateItem ? this.selectedAggregateItem : this.selectedItem
    },

    onePercentRisk () {
      return this.selectedFinalItem?.Seasonality[this.selectedSeasonality]?.OnePercentRiskFmt || ''
    },

    onePercentileRisk () {
      return this.selectedFinalItem?.Seasonality[this.selectedSeasonality]?.OnePercentileFmt || ''
    },

    averageCashFlow () {
      return this.selectedFinalItem?.Seasonality[this.selectedSeasonality]?.MeanFmt || ''
    },

    distributionChart () {
      return this.selectedFinalItem?.Seasonality[this.selectedSeasonality].Values || []
    },

    monteCarloChart () {
      return [{
        dates : this.dates,
        values: this.selectedFinalItem?.CashFlowPathsValues || []
      }]
    },

    chartDataValues () {
      if (this.isBarChart) {
        return this.distributionChart
      } else {
        return this.monteCarloChart
      }
    }

  },
  watch: {
    selectedAsset () {
      this.setSelectedAssetAggregate()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.$nextTick(() => {
      this.elementForwardSimulations = this.$refs?.forwardSimulations?.$el
    })
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.ValueAtRiskSimulation(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
    },
    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskForwardSimulationsResource(o)

        this.$set(this.riskForwardSimulationsData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    changeChart () {
      this.toggleChart = this.toggleChart === 'bar' ? 'line' : 'bar'
    },
    setSelectedAssetAggregate () {
      this.selectedAggregateAsset = ''
    }
  }
}
</script>

<style scoped>

</style>
