<template>
  <v-container :class="[{'ma-0': noMargin}, {'pa-0': noContainerPadding}, containerPadding]">
    <v-row :class="[{'ma-0': noMargin}, {'pa-0': noPadding}, rowPadding]">
      <v-col :class="[{'ma-0': noMargin}, {'pa-0': noPadding}, colPadding]">
        <vue-blob-json-csv
          :data="exportData"
          :file-name="fileName"
          :file-type="exportFileType"
          @error="disable=false"
          @success="disable=false"
        >
          <infotip>
            <template #content="{on, attrs}">
              <v-hover v-slot="{hover}">
                <v-btn
                  :color="hover ? colorHover : color"
                  :dark="disable ? false : dark"
                  :height="large ? height: '100%'"
                  :icon="text.length ? false : icon"
                  :large="large"
                  :loading="disable"
                  depressed
                  v-bind="attrs"
                  @click="disable=true"
                  v-on="on"
                >
                  {{ text }}
                  <v-icon :class="[{'pl-2': text.length}]">
                    mdi-tray-arrow-down
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            {{ $t('Common.Infotip.Export', {title: title}) }}
          </infotip>
        </vue-blob-json-csv>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import dayjs            from 'dayjs'
import { toPascalCase } from '@/lib/utils/string'
import Infotip          from '@/theme/default/components/common/Infotip'
import useExport        from '@/mixins/useExport'

export default {
  name      : 'ExportToCsv',
  components: { Infotip },
  directives: {},
  mixins    : [useExport],
  props     : {
    exportFileName: {
      type   : String,
      default: ''
    },
    exportFileType: {
      type   : String,
      default: 'csv'
    },
    exportData: {
      type   : Array,
      default: () => []
    },
    noMargin: {
      type   : Boolean,
      default: false
    },
    noPadding: {
      type   : Boolean,
      default: false
    },
    noContainerPadding: {
      type   : Boolean,
      default: false
    },
    containerPadding: {
      type   : String,
      default: ''
    },
    rowPadding: {
      type   : String,
      default: ''
    },
    colPadding: {
      type   : String,
      default: ''
    },
    large: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    icon: {
      type   : Boolean,
      default: true
    },
    color: {
      type   : String,
      default: 'synthesis-ui-grey-02'
    },
    colorHover: {
      type   : String,
      default: 'synthesis-ui-grey-01'
    },
    text: {
      type   : String,
      default: ''
    },
    height: {
      type   : Number,
      default: 42
    },
    toPascalCaseTitle: {
      type   : Boolean,
      default: true
    }
  },
  enums: {},
  data () {
    return {
      disable: false,
      nodes  : []
    }
  },
  computed: {
    title () {
      return this.exportFileName ? `${ this.exportFileName }` : `${ this.$route.name }`
    },

    fileName () {
      const prefixTitle = toPascalCase(this.$t('Common.App.Title'))
      const title = this.toPascalCaseTitle ? toPascalCase(this.title) : this.title
      const dateTime = dayjs().toISOString()

      return `${ prefixTitle }-${ title }-${ dateTime }`
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
