import BaseResource           from '@/lib/data/resource/BaseResource'
import { collect }            from '@/lib/utils/array'
import TradeInputEditResource from '@/api/resources/positions/TradeInputEditResource'

export default class PositionEditResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.accountId = ''
    this.positionId = payload.Id ?? null
    this.baseCurrencyCode = payload.BaseCurrency?.symbol ?? ''
    this.name = payload.Name ?? ''
    this.trades = collect(payload.Trades ?? [], TradeInputEditResource)
  }

  /* METHODS */
}
