import BaseResource      from '@/lib/data/resource/BaseResource'
import AssetNameResource from '@/api/resources/asset/AssetNameResource'
import AssetResource     from '@/api/resources/asset/AssetResource'
import { collect }       from '@/lib/utils/array'
import HedgingModeEnum   from '@/api/enums/HedgingModeEnum'

export default class HedgingPolicyParametersResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Assets = collect(payload.assets ?? [], AssetNameResource)
    this.Carry = payload.carry ?? ''
    this.CarryVol = payload.carryVol ?? ''
    this.ImpliedVol = payload.impliedVol ?? ''
    this.DetailedAssets = collect(payload.detailedAssets ?? [], AssetResource)
    this.FlatStrikeVsBenchmarkFlag = payload.flatStrikeVsBenchmarkFlag ?? false
    this.HistogramBinsMethod = payload.histogramBinsMethod ?? 'User' // Sturges
    this.HistogramBinsNumber = payload.histogramBinsNumber ?? ''
    this.HistoricalWindow = payload.historicalWindow ?? 'All' // Window
    this.MarketRatesUsed = payload.marketRatesUsed ?? false
    this.Mode = payload.mode ?? HedgingModeEnum.Historical
    this.MoneynessBenchmark = payload.moneynessBenchmark ?? 'ForwardsCurve' // SpotToday
    this.StrikeMoneynessOption = payload.strikeMoneynessOption ?? ''
    this.StrikeMoneynessRiskReversal = payload.strikeMoneynessRiskReversal ?? ''
    this.WindowEndDate = payload.windowEndDate ?? ''
    this.WindowStartDate = payload.windowStartDate ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
