<template>
  <view-data-container
    :loading="isLoadingData"
    :style="`${hasForecastFlow ? '' : 'min-height: calc(100vh - 160px);'}`"
  >
    <v-row
      v-if="hasForecastFlow"
    >
      <v-col>
        <risk-forecast-flow-show-block :id="id" />
      </v-col>
    </v-row>
  </view-data-container>
</template>

<script>

import i18nRouteMeta             from '@/mixins/i18n/i18nRouteMeta'
import riskAnalysisId            from '@/theme/default/views/risks/mixins/riskAnalysisId'
import API                       from '@/api/Api'
import { clone }                 from '@/lib/utils/helper'
import ViewDataContainer         from '@/theme/default/components/common/ViewDataContainer'
import RiskForecastFlowResource  from '@/api/resources/risks/RiskForecastFlowResource'
import RiskForecastFlowShowBlock from '@/theme/default/views/risks/blocks/RiskForecastFlowShowBlock.vue'

export default {
  name      : 'RiskForecastFlowBlock',
  components: {
    RiskForecastFlowShowBlock,
    ViewDataContainer
  },
  directives: {},
  mixins    : [i18nRouteMeta, riskAnalysisId],
  props     : {},
  dataStore : {
    riskForecastFlowData: 'Risks.ForecastFlow.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      isLoadingData: true
    }
  },
  computed: {
    forecastFlow () {
      return this.riskForecastFlowData[this.id]
    },

    hasForecastFlow () {
      return !!this.forecastFlow
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.RiskForecastFlow(this.user.selectedAccountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },

    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findLatestForecastFlow || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskForecastFlowResource(o)

        this.$set(this.riskForecastFlowData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
