import BaseResource                                   from '@/lib/data/resource/BaseResource'
import i18n                                           from '@/lang/lang'
import { toDecimalMark, toNumberFormatNotationShort } from '@/lib/utils/helper'
import AssetEnum                                      from '@/api/enums/AssetEnum'

export default class RiskAnalysisResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? ''
    this.accountId = payload.accountId ?? ''
    this.activeVar = payload.activeVar ?? true
    this.activeHistorical = payload.activeHistorical ?? false
    this.activeEvents = payload.activeEvents ?? false
    this.activeSimulation = payload.activeSimulation ?? false

    this.name = payload.name ?? ''
    this.baseCurrencyCode = payload.baseCurrencyCode ?? ''
    this.exposures = payload.exposures ?? []
    this.riskAnalyses = payload.riskAnalyses ?? []
    this.tags = payload.tags ?? []

    this.multipleExposuresPerAsset = payload.multipleExposuresPerAsset ?? false
  }

  /* PROPERTIES */
  get exposuresList () {
    const retVal = []
    this.exposures.forEach(exposure => {
      const actionsEnabled = !exposure?.selectedItem?.fromDebtProfile
      const isInterbankRate = exposure?.selectedItem?.pair?.base?.type === AssetEnum.INTERBANK_RATE
      const asset = exposure?.selectedItem?.pair?.base?.displayName || ''
      const notional = exposure?.selectedItem?.pair?.base?.type === AssetEnum.CURRENCY
        ? `${ toDecimalMark(parseFloat(exposure.notional), 0) }`
        : `${ toDecimalMark(parseFloat(exposure.notional), 0) } ${ exposure?.selectedItem?.quotation?.displayUnit ?? '' }`
      const notionalClass = parseFloat(exposure.notional) < 0 ? 'error--text' : ''
      const notionalBase = exposure?.selectedItem?.pair?.base?.type === AssetEnum.CURRENCY
        ? `${ exposure?.selectedItem?.pair?.quote?.displaySymbol } ${ toDecimalMark(parseFloat(exposure.notional) * exposure?.selectedItem?.price, 0) }`
        : `${ exposure?.selectedItem?.pair?.quote?.displaySymbol } ${ toDecimalMark(parseFloat(exposure.notional) * exposure?.selectedItem?.price, 0) }`
      const notionalBaseClass = parseFloat(exposure.notional) * exposure?.selectedItem?.price < 0 ? 'error--text' : ''
      const seasonality = exposure.userProvidedSeasonality ? i18n.t('Common.Button.Yes') : i18n.t('Common.Button.No')
      const label = exposure?.label || ''
      const name = exposure?.name || ''

      const showExposureName = this.exposures.filter(e => e.assetCode === exposure.assetCode).length > 1 && this.multipleExposuresPerAsset

      if (isInterbankRate) {
        retVal.push({
          Exposure      : exposure,
          ActionsEnabled: actionsEnabled,
          Items         : [
            {
              Title: i18n.t('Risks.Edit.Form.Fields.Exposures.Asset'),
              Value: asset
            },
            {
              Title     : i18n.t('Risks.Edit.Form.Fields.Exposures.InterbankRate'),
              Value     : notional,
              ValueClass: notionalClass
            },
            {
              Title     : i18n.t('Risks.Edit.Form.Fields.Exposures.AnnualInterest'),
              Value     : notionalBase,
              ValueClass: notionalBaseClass
            },
            {
              Title: i18n.t('Risks.AddExposure.Form.Fields.Label.Label'),
              Value: label
            }
          ]
        })
      } else {
        retVal.push({
          Exposure      : exposure,
          ActionsEnabled: actionsEnabled,
          Items         : [
            {
              Title: i18n.t('Risks.Edit.Form.Fields.Exposures.Asset'),
              Value: asset
            },
            {
              Title     : i18n.t('Risks.Edit.Form.Fields.Exposures.AnnualExposure'),
              Value     : notional,
              ValueClass: notionalClass
            },
            {
              Title     : i18n.t('Risks.Edit.Form.Fields.Exposures.AnnualExposureInBaseCurrency'),
              Value     : notionalBase,
              ValueClass: notionalBaseClass
            },
            {
              Title: i18n.t('Risks.Edit.Form.Fields.Exposures.Seasonality'),
              Value: seasonality
            },
            {
              Title: i18n.t('Risks.AddExposure.Form.Fields.Label.Label'),
              Value: label
            },
            ...[showExposureName && {
              Title: i18n.t('Risks.AddExposure.Form.Fields.Code.Label'),
              Value: name
            }]
          ]
        })
      }
    })

    return retVal
  }

  get riskAnalysesList () {
    const retVal = []
    this.riskAnalyses.forEach(item => {
      const analysis = item?.selectedItem?.name || ''
      const totalRisk = toNumberFormatNotationShort(item?.selectedItem?.result?.summary?.portfolioExposure ?? 0)
      const totalRiskClass = parseFloat(item?.selectedItem?.result?.summary?.portfolioExposure) < 0 ? 'error--text' : ''
      const hedgeRatio = Object.values(item?.hedgeRatios ?? {}).some(asset => parseFloat(asset.ratio) > 0) ? i18n.t('Common.Button.Yes') : i18n.t('Common.Button.No')

      retVal.push({
        Analysis: item,
        Items   : [
          {
            Title: i18n.t('Risks.Edit.Form.Fields.Exposures.Analysis'),
            Value: analysis
          },
          {
            Title     : i18n.t('Risks.Edit.Form.Fields.Exposures.TotalRisk'),
            Value     : totalRisk,
            ValueClass: totalRiskClass
          },
          {
            Title: i18n.t('Risks.Edit.Form.Fields.Exposures.HedgeRatio'),
            Value: hedgeRatio
          }
        ]
      })
    })

    return retVal
  }

  /* METHODS */
}
