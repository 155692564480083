import { API } from '@/api/Api'

import {
  ChartQuery,
  FunFactsQuery,
  ListAllAssetsLivePricesQuery,
  ListAllMyLivePriceAssetPairsQuery,
  ListSurveysQuery,
  SaveSurveyMutation,
  SubscribedAssetsLivePricesQuery
} from '@/api/query/Dashboard.graphql'

export default {
  // Queries
  async Chart (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(ChartQuery, Data)
  },

  async SurveyList () {
    const Data = {}

    return await API.GQLRequest(ListSurveysQuery, Data)
  },

  async FunFacts () {
    const Data = {}

    return await API.GQLRequest(FunFactsQuery, Data)
  },

  async SubscribedAssetsLivePrices () {
    const Data = {}

    return await API.GQLRequest(SubscribedAssetsLivePricesQuery, Data)
  },

  async MyLivePriceAssetPairs () {
    const Data = {}

    return await API.GQLRequest(ListAllMyLivePriceAssetPairsQuery, Data)
  },

  async AllAssetsLivePrices (quoteCurrency) {
    const Data = { quoteCurrency: quoteCurrency || window?.Vue?.user?.selectedAccountBaseCurrency?.symbol || 'EUR' }

    return await API.GQLRequest(ListAllAssetsLivePricesQuery, Data)
  },

  // Mutations
  async SaveSurvey (surveyId, response) {
    const Data = {
      surveyId: surveyId,
      response: response
    }

    return await API.GQLRequest(SaveSurveyMutation, Data)
  }
}
