<template>
  <div>
    <template v-for="(item, itemIndex) in items">
      <div
        :key="`list-item-${itemIndex}`"
        class="synthesis-card-border mb-4"
        style="background-color: #FFFFFF;"
      >
        <v-container
          fill-height
          fluid
          style="min-height: 80px;"
        >
          <v-row>
            <template v-for="(data, contentIndex) in item.Items">
              <v-col
                :key="`list-item-${itemIndex}-content-${contentIndex}`"
                class="shrink justify-center align-start"
              >
                <div
                  :style="contentIndex === item.Items.length-1 ? 'margin-right: 40px;' : ''"
                  class="d-inline-block text-no-wrap"
                  style="min-width: 150px;"
                >
                  <div
                    :class="data.TitleClass ? data.TitleClass : 'synthesis-ui-grey-01--text'"
                    class="synthesis-inter-text text-size-12"
                  >
                    {{ data.Title }}
                  </div>
                  <template
                    v-if="!!data.Tags"
                  >
                    <v-chip
                      v-for="(tag, index) in data.Tags"
                      :key="`tag-${index}`"
                      :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
                      class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
                      label
                      x-small
                    >
                      {{ tag }}
                    </v-chip>
                  </template>
                  <div
                    v-else
                    :class="data.ValueClass ? data.ValueClass : 'synthesis-brand-dark-blue--text' "
                    class="text-size-16 font-weight-medium"
                  >
                    {{ data.Value }}
                  </div>
                </div>
              </v-col>

              <div
                v-if="actions && item.ActionsEnabled"
                :key="`list-item-${itemIndex}-content-btn-${contentIndex}`"
                class="mr-6 mt-4"
                style="position: absolute; right: 0;"
              >
                <v-menu
                  v-if="menuItems && menuItems.length"
                  bottom
                  offset-y
                >
                  <template #activator="{on, attrs}">
                    <v-btn
                      color="grey"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-dots-horizontal
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list class="pa-0 ma-0">
                    <v-list-item
                      v-for="(menuItem, menuIndex) in menuItems"
                      :key="`menu-item-${menuIndex}`"
                      :disabled="!menuItem.Active"
                      @click="$emit(menuItem.Event, item, itemIndex)"
                    >
                      <v-list-item-title class="text-size-14">
                        {{ menuItem.Title }}
                      </v-list-item-title>

                      <v-list-item-action>
                        <v-icon :color="menuItem.Active ? menuItem.Color : 'grey lighten-2'">
                          {{ menuItem.Icon }}
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  v-else
                  color="grey"
                  icon
                  @click="$emit('list-item-action-button-click', item)"
                >
                  <v-icon>
                    mdi-dots-horizontal
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-row>

          <v-row
            v-if="item.Excluded && item.ExcludedMessage"
            dense
          >
            <v-col xs12>
              <span class="error--text caption">{{ item.ExcludedMessage }}</span>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name      : 'ItemListCard',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    items: {
      type    : Array,
      required: true
    },
    menuItems: {
      type   : Array,
      default: undefined
    },
    actions: {
      type   : Boolean,
      default: true
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/deep/ .synthesis-card-border {
  border        : 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  border-radius : 8px !important;
}
</style>
