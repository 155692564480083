<script>
import BaseEChartBar    from '@/theme/default/components/charts/echarts/BaseEChartBar'
import baseEChartColors from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

import { first }         from '@/lib/utils/array'
import { toDecimalMark } from '@/lib/utils/helper'

export default {
  name    : 'EChartHedgingCompareInstrumentBar',
  extends : BaseEChartBar,
  mixins  : [baseEChartColors],
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 45,
        bottom      : 0,
        containLabel: false
      }
    },

    getChartDataOptions () {
      const o = this.series

      return {
        animation              : this.chartAnimation,
        animationThreshold     : this.chartAnimationThreshold,
        animationDuration      : this.chartAnimationDuration,
        animationEasing        : this.chartAnimationEasing,
        animationDurationUpdate: this.chartAnimationDuration,
        animationEasingUpdate  : this.chartAnimationEasing,
        tooltip                : { show: false },
        legend                 : { show: false },
        textStyle              : {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          type     : 'value',
          axisLabel: { show: false },
          axisLine : { show: false },
          axisTick : { show: false },
          splitLine: { show: false }
        },
        yAxis: {
          data     : o.yAxis,
          type     : 'category',
          axisLabel: { show: false },
          axisLine : { show: false },
          axisTick : { show: false },
          splitLine: { show: false }
        },
        series: o.series
      }
    },

    series () {
      let o = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const labelColor = this.themeColorRGBA('synthesis-brand-dark-blue')

      const series = o.y.map((name, index) => {
        const color = this.themeColorRGBA(o.c[index])
        const values = new Array(o.x.length).fill('')

        values[index] = o.x[index] === null ? '' : parseFloat(o.x[index])

        return {
          name          : name,
          type          : 'bar',
          stack         : 'chart',
          barCategoryGap: '60%',
          label         : {
            z             : 10,
            show          : true,
            silent        : true,
            position      : 'right',
            offset        : [-3, 0],
            fontSize      : 12,
            fontWeight    : '600',
            valueAnimation: true,
            color         : labelColor,
            formatter     : params => {
              const value = params.value === 0 ? 0 : toDecimalMark(params.value, 0)
              return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
            }
          },
          showBackground : true,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius],
            color       : color
          },
          cursor: this.cursor,
          data  : values
        }
      })

      return {
        yAxis : o.y,
        series: series
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
