<template>
  <block-item-card
    :min-height="310"
    :title="$t('Dashboard.Block.Survey.Title')"
  >
    <survey-list-card
      :items="items"
      @survey-item-click="saveSurvey"
    />
  </block-item-card>
</template>

<script>
import BlockItemCard      from '@/theme/default/components/common/BlockItemCard'
import SurveyListCard     from '@/theme/default/components/common/SurveyListCard'
import API                from '@/api/Api'
import { clone }          from '@/lib/utils/helper'
import { collect }        from '@/lib/utils/array'
import SurveyItemResource from '@/api/resources/dashboard/SurveyItemResource'

export default {
  name      : 'SurveyBlock',
  components: {
    SurveyListCard,
    BlockItemCard
  },
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {
    items: 'Dashboard.SurveyList.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Dashboard.SurveyList()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.listMonthlySurveys || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data)
          this.items = collect(o, SurveyItemResource)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    saveSurvey (id, action) {
      API.Resource.Dashboard.SaveSurvey(id, action)
        .then(response => {
          this.handleSaveSurveyResponse(response)
        })
        .catch(e => {
          this.handleSaveSurveyResponse(e.response)
        })
        .finally(() => {})
    },
    handleSaveSurveyResponse (response) {
      const data = API.responseData(response)?.respondToSurvey || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.getData()
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>

</style>
