<template>
  <block-item-card
    :base-currency-symbol="baseCurrencyDisplay"
    :export-element="element"
    :min-height="minHeight"
    :show-overlay="isDummy"
    :title="$t('Dashboard.Block.LiveRisk.Title')"
    :title-large-size="$vuetify.breakpoint.mdAndUp ? 52 : 40"
    :title-prefix="$t('Dashboard.Block.LiveRisk.Label.Risk')"
    :title-prefix-tooltip="$t('Dashboard.Block.LiveRisk.InfoTip.Risk')"
    :title-value="titleValue"
    :title-value-change="titleValueChangeFmt"
    :title-value-change-color="exists?getDailyChangeColor(titleValueChange):''"
    :title-value-change-icon="exists?getDailyChangeIcon(titleValueChange):''"
    :title-value-color-red="false"
  >
    <template #avatar>
      <v-spacer />

      <template v-if="isDummy">
        <div class="text-center justify-center align-center">
          <v-btn
            :to="{name: 'RisksAnalysisNew'}"
            class="synthesis-text text-size-14 white--text"
            color="synthesis-ui-green-01"
            depressed
            height="42"
            large
          >
            <v-icon left>
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('Risks.Button.New.Title') }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div
          v-if="chartPieVisible"
          :style="`margin-top: -30px;width:${chartPieSize}px;`"
          class="d-inline-block"
        >
          <base-e-chart-pie
            :axis-y-tick-prefix="baseCurrencyDisplay"
            :chart-data-options="pieValues"
            :chart-height="chartPieSize"
            :default-colors="riskSummaryDashboardColors"
            :tooltip-show="true"
            chart-animation-easing="elasticOut"
          />
        </div>
      </template>
    </template>

    <v-card-text
      v-if="items && items.length"
      :style="`height: ${minHeight-140}px`"
      class="pb-2 mt-0 overflow-y-auto"
    >
      <template
        v-for="(item, i) in items"
      >
        <v-lazy
          :key="`lazy-live-risk-list-card-${i}`"
          :options="{threshold: .5}"
          transition="fade-transition"
        >
          <live-risk-list-card
            :key="`live-risk-list-card-${i}`"
            :item="item"
            :show-overlay="isDummy"
          />
        </v-lazy>
      </template>
    </v-card-text>
  </block-item-card>
</template>

<script>
import BlockItemCard                from '@/theme/default/components/common/BlockItemCard'
import LiveRiskListCard             from '@/theme/default/views/dashboard/blocks/LiveRiskListCard'
import API                          from '@/api/Api'
import { clone }                    from '@/lib/utils/helper'
import BaseEChartPie                from '@/theme/default/components/charts/echarts/BaseEChartPie'
import baseEChartColors             from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import DashboardRiskSummaryResource from '@/api/resources/dashboard/DashboardRiskSummaryResource'
import hasExport                    from '@/mixins/hasExport'

export default {
  name      : 'LiveRiskBlock',
  components: {
    BaseEChartPie,
    LiveRiskListCard,
    BlockItemCard
  },
  directives: {},
  mixins    : [baseEChartColors, hasExport],
  props     : {
    minHeight: {
      type   : [Number, String],
      default: 340
    }
  },
  dataStore: {
    appToolbarExtended: 'App.Config.AppToolbarExtended',
    riskSummaryData   : 'Dashboard.RiskSummary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      element        : null,
      chartPieSize   : 120,
      chartPieVisible: true
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    summary () {
      return this.riskSummaryData || null
    },

    exists () {
      return this.summary?.ActiveAnalysisExists ?? false
    },

    items () {
      return this.summary?.Items || null
    },

    isDummy () {
      return this.summary?.IsDummy || false
    },

    pieValues () {
      return this.exists ? (this.summary?.PieValues || []) : []
    },

    baseCurrencyDisplay () {
      return this.summary?.BaseCurrencyDisplayName || ''
    },

    titleValue () {
      return this.exists ? (this.summary?.TitleValueFmt || '') : '0'
    },

    titleValueChange () {
      return this.summary?.TitleValueChange || ''
    },

    titleValueChangeFmt () {
      return this.exists ? (this.summary?.TitleValueChangeFmt || '') : ''
    }
  },
  watch: {
    appToolbarExtended (val) {
      this.showHidePieChart(val)
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })

    if (this.hasExport) {
      this.element = this.$el
    }
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.SummaryDashboard(this.accountId)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.riskSummaryDashboard || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data)

          this.riskSummaryData = o?.activeAnalysisExists ? new DashboardRiskSummaryResource(o) : new DashboardRiskSummaryResource(this.getDummyData())
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getDailyChangeColor (v, text = '') {
      if (v === 0) {
        return 'synthesis-brand-dark-blue' + text
      } else {
        return (v > 0 ? 'synthesis-ui-green-01' : 'synthesis-ui-red-02') + text
      }
    },
    getDailyChangeIcon (v) {
      if (v === 0) {
        return ''
      } else {
        return (v > 0 ? 'mdi-arrow-top-right' : 'mdi-arrow-bottom-right')
      }
    },

    onResize () {
      this.showHidePieChart(this.appToolbarExtended)

      if (window.innerWidth >= 320 && window.innerWidth < 375) {
        this.chartPieSize = 60
      } else if (window.innerWidth >= 375 && window.innerWidth < 830) {
        this.chartPieSize = 80
      } else {
        this.chartPieSize = 100
      }
    },

    showHidePieChart (val) {
      if (val) {
        this.chartPieVisible = !(window.innerWidth >= 960 && window.innerWidth < 1130)
      } else {
        this.chartPieVisible = true
      }
    },

    getDummyData () {
      return {
        dummy               : true,
        activeAnalysisExists: true,
        baseCurrency        : {
          name         : 'Euro',
          symbol       : 'EUR',
          displaySymbol: '€',
          displayName  : 'EUR',
          type         : 'Currency'
        },
        aggregateRisk        : 1785437,
        aggregateChange      : 10000,
        aggregateHedgedRisk  : 178543.7,
        aggregateUnhedgedRisk: 1785437,
        assets               : [
          'Copper',
          'MYR',
          'USD'
        ],
        summaryItems: [
          {
            asset        : 'Copper',
            label        : '',
            notional     : 500000,
            hedgedRisk   : 59688.9,
            unhedgedRisk : 596889,
            detailedAsset: {
              name         : 'Copper',
              symbol       : 'HG1',
              displaySymbol: 'HG1',
              displayName  : 'Copper',
              type         : 'Commodity'
            },
            baseCurrency: {
              name         : 'Euro',
              symbol       : 'EUR',
              displaySymbol: '€',
              displayName  : 'EUR',
              type         : 'Currency'
            },
            quotation: {
              displayUnit: 'Lbs'
            }
          },
          {
            asset        : 'MYR',
            label        : 'cash',
            notional     : 1500000,
            hedgedRisk   : 3794.6,
            unhedgedRisk : 37946,
            detailedAsset: {
              name         : 'Malaysian Ringgit',
              symbol       : 'MYR',
              displaySymbol: 'MYR',
              displayName  : 'MYR',
              type         : 'Currency'
            },
            baseCurrency: {
              name         : 'Euro',
              symbol       : 'EUR',
              displaySymbol: '€',
              displayName  : 'EUR',
              type         : 'Currency'
            },
            quotation: {
              displayUnit: 'MYR'
            }
          },
          {
            asset        : 'USD',
            label        : 'Receiveables',
            notional     : 14000000,
            hedgedRisk   : 115060.2,
            unhedgedRisk : 1150602,
            detailedAsset: {
              name         : 'US Dollar',
              symbol       : 'USD',
              displaySymbol: '$',
              displayName  : 'USD',
              type         : 'Currency'
            },
            baseCurrency: {
              name         : 'Euro',
              symbol       : 'EUR',
              displaySymbol: '€',
              displayName  : 'EUR',
              type         : 'Currency'
            },
            quotation: {
              displayUnit: 'USD'
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>
