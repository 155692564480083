/**
 * Converts a string to PascalCase.
 *
 * Break the string into words and combine them capitalizing the first letter of each word, using a regexp.
 *
 * @example toPascalCase('some_database_field_name'); // 'SomeDatabaseFieldName'
 * @example toPascalCase('Some label that needs to be camelized'); // 'SomeLabelThatNeedsToBeCamelized'
 * @example toPascalCase('some-javascript-property'); // 'SomeJavascriptProperty'
 * @example toPascalCase('some-mixed_string with spaces_underscores-and-hyphens'); // 'SomeMixedStringWithSpacesUnderscoresAndHyphens'
 *
 * @param str
 * @returns {string}
 */
export const toPascalCase = str => {
  return str && str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase()).join('')
}

/**
 * Converts a string to title case.
 *
 * Break the string into words, using a regexp, and combine them capitalizing the first letter of each word and adding a whitespace between them.
 *
 * @example toTitleCase('some_database_field_name'); // 'Some Database Field Name'
 * @example toTitleCase('Some label that needs to be title-cased'); // 'Some Label That Needs To Be Title Cased'
 * @example toTitleCase('some-package-name'); // 'Some Package Name'
 * @example toTitleCase('some-mixed_string with spaces_underscores-and-hyphens'); // 'Some Mixed String With Spaces Underscores And Hyphens'
 *
 * @param str
 * @returns {*}
 */
export const toTitleCase = str => str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g).map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ')

/**
 * Truncates a string up to a specified length.
 *
 * Determine if the string's length is greater than num. Return the string truncated to the desired length, with '...' appended to the end or the original string.
 *
 * @example truncateString('boomerang', 7); // 'boom...'
 *
 * @param str
 * @param num
 * @returns {string}
 */
export const truncateString = (str, num) => str && str.length > num ? num === -1 ? str : (str.slice(0, num > 3 ? num - 3 : num) + '...') : str
export { truncateString as truncate }

/**
 * Converts a string into UpperCase
 *
 * @param value
 * @returns {string}
 */
export const uppercase = value => (value || value === 0) ? String(value).toUpperCase() : ''

/**
 * If the value is missing outputs the placeholder text
 *
 * @example '' => {placeholder}
 * @example 'foo' => 'foo'
 *
 * @param input
 * @param property
 * @returns {*}
 */
export const placeholder = (input, property) => (input === undefined || input === '' || input === null) ? property : input

/**
 * Returns the singular of an English word.
 *
 * @export
 * @param {string} word
 * @param {number} [amount]
 * @returns {string}
 */
export const singular = (word, amount) => {
  if (amount !== undefined && amount !== 1) {
    return word
  }
  const singular = {
    '(quiz)zes$'                                                   : '$1',
    '(matr)ices$'                                                  : '$1ix',
    '(vert|ind)ices$'                                              : '$1ex',
    '^(ox)en$'                                                     : '$1',
    '(alias)es$'                                                   : '$1',
    '(octop|vir)i$'                                                : '$1us',
    '(cris|ax|test)es$'                                            : '$1is',
    '(shoe)s$'                                                     : '$1',
    '(o)es$'                                                       : '$1',
    '(bus)es$'                                                     : '$1',
    '([m|l])ice$'                                                  : '$1ouse',
    '(x|ch|ss|sh)es$'                                              : '$1',
    '(m)ovies$'                                                    : '$1ovie',
    '(s)eries$'                                                    : '$1eries',
    '([^aeiouy]|qu)ies$'                                           : '$1y',
    '([lr])ves$'                                                   : '$1f',
    '(tive)s$'                                                     : '$1',
    '(hive)s$'                                                     : '$1',
    '(li|wi|kni)ves$'                                              : '$1fe',
    '(shea|loa|lea|thie)ves$'                                      : '$1f',
    '(^analy)ses$'                                                 : '$1sis',
    '((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$': '$1$2sis',
    '([ti])a$'                                                     : '$1um',
    '(n)ews$'                                                      : '$1ews',
    '(h|bl)ouses$'                                                 : '$1ouse',
    '(corpse)s$'                                                   : '$1',
    '(us)es$'                                                      : '$1',
    s$                                                             : ''
  }
  const irregular = {
    move  : 'moves',
    foot  : 'feet',
    goose : 'geese',
    sex   : 'sexes',
    child : 'children',
    man   : 'men',
    tooth : 'teeth',
    person: 'people'
  }
  const uncountable = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'bison',
    'cod',
    'offspring',
    'pike',
    'salmon',
    'shrimp',
    'swine',
    'trout',
    'aircraft',
    'hovercraft',
    'spacecraft',
    'sugar',
    'tuna',
    'you',
    'wood'
  ]
  // save some time in the case that singular and plural are the same
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word
  }
  // check for irregular forms
  for (const w in irregular) {
    const pattern = new RegExp(`${ irregular[w] }$`, 'i')
    const replace = w
    if (pattern.test(word)) {
      return word.replace(pattern, replace)
    }
  }
  // check for matches using regular expressions
  for (const reg in singular) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, singular[reg])
    }
  }
  return word
}
