<script>
import BaseEChartGauge from '@/theme/default/components/charts/echarts/BaseEChartGauge.vue'

export default {
  name    : 'EChartDebtProfileGauge',
  extends : BaseEChartGauge,
  computed: {
    getChartDataOptions () {
      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          trigger        : 'item',
          show           : this.tooltipShow,
          confine        : this.tooltipConfine,
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          textStyle      : {
            color: this.tooltipLabelColor
          }
        },
        title: {
          show      : this.titleShow,
          text      : this.titleValue,
          left      : 'center',
          color     : this.themeColorRGBA('synthesis-ui-blue-01', 0.8),
          fontFamily: this.fontFamily,
          fontSize  : 42,
          fontWeight: 'bolder'
        },
        color    : this.defaultColors ?? this.colors,
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid  : this.getChartGridMargin,
        series: this.series
      }
    },

    series () {
      let values = []

      if (this.chartDataOptions.length) {
        values = this.chartDataOptions
      }

      let radius = '110%'
      let centerY = '72%'
      let detailOffsetY = '25%'

      if (this.$vuetify.breakpoint.smAndUp) {
        radius = '130%'
        centerY = '80%'
        detailOffsetY = '20%'
      }

      if (this.$vuetify.breakpoint.lgAndUp) {
        radius = '145%'
        centerY = '84%'
        detailOffsetY = '16%'
      }

      return [
        {
          type      : 'gauge',
          startAngle: 180,
          endAngle  : 0,
          center    : ['50%', centerY],
          radius    : radius,
          min       : 0,
          max       : 100,
          tooltip   : {
            show: this.tooltipShow
          },
          itemStyle: {
            color: this.themeColorRGBA('synthesis-brand-dark-blue', 0.8)
          },
          progress: {
            show    : this.gaugeProgressShow,
            roundCap: false,
            width   : 14,
            color   : 'inherit'
          },
          pointer: {
            show        : true,
            keepAspect  : true,
            icon        : 'triangle', // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
            width       : 20,
            length      : '90%',
            offsetCenter: [0, 0],
            itemStyle   : {
              color: this.themeColorRGBA('synthesis-brand-dark-blue', 0.8)
            }
          },
          anchor: {
            show     : false,
            showAbove: true,
            size     : 20,
            itemStyle: {
              borderWidth: 20,
              borderColor: this.themeColorRGBA('synthesis-ui-blue-01', 0.9),
              color      : this.themeColorRGBA('synthesis-ui-blue-01', 0.9)
            }
          },
          axisLine: {
            lineStyle: {
              width: 14,
              color: this.chartColors
            }
          },
          axisTick: {
            show     : true,
            length   : 8,
            distance : 4,
            lineStyle: {
              color: this.themeColorRGBA('synthesis-ui-grey-01', 0.7),
              width: 1
            }
            // splitNumber: 1
          },
          splitLine: {
            show     : true,
            length   : 8,
            distance : 4,
            lineStyle: {
              color: this.themeColorRGBA('synthesis-ui-grey-01', 0.7),
              width: 2
            }
          },
          splitNumber: 2,
          axisLabel  : {
            fontSize : 12,
            distance : 0,
            rotate   : 'tangential', // 'tangential'. 'radial'
            padding  : [0, 0, 0, 0], // [top, right, bottom, left]
            precision: 0,
            // formatter: '{value} ' + this.axisXTickSuffix
            formatter: value => {
              /* if (value === val) {
                return `{value|${ value }}`
              } */
              if (value === 0) {
                return `{start|${ value } ${ this.axisXTickSuffix }}`
              }
              if (value === 100) {
                return `{end|${ value } ${ this.axisXTickSuffix }}`
              }
            },
            rich: {
              start: {
                color     : 'inherit',
                fontFamily: this.fontFamily,
                fontSize  : 14,
                fontWeight: 'bolder',
                padding   : [-4, 0, 0, 18] // [top, right, bottom, left]
              },
              end: {
                color     : 'inherit',
                fontFamily: this.fontFamily,
                fontSize  : 14,
                fontWeight: 'bolder',
                padding   : [-4, 18, 0, 0] // [top, right, bottom, left]
              },
              value: {
                color     : this.themeColorRGBA('synthesis-ui-blue-01', 0.8),
                fontFamily: this.fontFamily,
                fontSize  : 48,
                fontWeight: 'bolder'
                // padding   : [32, 0, 0, 0] // [top, right, bottom, left]
              }
            }
          },
          cursor: this.cursor,
          silent: true,
          detail: {
            valueAnimation: true,
            offsetCenter  : [0, detailOffsetY],
            fontSize      : 42,
            fontWeight    : 'bolder',
            formatter     : '{value} ' + this.axisXTickSuffix,
            color         : this.themeColorRGBA('synthesis-ui-blue-01', 0.8)
          },
          data: values
        }
      ]
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
