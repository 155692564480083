import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskSummaryPieAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.asset ?? ''
    this.Type = payload.type ?? ''
    this.Value = payload.value ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
