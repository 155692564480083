<template>
  <v-row class="mt-1">
    <v-col>
      <v-data-table
        :headers="items.headers"
        :items="items.values"
        class="font-weight-medium synthesis-card-border"
        disable-pagination
        disable-sort
        hide-default-header
        hide-default-footer
        group-by="group"
        show-group-by
        mobile-breakpoint="0"
        dense
      >
        <template
          v-for="(key, i) in items.headers"
          #[`item.${key.value}`]="{item}"
        >
          <td
            :key="`header-key-${i}`"
            class="text-no-wrap"
            :class="getTableCellClass(key, item)"
            :style="getTableCellStyle(key, item)"
          >
            {{ item[`${ key.value }`] }}
          </td>
        </template>
        <template #group.header="{group}">
          <td
            v-if="group"
            class="text-start"
            :class="getTableGroupClass(true)"
            :style="getTableGroupStyle()"
          >
            {{ group.includes('_') ? group.split('_')[1] : group }}
          </td>
          <td
            v-if="group"
            :colspan="items.headers.length-1"
            class="text-start"
            :class="getTableGroupClass()"
            :style="getTableGroupStyle()"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>

import riskAnalysisId from '@/theme/default/views/risks/mixins/riskAnalysisId'

export default {
  name      : 'ForecastFlowRuglTable',
  components: {},
  directives: {},
  mixins    : [riskAnalysisId],
  props     : {
    items: {
      type    : Object,
      required: true
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    getTableCellClass (key, item) {
      const textAlign = item[`${ key.value + '_' + 'textAlign' }`] ?? item.textAlign ?? 'left'
      const textBold = item[`${ key.value + '_' + 'textBold' }`] ?? item.textBold ?? false

      const borderTop = item[`${ key.value + '_' + 'borderTop' }`] ?? item.borderTop ?? false
      const borderBottom = item[`${ key.value + '_' + 'borderBottom' }`] ?? item.borderBottom ?? false
      const borderLeft = item[`${ key.value + '_' + 'borderLeft' }`] ?? item.borderLeft ?? false
      const borderRight = item[`${ key.value + '_' + 'borderRight' }`] ?? item.borderRight ?? false

      const borderTopBold = item[`${ key.value + '_' + 'borderTopBold' }`] ?? item.borderTopBold ?? false
      const borderBottomBold = item[`${ key.value + '_' + 'borderBottomBold' }`] ?? item.borderBottomBold ?? false
      const borderLeftBold = item[`${ key.value + '_' + 'borderLeftBold' }`] ?? item.borderLeftBold ?? false
      const borderRightBold = item[`${ key.value + '_' + 'borderRightBold' }`] ?? item.borderRightBold ?? false

      return this.getCssClass(textAlign, textBold, borderTop, borderTopBold, borderBottom, borderBottomBold, borderLeft, borderLeftBold, borderRight, borderRightBold)
    },
    getTableCellStyle (key, item) {
      const bgColor = item[`${ key.value + '_' + 'bgColor' }`] ?? item.bgColor
      const textColor = item[`${ key.value + '_' + 'textColor' }`] ?? item.textColor

      return this.getCssStyle(bgColor, textColor)
    },
    getTableGroupClass (labelCell) {
      const textAlign = this.items.group.textAlign ?? 'left'
      const textBold = this.items.group.textBold ?? false

      const borderTop = this.items.group.borderTop ?? false
      const borderBottom = this.items.group.borderBottom ?? false
      const borderLeft = labelCell ? (this.items.group.borderLeft ?? false) : false
      const borderRight = labelCell ? false : (this.items.group.borderRight ?? false)

      const borderTopBold = this.items.group.borderTopBold ?? false
      const borderBottomBold = this.items.group.borderBottomBold ?? false
      const borderLeftBold = this.items.group.borderLeftBold ?? false
      const borderRightBold = this.items.group.borderRightBold ?? false

      return this.getCssClass(textAlign, textBold, borderTop, borderTopBold, borderBottom, borderBottomBold, borderLeft, borderLeftBold, borderRight, borderRightBold)
    },
    getTableGroupStyle () {
      const bgColor = this.items.group.bgColor ?? ''
      const textColor = this.items.group.textColor ?? ''

      return this.getCssStyle(bgColor, textColor)
    },
    getCssStyle (bgColor, textColor) {
      return 'background-color:' + bgColor + ';color:' + textColor + ';'
    },
    getCssClass (textAlign, textBold, borderTop, borderTopBold, borderBottom, borderBottomBold, borderLeft, borderLeftBold, borderRight, borderRightBold) {
      let cssClass = ''

      if (textAlign === 'left') {
        cssClass = 'text-start '
      }
      if (textAlign === 'center') {
        cssClass = 'text-center '
      }
      if (textAlign === 'right') {
        cssClass = 'text-right '
      }

      if (textBold) {
        cssClass += 'font-weight-bold '
      }

      if (borderTop) {
        cssClass += 'table-cell-border-top '

        if (borderTopBold) {
          cssClass += 'table-cell-border-top-bold '
        }
      }
      if (borderBottom) {
        cssClass += 'table-cell-border-bottom '

        if (borderBottomBold) {
          cssClass += 'table-cell-border-bottom-bold '
        }
      }
      if (borderLeft) {
        cssClass += 'table-cell-border-left '

        if (borderLeftBold) {
          cssClass += 'table-cell-border-left-bold '
        }
      }
      if (borderRight) {
        cssClass += 'table-cell-border-right '

        if (borderRightBold) {
          cssClass += 'table-cell-border-right-bold '
        }
      }

      return cssClass
    }
  }
}
</script>

<style scoped>
.table-cell-border-top {
  border-top: thin solid var(--v-synthesis-brand-dark-blue-base) !important;
}

.table-cell-border-bottom {
  border-bottom: thin solid var(--v-synthesis-brand-dark-blue-base) !important;
}

.table-cell-border-left {
  border-left: thin solid var(--v-synthesis-brand-dark-blue-base) !important;
}

.table-cell-border-right {
  border-right: thin solid var(--v-synthesis-brand-dark-blue-base) !important;
}

.table-cell-border-top-bold {
  border-top-width: 2px !important;
}

.table-cell-border-bottom-bold {
  border-bottom-width: 2px !important;
}

.table-cell-border-left-bold {
  border-left-width: 2px !important;
}

.table-cell-border-right-bold {
  border-right-width: 2px !important;
}

/deep/ .theme--light.v-data-table .v-row-group__header, .theme--light.v-data-table .v-row-group__summary {
  background-color: var(--v-synthesis-brand-white-base);
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  /*box-shadow: 0 1px 0 1px #dfe3eb;*/
  left: 0;
  z-index: 1;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 2;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-of-type(even) > td:nth-child(1) {
  /*background-color: var(--v-synthesis-ui-grey-06-base);*/
  background-color: var(--v-synthesis-brand-white-base);
  text-wrap: nowrap;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-of-type(odd) > td:nth-child(1) {
  background-color: var(--v-synthesis-brand-white-base);
  text-wrap: nowrap;
}

</style>
