export default {
  computed: {
    riskBaseCurrency () {
      return this.riskBaseCurrencyData[this.id] ?? null
    },

    riskBaseCurrencyDisplayName () {
      return this.riskBaseCurrency?.displaySymbol || ''
    },

    isRiskBaseCurrencySymbolSingle () {
      return this.riskBaseCurrencyDisplayName.length === 1
    },

    riskBaseCurrencySymbolSingle () {
      return this.isRiskBaseCurrencySymbolSingle ? this.riskBaseCurrencyDisplayName : ''
    }

  }
}
