import ChartsStyleEnum  from '@/api/enums/ChartsEnum'
import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'

export default {
  mixins  : [chartsColorMixin],
  computed: {
    colors () {
      return ChartsStyleEnum.Colors.Global.map(color => this.$vuetify.theme.themes.light[color])
    },

    riskSummaryDashboardColors () {
      return ChartsStyleEnum.Colors.RiskSummaryDashboardColors.map(color => this.$vuetify.theme.themes.light[color])
    },

    surveyColors () {
      return ChartsStyleEnum.Colors.Survey.map(color => this.$vuetify.theme.themes.light[color])
    },

    gaugeColors () {
      return ChartsStyleEnum.Colors.Gauge.map(color => this.$vuetify.theme.themes.light[color])
    }
  },
  methods: {
    getColor (index, opacity = 1) {
      return this.themeColorRGBA(ChartsStyleEnum.Colors.Global[index], opacity)
    },

    getColorBg (index, opacity = 0.1) {
      return this.themeColorRGBA(ChartsStyleEnum.Colors.Global[index], opacity)
    },

    getHedgingInstrumentBarColors (index, opacity = 1) {
      return this.themeColorRGBA(ChartsStyleEnum.Colors.HedgingInstrumentBar[index], opacity)
    }
  }
}
