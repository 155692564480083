<template>
  <v-row>
    <v-col>
      <block-item-card
        :title="type.title"
        :title-large="false"
        :title-value="type.subTitle"
        :title-value-color-red="false"
        :tooltip="type.infotip"
        :tooltip-value="type.tooltip"
      >
        <template
          v-if="$vuetify.breakpoint.mdAndUp"
          #actions
        >
          <v-spacer />
          <asset-button-group
            :items="assets"
            :selected.sync="selectedAsset"
            class="mt-4"
          />

          <button-text-toggle
            v-if="rollingCashFlowsExist"
            :title="buttonTitle"
            class="mt-4 ml-3 mr-1"
            style="min-width: 70px"
            @button-text-toggle-click="toggleChart"
          />
        </template>
        <template
          v-else
          #actions-row
        >
          <v-card-title class="mx-3 mb-4 pa-0">
            <asset-button-group
              :items="assets"
              :selected.sync="selectedAsset"
            />
            <button-text-toggle
              v-if="rollingCashFlowsExist"
              :title="buttonTitle"
              class="mt-0 ml-3 mr-1"
              style="min-width: 70px"
              @button-text-toggle-click="toggleChart"
            />
          </v-card-title>
        </template>

        <v-row class="ma-0 mb-5">
          <v-col
            class="ma-0"
            cols="12"
          >
            <v-row
              class="ma-0 flex-md-nowrap"
            >
              <v-col
                class="pa-0 pr-4 flex-shrink-1"
                cols="12"
                lg="9"
                md="8"
                xl="10"
              >
                <e-chart-hedging-histogram
                  ref="ChartHedgingHistogram"
                  :axis-x-boundary-gap="true"
                  :axis-x-tick-prefix="baseCurrency"
                  :axis-y-label-inside="false"
                  :chart-animation-duration="800"
                  :chart-data-options="chartDataValues"
                  :chart-height="250"
                  :chart-scale="false"
                  axis-x-label-value-format="number"
                  axis-x-max-value="dataMax"
                  axis-x-min-value="dataMin"
                  chart-animation-easing="quarticOut"
                  chart-ref-id="HedgingHistogram"
                />
              </v-col>
              <v-col
                class="pa-0 pt-4 pt-md-0"
                cols="12"
                lg="3"
                md="4"
                style="min-width: 270px"
                xl="2"
              >
                <v-row
                  class="ma-0"
                >
                  <v-col
                    :class="[{'pr-sm-1': $vuetify.breakpoint.sm && !appToolbarExtended}]"
                    :sm="appToolbarExtended ? 12 : 6"
                    class="ma-0 pa-0"
                    cols="12"
                    md="12"
                  >
                    <item-chart-legend
                      :key="`histogram-legend-unhedged-risk`"
                      :background-color="themeColorRGBA('synthesis-ui-grey-06')"
                      :base-currency-symbol="unhedgedRiskValue === '-' ? '' : baseCurrency"
                      :icon-color="themeColorRGBA('synthesis-ui-grey-02')"
                      :title="$t('Hedging.Card.UnhedgedRisk.Title')"
                      :title-size="14"
                      :title-value="unhedgedRiskValue"
                      :title-value-color="themeColorRGBA('synthesis-brand-dark-blue')"
                      :title-value-size="18"
                      :tooltip="$t('Hedging.Card.UnhedgedRisk.InfoTip')"
                      class="mb-3 pa-5 pt-6 item-chart-legend"
                    />
                  </v-col>
                  <v-col
                    :class="[{'pl-sm-1': $vuetify.breakpoint.sm && !appToolbarExtended}]"
                    :sm="appToolbarExtended ? 12 : 6"
                    class="ma-0 pa-0"
                    cols="12"
                    md="12"
                  >
                    <item-chart-legend
                      :key="`histogram-legend-hedged-risk`"
                      :background-color="themeColorRGBA('synthesis-ui-purple-08')"
                      :base-currency-symbol="hedgedRiskValue === '-' ? '' : baseCurrency"
                      :icon-color="themeColorRGBA('synthesis-ui-purple-02')"
                      :title="$t('Hedging.Card.HedgedRisk.Title')"
                      :title-size="14"
                      :title-value="hedgedRiskValue"
                      :title-value-color="themeColorRGBA('synthesis-brand-dark-blue')"
                      :title-value-size="18"
                      :tooltip="$t('Hedging.Card.HedgedRisk.InfoTip')"
                      class="mb-3 pa-5 pt-6 item-chart-legend"
                    />
                  </v-col>
                  <v-col
                    class="ma-0 pa-0"
                    cols="12"
                  >
                    <item-chart-legend
                      :key="`histogram-legend-potential-saving-risk`"
                      :background-color="themeColorRGBA('synthesis-ui-green-08')"
                      :base-currency-symbol="potentialSavingValue === '-' ? '' : baseCurrency"
                      :title="$t('Hedging.Card.PotentialSaving.Title')"
                      :title-size="14"
                      :title-value="potentialSavingValue"
                      :title-value-color="themeColorRGBA('synthesis-ui-green-01')"
                      :title-value-size="18"
                      :tooltip="$t('Hedging.Card.PotentialSaving.InfoTipChartLegend')"
                      class="mb-3 pa-5 pt-6 item-chart-legend"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>

import ItemChartLegend                  from '@/theme/default/components/common/ItemChartLegend'
import BlockItemCard                    from '@/theme/default/components/common/BlockItemCard'
import AssetButtonGroup                 from '@/theme/default/components/common/AssetButtonGroup'
import chartsColorMixin                 from '@/theme/default/components/charts/mixins/chartsColorMixin'
import EChartHedgingHistogram           from '@/theme/default/components/charts/echarts/EChartHedgingHistogram'
import InstrumentChartTypeEnum          from '@/api/enums/InstrumentChartTypeEnum'
import ButtonTextToggle                 from '@/theme/default/components/common/ButtonTextToggle'
import API                              from '@/api/Api'
import { clone }                        from '@/lib/utils/helper'
import policyId                         from '@/theme/default/views/hedging/mixins/policyId'
import HedgingRollingInstrumentResource from '@/api/resources/hedging/HedgingRollingInstrumentResource'
import { InstrumentTypeEnumHelper }     from '@/api/enums/InstrumentTypeEnum'

let chartInstance = null

export default {
  name      : 'InstrumentHistogramBlock',
  components: {
    ButtonTextToggle,
    EChartHedgingHistogram,
    AssetButtonGroup,
    BlockItemCard,
    ItemChartLegend
  },
  directives: {},
  mixins    : [policyId, chartsColorMixin],
  props     : {
    instrumentData: {
      type   : Object,
      default: () => {}
    },
    instrumentType: {
      type    : String,
      required: true
    }
  },
  dataStore: {
    appToolbarExtended: 'App.Config.AppToolbarExtended',
    hedgingInstrument : 'Hedging.Instrument'
  },
  enums    : { InstrumentChartTypeEnum },
  dataModel: null,
  data () {
    return {
      buttonTitle  : this.$t('Hedging.Rolling'),
      chartType    : InstrumentChartTypeEnum.HEDGED_VS_UNHEDGED,
      selectedAsset: 'portfolio'
    }
  },
  computed: {
    type () {
      switch (this.chartType) {
        case InstrumentChartTypeEnum.HEDGED_VS_UNHEDGED:
          return {
            title   : this.$t('Hedging.Section.SuggestedStrategy.HedgedVsUnhedged.Title'),
            infotip : this.$t('Hedging.Section.SuggestedStrategy.HedgedVsUnhedged.InfoTip'),
            subTitle: this.$t('Hedging.Section.SuggestedStrategy.HedgedVsUnhedged.Subtitle', { Type: this.instrumentType }),
            tooltip : this.$tc('Hedging.Section.SuggestedStrategy.HedgedVsUnhedged.Tooltip', InstrumentTypeEnumHelper.getTranslationChoice(this.instrumentType)),
            data    : this.instrumentData?.HedgedCashFlows
          }
        case InstrumentChartTypeEnum.ROLLING_TRADE_VS_STRIP_OF_TRADES:
          return {
            title   : this.$t('Hedging.Section.SuggestedStrategy.RollingTradeVsStripTrade.Title'),
            infotip : this.$t('Hedging.Section.SuggestedStrategy.RollingTradeVsStripTrade.InfoTip'),
            subTitle: this.$t('Hedging.Section.SuggestedStrategy.RollingTradeVsStripTrade.Subtitle', { Type: this.instrumentType }),
            tooltip : this.$tc('Hedging.Section.SuggestedStrategy.RollingTradeVsStripTrade.Tooltip', InstrumentTypeEnumHelper.getTranslationChoice(this.instrumentType)),
            data    : null
          }
      }

      return {
        title   : '',
        subTitle: '',
        tooltip : '',
        data    : null
      }
    },

    assets () {
      return this.instrumentData?.Items || []
    },

    instrumentTypeRollingData () {
      return this.hedgingInstrument[this.instrumentType].Rolling.Data[this.id] ?? null
    },

    instrumentTypeData () {
      let v = this.type.data ?? null

      if (this.chartType === InstrumentChartTypeEnum.ROLLING_TRADE_VS_STRIP_OF_TRADES) {
        v = this.instrumentTypeRollingData?.RollingCashFlows
      }

      if (v) {
        return v.find(item => item.AssetValue === this.selectedAsset) || null
      }

      return null
    },

    baseCurrency () {
      return this.instrumentTypeData?.BaseCurrencyDisplayName || ''
    },

    unhedgedRiskValue () {
      return this.instrumentTypeData?.UnhedgedRiskFmt || '-'
    },

    hedgedRiskValue () {
      return this.instrumentTypeData?.HedgedRiskFmt || '-'
    },

    potentialSavingValue () {
      return this.instrumentTypeData?.PotentialSavingFmt || '-'
    },

    chartDataValues () {
      return this.instrumentTypeData?.ChartValues || []
    },

    rollingCashFlowsExist () {
      return this.instrumentData?.RollingCashFlowsExist
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.$nextTick(() => {
      chartInstance = this.$refs?.ChartHedgingHistogram?.$refs?.echartHedgingHistogram?.chart
    })
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    chartInstance = null
  },
  methods: {
    toggleChart () {
      if (this.chartType === InstrumentChartTypeEnum.HEDGED_VS_UNHEDGED) {
        this.chartType = InstrumentChartTypeEnum.ROLLING_TRADE_VS_STRIP_OF_TRADES
        this.buttonTitle = this.$t('Hedging.Strip')

        if (!this.instrumentTypeRollingData) {
          this.getRollingData()
        }
      } else {
        this.chartType = InstrumentChartTypeEnum.HEDGED_VS_UNHEDGED
        this.buttonTitle = this.$t('Hedging.Rolling')
      }
    },

    showChartLoader () {
      chartInstance && chartInstance.showLoading('default', {
        text         : '',
        spinnerRadius: 20,
        color        : this.$vuetify.theme.themes.light['synthesis-ui-blue-04'],
        maskColor    : 'rgba(255,255,255,0.8)',
        lineWidth    : 4
      })
    },

    hideChartLoader () {
      chartInstance && chartInstance.hideLoading()
    },

    getRollingData () {
      this.showChartLoader()

      API.Resource.Hedging.RollingPolicy(this.id)
        .then(response => {
          this.handleRollingResponse(response)
        })
        .catch(e => {
          this.handleRollingResponse(e.response)
        })
        .finally(() => {
          this.hideChartLoader()
        })
    },

    handleRollingResponse (response) {
      const data = API.responseData(response)?.runRollingHedging || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)

        // Store instrument types to DataStore
        o?.forwards && this.$set(this.hedgingInstrument.Forward.Rolling.Data, this.id, new HedgingRollingInstrumentResource(o?.forwards))
        o?.options && this.$set(this.hedgingInstrument.Option.Rolling.Data, this.id, new HedgingRollingInstrumentResource(o?.options))
        o?.riskReversal && this.$set(this.hedgingInstrument.Collar.Rolling.Data, this.id, new HedgingRollingInstrumentResource(o?.riskReversal))
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
.item-chart-legend {
  min-height: 70px;
  height: 70px
}
</style>
