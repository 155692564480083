import BaseValidator from '@/lib/data/validator/BaseValidator'

export default class RiskAnalysisValidator extends BaseValidator {
  validatorRules = {
    accountId: {
      required: true,
      type    : BaseValidator.Type.String
    },
    activeVar: {
      required: false,
      type    : BaseValidator.Type.Boolean
    },
    activeHistorical: {
      required: false,
      type    : BaseValidator.Type.Boolean
    },
    activeEvents: {
      required: false,
      type    : BaseValidator.Type.Boolean
    },
    activeSimulation: {
      required: false,
      type    : BaseValidator.Type.Boolean
    },
    name: {
      required: true,
      type    : BaseValidator.Type.String,
      minLen  : 3
    },
    baseCurrencyCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    exposures: {
      required             : true,
      type                 : BaseValidator.Type.Array,
      HasExposureOrAnalyses: (v, model = this.model) => {
        return model.riskAnalyses.length || v.length
      }
    },
    riskAnalyses: {
      required             : true,
      type                 : BaseValidator.Type.Array,
      HasExposureOrAnalyses: (v, model = this.model) => {
        return model.exposures.length || v.length
      }
    },
    tags: {
      required: false,
      type    : BaseValidator.Type.Array
    }
  }

  validatorMessages = {
    el: {},
    en: {}
  }

  constructor (data = {}) {
    super(data)
  }
}
