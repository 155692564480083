<template>
  <v-container fluid>
    <v-row>
      <section-header
        :breadcrumb-items="breadcrumb"
      />
    </v-row>

    <v-row>
      <v-col
        class="pt-0"
        lg="6"
        md="8"
        offset-lg="3"
        offset-md="2"
        offset-sm="1"
        offset-xl="3"
        sm="10"
        xl="6"
        xs="12"
      >
        <v-card
          class="pa-3 pa-sm-5 pa-md-10 text-center"
          flat
        >
          <v-card-text>
            <div class="text-h5 text--primary font-weight-bold">
              {{ title }}
            </div>
          </v-card-text>

          <v-card-text>
            <div
              class="text-left"
              v-html="body"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta          from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader          from '@/theme/default/components/common/SectionHeader'
import API                    from '@/api/Api'
import { clone }              from '@/lib/utils/helper'
import articleId              from '@/theme/default/views/knowledgeCenter/mixins/articleId'
import ArticleSummaryResource from '@/api/resources/knowledgeCenter/ArticleSummaryResource'

export default {
  name      : 'KnowledgeCenterArticleShow',
  components: {
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, articleId],
  props     : {},
  dataStore : {
    knowledgeCenterArticleData: 'KnowledgeCenter.Article.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    articleData () {
      return this.knowledgeCenterArticleData[this.id] || null
    },

    article () {
      return this.articleData?.Article
    },

    title () {
      return this.article?.Title || ''
    },

    image () {
      return this.article?.ImageUrl || ''
    },

    body () {
      return this.article?.Content || ''
    },

    breadcrumb () {
      return [{
        text    : this.$t('KnowledgeCenter.Title'),
        disabled: false,
        exact   : true,
        to      : { name: 'KnowledgeCenter' }
      }, {
        text    : this.title,
        disabled: true
      }]
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.KnowledgeCenter.Article(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },

    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const p = data?.article || null
        if (p) {
          const o = clone(p)
          const v = new ArticleSummaryResource(o)

          // Store data to DataStore
          if (v?.Article?.Id) {
            this.$set(this.knowledgeCenterArticleData, v.Article.Id, v)
          }
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .theme--light.v-card > .v-card__text img {
  flex: 1 0 0;
  max-width: 100%;
}
</style>
