<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-btn
              v-if="model.selectedItem && !isEditMode"
              class="pa-2"
              text
              @click="onBackToListClick"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              {{ $t('Common.Button.Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <template v-if="!model.selectedItem">
              <v-icon
                color="orange"
                size="24"
              >
                mdi-poll
              </v-icon>

              <div class="mt-4">
                <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                  {{ $t(`${ componentLang }.AddExposure.Title`) }}
                </div>
                <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                  {{ includeDebtProfile ? $t(`${ componentLang }.AddExposure.SubtitleDebt`) : $t(`${ componentLang }.AddExposure.Subtitle`) }}
                </div>
              </div>

              <div
                v-if="userIsGroupCFO"
                class="my-5"
              >
                <div class="d-inline-block">
                  <asset-button-group
                    :height="27"
                    :items="categories"
                    :selected.sync="selectedCategory"
                    disable-dropdown-on-mobile
                  />
                </div>
              </div>

              <div class="mt-4">
                <v-text-field
                  v-model="searchTerms"
                  :placeholder="$t('Common.Search.Title')"
                  append-icon="mdi-magnify"
                  outlined
                  single-line
                />
              </div>
            </template>

            <template v-else>
              <template v-if="userIsGroupCFO && selectedCategory === 'summary'">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                        {{ model.selectedItem.name }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                        {{ model.selectedItem.baseCurrency.displayName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action-text class="text-right">
                      <v-chip
                        class="font-weight-medium"
                        color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                        label
                        small
                      >
                        {{ toNumberFormatNotationShort(model.selectedItem.result.summary.portfolioExposure) }}
                      </v-chip>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </template>

              <template v-else>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                        {{ model.selectedItem.pair.base.displayName }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                        {{ model.selectedItem.pair.quote.displayName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action-text class="text-right">
                      <v-chip
                        class="font-weight-medium"
                        color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                        label
                        small
                      >
                        {{ model.selectedItem.displayPrice }}
                      </v-chip>
                      <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                        {{ model.selectedItem.quotation.description }}
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </template>
            </template>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          :class="[{'pa-0': !model.selectedItem}]"
          class="pt-5"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <template v-if="!model.selectedItem">
              <template v-if="userIsGroupCFO && selectedCategory === 'summary'">
                <v-list>
                  <template v-for="(item, index) in summaryItemsFiltered">
                    <v-list-item
                      :key="`summary-list-item-${index}`"
                      :class="[{'grey lighten-4': isSummaryInAnalysisList(item.id) || isSummaryItemDisabled(item)}]"
                      :disabled="isSummaryInAnalysisList(item.id) || isSummaryItemDisabled(item)"
                      class="px-6 py-1"
                      @click="onSummariesListItemClick(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          :class="isSummaryInAnalysisList(item.id) || isSummaryItemDisabled(item) ? 'synthesis-ui-grey-01--text' : 'synthesis-brand-dark-blue--text'"
                          class="synthesis-text text-size-16 font-weight-medium"
                        >
                          {{ item.name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium"
                        >
                          {{ item.baseCurrency.displayName }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action-text class="text-right">
                        <v-chip
                          :color="isSummaryInAnalysisList(item.id) ? 'synthesis-ui-grey-07 synthesis-ui-grey-00--text' : 'synthesis-ui-green-07 synthesis-ui-green-00--text'"
                          class="font-weight-medium"
                          label
                          small
                        >
                          {{ toNumberFormatNotationShort(item.result.summary.portfolioExposure) }}
                        </v-chip>
                      </v-list-item-action-text>
                    </v-list-item>
                  </template>
                </v-list>
              </template>

              <template v-else>
                <v-list>
                  <template v-for="(item, index) in exposureItemsFiltered">
                    <v-list-item
                      :key="`exposures-list-item-${index}`"
                      :class="[{'grey lighten-4': isAssetDisabled(item.pair.base.symbol) || !canAssetBeSelected(item.pair.base.symbol)}]"
                      :disabled="isAssetDisabled(item.pair.base.symbol) || !canAssetBeSelected(item.pair.base.symbol)"
                      class="px-6 py-1"
                      @click="onExposuresListItemClick(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          :class="isAssetDisabled(item.pair.base.symbol) || !canAssetBeSelected(item.pair.base.symbol) ? 'synthesis-ui-grey-01--text' : 'synthesis-brand-dark-blue--text'"
                          class="synthesis-text text-size-16 font-weight-medium"
                        >
                          {{ item.pair.base.displayName }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium"
                        >
                          {{ item.pair.quote.displayName }}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-if="isAssetDisabled(item.pair.base.symbol) && !canAssetBeSelected(item.pair.base.symbol)"
                          class="synthesis-text text-size-10 error--text mt-2"
                        >
                          4 Exposures per Asset already used
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action-text class="text-right">
                        <v-chip
                          :color="isAssetDisabled(item.pair.base.symbol) ? 'synthesis-ui-grey-07 synthesis-ui-grey-00--text' : 'synthesis-ui-green-07 synthesis-ui-green-00--text'"
                          class="font-weight-medium"
                          label
                          small
                        >
                          {{ item.displayPrice }}
                        </v-chip>
                        <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                          {{ item.quotation.description }}
                        </div>
                      </v-list-item-action-text>
                    </v-list-item>

                    <v-divider :key="`list-item-divider-${index}`" />
                  </template>
                </v-list>
              </template>
            </template>

            <template v-else>
              <template v-if="userIsGroupCFO && selectedCategory === 'summary'">
                <div class="text-center mb-2">
                  <label class="field-label">{{ $t(`${ componentLang }.AddExposure.Form.Fields.TotalRisk.Label`) }}</label>

                  <div
                    :class="{'negative-value-input-warning': isNegativeValue(model.selectedItem.result.summary.portfolioExposure), 'notional-large-value': isLargeValue(model.selectedItem.result.summary.portfolioExposure), 'notional-extra-large-value': isExtraLargeValue(model.selectedItem.result.summary.portfolioExposure)}"
                    class="text-no-wrap justify-center align-center d-flex"
                  >
                    <span
                      class="synthesis-text text-size-20 font-weight-medium synthesis-brand-dark-blue--text pr-2 pt-2"
                      v-text="model.selectedItem.baseCurrency.displayName"
                    />
                    <span
                      class="synthesis-text text-size-52 font-weight-regular synthesis-brand-dark-blue--text"
                      v-text="toNumberFormatNotationShort(model.selectedItem.result.summary.portfolioExposure)"
                    />
                  </div>
                </div>

                <v-container
                  fluid
                  px-0
                >
                  <v-row>
                    <v-col cols="12">
                      <v-messages
                        v-model="hedgeRatiosValidationMessages"
                        class="text-size-14 error--text text-center"
                      />
                    </v-col>

                    <template v-for="(item, key) in model.hedgeRatios">
                      <v-col
                        :key="`hedge-ratios-${key}-1`"
                        cols="6"
                      >
                        <label class="field-label">{{ item.itemName }}</label>
                        <v-text-field-currency-directive
                          v-model="item.exposure"
                          :options="{precision: 0, locale: $i18n.code, currency: item.baseCurrency.symbol}"
                          :prefix="item.baseCurrency.displaySymbol"
                          background-color="grey lighten-4"
                          class="add-seasonality"
                          flat
                          hide-details="auto"
                          outlined
                          readonly
                        />
                      </v-col>

                      <v-col
                        :key="`hedge-ratios-${key}-2`"
                        cols="6"
                      >
                        <label class="field-label">{{ $t(`${ componentLang }.AddExposure.Form.Fields.HedgeRatio.Label`) }}</label>
                        <v-text-field-currency-directive
                          v-model="item.ratio"
                          :options="{precision: 2, autoSign: false, valueRange: {min: 0, max: 100}, valueScaling: undefined, useGrouping: false, locale: $i18n.code}"
                          :placeholder="$t(`${componentLang}.AddExposure.Form.Fields.HedgeRatio.Placeholder`)"
                          class="add-seasonality"
                          flat
                          hide-details="auto"
                          outlined
                          suffix="%"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </template>

              <template v-else>
                <div class="text-center mb-2">
                  <label class="field-label">{{ selectedItemIsInterbankRate ? $t(`${ componentLang }.AddExposure.Form.Fields.InterbankRate.Label`) : $t(`${ componentLang }.AddExposure.Form.Fields.Notional.Label`) }}
                    <infotip
                      v-if="selectedItemIsInterbankRate && $t(`${ componentLang }.AddExposure.Form.Fields.InterbankRate.InfoTip`)"
                      icon-style="margin-top: -16px"
                    >{{ $t(`${ componentLang }.AddExposure.Form.Fields.InterbankRate.InfoTip`) }}
                    </infotip>

                    <infotip
                      v-else-if="!selectedItemIsInterbankRate && $t(`${ componentLang }.AddExposure.Form.Fields.Notional.InfoTip`)"
                      icon-style="margin-top: -16px"
                    >{{ $t(`${ componentLang }.AddExposure.Form.Fields.Notional.InfoTip`) }}
                    </infotip>
                  </label>
                  <v-text-field-currency-directive
                    v-model="model.notional"
                    :class="{'negative-value-input-warning': isNegativeValue(model.notional), 'notional-large-value': isLargeValue(model.notional), 'notional-extra-large-value': isExtraLargeValue(model.notional), 'notional-extra-extra-large-value': isExtraExtraLargeValue(model.notional)}"
                    :options="{precision: 0, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                    :placeholder="$t(`${componentLang}.AddExposure.Form.Fields.Notional.Placeholder`)"
                    :readonly="model.userProvidedSeasonality"
                    autofocus
                    class="annual-exposure"
                    flat
                    height="70"
                    hide-details="auto"
                    solo
                  >
                    <template #prepend-inner>
                      <span
                        ref="notionalPrepend"
                        :style="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? `width: ${$refs.notionalAppend && $refs.notionalAppend.clientWidth}px` : ''"
                        class="text-no-wrap"
                        v-html="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? model.selectedItem.pair.base.displayName : selectedItemIsInterbankRate ? model.selectedItem.pair.quote.displayName : ''"
                      />
                    </template>

                    <template #append>
                      <span
                        ref="notionalAppend"
                        :style="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? `width: ${$refs.notionalPrepend && $refs.notionalPrepend.clientWidth}px` : ''"
                        class="text-no-wrap"
                        v-html="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? model.selectedItem.quotation.displayUnit : ''"
                      />
                    </template>
                  </v-text-field-currency-directive>

                  <div
                    v-if="!selectedItemIsInterbankRate"
                    :class="[isNegativeValue(model.notional) ? 'negative-value-input-warning' : 'synthesis-ui-grey-01--text']"
                    class="synthesis-text text-size-14 font-weight-medium pa-2"
                  >
                    {{ notionalInBaseCurrency }}
                  </div>
                </div>

                <v-divider />

                <div class="mt-4">
                  <div class="text-left mb-4">
                    <label class="field-label">{{ $t(`${ componentLang }.AddExposure.Form.Fields.Label.Label`) }}</label>
                    <v-text-field
                      v-model="model.label"
                      :error-messages="serverErrorMessages['label']"
                      :placeholder="$t(`${componentLang}.AddExposure.Form.Fields.Label.Placeholder`)"
                      :rules="model.validator.vuetifyFormFieldRules('label')"
                      hide-details="auto"
                      outlined
                    />
                  </div>

                  <div class="text-left">
                    <v-switch
                      v-model="hasMultipleExposuresPerAsset"
                      :disabled="isMultipleExposuresPerAssetDisabled"
                      :label="$t(`${ componentLang }.AddExposure.Form.Fields.MultipleExposuresPerAsset.Label`)"
                      color="synthesis-ui-green-01"
                      class="mx-1"
                      inset
                    />
                  </div>

                  <div
                    v-if="hasMultipleExposuresPerAsset"
                    class="text-left"
                  >
                    <label class="field-label">{{ $t(`${ componentLang }.AddExposure.Form.Fields.Code.Label`) }}</label>

                    <v-text-field
                      v-model="model.name"
                      :error-messages="serverErrorMessages['code']"
                      :placeholder="$t(`${componentLang}.AddExposure.Form.Fields.Code.Placeholder`)"
                      :rules="validationRules.name"
                      hide-details="auto"
                      outlined
                    />
                  </div>

                  <template v-if="!selectedItemIsInterbankRate">
                    <v-container
                      v-if="model.userProvidedSeasonality"
                      fluid
                      pa-0
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-messages
                            v-model="seasonalityValidationMessages"
                            class="text-size-14 error--text text-center"
                          />
                        </v-col>

                        <template v-for="(month, index) in 12">
                          <v-col
                            :key="`seasonality-${month}`"
                            cols="6"
                          >
                            <label class="field-label">{{ $tc('Common.Date.Month', 1) }} {{ month }}</label>
                            <v-text-field-currency-directive
                              v-model="model.seasonality[index]"
                              :class="{'negative-value-input-warning': isNegativeValue(model.seasonality[index])}"
                              :options="{precision: 0, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                              :prefix="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? model.selectedItem.pair.base.displayName : ''"
                              :rules="model.validator.vuetifyFormFieldRules(`seasonality.${index}`)"
                              :suffix="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? model.selectedItem.quotation.displayUnit : ''"
                              class="add-seasonality"
                              dense
                              hide-details="auto"
                              outlined
                            />
                          </v-col>
                        </template>
                      </v-row>
                    </v-container>

                    <v-btn
                      block
                      class="my-4 text-size-14 font-weight-medium"
                      color="synthesis-ui-green-01"
                      text
                      @click="toggleUserProvidedSeasonality"
                    >
                      {{ model.userProvidedSeasonality ? 'Remove seasonality' : 'Add seasonality' }}
                    </v-btn>
                  </template>
                </div>
              </template>
            </template>
          </v-form>
        </v-card-text>

        <template v-if="model.selectedItem">
          <v-divider />

          <v-card-actions class="py-3">
            <v-btn
              v-if="userIsGroupCFO && selectedCategory === 'summary'"
              :disabled="isFormSaving || !isModelValid || !isFormValid"
              :loading="isFormSaving"
              block
              class="action-button white--text"
              color="synthesis-ui-green-02"
              depressed
              large
              @click="saveSummaryFromData"
            >
              {{ isEditMode ? $t(`${ componentLang }.AddExposure.Form.Button.UpdateSummary.Title`) : $t(`${ componentLang }.AddExposure.Form.Button.SaveSummary.Title`) }}
            </v-btn>

            <v-btn
              v-else
              :disabled="isFormSaving || !isModelValid || !isFormValid"
              :loading="isFormSaving"
              block
              class="action-button white--text"
              color="synthesis-ui-green-02"
              depressed
              large
              @click="saveFromData"
            >
              {{ isEditMode ? $t(`${ componentLang }.AddExposure.Form.Button.UpdateExposure.Title`) : $t(`${ componentLang }.AddExposure.Form.Button.SaveExposure.Title`) }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                                            from '@/mixins/dialog/dialogData'
import dialogVisible                                         from '@/mixins/dialog/dialogVisible'
import API                                                   from '@/api/Api'
import AssetExposureModel                                    from '@/api/models/asset/AssetExposureModel'
import { clone, toDecimalMark, toNumberFormatNotationShort } from '@/lib/utils/helper'
import VTextFieldCurrencyDirective                           from '@/theme/default/components/form/VTextFieldCurrencyDirective'
import AssetEnum                                             from '@/api/enums/AssetEnum'
import AssetButtonGroup                                      from '@/theme/default/components/common/AssetButtonGroup'
import ActiveAnalysesRiskSummariesModel                      from '@/api/models/risks/ActiveAnalysesRiskSummariesModel'
import Infotip                                               from '@/theme/default/components/common/Infotip'
import FeatureFilterEnum                                     from '@/api/enums/FeatureFilterEnum'
import { groupBy }                                           from '@/lib/utils/array'

export default {
  name      : 'AddRiskExposureDialog',
  components: {
    Infotip,
    AssetButtonGroup,
    VTextFieldCurrencyDirective
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    componentLang: {
      type   : String,
      default: 'Assets'
    },

    baseCurrencyCode: {
      type   : String,
      default: ''
    },

    exposuresList: {
      type   : Array,
      default: () => []
    },

    analysesList: {
      type   : Array,
      default: () => []
    },

    excludedAssets: {
      type   : Array,
      default: () => []
    },

    includeDebtProfile: {
      type   : Boolean,
      default: false
    },

    multipleExposuresPerAsset: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: { SupportedAssets: 'Assets.All' },
  enums    : { AssetEnum },
  dataModel: AssetExposureModel,
  data () {
    return {
      maxExposures                  : 10,
      maxExposuresPerAsset          : 4,
      exposureItems                 : [],
      summaryItems                  : [],
      summaryItemsFiltered          : [],
      searchTerms                   : '',
      searchExposuresDebounceTimeout: null,
      searchSummariesDebounceTimeout: null,
      selectedCategory              : null,
      categories                    : [
        {
          Title   : this.$t(`${ this.componentLang }.AddExposure.Tab.Exposures`),
          Value   : 'exposure',
          Disabled: false
        },
        {
          Title   : this.$t(`${ this.componentLang }.AddExposure.Tab.RiskSummaries`),
          Value   : 'summary',
          Disabled: false
        }
      ]
    }
  },
  computed: {
    exposureItemsFiltered () {
      return this.exposureItems.filter(pair => !!pair && !this.excludedAssets?.includes(pair?.pair?.base?.symbol))
    },

    validationRules () {
      const validatorRules = { name: this.model.validator.vuetifyFormFieldRules('name') || [] }
      const customRules = {
        name: [v => !!v && this.exposurePropIsUnique(v, 'name') || 'Must be unique per asset']
      }

      return {
        name: [...validatorRules.name, ...customRules.name]
      }
    },

    hasMultipleExposuresPerAsset: {
      get () {
        return this.multipleExposuresPerAsset
      },
      set (val) {
        if (val === null || val === undefined) return

        this.$emit('update:multipleExposuresPerAsset', val)
      }
    },

    isMultipleExposuresPerAssetDisabled () {
      const valuesArr = this.exposuresList.map(exposure => exposure.assetCode)
      const hasDuplicates = valuesArr.some((item, idx) => valuesArr.indexOf(item) !== idx)

      return hasDuplicates || (this.isAssetInExposuresList(this.model.selectedItem?.pair?.base?.symbol) && this.hasMultipleExposuresPerAsset)
    },

    notionalInBaseCurrency () {
      return `${ this.model?.selectedItem?.pair?.quote?.displaySymbol } ${ toDecimalMark(parseFloat(this.model.notional || 0) * this.model.selectedItem.price, 0) }`
    },

    seasonalityValidationMessages () {
      return this.model.errors?.seasonality || []
    },

    hedgeRatiosValidationMessages () {
      if (!this.isModelValid && this.model.errors?.hedgeRatios?.length) return Object.keys(this.model?.hedgeRatios).length > 1 ? ['Enter at least one Hedge Ratio'] : ['Enter Hedge Ratio']
      return []
    },

    selectedItemIsInterbankRate () {
      return this.model?.selectedItem?.pair?.base?.type === AssetEnum.INTERBANK_RATE
    },

    isEditMode () {
      return !!this.item
    }
  },
  watch: {
    'model.seasonality': function () {
      if (this.selectedCategory === 'exposure') this.model.sumSeasonality()
    },

    'model.selectedItem': function () {
      if (this.selectedCategory === 'summary') this.initDynamicFieldsModel()
    },

    exposuresList (list) {
      const item = this.categories.find(category => category.Value === 'summary')
      item.Disabled = list.length > 0
    },

    analysesList (list) {
      const item = this.categories.find(category => category.Value === 'exposure')
      item.Disabled = list.length > 0
    },

    selectedCategory () {
      this.searchTerms = ''
      this.initDynamicModel()
    },

    baseCurrencyCode: {
      handler (newVal) {
        this.getData()
      },
      immediate: true
    },

    searchTerms (searchTerm) {
      if (this.selectedCategory === 'summary') {
        this.searchSummaries(searchTerm)
      } else {
        this.searchAssets(searchTerm)
      }
    },

    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
        this.model.selectedItem = null
        this.isEditMode && this.item.isTypeSummary ? this.selectedCategory = 'summary' : this.selectedCategory = 'exposure'
        this.initDynamicModel()
      } else {
        this.searchTerms = ''
        this.selectedCategory = null
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toDecimalMark,
    toNumberFormatNotationShort,

    initDynamicModel () {
      if (this.selectedCategory === 'summary') {
        this.$options.dataModel = ActiveAnalysesRiskSummariesModel
        this.$nextTick(() => {
          this.initModel()
          this.initDynamicFieldsModel()
        })
      } else {
        this.$options.dataModel = AssetExposureModel
        this.$nextTick(() => {
          this.initModel()
        })
      }
    },

    /**
     * initDynamicFieldsModel
     *
     * Dynamically add fields and values to the model
     * and create corresponding field model validators
     */
    initDynamicFieldsModel () {
      if (!this.isEditMode) {
        this.model.selectedItem = this.model?.selectedItem ?? null
        this.model.riskAnalysisId = this.model?.selectedItem?.id ?? ''

        this.model.selectedItem && this.model.selectedItem.result.summary.itemsWithoutPortfolio.forEach(item => {
          this.$set(this.model.hedgeRatios, item.detailedAsset.symbol, { ...item, ...{ ratio: 0 } })
        })
      }

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },

    /**
     * getData
     * getData
     *
     * Get latest quotes from api
     */
    getData () {
      this.getLatestQuotes()
      this.getActiveAnalyses()
    },

    /**
     * toggleUserProvidedSeasonality
     *
     * Toggle user provided seasonality fields
     */
    toggleUserProvidedSeasonality () {
      this.model.userProvidedSeasonality = !this.model.userProvidedSeasonality

      if (this.model.userProvidedSeasonality) {
        this.model.sumSeasonality()
      } else {
        this.model.resetSeasonality()
      }
    },

    /**
     * searchAssets
     *
     * Search in supported assets locally, create pairs
     * for the result and request latest quotes with
     * 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchAssets (searchTerm) {
      clearTimeout(this.searchExposuresDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      if (!searchTerm) {
        this.getLatestQuotes()
        return
      }

      this.searchExposuresDebounceTimeout = setTimeout(() => {
        const results = this.SupportedAssets.filter(asset => asset.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.symbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.displaySymbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm))
        const resultPairs = []

        results.forEach(asset => {
          if (this.baseCurrencyCode && asset.symbol !== this.baseCurrencyCode) {
            resultPairs.push({
              baseSymbol : asset.symbol,
              quoteSymbol: this.baseCurrencyCode
            })
          }
        })

        this.getLatestQuotes(resultPairs)
      }, 500)
    },

    /**
     * searchSummaries
     *
     * Search in group cfo analyses locally,
     * with 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchSummaries (searchTerm) {
      clearTimeout(this.searchSummariesDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      this.searchExposuresDebounceTimeout = setTimeout(() => {
        this.summaryItemsFiltered = this.summaryItems.filter(item => item.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm))
      }, 500)
    },

    /**
     * getLatestQuotes
     *
     * Request latest quotes for passed asset pairs
     *
     * @param assetPairs {Array<Object>}
     */
    getLatestQuotes (assetPairs = this.user.getLivePricesAssetPairs(this.baseCurrencyCode)) {
      if (!Array.isArray(assetPairs) || assetPairs.length <= 0 || !this.baseCurrencyCode) return

      const resultPairs = []
      assetPairs.forEach(asset => {
        if (this.baseCurrencyCode && asset.baseSymbol !== this.baseCurrencyCode) {
          resultPairs.push({
            baseSymbol : asset.baseSymbol,
            quoteSymbol: this.baseCurrencyCode
          })
        }
      })

      API.Resource.Assets.LatestQuotes(resultPairs, FeatureFilterEnum.RiskAnalysis)
        .then(response => {
          this.handleLatestQuotesResponse(response)
        })
        .catch(e => {
          this.handleLatestQuotesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleLatestQuotesResponse
     *
     * @param response <Object>
     */
    handleLatestQuotesResponse (response) {
      const data = API.responseData(response)?.latestQuotes || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.exposureItems = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * getActiveAnalyses
     *
     * Request Group CFO Active Analyses
     *
     * @param assetPairs {Array<Object>}
     */
    getActiveAnalyses () {
      if (!this.userIsGroupCFO) return

      API.Resource.Risks.ActiveAnalyses(this.user.selectedAccountSubsidiaryIds)
        .then(response => {
          this.handleActiveAnalysesResponse(response)
        })
        .catch(e => {
          this.handleActiveAnalysesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleActiveAnalysesResponse
     *
     * @param response <Object>
     */
    handleActiveAnalysesResponse (response) {
      const data = API.responseData(response)?.activeAnalyses || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.summaryItems = this.summaryItemsFiltered = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * onExposuresListItemClick
     *
     * @param item {Object}
     */
    onExposuresListItemClick (item) {
      this.model.selectedItem = item
      this.model.assetCode = item?.pair?.base?.symbol || ''

      const uniqueExposureCode = this.getUniqueExposureCode(item.pair.base.symbol)
      this.model.code = uniqueExposureCode || this.model.assetCode
      this.model.name = this.model.code

      if (!this.hasMultipleExposuresPerAsset && this.countAssetInExposuresList(this.model.assetCode) > 0) {
        this.hasMultipleExposuresPerAsset = true
      }
    },

    /**
     * onSummariesListItemClick
     *
     * @param item {Object}
     */
    onSummariesListItemClick (item) {
      this.model.selectedItem = item
    },

    /**
     * onBackToListClick
     *
     * Handle back to asset list button click
     */
    onBackToListClick () {
      this.model.selectedItem = null
      this.model.label = ''

      if (this.selectedCategory === 'summary') {
        this.model.hedgeRatios = {}
        this.model.riskAnalysisId = ''
      } else {
        this.model.assetCode = ''
        this.model.code = ''
        this.model.name = ''
        this.model.userProvidedSeasonality = false
        this.model.resetSeasonality()
      }
    },

    /**
     * saveSummaryFromData
     *
     * Locally persist entered Risk Summaries
     */
    saveSummaryFromData () {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const data = new ActiveAnalysesRiskSummariesModel(clone(this.model))
      this.validateForm() && this.formSaveSuccess(data)
    },

    /**
     * saveFromData
     *
     * Locally persist entered exposures
     */
    saveFromData () {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const data = new AssetExposureModel(clone(this.model))
      if (data.userProvidedSeasonality) {
        data.seasonality = data.seasonality.map(item => item === '' ? '0' : item)
      } else {
        data.seasonality = []
      }
      this.validateForm() && this.formSaveSuccess(data)
    },

    isAssetInExposuresList (symbol) {
      return !!this.exposuresList.find(e => e.assetCode === symbol)
    },

    isAssetDisabled (symbol) {
      return this.countAssetInExposuresList(symbol) >= this.maxExposuresPerAsset
    },

    canAssetBeSelected (symbol) {
      const grouped = groupBy(this.exposuresList, item => item.assetCode)

      return (!this.isAssetDisabled(symbol) && Object.keys(grouped).length < this.maxExposures) || (Object.keys(grouped).length === this.maxExposures && Object.keys(grouped).includes(symbol) && !this.isAssetDisabled(symbol))
    },

    countAssetInExposuresList (symbol) {
      return this.exposuresList.filter(e => e.assetCode === symbol).length
    },

    getUniqueExposureCode (symbol) {
      const availableCodes = [1, 2, 3, 4]
      const exposureUsedCodes = this.exposuresList.filter(e => e.assetCode === symbol).map(exposure => parseInt(exposure.code.charAt(exposure.code.length - 1)))

      for (let i = 0; i <= availableCodes.length - 1; i++) {
        const item = availableCodes[i]
        if (!exposureUsedCodes.includes(item)) return `${ symbol }-${ item }`
      }

      return `${ symbol }-1`
    },

    exposurePropIsUnique (val, prop) {
      return !this.exposuresList.filter(exp => exp.code !== this.model.code).find(e => e[prop] === val)
    },

    isSummaryInAnalysisList (analysisId) {
      return !!this.analysesList.find(e => e.riskAnalysisId === analysisId)
    },

    isSummaryItemDisabled (item) {
      return item.baseCurrency?.symbol !== this.baseCurrencyCode
    },

    isNegativeValue (val) {
      return parseInt(val) < 0
    },
    isLargeValue (val) {
      return parseInt(val) >= 1_000_000_000
    },
    isExtraLargeValue (val) {
      return parseInt(val) >= 10_000_000_000
    },
    isExtraExtraLargeValue (val) {
      return parseInt(val) >= 1_000_000_000_000
    },
    isOverPercentLimit (val) {
      return parseFloat(val) > 100
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items     : start;
  justify-content : end;
  height          : 100%;
}

/deep/ .v-dialog {
  border-radius : 4px;
  margin        : 0px !important;
  max-height    : 100% !important;
}

/deep/ .annual-exposure .v-input__prepend-inner, /deep/ .annual-exposure .v-input__append-inner {
  font-size   : 20px;
  font-weight : 500;
}

/deep/ .annual-exposure .v-text-field__slot input {
  text-align    : center;
  font-size     : 46px;
  font-weight   : 500;
  padding-right : 4px;
  padding-left  : 4px;
  min-height    : 70px;
}

/deep/ .notional-large-value .v-text-field__slot input {
  font-size : 38px;
}

/deep/ .notional-extra-large-value .v-text-field__slot input {
  font-size : 32px;
}

/deep/ .notional-extra-extra-large-value .v-text-field__slot input {
  font-size : 25px;
}

/deep/ .add-seasonality .v-input__slot {
  padding : 0 !important;
}

/deep/ .add-seasonality .v-text-field__slot .v-text-field__prefix, /deep/ .add-seasonality .v-text-field__slot .v-text-field__suffix {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
  display       : flex;
  align-items   : center;
  font-weight   : 400;
}

/deep/ .add-seasonality .v-text-field__slot input {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
}
</style>
