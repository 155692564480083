import BaseModel                   from '@/lib/data/model/BaseModel'
import AssetStrategyInputValidator from '@/api/validations/asset/AssetStrategyInputValidator'
import AssetStrategyInputResource  from '@/api/resources/asset/AssetStrategyInputResource'

export default class AssetStrategyInputModel extends BaseModel {
  constructor (payload = {}, resource = AssetStrategyInputResource, validator = AssetStrategyInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['currentStrategyProvided', 'asset', 'baseCurrency', 'instrumentOptions']
  }

  /* PROPERTIES */

  /* METHODS */
}
