import BaseResource                from '@/lib/data/resource/BaseResource'
import AssetModel                  from '@/api/models/asset/AssetModel'
import dayjs                       from 'dayjs'
import { collect }                 from '@/lib/utils/array'
import AssetPriceTriggerResource   from '@/api/resources/alerts/AssetPriceTriggerResource'
import { AlertPriorityEnumHelper } from '@/api/enums/AlertPriorityEnum'
import i18n                        from '@/lang/lang'

export default class AlertResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Description = payload.description ?? ''
    this.EmailNotifications = payload.emailNotifications ?? false
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Priority = payload.priority ?? ''
    this.AlertType = payload.alertType ?? ''
    this.ExpiryDate = payload.expiryDate ?? ''
    this.ExpiryDateFmt = this.getDateFmt(payload.expiryDate ?? null)
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? null)
    this.AssetPriceTriggers = payload.assetPriceTriggers ? collect(payload.assetPriceTriggers, AssetPriceTriggerResource) : null
    this.PositionMtmTriggers = payload.positionMtmTriggers ? collect(payload.positionMtmTriggers, AssetPriceTriggerResource) : null
    this.PortfolioMtmTriggers = payload.portfolioMtmTriggers ? collect(payload.portfolioMtmTriggers, AssetPriceTriggerResource) : null
  }

  /* PROPERTIES */
  get Currency () {
    return this.BaseCurrency?.displayName || ''
  }

  get Caption () {
    return i18n.t(`Alerts.Section.History.AlertType.Type.${ this.AlertType }`)
  }

  get Color () {
    return AlertPriorityEnumHelper.getColor(this.Priority)
  }

  get Triggers () {
    if (this.AssetPriceTriggers) {
      return this.AssetPriceTriggers
    }

    if (this.PositionMtmTriggers) {
      return this.PositionMtmTriggers
    }

    if (this.PortfolioMtmTriggers) {
      return this.PortfolioMtmTriggers
    }

    return []
  }

  get TriggerItems () {
    if (this.Triggers && !!this.Triggers.length) {
      return this.Triggers.map(item => item.Card)
    }
    return []
  }

  get Tags () {
    if (this.Triggers && !!this.Triggers.length) {
      return this.Triggers.map(item => item.Tag)
    }
    return []
  }

  get Details () {
    return {
      Description  : this.Description,
      Priority     : i18n.t(`Alerts.Priority.${ this.Priority }`),
      Currency     : this.Currency,
      ExpiryDate   : this.ExpiryDateFmt,
      Notifications: this.EmailNotifications ? i18n.t('Common.Button.Yes') : i18n.t('Common.Button.No')
    }
  }

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
