import BaseResource                                          from '@/lib/data/resource/BaseResource'
import { collect }                                           from '@/lib/utils/array'
import RiskDebtInterestExpenseVariabilityItemsResource       from '@/api/resources/risks/RiskDebtInterestExpenseVariabilityItemsResource'
import RiskDebtInterestExpenseVariabilityAssetResource       from '@/api/resources/risks/RiskDebtInterestExpenseVariabilityAssetResource'
import RiskDebtInterestExpenseVariabilitySensitivityResource from '@/api/resources/risks/RiskDebtInterestExpenseVariabilitySensitivityResource'
import { clone, toDecimalMark }                              from '@/lib/utils/helper'

export default class RiskDebtInterestExpenseVariabilityResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Assets = collect(payload?.years ?? [], RiskDebtInterestExpenseVariabilityAssetResource)
    this.Sensitivities = collect(payload?.sensitivities ?? [], RiskDebtInterestExpenseVariabilitySensitivityResource)
    this.Items = collect(payload?.items ?? [], RiskDebtInterestExpenseVariabilityItemsResource)
    this.CurrentInterestRate = payload?.currentInterestRate ?? ''
    this.CurrentInterestRateFmt = toDecimalMark(this.CurrentInterestRate)
  }

  /* PROPERTIES */
  get ChartValues () {
    const values = {}

    const v = clone(this.Items)

    if (v && !!v.length) {
      this.Assets.forEach(asset => {
        values[asset.Value] = {}

        this.Sensitivities.forEach(sensitivity => {
          const item = v.find(item => (item.AssetValue === asset.Value && item.SensitivityValue === sensitivity.Value))

          if (item) {
            values[asset.Value][sensitivity.Value] = item
          }
        })
      })
    }

    return values
  }

  /* METHODS */
  getClosestIndex (arr, val) {
    const closest = arr.reduce(function (prev, curr) {
      return (Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev)
    })

    return arr.findIndex(value => value === closest)
  }
}
