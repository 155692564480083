<template>
  <v-row class="ma-0 pa-0 justify-end">
    <v-col class="ma-0 pa-0">
      <v-select
        v-model="model"
        :class="['synthesis-inter-text', 'text-size-14', 'font-weight-600', ExportEnum.NO_EXPORT_CLASS]"
        :color="color"
        :disabled="value && value.Loading"
        :items="items"
        dense
        flat
        height="30"
        hide-details="auto"
        :item-text="(data) => `${data.Title}%`"
        item-value="Value"
        prefix="Factor"
        return-object
      />
    </v-col>
  </v-row>
</template>

<script>

import ExportEnum       from '@/api/enums/ExportEnum'
import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'

export default {
  name      : 'PositionTradeStressTestSelect',
  components: {},
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    value: {
      type   : [Object, Number],
      default: undefined
    },
    name: {
      type   : String,
      default: ''
    },
    items: {
      type   : Array,
      default: () => []
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    height: {
      type   : [String, Number],
      default: 28
    }
  },
  enums: { ExportEnum },
  data () {
    return {}
  },
  computed: {
    model: {
      get () {
        return this.value || ''
      },
      set (val, id) {
        this.$emit('input', val)
      }
    },

    color () {
      return this.themeColorRGBA('synthesis-ui-grey-01')
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .theme--light.v-select .v-select__selections {
  color: var(--v-synthesis-ui-grey-01-base);
}

/deep/ .v-text-field {
  margin-top: 0;
}
</style>
