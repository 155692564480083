import baseEChartProps from '@/theme/default/components/charts/echarts/mixins/common/baseEChartProps'

export default {
  mixins: [baseEChartProps],
  props : {
    gaugeProgressShow: {
      type   : Boolean,
      default: false
    },
    titleShow: {
      type   : Boolean,
      default: false
    },
    titleValue: {
      type   : String,
      default: undefined
    }
  }
}
