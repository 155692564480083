<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartLineProps                                   from '@/theme/default/components/charts/echarts/mixins/line/baseEChartLineProps'
import baseEChartLineData                                    from '@/theme/default/components/charts/echarts/mixins/line/baseEChartLineData'
import baseEChartBarProps                                    from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData                                     from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'
import chartsColorMixin                                      from '@/theme/default/components/charts/mixins/chartsColorMixin'
import baseEChartColors                                      from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import { toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import { first }                                             from '@/lib/utils/array'
import dayjs                                                 from 'dayjs'

import { use }                              from 'echarts/core'
import { BarChart, GraphChart, LineChart }  from 'echarts/charts'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer }                   from 'echarts/renderers'

import { DatasetComponent, GridComponent, LegendComponent, MarkLineComponent, MarkPointComponent, TitleComponent, TooltipComponent, TransformComponent } from 'echarts/components'
import chartsBaseCurrencySymbolSingle
  from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'

use([
  DatasetComponent,
  GraphChart,
  LineChart,
  BarChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  MarkLineComponent,
  MarkPointComponent,
  CanvasRenderer,
  LabelLayout,
  UniversalTransition
])

export default {
  name  : 'EChartForwardSimulations',
  mixins: [chartsColorMixin, baseEChartColors, baseEChartLineProps, baseEChartLineData, baseEChartBarProps, baseEChartBarData, chartsBaseCurrencySymbolSingle],
  props : {
    isBarChart: {
      type   : Boolean,
      default: true
    },
    hasLineBell: {
      type   : Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {
    setChartDataOptions () {
      return this.getChartDataOptions
    },

    getChartDataOptions () {
      return this.isBarChart ? this.barChartOptions : this.lineChartOptions
    },

    lineChartSeries () {
      if (this.chartDataOptions.length) {
        const o = first(this.chartDataOptions)

        const series = []

        o.values.forEach((value, index) => {
          series.push({
            name       : 'Sim ' + index,
            stack      : index + 1,
            type       : 'line',
            smooth     : true,
            symbol     : this.chartLineSymbol,
            symbolSize : this.chartLineSymbolSize,
            showSymbol : this.chartLineShowSymbol,
            labelLayout: {
              moveOverlap: 'shiftY'
            },
            emphasis: {
              focus: 'series'
            },
            animationDelay: function (idx) {
              return index * (index * 2)
            },
            data: value.y
          })
        })

        return {
          xAxis : o.dates,
          series: series
        }
      }

      return {
        xAxis : [],
        series: []
      }
    },

    barChartSeries () {
      let o = {
        x                        : [],
        y                        : [],
        onePercentRisk           : '',
        meanBucketIndex          : '',
        onePercentileBucketIndex : '',
        fivePercentileBucketIndex: ''
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      let color = this.themeColorRGBA('synthesis-ui-blue-04')

      const series = Object.keys(o.y).map((key, index) => {
        const name = o.x[key] || ''
        const values = new Array(o.y.length).fill('')

        values[key] = parseFloat(o.y[key]) || 0

        if (index === o.onePercentileBucketIndex) {
          color = this.themeColorRGBA('synthesis-ui-orange-02')
        } else if (index === o.meanBucketIndex) {
          color = this.themeColorRGBA('synthesis-ui-green-02')
        } else {
          color = this.themeColorRGBA('synthesis-ui-blue-04')
        }
        return {
          name        : name,
          type        : 'bar',
          stack       : 'chart',
          barMinHeight: 1,
          label       : { show: false },
          itemStyle   : {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius],
            color       : color
          },
          showBackground : false,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          animationDelay: function (idx) {
            return key * 10
          },
          cursor: this.cursor,
          data  : values
        }
      })

      if (this.hasLineBell) {
        series.push({
          type      : 'line',
          smooth    : 0.6,
          showSymbol: false,
          lineStyle : {
            color: this.themeColorRGBA('synthesis-ui-yellow-01'),
            width: 2
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          emphasis: {
            focus: 'series'
          },
          animationDelay: 400,
          data          : o.y
        })
      }

      if (this.graphLineShow) {
        // const tooltipValuePrefix = this.isBaseCurrencySymbolSingle ? '' : '<span class="pt-4 pr-1 text-size-12">' + this.axisYTickPrefix + '</span>'

        const highestValue = Math.max.apply(Math, o.y.map(v => v))

        const graphLineValues = o.y.map((v, index) => {
          const value = index === o.meanBucketIndex ? highestValue + 0.5 : v

          return {
            x    : o.x[index],
            y    : v,
            value: value
          }
        })

        // https://echarts.apache.org/en/option.html#series-graph.type
        series.push({
          z               : 10,
          animationDelay  : 400,
          silent          : true,
          type            : 'graph',
          layout          : 'none',
          symbol          : 'none',
          edgeSymbol      : ['circle', 'arrow'],
          coordinateSystem: 'cartesian2d',
          data            : graphLineValues,
          links           : [
            {
              source      : o.meanBucketIndex,
              target      : o.onePercentileBucketIndex,
              symbolSize  : [10, 10],
              symbol      : ['pin', 'arrow'],
              symbolRotate: -90,
              label       : {
                show      : true,
                fontFamily: this.fontFamily,
                fontSize  : this.axisLabelFontSize,
                fontWeight: this.axisLabelFontWeight,
                color     : 'synthesis-ui-orange-01',
                formatter : params => {
                  return (this.axisYTickPrefix + ' ' || '') + o.onePercentRisk
                }
              },
              lineStyle: {
                type     : 'dotted',
                width    : 3,
                opacity  : 1,
                curveness: -0.36,
                color    : this.themeColorRGBA('synthesis-ui-orange-01')
                /* color    : {
                  type      : 'linear',
                  x         : 0,
                  y         : 0,
                  x2        : 0,
                  y2        : 1,
                  colorStops: [{
                    offset: 0,
                    color : this.themeColorRGBA('synthesis-ui-green-01')
                  }, {
                    offset: 0.7,
                    color : this.themeColorRGBA('synthesis-ui-orange-01')
                  }, {
                    offset: 1,
                    color : this.themeColorRGBA('synthesis-ui-orange-01')
                  }]
                } */
              }
            }
          ]
        })
      }

      return {
        xAxis : o.x,
        yAxis : o.y,
        series: series
      }
    },

    /**
     * Bar chart [ Distribution ]
     */
    barChartOptions () {
      const data = this.barChartSeries

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : 1500,
        animationEasing   : 'elasticOut',
        tooltip           : {
          show: false
        },
        legend: {
          show: false
        },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid: {
          left        : 10,
          top         : 20,
          right       : 10,
          bottom      : 15,
          containLabel: true
        },
        xAxis: {
          type     : 'category',
          axisLabel: {
            show      : true,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              return (this.axisYTickPrefix + ' ' || '') + toNumberFormatNotationShort(parseFloat(value)) + (this.axisYTickSuffix || '')
            },
            interval: 8
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          boundaryGap: this.axisXBoundaryGap,
          data       : data.xAxis
        },
        yAxis: {
          // z        : 2,
          type     : 'value',
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : false,
            margin    : this.axisYLabelMargin,
            padding   : [0, 2, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              return (value === 0 ? value : toDecimalSignificant(parseFloat(value))) + '%'
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : {
            show: false
          },
          axisTick: {
            show: false
          },
          silent: true,
          data  : data.yAxis
        },
        series: data.series
      }
    },

    /**
     * Line chart [ Monte Carlo ]
     */
    lineChartOptions () {
      const data = this.lineChartSeries

      return {
        /*  legend: {
           show: true,
           top : '10',
           data: data.series.map(item => item.name)
         }, */
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : 300,
        animationEasing   : 'elasticOut',
        tooltip           : {
          show   : false,
          trigger: 'axis',
          order  : 'valueDesc'
        },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid: {
          left        : 10,
          top         : 20,
          right       : 10,
          bottom      : 15,
          containLabel: true
        },
        xAxis: {
          data        : data.xAxis,
          type        : 'category',
          offset      : 5,
          nameLocation: 'middle',
          axisPointer : {
            show     : false,
            lineStyle: {
              color: this.axisPointerLineStyleColor,
              width: this.axisPointerLineStyleWidth
            },
            label: {
              show           : true,
              fontFamily     : this.fontFamily,
              fontSize       : this.axisPointerLabelFontSize,
              color          : this.axisPointerLabelFontColor,
              backgroundColor: this.axisPointerLabelBackgroundColor,
              formatter      : params => {
                return (this.axisXTickPrefix || '') + params.value + (this.axisXTickSuffix || '')
              }
            }
          },
          axisLabel: {
            visible   : true,
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisXLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }
              if (this.axisXLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisXLabelValueFormat === 'date') {
                value = dayjs(value).format(this.axisXLabelValueDateFormat)
              }
              return (this.axisXTickPrefix + ' ' || '') + value + (this.axisXTickSuffix || '')
            },
            align      : 'right',
            padding    : [0, 0, 0, 0], // [top, right, bottom, left]
            interval   : this.axisXLabelInterval,
            hideOverlap: this.axisXLabelHideOverlap
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show     : this.axisXSplitLineShow,
            lineStyle: {
              type   : this.axisXSplitLineType,
              opacity: this.axisXSplitLineOpacity
            }
          },
          boundaryGap: false,
          silent     : true // prevent interaction with the axis
        },
        yAxis: {
          type       : 'value',
          scale      : true,
          axisPointer: {
            show     : false,
            lineStyle: {
              color: this.axisPointerLineStyleColor,
              width: this.axisPointerLineStyleWidth
            },
            label: {
              show           : true,
              fontFamily     : this.fontFamily,
              fontSize       : this.axisPointerLabelFontSize,
              color          : this.axisPointerLabelFontColor,
              backgroundColor: this.axisPointerLabelBackgroundColor,
              formatter      : params => {
                return (this.axisYTickPrefix + ' ' || '') + toNumberFormatNotationShort(params.value) + (this.axisYTickSuffix || '')
              }
            }
          },
          axisLabel: {
            show         : true,
            inside       : false,
            fontFamily   : this.fontFamily,
            fontSize     : this.axisLabelFontSize,
            color        : this.axisLabelFontColor,
            padding      : [0, 0, 0, -10], // [top, right, bottom, left]
            hideOverlap  : this.axisYLabelHideOverlap,
            verticalAlign: this.axisYLabelVerticalAlign,
            formatter    : value => {
              return (this.axisYTickPrefix + ' ' || '') + (value === 0 ? value : toNumberFormatNotationShort(parseFloat(value))) + (this.axisYTickSuffix || '')
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show          : false,
            inside        : true,
            alignWithLabel: true
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              opacity: this.axisYSplitLineOpacity
            }
          },
          splitNumber: 4,
          silent     : true
        },
        color : this.colors,
        series: data.series
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
