export default {
  data () {
    return {
      notMergeOption     : false,
      replaceMergeOptions: ['xAxis', 'yAxis', 'series']
    }
  },
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 10,
        top         : 10,
        right       : 20,
        bottom      : 20,
        containLabel: true
      }
    },
    getChartOptions () {
      return this.chartOptions ? this.chartOptions : this.getChartDataOptions
    },
    setChartOptions () {
      return this.setChartDataOptions
    },
    getChartHeight () {
      return this.chartHeight
    }
  },
  watch: {
    setChartOptions (val, oldVal) {
      const echart = this.$refs[`echart${ this.chartRefId }`]
      echart.setOption(val, {
        notMerge    : this.notMergeOption,
        replaceMerge: this.replaceMergeOptions,
        lazyUpdate  : true,
        silent      : true
      })
    }
  }
}
