import BaseModel                     from '@/lib/data/model/BaseModel'
import RiskDebtProfileInputValidator from '@/api/validations/risks/RiskDebtProfileInputValidator'
import RiskDebtProfileInputResource  from '@/api/resources/risks/RiskDebtProfileInputResource'
import API                           from '@/api/Api'
import { collect }                   from '@/lib/utils/array'
import RiskDeptFixedInputModel       from '@/api/models/risks/RiskDeptFixedInputModel'
import RiskDeptFloatingInputModel    from '@/api/models/risks/RiskDeptFloatingInputModel'
import RiskDeptDerivativeInputModel  from '@/api/models/risks/RiskDeptDerivativeInputModel'

export default class RiskDebtProfileInputModel extends BaseModel {
  constructor (payload = {}, resource = RiskDebtProfileInputResource, validator = RiskDebtProfileInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['id', 'base']
  }

  /* PROPERTIES */

  /* METHODS */
  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)
    data.fixedDebts = collect(data.fixedDebts ?? [], RiskDeptFixedInputModel).map(item => item.clone())
    data.floatingDebts = collect(data.floatingDebts ?? [], RiskDeptFloatingInputModel).map(item => item.clone())
    data.debtDerivatives = collect(data.debtDerivatives ?? [], RiskDeptDerivativeInputModel).map(item => item.clone())

    return data
  }

  async SaveDebtProfile () {
    return await API.Resource.Risks.SaveDebtProfile(this.clone())
  }
}
