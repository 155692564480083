const AlertPriorityEnum = {
  Low   : 'Low',
  High  : 'High',
  Medium: 'Medium'
}

export const AlertPriorityEnumHelper = {
  getColor: function (priority) {
    switch (priority) {
    case AlertPriorityEnum.Low:
      return 'green'
    case AlertPriorityEnum.High:
      return 'red'
    case AlertPriorityEnum.Medium:
      return 'yellow'
    }

    return 'blue'
  }
}

export default AlertPriorityEnum
