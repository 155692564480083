const ChartsStyleEnum = {
  Colors: {
    Global: [
      'synthesis-ui-purple-02',
      'synthesis-ui-orange-02',
      'synthesis-ui-green-02',
      'synthesis-ui-yellow-02',
      'synthesis-ui-blue-04',
      'synthesis-ui-red-02',
      'synthesis-ui-purple-05',
      'synthesis-ui-orange-05',
      'synthesis-ui-green-05',
      'synthesis-ui-yellow-05',
      'synthesis-ui-blue-07',
      'synthesis-ui-red-05',
      'synthesis-ui-grey-01',
      'synthesis-ui-orange-01',
      'synthesis-ui-purple-01',
      'synthesis-ui-green-00',
      'synthesis-ui-red-00',
      'synthesis-ui-blue-04',
      'synthesis-ui-orange-04',
      'synthesis-ui-purple-04'

    ],

    LineChartSeasonality: {
      user: 'synthesis-ui-purple-04',
      max : 'synthesis-ui-orange-02',
      no  : 'synthesis-ui-blue-04'
    },

    LineChartSeasonalityGraphLine: {
      user: 'synthesis-ui-purple-01',
      max : 'synthesis-ui-orange-01',
      no  : 'synthesis-ui-blue-01'
    },

    HedgingInstrumentBar: [
      'synthesis-ui-blue-04',
      'synthesis-ui-orange-02'
    ],

    HedgingCompareInstrumentBar: {
      Current : 'synthesis-ui-blue-04',
      Optimal : 'synthesis-ui-yellow-02',
      Industry: 'synthesis-ui-purple-02'
    },

    RiskSummaryDashboardColors: [
      'synthesis-ui-red-02',
      'synthesis-ui-green-02'
    ],

    Survey: [
      'synthesis-ui-green-02',
      'synthesis-ui-red-02',
      'synthesis-ui-grey-04'
    ],

    Gauge: [
      'synthesis-ui-green-03',
      'synthesis-ui-blue-05',
      'synthesis-ui-yellow-03',
      'synthesis-ui-orange-03',
      'synthesis-ui-red-03'
    ],

    KnowledgeCenter: [
      ['synthesis-ui-purple-03', 'synthesis-ui-purple-01'],
      ['synthesis-ui-orange-03', 'synthesis-ui-orange-01'],
      ['synthesis-ui-green-03', 'synthesis-ui-green-01'],
      ['synthesis-ui-yellow-03', 'synthesis-ui-yellow-01'],
      ['synthesis-ui-blue-04', 'synthesis-ui-blue-02'],
      ['synthesis-ui-red-03', 'synthesis-ui-red-01']
    ]
  },

  Bar: {
    BorderRadius: 4
  }
}

export default ChartsStyleEnum
