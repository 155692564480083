import BaseResource from '@/lib/data/resource/BaseResource'

export default class CurrentRiskAssetExposureResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.asset = payload.asset ?? {
      displayName  : payload?.selectedItem?.pair?.base?.displayName ?? '',
      displaySymbol: payload?.selectedItem?.pair?.base?.displaySymbol ?? '',
      name         : payload?.selectedItem?.pair?.base?.name ?? '',
      symbol       : payload?.selectedItem?.pair?.base?.symbol ?? '',
      type         : payload?.selectedItem?.pair?.base?.type ?? ''
    }
    this.assetCode = payload.assetCode ?? this.asset?.symbol ?? ''
    this.notional = payload.notional ?? ''
    this.label = payload.label ?? ''
    this.code = payload.code ?? ''
    this.name = payload.name ?? ''
    this.seasonality = payload.seasonality ?? new Array(12).fill('')
    this.userProvidedSeasonality = payload.userProvidedSeasonality ?? false
    this.selectedItem = payload.selectedItem ?? {
      price       : payload.baseCurrencyAmount ?? 0,
      displayPrice: payload.baseCurrencyAmount ?? 0,
      pair        : {
        base : payload.asset,
        quote: payload.baseCurrency
      },
      quotation: payload.quotation ?? {}
    }
  }

  /* PROPERTIES */

  /* METHODS */
}
