import { API } from '@/api/Api'

import { ArticleQuery, ArticlesListQuery } from '@/api/query/KnowledgeCenter.graphql'

export default {
  // Queries
  async List (data) {
    const Data = { ...data }

    return await API.GQLRequest(ArticlesListQuery, Data)
  },

  async Article (id) {
    const Data = { id: id }

    return await API.GQLRequest(ArticleQuery, Data)
  }

  // Mutations
}
