<template>
  <v-card
    :class="[!large && 'd-flex align-center']"
    :disabled="disabled"
    :height="large ? 'auto' : height"
    :min-height="minHeight"
    :style="[active ? activeItemStyles : {}]"
    class="item-group-card"
    flat
    @click="toggle"
  >
    <v-avatar
      v-if="item.Icon || item.AvatarText || item.Image"
      :class="large ? 'ma-4 mb-0' : 'ma-3'"
      :color="item.AvatarColor || avatarColor"
      :size="avatarSize"
      :tile="!!item.Image"
    >
      <slot
        :item="item"
        name="avatar"
      >
        <img
          v-if="item.Image"
          :src="item.Image"
        >

        <span
          v-else-if="item.AvatarText"
          :class="item.AvatarIconClass || avatarIconClass"
          :style="`font-size: ${avatarIconSize}px`"
          class="synthesis-text font-weight-medium"
        >
          {{ item.AvatarText }}
        </span>

        <v-icon
          v-else
          :class="item.AvatarIconClass || avatarIconClass"
          :style="`font-size: ${avatarIconSize}px`"
        >
          {{ item.Icon }}
        </v-icon>
      </slot>
    </v-avatar>

    <v-card-title
      :class="[large ? 'text-size-20 font-weight-medium' : 'text-size-14 font-weight-regular pl-0', avatarTitleClass]"
      class="synthesis-text synthesis-brand-dark-blue--text"
    >
      <slot
        :title="item.Title"
        name="title"
      >
        {{ item.Title }}
      </slot>
    </v-card-title>

    <template v-if="large">
      <v-card-subtitle
        v-if="item.InfoTip"
        class="synthesis-text text-size-14 synthesis-ui-grey-01--text font-weight-regular pt-4"
      >
        {{ item.InfoTip }}
      </v-card-subtitle>
    </template>

    <template v-else>
      <infotip
        v-if="item.InfoTip"
        icon-style="position: absolute; top: 0; right: 0;"
      >
        {{ item.InfoTip }}
      </infotip>
    </template>
  </v-card>
</template>

<script>
import Infotip from '@/theme/default/components/common/Infotip'

export default {
  name      : 'ItemGroupItemCard',
  components: { Infotip },
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type    : Object,
      required: true
    },

    large: {
      type   : Boolean,
      default: false
    },

    height: {
      type   : Number,
      default: 80
    },

    minHeight: {
      type   : Number,
      default: 80
    },

    active: {
      type   : Boolean,
      default: false
    },

    disabled: {
      type   : Boolean,
      default: false
    },

    toggle: {
      type   : Function,
      default: function () {}
    },

    avatarSize: {
      type   : [String, Number],
      default: 35
    },

    avatarIconSize: {
      type   : [String, Number],
      default: 20
    },

    avatarIconClass: {
      type   : String,
      default: 'synthesis-ui-purple-01--text'
    },

    avatarColor: {
      type   : String,
      default: 'synthesis-ui-purple-07'
    },

    avatarTitleClass: {
      type   : String,
      default: ''
    },

    activeBorderColor: {
      type   : String,
      default: 'synthesis-ui-blue-04-base'
    },

    activeBgColor: {
      type   : String,
      default: 'synthesis-brand-lightest-blue-base'
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    activeItemStyles () {
      return {
        border         : `1px solid var(--v-${ this.activeBorderColor }) !important`,
        backgroundColor: `var(--v-${ this.activeBgColor })`
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
.item-group-card {
  border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  background-color: #FFFFFF;
}
</style>
