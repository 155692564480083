<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import chartsBaseCurrencySymbolSingle                                       from '@/theme/default/components/charts/mixins/chartsBaseCurrencySymbolSingle'
import baseEChartLineAreaProps                                              from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaProps'
import baseEChartLineAreaData                                               from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaData'
import baseEChartLineProps                                                  from '@/theme/default/components/charts/echarts/mixins/line/baseEChartLineProps'
import baseEChartLineData                                                   from '@/theme/default/components/charts/echarts/mixins/line/baseEChartLineData'
import baseEChartBarProps                                                   from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData                                                    from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'
import chartsColorMixin                                                     from '@/theme/default/components/charts/mixins/chartsColorMixin'
import baseEChartColors                                                     from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import { toDecimalMark, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import { first }                                                            from '@/lib/utils/array'
import { isEmpty }                                                          from '@/lib/utils/type'
import utils                                                                from '@/lib/utils'
import dayjs                                                                from 'dayjs'

import { graphic, use }                     from 'echarts/core'
import { BarChart, GraphChart, LineChart }  from 'echarts/charts'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer }                   from 'echarts/renderers'

import { DatasetComponent, GraphicComponent, GridComponent, LegendComponent, MarkLineComponent, MarkPointComponent, TitleComponent, TooltipComponent, TransformComponent } from 'echarts/components'

use([
  DatasetComponent,
  GraphChart,
  LineChart,
  BarChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  MarkLineComponent,
  MarkPointComponent,
  CanvasRenderer,
  LabelLayout,
  GraphicComponent,
  UniversalTransition
])

export default {
  name  : 'EChartInterestExpenseVariability',
  mixins: [chartsColorMixin, baseEChartColors, baseEChartLineProps, baseEChartLineData, baseEChartBarProps, baseEChartBarData, chartsBaseCurrencySymbolSingle, baseEChartLineAreaProps, baseEChartLineAreaData],
  props : {
    isBarChart: {
      type   : Boolean,
      default: true
    },
    hasLineBell: {
      type   : Boolean,
      default: true
    },
    graphValueIndex: {
      type   : Number,
      default: 4
    }
  },
  data () {
    return {}
  },
  computed: {
    setChartDataOptions () {
      return this.getChartDataOptions
    },

    getChartDataOptions () {
      return this.isBarChart ? this.barChartOptions : this.lineChartOptions
    },

    barChartSeries () {
      let o = {
        x                : [],
        y                : [],
        lowBucket        : '',
        medianBucket     : '',
        highBucket       : '',
        lowBucketIndex   : '',
        medianBucketIndex: '',
        highBucketIndex  : '',
        marketValue      : '',
        marketIndex      : ''
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      let color = this.themeColorRGBA('synthesis-ui-blue-04')

      const highestValue = Math.max.apply(Math, o.y.map(v => v))

      const series = Object.keys(o.y).map((key, index) => {
        const name = o.x[key] || ''
        const values = new Array(o.y.length).fill('')

        // Value
        values[key] = parseFloat(o.y[key]) || 0

        const markLine = {
          animation              : this.markLineAnimation,
          animationThreshold     : this.markLineAnimationThreshold,
          animationDuration      : this.markLineAnimationDuration,
          animationEasing        : this.markLineAnimationEasing,
          animationDurationUpdate: this.markLineAnimationDurationUpdate,
          silent                 : true,
          lineStyle              : {
            type      : this.markLineLineStyleType,
            dashOffset: this.markLineLineStyleDashOffset,
            width     : this.markLineLineStyleWidth,
            color     : this.markLineLineStyleColor
          },
          label: {
            show     : this.markLineLabelShow,
            formatter: params => {
              let value = this.markLineValueLabel ?? params.value

              if (this.markLineValueLabelFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }

              if (this.markLineValueLabelFormat === 'percent') {
                value = toDecimalMark(parseFloat(value))
              }

              if (this.markLineValueLabelFormat === 'date') {
                value = dayjs(value).format('MMM YYYY')
              }

              return (this.markLineLabelPrefix || '') + value + (this.markLineLabelSuffix || '')
            },
            position       : 'end', // 'start', 'middle', 'end', 'insideStartTop', 'insideStartBottom', 'insideMiddleTop', 'insideMiddleBottom', 'insideEndTop', 'insideEndBottom'
            color          : this.markLineLabelColor,
            fontSize       : this.markLineLabelFontSize,
            fontFamily     : this.markLineLabelFontFamily,
            fontWeight     : this.markLineLabelFontWeight,
            backgroundColor: this.markLineLabelBackgroundColor,
            borderColor    : this.markLineLabelBorderColor,
            borderWidth    : this.markLineLabelBorderWidth,
            borderRadius   : this.markLineLabelBorderRadius,
            padding        : this.markLineLabelPadding,
            distance       : this.markLineLabelDistance
          },
          data: [
            [
              {
                xAxis     : o.marketIndex,
                yAxis     : 0,
                value     : name,
                symbolSize: 6
              },
              {
                xAxis : o.marketIndex,
                yAxis : highestValue, // values[key]
                symbol: 'none' // 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'
                // symbolSize: [8, 10],
              }
            ]
          ]
        }

        /* if (index === this.labelValueIndex) {
          values[key] = parseFloat(this.labelValue)
        } */

        if (index === o.lowBucketIndex) {
          color = this.themeColorRGBA('synthesis-ui-green-02')
        } else if (index === o.medianBucketIndex) {
          color = this.themeColorRGBA('synthesis-ui-blue-02')
        } else if (index === o.highBucketIndex) {
          color = this.themeColorRGBA('synthesis-ui-red-02')
        } else if (index === o.marketIndex) {
          // color = this.themeColorRGBA('synthesis-ui-orange-02')
          color = this.themeColorRGBA('synthesis-ui-grey-04')
        } else {
          color = this.themeColorRGBA('synthesis-ui-grey-04')
        }

        const v = {
          name        : name,
          type        : 'bar',
          stack       : 'chart',
          barMinHeight: 1,
          itemStyle   : {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius],
            color       : color
          },
          showBackground : false,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          label: {
            show      : this.labelShow,
            position  : 'top',
            fontSize  : 16,
            fontFamily: this.fontFamily,
            fontWeight: '500',
            color     : this.themeColorRGBA('synthesis-brand-dark-blue'),
            formatter : params => {
              if (params.seriesIndex === o.marketIndex) {
                return (this.axisYTickPrefix + ' ' || '') + toNumberFormatNotationShort(parseFloat(params.value)) + (this.axisYTickSuffix || '')
              }

              return ''
            }
          },
          animationDelay: function (idx) {
            return key * 10
          },
          cursor: this.cursor,
          silent: this.silent,
          data  : values
        }

        if (index === o.marketIndex) {
          v.markLine = markLine
        }

        return v
      })

      if (this.hasLineBell) {
        series.push({
          type      : 'line',
          smooth    : 0.6,
          showSymbol: false,
          lineStyle : {
            color: this.themeColorRGBA('synthesis-ui-yellow-01'),
            width: 2
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          emphasis: {
            focus: 'series'
          },
          animationDelay: 400,
          data          : o.y
        })
      }

      if (this.graphLineShow) {
        const graphLineValues = o.y.map((v, index) => {
          const value = index === o.marketIndex ? (v + 1.5) : (index === this.graphValueIndex ? highestValue : v)

          return {
            x    : o.x[index],
            y    : v,
            value: value
          }
        })

        // https://echarts.apache.org/en/option.html#series-graph.type
        series.push({
          z               : 10,
          animationDelay  : 400,
          silent          : true,
          type            : 'graph',
          layout          : 'none',
          symbol          : 'none',
          edgeSymbol      : ['circle', 'arrow'],
          coordinateSystem: 'cartesian2d',
          data            : graphLineValues,
          links           : [
            {
              source      : o.marketIndex,
              target      : this.graphValueIndex,
              symbolSize  : [10, 10],
              symbol      : ['pin', 'arrow'],
              symbolRotate: -90,
              label       : {
                show      : false,
                fontFamily: this.fontFamily,
                fontSize  : this.axisLabelFontSize,
                fontWeight: this.axisLabelFontWeight,
                color     : 'synthesis-ui-orange-02',
                formatter : params => {
                  return (this.axisYTickPrefix + ' ' || '') + o.marketValue
                }
              },
              lineStyle: {
                type     : 'dotted',
                width    : 3,
                opacity  : 1,
                curveness: -0.1, // -0.36,
                color    : this.themeColorRGBA('synthesis-ui-orange-01')
                /* color    : {
                  type      : 'linear',
                  x         : 0,
                  y         : 0,
                  x2        : 0,
                  y2        : 1,
                  colorStops: [{
                    offset: 0,
                    color : this.themeColorRGBA('synthesis-ui-green-01')
                  }, {
                    offset: 0.7,
                    color : this.themeColorRGBA('synthesis-ui-orange-01')
                  }, {
                    offset: 1,
                    color : this.themeColorRGBA('synthesis-ui-orange-01')
                  }]
                } */
              }
            }
          ]
        })
      }

      let colors = [this.themeColorRGBA('synthesis-ui-orange-02'), this.themeColorRGBA('synthesis-ui-green-02'), this.axisLabelFontColor, this.themeColorRGBA('synthesis-ui-red-02')]
      let valuesIndex = [o.marketIndex, o.lowBucketIndex, o.medianBucketIndex, o.highBucketIndex]
      let values = {}
      values[o.marketIndex] = o.marketValue
      values[o.lowBucketIndex] = o.lowBucket
      values[o.medianBucketIndex] = o.medianBucket
      values[o.highBucketIndex] = o.highBucket

      if (isEmpty(o.marketValue)) {
        colors = [this.themeColorRGBA('synthesis-ui-green-02'), this.axisLabelFontColor, this.themeColorRGBA('synthesis-ui-red-02')]
        valuesIndex = [o.lowBucketIndex, o.medianBucketIndex, o.highBucketIndex]
        values = {}
        values[o.lowBucketIndex] = o.lowBucket
        values[o.medianBucketIndex] = o.medianBucket
        values[o.highBucketIndex] = o.highBucket
      }

      return {
        xAxis      : o.x,
        yAxis      : o.y,
        series     : series,
        values     : values,
        valuesIndex: valuesIndex,
        colorsIndex: colors,
        marketValue: toNumberFormatNotationShort(parseFloat(o.marketValue))
      }
    },

    barChartOptions () {
      const data = this.barChartSeries

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : 1500,
        animationEasing   : 'elasticOut',
        tooltip           : {
          show: false
        },
        legend: {
          show: false
        },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.chartGridMargin,
        xAxis: {
          type     : 'category',
          axisLabel: {
            show      : true,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : (value, index) => {
              return data.colorsIndex[index]
            },
            formatter: (value, index) => {
              return (this.axisYTickPrefix + ' ' || '') + toNumberFormatNotationShort(parseFloat(data.values[index])) + (this.axisYTickSuffix || '')
            },
            interval: (index, value) => utils.array.includesAny(data.valuesIndex, [index])
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          boundaryGap: this.axisXBoundaryGap,
          data       : data.xAxis
        },
        yAxis: {
          // z        : 2,
          type     : 'value',
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : false,
            margin    : this.axisYLabelMargin,
            padding   : [0, 2, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              return (value === 0 ? value : toDecimalSignificant(parseFloat(value))) + '%'
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : {
            show: false
          },
          axisTick: {
            show: false
          },
          silent: true,
          data  : data.yAxis
        },
        graphic: [{
          elements: [{
            type     : 'text',
            invisible: !this.graphLineShow,
            silent   : true,
            style    : {
              x                : '24',
              y                : '32',
              z                : 0,
              cursor           : this.cursor,
              textAlign        : 'left',
              textVerticalAlign: 'middle',
              text             : data.marketValue,
              fontSize         : this.axisLabelFontSize,
              fontFamily       : this.fontFamily,
              fontWeight       : '500',
              textFill         : this.themeColorRGBA('synthesis-brand-dark-blue')
            },
            transition: 'style'
          }]
        }],
        series: data.series
      }
    },

    lineChartSeries () {
      let o = {
        x                : [],
        y                : [],
        lowBucket        : '',
        medianBucket     : '',
        highBucket       : '',
        lowBucketIndex   : '',
        medianBucketIndex: '',
        highBucketIndex  : ''
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      let chartAreaFillColor = this.chartAreaFillColor

      if (this.chartAreaFillColor?.length && this.chartAreaFillColor?.length < 2) {
        chartAreaFillColor = [this.chartAreaFillColor[0], '']
      }

      const series = []

      series.push({
        type        : 'line',
        smooth      : this.chartLineSmooth,
        symbol      : this.chartLineSymbol,
        symbolSize  : this.chartLineSymbolSize,
        symbolOffset: this.chartLineSymbolOffset,
        showSymbol  : this.chartLineShowSymbol,
        sampling    : 'lttb',
        itemStyle   : {
          color: this.chartLineColor
        },
        lineStyle: {
          width: this.chartLineWidth
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color : chartAreaFillColor[0]
            },
            {
              offset: 0.60,
              color : chartAreaFillColor[1]
            },
            {
              offset: 1,
              color : chartAreaFillColor[1]
            }
          ])
        },
        universalTransition    : true,
        animationDurationUpdate: 400,
        animationEasingUpdate  : 'linear',
        cursor                 : this.cursor,
        silent                 : this.silent,
        data                   : o.y
      })

      if (this.hasLineBell) {
        series.push({
          type      : 'line',
          smooth    : 0.6,
          showSymbol: false,
          lineStyle : {
            color: this.themeColorRGBA('synthesis-ui-yellow-01'),
            width: 2
          },
          labelLayout: {
            moveOverlap: 'shiftY'
          },
          emphasis: {
            focus: 'series'
          },
          animationDelay: 400,
          data          : o.y
        })
      }

      return {
        xAxis      : o.x,
        yAxis      : o.y,
        series     : series,
        values     : [o.lowBucket, o.medianBucket, o.highBucket],
        valuesIndex: [o.lowBucketIndex, o.medianBucketIndex, o.highBucketIndex]
      }
    },

    lineChartOptions () {
      const data = this.lineChartSeries

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : 1500,
        animationEasing   : 'elasticOut',
        tooltip           : {
          show: false
        },
        legend: {
          show: false
        },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.chartGridMargin,
        xAxis: {
          type     : 'category',
          axisLabel: {
            show      : this.axisXLabelShow,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : (value, index) => {
              if (index === 0) {
                return this.themeColorRGBA('synthesis-ui-green-02')
              }
              if (index === 1) {
                // Black
                return this.axisLabelFontColor
              }
              if (index === 2) {
                return this.themeColorRGBA('synthesis-ui-red-02')
              }

              return this.axisLabelFontColor
            },
            formatter: (value, index) => {
              if (utils.array.includesAny(data.valuesIndex, [index])) {
                if (index === data.valuesIndex[0]) {
                  value = data.values[0]
                }
                if (index === data.valuesIndex[1]) {
                  value = data.values[1]
                }
                if (index === data.valuesIndex[2]) {
                  value = data.values[2]
                }
                return (this.axisYTickPrefix + ' ' || '') + toNumberFormatNotationShort(parseFloat(value)) + (this.axisYTickSuffix || '')
              }

              return ''
            },
            interval: (index, value) => utils.array.includesAny(data.valuesIndex, [index])
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            alignWithLabel: true,
            length        : 5,
            lineStyle     : {
              color: this.axisXTickColor,
              width: this.axisXTickWidth,
              type : 'dashed'
            }
          },
          boundaryGap: this.axisXBoundaryGap,
          data       : data.xAxis
        },
        yAxis: {
          // z        : 2,
          type     : 'value',
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : false,
            margin    : this.axisYLabelMargin,
            padding   : [0, 2, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              return (value === 0 ? value : toDecimalSignificant(parseFloat(value))) + '%'
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : {
            show: false
          },
          axisTick: {
            show: false
          },
          silent: true,
          data  : data.yAxis
        },
        series: data.series
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
