<template>
  <block-item-card
    :title="title"
    min-height="150"
  >
    <v-simple-table class="px-2">
      <template #default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Name.Label') }}
            </th>
            <th class="text-left">
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Maturity.Label') }}
            </th>
            <th class="text-left">
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Notional.Label') }}
            </th>
            <th class="text-left text-no-wrap">
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.FixedRate.Label') }}
            </th>
            <th class="text-left text-no-wrap">
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Indication.Label') }}
            </th>
            <th class="text-right">
              {{ $t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Actions.Label') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in model"
            :key="`fixed-debt-${index}`"
          >
            <td
              class="text-left pa-2"
              style="width: 20%"
            >
              <v-text-field
                v-model="item.name"
                :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Name.Placeholder')"
                :rules="item.validator.vuetifyFormFieldRules('name')"
                hide-details
                :clearable="hideForExport"
                outlined
                dense
                class=""
              />
            </td>
            <td
              class="text-left pa-2"
              style="width: 20%"
            >
              <select-date
                v-model="item.maturity"
                :min="$dayjs().format('YYYY-MM-DD')"
                :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Maturity.Placeholder')"
                :rules="item.validator.vuetifyFormFieldRules('maturity')"
                hide-details
                :clearable="hideForExport"
                dense
                @change="onDerivativeMaturityChange(item, index)"
              />
            </td>
            <td
              class="text-left pa-2"
              style="width: 20%"
            >
              <v-text-field-currency-directive
                v-model="item.notional"
                :options="{precision: 0, locale: $i18n.code, currency: baseCurrency.symbol}"
                :prefix="baseCurrency.displayName"
                :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Notional.Placeholder')"
                :rules="item.validator.vuetifyFormFieldRules('notional')"
                hide-details
                :clearable="hideForExport"
                outlined
                dense
                class=""
                @change="onItemNotionalChange(item, index)"
              />
            </td>
            <td
              class="text-left pa-2"
              style="width: 20%"
            >
              <v-text-field-currency-directive
                v-model="item.fixedRate"
                :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: 100}, valueScaling: undefined, locale: $i18n.code, currency: baseCurrency.symbol}"
                :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.FixedRate.Placeholder')"
                :rules="item.validator.vuetifyFormFieldRules('fixedRate')"
                hide-details
                :clearable="hideForExport"
                outlined
                dense
                class=""
              />
            </td>
            <td
              class="text-left pa-2"
              style="width: 10%"
            >
              <v-text-field-currency-directive
                v-model="item.indication"
                :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: 100}, valueScaling: undefined, locale: $i18n.code, currency: baseCurrency.symbol}"
                :placeholder="$t('Risks.Section.DebtProfile.Edit.Form.Section.Derivatives.Fields.Indication.Placeholder')"
                hide-details
                readonly
                outlined
                dense
                style="min-width: 110px;"
                class=""
              >
                <template
                  v-if="item.indicationLoading"
                  #append
                >
                  <v-progress-circular
                    :size="16"
                    :width="2"
                    color="grey"
                    class="mt-1"
                    indeterminate
                  />
                </template>
              </v-text-field-currency-directive>
            </td>
            <td
              class="text-right text-no-wrap pa-2"
              style="width: 10%"
            >
              <v-btn
                v-if="item.amortisation.length"
                :disabled="!item.maturity || !item.notional"
                class="synthesis-ui-blue-05--text px-2"
                color="synthesis-ui-blue-05"
                depressed
                text
                @click="editAmortisation(item, index)"
              >
                <v-icon left>
                  mdi-pencil-circle-outline
                </v-icon>
                {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.EditAmortisation.Title') }}
              </v-btn>
              <v-btn
                v-else
                :disabled="!item.maturity || !item.notional"
                class="synthesis-ui-green-02--text px-2"
                color="synthesis-ui-green-02"
                depressed
                text
                @click="addAmortisation(item, index)"
              >
                <v-icon left>
                  mdi-plus-circle-outline
                </v-icon>
                {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.AddAmortisation.Title') }}
              </v-btn>

              <v-btn
                v-if="hideForExport"
                class="synthesis-ui-red-03--text px-2"
                color="synthesis-ui-red-03"
                depressed
                text
                @click="removeMaturity(index)"
              >
                <v-icon left>
                  mdi-close-circle-outline
                </v-icon>
                {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.DeleteRow.Title') }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-actions
      v-if="hideForExport"
    >
      <v-btn
        class="synthesis-ui-green-02--text"
        color="synthesis-ui-green-02"
        depressed
        large
        text
        @click="addMaturity()"
      >
        <v-icon
          small
          left
        >
          mdi-plus-circle-outline
        </v-icon>
        {{ $t('Risks.Section.DebtProfile.Edit.Form.Button.AddNew.Title') }}
      </v-btn>
    </v-card-actions>

    <add-edit-risk-debt-amortisation-dialog
      v-if="addEditAmortisationDialogVisible"
      :visible.sync="addEditAmortisationDialogVisible"
      :item="selectedItem"
      :edit-mode="editMode"
      :base-currency="baseCurrency"
      @amortisation-type-change="onAmortisationTypeChange"
      @form:save:success="onAddEditAmortisationSuccess"
    />
  </block-item-card>
</template>

<script>

import BlockItemCard                     from '@/theme/default/components/common/BlockItemCard.vue'
import RiskDeptDerivativeInputModel      from '@/api/models/risks/RiskDeptDerivativeInputModel'
import VTextFieldCurrencyDirective       from '@/theme/default/components/form/VTextFieldCurrencyDirective.vue'
import SelectDate                        from '@/theme/default/components/form/SelectDate.vue'
import riskDebtMaturityCommon            from '@/theme/default/views/risks/mixins/riskDebtMaturityCommon'
import AddEditRiskDebtAmortisationDialog from '@/theme/default/views/risks/blocks/AddEditRiskDebtAmortisationDialog.vue'

export default {
  name      : 'RiskDebtDerivativesInput',
  components: {
    AddEditRiskDebtAmortisationDialog,
    SelectDate,
    VTextFieldCurrencyDirective,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskDebtMaturityCommon],
  props     : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    onDerivativeMaturityChange (item, index) {
      item.GetDebtDerivativeMarketRate()
      this.onItemMaturityChange(item, index)
    },

    addMaturity () {
      this.model.push(new RiskDeptDerivativeInputModel())
    }
  }
}
</script>

<style scoped>
/deep/ .v-input {
  min-width : 200px;
}

/deep/ .v-text-field__slot .v-text-field__prefix, /deep/ .v-text-field__slot .v-text-field__suffix {
  padding-right : 4px;
  margin-right  : 2px;
  display       : flex;
  align-items   : center;
  font-weight   : 400;
}

/deep/ .v-text-field__slot input {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
}
</style>
