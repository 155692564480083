/**
 * Checks if the provided value is a valid Array.
 *
 * @example isArray([1]); // true
 *
 * @param val
 * @return {arg is Array<any>}
 */
export const isArray = (val) => Array.isArray(val)

/**
 * Checks if the given argument is a native boolean element.
 *
 * Use typeof to check if a value is classified as a boolean primitive.
 *
 * @example isBoolean(null); // false
 * @example isBoolean(false); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isBoolean = val => typeof val === 'boolean'

/**
 * Returns true if the a value is an empty object, collection, has no enumerable properties or is any type that is not considered a collection.
 *
 * Check if the provided value is null or if its length is equal to 0.
 *
 * @example isEmpty([]); // true
 * @example isEmpty({}); // true
 * @example isEmpty(''); // true
 * @example isEmpty([1, 2]); // false
 * @example isEmpty({ a: 1, b: 2 }); // false
 * @example isEmpty('text'); // false
 * @example isEmpty(123); // true - type is not considered a collection
 * @example isEmpty(true); // true - type is not considered a collection
 *
 * @param val
 * @returns {boolean}
 */
export const isEmpty = val => val === undefined || val === null || val === '' || !(Object.keys(val) || val).length

/**
 * Checks if the given argument is a function.
 *
 * Use typeof to check if a value is classified as a function primitive.
 *
 * @example isFunction('x'); // false
 * @example isFunction(x => x); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isFunction = val => typeof val === 'function'

/**
 * Checks if the given argument is a number.
 *
 * @example isNumber(1); // true
 * @example isNumber('1'); // false
 * @example isNumber(NaN); // false
 *
 * @param val
 * @returns {boolean}
 */
export const isNumber = val => !isNaN(parseFloat(val)) && isFinite(val) && !isArray(val)

/**
 * Returns a boolean determining if the passed value is an object or not.
 *
 * @example isObject([1, 2, 3, 4]); // false
 * @example isObject([]); // false
 * @example isObject(['Hello!']); // false
 * @example isObject({ a: 1 }); // true
 * @example isObject({}); // true
 * @example isObject(true); // false
 *
 * @param obj
 * @returns {boolean}
 */
export const isObject = obj => obj !== null && !isArray(obj) && typeof obj === 'object'

/**
 * Checks if the provided value is an object created by the Object constructor.
 *
 * Check if the provided value is truthy, use typeof to check if it is an object and
 * Object.constructor to make sure the constructor is equal to Object.
 *
 * @example isPlainObject({ a: 1 }); // true
 * @example isPlainObject(new Map()); // false
 *
 * @param val
 * @returns {boolean}
 */
export const isPlainObject = val => !!val && typeof val === 'object' && val.constructor === Object

/**
 * Returns true if an object looks like a Promise, false otherwise.
 *
 * Check if the object is not null, its typeof matches either object or function
 * and if it has a .then property, which is also a function.
 *
 * @example
 * isPromiseLike({
 *   then: function() {
 *     return '';
 *   }
 * }); // true
 * isPromiseLike(null); // false
 * isPromiseLike({}); // false
 *
 * @param obj
 * @returns {boolean}
 */
export const isPromiseLike = obj => obj !== null && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function'

/**
 * Checks if the given argument is a string. Only works for string primitives.
 *
 * Use typeof to check if a value is classified as a string primitive.
 *
 * @example isString('10'); // true
 *
 * @param val
 * @returns {boolean}
 */
export const isString = val => typeof val === 'string'
