<template>
  <infotip>
    <template #content="{on, attrs}">
      <v-hover v-slot="{hover}">
        <v-btn
          :color="hover ? colorHover : color"
          :dark="disabled ? false : dark"
          :height="large ? height: '100%'"
          :icon="text.length ? false : icon"
          :large="large"
          :loading="disabled"
          depressed
          v-bind="attrs"
          @click="onButtonClick"
          v-on="on"
        >
          {{ text }}
          <v-icon :class="[{'pl-2': text.length}]">
            mdi-lock
          </v-icon>
        </v-btn>
      </v-hover>
    </template>
    {{ $t('Common.Infotip.Unlock', {title: title}) }}
  </infotip>
</template>

<script>
import Infotip from '@/theme/default/components/common/Infotip'

export default {
  name      : 'ButtonUnlock',
  components: { Infotip },
  directives: {},
  mixins    : [],
  props     : {
    title: {
      type   : String,
      default: ''
    },
    icon: {
      type   : Boolean,
      default: true
    },
    disabled: {
      type   : Boolean,
      default: false
    },
    large: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    color: {
      type   : String,
      default: 'synthesis-ui-green-01'
    },
    colorHover: {
      type   : String,
      default: 'synthesis-ui-green-02'
    },
    text: {
      type   : String,
      default: ''
    },
    height: {
      type   : Number,
      default: 42
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {
    onButtonClick () {
      this.$emit('button-unlock-click')
    }
  }
}
</script>

<style scoped>
/deep/ .theme--light.toggle-active.v-btn--active::before,
.theme--light.toggle-active.v-btn--active:hover::before,
.theme--light.toggle-active.v-btn--active {
  opacity: 1 !important;
  background-color: var(--v-synthesis-ui-grey-06-base) !important;
}

/deep/ .theme--light.v-btn {
  font-size: 12px !important;
  font-weight: 600 !important;
}

/deep/ .theme--light.v-btn::before {
  background-color: var(--v-synthesis-ui-grey-06-base) !important;
  opacity: 0;
}

/deep/ .theme--light.v-btn--active:hover::before, .theme--light.v-btn--active::before {
  color: var(--v-synthesis-ui-purple-02-base);
}

/deep/ .v-btn-toggle .v-btn > span.v-btn__content {
  color: var(--v-synthesis-ui-purple-02-base) !important;
  font-weight: normal !important;
}

/deep/ .theme--light.toggle-active.v-btn--active > span.v-btn__content {
  color: var(--v-synthesis-ui-purple-02-base) !important;
  font-weight: 600 !important;
}

/deep/ .theme--light.v-btn {
  color: var(--v-synthesis-ui-grey-01-base) !important;
}
</style>
