import baseEChartProps from '@/theme/default/components/charts/echarts/mixins/common/baseEChartProps'
import ChartsStyleEnum from '@/api/enums/ChartsEnum'

export default {
  mixins: [baseEChartProps],
  props : {
    barBorderRadius: {
      type   : Number,
      default: ChartsStyleEnum.Bar.BorderRadius
    },
    showBackground: {
      type   : Boolean,
      default: false
    },
    barBackgroundColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-06'] }
    },
    barColor: {
      type   : String,
      default: function () { return 'auto' }
    },
    barCategoryGap: {
      type   : String,
      default: '20%'
    },
    barMinHeight: {
      type   : [String, Number],
      default: 2
    }
  }
}
