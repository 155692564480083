import AlertTypeEnum                          from '@/api/enums/AlertTypeEnum'
import AlertPriorityEnum                      from '@/api/enums/AlertPriorityEnum'
import AlertTriggerConditionThresholdTypeEnum from '@/api/enums/AlertTriggerConditionThresholdTypeEnum'

export default {
  Title: 'Alerts',

  Message: {
    NoData: {
      Title: 'No Alerts currently setup'
    }
  },

  Priority: {
    Low   : 'Low',
    Medium: 'Medium',
    High  : 'High'
  },

  TriggerType: {
    Lower_Limit: 'Lower Limit',
    Upper_Limit: 'Upper Limit'
  },

  Section: {
    Summary: {
      Details: {
        Title : 'Details',
        Fields: {
          Description: {
            Label: 'Description'
          },
          Priority: {
            Label: 'Priority '
          },
          Currency: {
            Label: 'Currency'
          },
          ExpiryDate: {
            Label: 'Expiry Date'
          },
          Notifications: {
            Label: 'Notifications'
          }
        }
      },
      Triggers: {
        Title: 'Triggers',
        Card : {
          Asset       : 'Asset',
          Assets      : 'Assets',
          Portfolio   : 'Portfolio',
          Condition   : 'Condition',
          TriggerLevel: 'Trigger Level'
        }
      }
    },
    History: {
      AlertType: {
        Title      : 'Alert Type',
        Placeholder: 'Select type',
        Type       : {
          Asset_Price  : 'Asset Price',
          Position_MTM : 'Position MTM',
          Portfolio_MTM: 'Portfolio MTM'
        },
        Items: [
          {
            Title: 'All',
            Value: null
          }, {
            Title: 'Asset Price',
            Value: AlertTypeEnum.AssetPrice
          },
          {
            Title: 'Position MTM',
            Value: AlertTypeEnum.PositionMTM
          },
          {
            Title: 'Portfolio MTM',
            Value: AlertTypeEnum.PortfolioMTM
          }
        ]
      },
      Acknowledged: {
        Title      : 'Acknowledged',
        Placeholder: 'Select',
        Items      : [
          {
            Title: 'All',
            Value: null
          },
          {
            Title: 'Yes',
            Value: true
          },
          {
            Title: 'No',
            Value: false
          }
        ]
      }
    }
  },

  Table: {
    Headers: {
      Name              : 'Name',
      Asset             : 'Asset',
      Type              : 'Type',
      AlertInception    : 'Alert Inception',
      TriggerDate       : 'Trigger Date',
      TriggerDateInfoTip: 'This field denotes either the date that the alert trigger was activated or the date that the alert expired with no trigger event.',
      TriggerLevel      : 'Trigger Level',
      Active            : 'Active',
      Priority          : 'Priority',
      Acknowledged      : 'Acknowledged'
    }
  },

  Tab: {
    Active: {
      Title  : 'Active Alerts',
      Slug   : 'active',
      Confirm: {
        Delete: {
          Title: 'Delete Alert?',
          Body : 'Are you sure you want to delete <span class="grey--text">"{Name}"</span>?'
        }
      },
      Card: {
        MenuItems: [
          {
            Event : 'active-list-item-edit-button-click',
            Title : 'Edit',
            Icon  : 'mdi-pencil',
            Color : 'synthesis-ui-grey-01',
            Active: true
          },
          {
            Event : 'archive-item-delete-button-click',
            Title : 'Delete',
            Icon  : 'mdi-trash-can',
            Color : 'synthesis-ui-red-05',
            Active: true
          }
        ]
      }
    },
    History: {
      Title: 'Alert History',
      Slug : 'history'
    }
  },

  Button: {
    New: {
      Title: '@:Common.Button.New Alert'
    },
    Add: {
      Title: '@:Common.Button.Add Condition'
    },
    Save: {
      Title: '@:Common.Button.Save Condition'
    }
  },

  Edit: {
    Title: '@:Common.Button.Edit Alert',
    Form : {
      Confirm: {
        IgnoreFiring: {
          Title: 'Immediate Alert Firing',
          Body : 'The defined alert will fire immediately! Are you sure you want to proceed?'
        }
      },

      Steps: {
        Step1: {
          Title: '@:Alerts.New.Form.Steps.Step1.Title'
        },
        Step2: {
          Title : '@:Alerts.New.Form.Steps.Step2.Title',
          Button: {
            Action: '@:Common.Button.Update Alert '
          }
        }
      },

      Fields: {
        Name: {
          Label      : '@:Alerts.New.Form.Fields.Name.Label',
          Placeholder: '@:Alerts.New.Form.Fields.Name.Placeholder'
        },
        Description: {
          Label      : '@:Alerts.New.Form.Fields.Description.Label',
          Placeholder: '@:Alerts.New.Form.Fields.Description.Placeholder'
        },
        Type: {
          Label      : '@:Alerts.New.Form.Fields.Type.Label',
          Placeholder: '@:Alerts.New.Form.Fields.Type.Placeholder',
          Items      : [
            {
              Title: 'Asset Prices',
              Value: AlertTypeEnum.AssetPrice
            },
            {
              Title: 'Positions MTM',
              Value: AlertTypeEnum.PositionMTM
            },
            {
              Title: 'Portfolio MTM',
              Value: AlertTypeEnum.PortfolioMTM
            }
          ]
        },
        Priority: {
          Label      : 'Priority',
          Placeholder: 'Please select',
          Items      : [
            {
              Title    : 'Low',
              Icon     : 'mdi-bell',
              IconColor: 'synthesis-ui-green-05',
              Value    : AlertPriorityEnum.Low
            },
            {
              Title    : 'Medium',
              Icon     : 'mdi-bell',
              IconColor: 'synthesis-ui-yellow-05',
              Value    : AlertPriorityEnum.Medium
            },
            {
              Title    : 'High',
              Icon     : 'mdi-bell',
              IconColor: 'synthesis-ui-red-02',
              Value    : AlertPriorityEnum.High
            }
          ]
        },
        ExpirationDate: {
          Label      : '@:Alerts.New.Form.Fields.ExpirationDate.Label',
          Placeholder: '@:Alerts.New.Form.Fields.ExpirationDate.Placeholder'
        },
        EnableEmailNotifications: {
          Label: '@:Alerts.New.Form.Fields.EnableEmailNotifications.Label'
        },
        Triggers: {
          Label      : '@:Alerts.New.Form.Fields.Triggers.Label',
          Placeholder: '@:Alerts.New.Form.Fields.Triggers.Placeholder',
          MenuItems  : [
            {
              Event : 'list-item-edit-button-click',
              Title : 'Edit',
              Icon  : 'mdi-pencil',
              Color : 'synthesis-ui-grey-01',
              Active: true
            },
            {
              Event : 'list-item-delete-button-click',
              Title : 'Delete',
              Icon  : 'mdi-trash-can',
              Color : 'synthesis-ui-red-05',
              Active: true
            }
          ]
        }
      }
    }
  },

  New: {
    Title: '@:Common.Button.New Alert',
    Form : {
      Confirm: {
        IgnoreFiring: {
          Title: 'Immediate Alert Firing',
          Body : 'The defined alert will fire immediately! Are you sure you want to proceed?'
        }
      },

      Steps: {
        Step1: {
          Title: 'Inputs'
        },
        Step2: {
          Title : 'Alert Triggers',
          Button: {
            Action: '@:Common.Button.Save Alert '
          }
        }
      },

      Fields: {
        Name: {
          Label      : 'Alert Name',
          Placeholder: 'Enter alert name'
        },
        Description: {
          Label      : 'Description',
          Placeholder: 'What is this alert for?'
        },
        Type: {
          Label      : 'Alert Type',
          Placeholder: 'Please select',
          Items      : [
            {
              Title: 'Asset Prices',
              Value: AlertTypeEnum.AssetPrice
            },
            {
              Title: 'Positions MTM',
              Value: AlertTypeEnum.PositionMTM
            },
            {
              Title: 'Portfolio MTM',
              Value: AlertTypeEnum.PortfolioMTM
            }
          ]
        },
        Priority: {
          Label      : 'Priority',
          Placeholder: 'Please select',
          Items      : [
            {
              Title    : 'Low',
              Icon     : 'mdi-bell',
              IconColor: 'synthesis-ui-green-05',
              Value    : AlertPriorityEnum.Low
            },
            {
              Title    : 'Medium',
              Icon     : 'mdi-bell',
              IconColor: 'synthesis-ui-yellow-05',
              Value    : AlertPriorityEnum.Medium
            },
            {
              Title    : 'High',
              Icon     : 'mdi-bell',
              IconColor: 'synthesis-ui-red-02',
              Value    : AlertPriorityEnum.High
            }
          ]
        },
        ExpirationDate: {
          Label      : 'Expiry Date',
          Placeholder: 'Add Date'
        },
        EnableEmailNotifications: {
          Label: 'Enable email notifications'
        },
        Triggers: {
          Label      : '@:Common.Button.Add Trigger',
          Placeholder: 'Select currency, commodity or interest rate',
          MenuItems  : [
            {
              Event : 'list-item-edit-button-click',
              Title : 'Edit',
              Icon  : 'mdi-pencil',
              Color : 'synthesis-ui-grey-01',
              Active: true
            },
            {
              Event : 'list-item-delete-button-click',
              Title : 'Delete',
              Icon  : 'mdi-trash-can',
              Color : 'synthesis-ui-red-05',
              Active: true
            }
          ]
        }
      }
    }
  },

  AddTrigger: {
    Title               : '@:Common.Button.Add Trigger',
    InfoTip             : 'Each Alert can have multiple trigger conditions to be activated. For the alert to be triggered all conditions will need to be met. Currency in bottom row in smaller font is the base currency chosen. Currency or Commodity on the top row is the traded asset versus the base currency. The value to the right denotes the most recent price of the asset in base currency terms.',
    Subtitle            : 'Select currency, commodity or interest rate',
    SubtitlePositionMTM : 'Select a trade',
    SubtitlePortfolioMTM: 'Select a portfolio',
    Form                : {
      Confirm: {
        Delete: {
          Title: 'Delete Trigger?',
          Body : 'Are you sure you want to delete this trigger?'
        }
      },
      Button: {
        Save: {
          Title: '@:Common.Button.Save Trigger'
        },
        Update: {
          Title: '@:Common.Button.Update Trigger'
        }
      },
      Fields: {
        Asset: {
          Label: 'Asset'
        },
        Assets: {
          Label: 'Assets'
        },
        Portfolio: {
          Label: 'Portfolio'
        },
        Threshold: {
          Label      : 'Trigger Level',
          Placeholder: 'Enter level'
        },
        ConditionType: {
          Label  : 'Condition',
          InfoTip: 'The alert condition is activated even if the asset price touches the Trigger Level only once. Green dotted line shows how far % the alert is from current market level)',
          Items  : [
            {
              Label: 'Upper Limit',
              Value: AlertTriggerConditionThresholdTypeEnum.UpperLimit
            },
            {
              Label: 'Lower Limit',
              Value: AlertTriggerConditionThresholdTypeEnum.LowerLimit
            }
          ]
        }
      }
    }
  }
}
