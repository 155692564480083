<template>
  <block-item-card
    :base-currency-symbol="baseCurrency"
    :export-element="element"
    :min-height="minHeight"
    :title="$t('Dashboard.Block.LiveHedging.Title')"
    :title-large-size="$vuetify.breakpoint.mdAndUp ? 52 : 40"
    :title-prefix="$t('Dashboard.Block.LiveHedging.Label.MTM')"
    :title-prefix-tooltip="$t('Dashboard.Block.LiveHedging.InfoTip.MTM')"
    :title-value="titleValue"
    :title-value-change="titleValueChangeFmt"
    :title-value-change-color="getDailyChangeColor(titleValueChange)"
    :title-value-change-icon="getDailyChangeIcon(titleValueChange)"
    :title-value-color-red="false"
    @export-to-image="exportToImage"
  >
    <template #actions>
      <v-spacer />
      <v-card-title
        :class="ExportEnum.NO_EXPORT_CLASS"
        class="pa-0"
      >
        <v-btn
          :to="{name: 'Positions'}"
          class="px-2 button-text-underline synthesis-text text-size-12 font-weight-400"
          color="synthesis-ui-green-02"
          height="30"
          text
        >
          {{ $t('Dashboard.Block.LiveHedging.Button.ViewAll.Title') }}
        </v-btn>
      </v-card-title>
    </template>
    <v-card-text
      :style="chartsHeight"
      class="pb-0 overflow-y-auto"
    >
      <v-row class="ma-0">
        <template
          v-for="(item, i) in items"
        >
          <v-col
            :key="`live-hedging-list-col-${i}`"
            class="pa-0"
            cols="12"
            md="6"
          >
            <live-hedging-list-card
              :key="`live-hedging-list-card-${i}`"
              :class="[{'pr-md-1': (i%2===0)},{'pl-md-1': (i%2===1)}]"
              :disable-lazy="disableLazy"
              :item="item"
            />
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </block-item-card>
</template>

<script>
import BlockItemCard       from '@/theme/default/components/common/BlockItemCard'
import LiveHedgingListCard from '@/theme/default/views/dashboard/blocks/LiveHedgingListCard'
import hasExport           from '@/mixins/hasExport'
import ExportEnum          from '@/api/enums/ExportEnum'

export default {
  name      : 'LiveHedgingBlock',
  components: {
    LiveHedgingListCard,
    BlockItemCard
  },
  directives: {},
  mixins    : [hasExport],
  props     : {
    minHeight: {
      type   : [Number, String],
      default: 364
    }
  },
  dataStore: {
    hedgingPositionsData: 'Dashboard.HedgingPositions.Data'
  },
  enums    : { ExportEnum },
  dataModel: null,
  data () {
    return {
      element    : null,
      disableLazy: false
    }
  },
  computed: {
    positions () {
      return this.hedgingPositionsData || null
    },

    items () {
      return this.positions?.Items || []
    },

    baseCurrency () {
      return this.positions?.BaseCurrencyTitle || ''
    },

    titleValue () {
      return this.positions?.TitleValueFmt || ''
    },

    titleValueChange () {
      return this.positions?.TitleValueChange || ''
    },

    titleValueChangeFmt () {
      return this.positions?.TitleValueChangeFmt || ''
    },

    chartsHeight () {
      if (this.minHeight === 'auto') {
        return `height: ${ this.minHeight }`
      }

      return `height: ${ this.minHeight - (this.$vuetify.breakpoint.mdAndUp ? 124 : 112) }px`
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    if (this.hasExport) {
      this.$nextTick(() => {
        this.element = this.$el
      })
    }
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getDailyChangeColor (v, text = '') {
      if (v === 0) {
        return 'synthesis-brand-dark-blue' + text
      } else {
        return (v > 0 ? 'synthesis-ui-green-01' : 'synthesis-ui-red-02') + text
      }
    },

    getDailyChangeIcon (v) {
      if (v === 0) {
        return ''
      } else {
        return (v > 0 ? 'mdi-arrow-top-right' : 'mdi-arrow-bottom-right')
      }
    },

    exportToImage () {
      this.disableLazy = true
    }
  }
}
</script>

<style scoped>
/deep/ .v-breadcrumbs li:nth-child(even) {
  padding: 0 8px;
}
</style>
