import BaseResource                           from '@/lib/data/resource/BaseResource'
import { collect }                            from '@/lib/utils/array'
import DashboardLivePriceItemResource         from '@/api/resources/dashboard/DashboardLivePriceItemResource'
import DashboardLivePriceCategoryItemResource from '@/api/resources/dashboard/DashboardLivePriceCategoryItemResource'

export default class DashboardLivePricesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.CategoriesItems = payload.categories ? collect(payload.categories, DashboardLivePriceCategoryItemResource) : null
    this.PricesItems = payload.prices ? collect(payload.prices, DashboardLivePriceItemResource) : null
  }

  /* PROPERTIES */
  get Categories () {
    if (this.CategoriesItems) {
      return this.CategoriesItems.filter(item => {
        return item.Show
      }).map(item => {
        return {
          Title: item.Name,
          Value: item.Category
        }
      })
    }

    return []
  }

  get Prices () {
    if (this.PricesItems) {
      return this.PricesItems
    }
    return []
  }

  /* METHODS */
}
