import BaseResource                 from '@/lib/data/resource/BaseResource'
import { collect }                  from '@/lib/utils/array'
import ComparisonAttributeResource  from '@/api/resources/industry/ComparisonAttributeResource'
import CompanyAttributeCategoryEnum from '@/api/enums/CompanyAttributeCategoryEnum'

export default class AttributesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Items = payload?.listComparisonAttributes ? collect(payload?.listComparisonAttributes, ComparisonAttributeResource) : []
  }

  /* PROPERTIES */
  get Financial () {
    if (this.Items && !!this.Items.length) {
      return this.Items.filter(item => item.Category === CompanyAttributeCategoryEnum.Financial)
    }
    return []
  }

  get Hedging () {
    if (this.Items && !!this.Items.length) {
      return this.Items.filter(item => item.Category === CompanyAttributeCategoryEnum.Hedging)
    }
    return []
  }

  /* METHODS */
}
