<template>
  <v-row class="ma-0">
    <v-col
      class="pa-0 pb-2"
      cols="12"
    >
      <block-item-card
        v-ripple="!showOverlay"
        :min-height="44"
        style="border: none;"
      >
        <v-overlay
          :absolute="true"
          :opacity=".1"
          :style="showOverlay ? 'backdrop-filter: blur(2px);background-color: rgba(165, 165, 165, 0.1)': ''"
          :value="showOverlay"
          color="rgba(165, 165, 165, .1)"
        />
        <v-container
          :style="`height: 44px;border-radius: 8px !important;cursor: ${showOverlay ? 'default' : 'pointer'}`"
          class="synthesis-ui-grey-06"
          fill-height
          pa-0
          @click="showOverlay ? {} : $emit('alert-list-card-click', item)"
        >
          <v-row class="ma-0 py-2">
            <v-col
              class="pa-0 pl-3 text-left"
              cols="2"
            >
              <v-avatar
                :color="`synthesis-ui-${item.Color}-07`"
                size="30"
              >
                <v-icon :color="`synthesis-ui-${item.Color}-01`">
                  mdi-bell
                </v-icon>
              </v-avatar>
            </v-col>
            <v-col
              class="pa-0 pl-0"
              cols="6"
            >
              <div class="d-inline-block text-no-wrap">
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.Title }}
                </div>
                <div class="synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-grey-01--text">
                  {{ item.Type }}
                </div>
              </div>
            </v-col>
            <v-col
              class="pa-0 px-3 text-right"
              cols="4"
            >
              <div class="d-inline-block text-no-wrap">
                <div class="text-size-14 font-weight-medium synthesis-brand-dark-blue--text">
                  {{ item.Value }}
                </div>
                <div :class="`synthesis-inter-text text-size-10 font-weight-regular synthesis-ui-${item.ChangeValueColor}-01--text`">
                  {{ item.ChangeValue }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </block-item-card>
    </v-col>
  </v-row>
</template>

<script>
import BlockItemCard from '@/theme/default/components/common/BlockItemCard'

export default {
  name      : 'AlertListCard',
  components: { BlockItemCard },
  directives: {},
  mixins    : [],
  props     : {
    item: {
      type    : Object,
      required: true
    },
    showOverlay: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>

</style>
