import BaseResource from '@/lib/data/resource/BaseResource'

export default class UserProfileInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.personalDetailsUpdate = payload.personalDetailsUpdate ?? {
      name: payload.personalDetailsUpdate?.name ?? ''
    }

    this.userPreferencesUpdate = payload.userPreferencesUpdate ?? {
      emailOnAlertIncident     : payload.userPreferencesUpdate?.emailOnAlertIncident ?? false,
      emailOnNewAssets         : payload.userPreferencesUpdate?.emailOnNewAssets ?? false,
      emailOnCompanyUpdates    : payload.userPreferencesUpdate?.emailOnCompanyUpdates ?? false,
      emailOnNewArticles       : payload.userPreferencesUpdate?.emailOnNewArticles ?? false,
      emailOnRiskSummaryUpdates: payload.userPreferencesUpdate?.emailOnRiskSummaryUpdates ?? false
    }

    this.passwordUpdate = payload.passwordUpdate ?? {
      oldPassword            : '',
      newPassword            : '',
      newPasswordConfirmation: ''
    }
  }

  /* PROPERTIES */

  /* METHODS */
}
