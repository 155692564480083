import BaseModel                        from '@/lib/data/model/BaseModel'
import RiskDeptDerivativeInputValidator from '@/api/validations/risks/RiskDeptDerivativeInputValidator'
import RiskDeptDerivativeInputResource  from '@/api/resources/risks/RiskDeptDerivativeInputResource'
import API                              from '@/api/Api'

export default class RiskDeptDerivativeInputModel extends BaseModel {
  constructor (payload = {}, resource = RiskDeptDerivativeInputResource, validator = RiskDeptDerivativeInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['amortisationAnnually', 'amortisationQuarterly', 'indication', 'indicationLoading']
  }

  /* PROPERTIES */

  /* METHODS */
  async GetDebtDerivativeMarketRate () {
    const accountId = window?.Vue?.user?.selectedAccountId || null
    const maturity = this.maturity || null

    if (!accountId || !maturity) return

    this.indicationLoading = true

    return await API.Resource.Risks.GetDebtDerivativeMarketRate(accountId, maturity)
      .then(response => {
        this.handleGetDebtDerivativeMarketRateResponse(response)
      })
      .catch(e => {
        this.handleGetDebtDerivativeMarketRateResponse(e.response)
      })
      .finally(() => {
        this.indicationLoading = false
      })
  }

  handleGetDebtDerivativeMarketRateResponse (response) {
    if (!response) return

    const data = API.responseData(response)?.debtDerivativeMarketRate || null
    const errors = API.responseErrors(response) || []

    if (API.isResponseSuccess(response) && data) {
      this.indication = data.indication
      if (!this.fixedRate) this.fixedRate = data.indication
    } else {
      this.$bus.$emit('app:error', errors)
    }
  }
}
