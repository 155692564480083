import BaseResource                    from '@/lib/data/resource/BaseResource'
import dayjs                           from 'dayjs'
import { collect, take }               from '@/lib/utils/array'
import AssetModel                      from '@/api/models/asset/AssetModel'
import PositionsTradeResource          from '@/api/resources/positions/PositionsTradeResource'
import i18n                            from '@/lang/lang'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import { isEmpty }                     from '@/lib/utils/type'

export default class PositionsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.AggregateMtmValue = payload.aggregateMtm ?? ''
    this.AggregateMtmValueFmt = payload.aggregateMtm ? toNumberFormatNotationShort(payload.aggregateMtm) : ''
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? null)
    this.VisibleOnDashboard = payload.visibleOnDashboard ?? false
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Trades = collect(payload.trades ?? null, PositionsTradeResource)
    this.Tags = this.getTags()
  }

  /* PROPERTIES */
  get TagsTrimmed () {
    const showTotal = 4
    const addMore = this.Tags.length > showTotal
    const showTags = take(this.Tags, showTotal)
    addMore && showTags.push('...')

    return showTags
  }

  get TableValues () {
    const rows = this.getTableRows()

    if (isEmpty(rows)) {
      return []
    }

    rows.push(this.getLastTableRow())

    return rows
  }

  get TableValuesMatured () {
    return this.getTableRows()
  }

  get ExportMaturedData () {
    return this.getExportDataCsv(i18n.t('Positions.Table.Export.MaturedTrades.Headers'))
  }

  get ExportData () {
    return this.getExportDataCsv(i18n.t('Positions.Table.Export.OpenTrades.Headers'))
  }

  get CardValues () {
    if (this.Trades) {
      return this.Trades.map(item => item.Card)
    }
    return []
  }

  get TradeStressFactorSelected () {
    return this.CardValues.map(card => {
      return {
        Id     : card.Id,
        Title  : card.CurrentStressTestFactor,
        Value  : card.CurrentStressTestFactor,
        Loading: false
      }
    })
  }

  getExportDataCsv (headers) {
    const v = []

    let data = []
    if (this.Trades) {
      data = this.Trades.map(item => item.ExportRow)
    }

    if (data && data.length && headers) {
      data.forEach(item => {
        const row = {}

        for (const [key, value] of Object.entries(headers)) {
          const val = item[`${ key }Fmt`] || item[key]
          row[value] = `"${ val }"`
        }

        v.push(row)
      })
    }

    return v
  }

  /* METHODS */
  getTags () {
    if (this.Trades) {
      return this.Trades.map(item => item.BaseAsset)
    }

    return []
  }

  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }

  getTableRows () {
    if (this.Trades) {
      return this.Trades.map(item => item.TableRow)
    }
    return []
  }

  getLastTableRow () {
    return {
      Id                 : -1,
      Asset              : i18n.t('Positions.Table.Rows.AggregateMTM'),
      BaseAsset          : '',
      QuoteAsset         : '',
      Pair               : '',
      Label              : '',
      Instrument         : '',
      TradeDate          : '',
      MaturityDate       : '',
      Rate               : '',
      MTMValue           : '',
      MTMValueFmt        : this.AggregateMtmValueFmt,
      Notional           : '',
      DailyChange        : 0,
      DailyChangeFmt     : '',
      Direction          : '',
      BaseCurrency       : this.BaseCurrency?.displaySymbol || '',
      DailyChangeBgColor : '',
      DailyChangeBgColors: '',
      AnimatePriceTimeout: null
    }
  }
}
