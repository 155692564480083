import BaseResource          from '@/lib/data/resource/BaseResource'
import AssetModel            from '@/api/models/asset/AssetModel'
import DashboardPlotTypeEnum from '@/api/enums/DashboardPlotTypeEnum'

export default class DashboardChartResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload.title ?? ''
    this.Type = payload.plotType ?? ''
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.DatesValuesX = payload.datesValuesX ?? []
    this.NumericalValuesX = payload.numericalValuesX ?? []
    this.LabelsValuesX = payload.labelsValuesX ?? []
    this.ValuesY = payload.valuesY ?? []
    this.PathValuesY = payload.pathValuesY ?? []
  }

  /* PROPERTIES */
  get BaseCurrencyTitle () {
    return this.getBaseCurrencySymbolSingle()
  }

  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displayName || ''
  }

  get BaseCurrencyDisplaySymbol () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get Values () {
    switch (this.Type) {
    case DashboardPlotTypeEnum.FORWARD_LOOKING_CHART:
      return [{
        dates : this.getXAxisValues(),
        values: this.getYAxisValues()
      }]
    case DashboardPlotTypeEnum.PIE_CHART:
      return this.getPieValues()
    }

    return [{
      x: this.getXAxisValues(),
      y: this.getYAxisValues()
    }]
  }

  /* METHODS */
  getXAxisValues () {
    switch (this.Type) {
    case DashboardPlotTypeEnum.PIE_CHART:
    case DashboardPlotTypeEnum.BAR_CHART:
    case DashboardPlotTypeEnum.BAR_CHART_PCT:
      return this.LabelsValuesX
    case DashboardPlotTypeEnum.FORWARD_LOOKING_CHART:
      return this.DatesValuesX
    case DashboardPlotTypeEnum.BAR_CHART_HISTOGRAM:
      return this.NumericalValuesX
    }
    return []
  }

  getYAxisValues () {
    switch (this.Type) {
    case DashboardPlotTypeEnum.FORWARD_LOOKING_CHART:
      return this.getCashFlowPaths()
    }
    return this.ValuesY
  }

  getCashFlowPaths () {
    return this.PathValuesY.map(item => { return { y: item } })
  }

  getPieValues () {
    const names = this.getXAxisValues()
    const values = this.getYAxisValues()

    return names.map((name, i) => {
      return {
        name : name,
        value: values[i]
      }
    })
  }

  baseCurrencySymbolSingle () {
    return this.BaseCurrencyDisplaySymbol.length === 1
  }

  getBaseCurrencySymbolSingle () {
    return this.baseCurrencySymbolSingle() ? this.BaseCurrencyDisplaySymbol : this.BaseCurrencyDisplayName
  }
}
