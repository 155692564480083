import BaseResource from '@/lib/data/resource/BaseResource'

export default class RiskDebtOptimalFixedRatioValueResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.RatesView = payload.ratesView || ''
    this.RiskAversion = payload.riskAversion || ''
    this.Value = payload.optimalFixedRatio || 0
  }

  /* PROPERTIES */

  /* METHODS */
}
