import BaseModel         from '@/lib/data/model/BaseModel'
import RiskDebtAmortisationInputValidator from '@/api/validations/risks/RiskDebtAmortisationInputValidator'
import RiskDebtAmortisationInputResource  from '@/api/resources/risks/RiskDebtAmortisationInputResource'

export default class RiskDebtAmortisationInputModel extends BaseModel {
  constructor (payload = {}, resource = RiskDebtAmortisationInputResource, validator = RiskDebtAmortisationInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
