import BaseModel           from '@/lib/data/model/BaseModel'
import TradeInputValidator from '@/api/validations/positions/TradeInputValidator'
import TradeInputResource  from '@/api/resources/positions/TradeInputResource'

export default class TradeInputModel extends BaseModel {
  constructor (payload = {}, resource = TradeInputResource, validator = TradeInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['selectedItem', 'asset', 'uuid', 'displayInstrument']
  }

  /* PROPERTIES */

  /* METHODS */
}
