import BaseResource  from '@/lib/data/resource/BaseResource'
import AssetModel    from '@/api/models/asset/AssetModel'
import i18n          from '@/lang/lang'
import AlertTypeEnum from '@/api/enums/AlertTypeEnum'
import { isEmpty }   from '@/lib/utils/type'

export default class AssetPriceTriggerResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? ''
    this.TradeId = payload.tradeId ?? ''
    this.PortfolioId = payload.portfolioId ?? ''
    this.Threshold = payload.threshold ?? ''
    this.DisplayThreshold = payload.displayThreshold ?? ''
    this.ConditionType = payload.conditionType ?? ''
    this.SnapshotValue = payload.snapshotValue ?? payload.threshold
    this.Instrument = payload.instrument ?? ''
    this.PositionName = payload.positionName ?? ''
    this.PortfolioName = payload.portfolioName ?? ''
    this.Asset = payload.asset ? new AssetModel(payload.asset) : null
    this.Assets = this.getPortfolioAssets(payload)
    this.Tags = this.getPortfolioTags(payload)
  }

  /* PROPERTIES */
  get IsPortfolio () {
    return this.Type === AlertTypeEnum.PortfolioMTM
  }

  get Type () {
    if (!isEmpty(this.PortfolioId)) {
      return AlertTypeEnum.PortfolioMTM
    } else if (!isEmpty(this.TradeId)) {
      return AlertTypeEnum.PositionMTM
    } else {
      return AlertTypeEnum.AssetPrice
    }
  }

  get Tag () {
    return this.IsPortfolio ? this.PortfolioName : this.Asset?.displayName || ''
  }

  get Card () {
    const items = [
      {
        Title: this.IsPortfolio ? i18n.t('Alerts.Section.Summary.Triggers.Card.Portfolio') : i18n.t('Alerts.Section.Summary.Triggers.Card.Asset'),
        Value: this.Tag,
        Tags : null
      },
      /* {
        Title: i18n.t('Alerts.Section.Summary.Triggers.Card.Condition'),
        Value: i18n.t(`Alerts.TriggerType.${ this.ConditionType }`),
        Tags : null
      }, */
      {
        Title: i18n.t('Alerts.Section.Summary.Triggers.Card.TriggerLevel'),
        Value: this.DisplayThreshold || '0',
        Tags : null
      }
    ]

    if (this.IsPortfolio) {
      items.splice(1, 0,
        {
          Title: i18n.t('Alerts.AddTrigger.Form.Fields.Assets.Label'),
          Value: this.Assets || '',
          Tags : this.Tags || null
        }
      )
    }
    return items
  }

  /* METHODS */
  getPortfolioAssets (payload) {
    const trades = payload.trades ?? []

    return trades && trades.map(trade => {
      return trade.assetPair.base.displayName ?? ''
    }).filter(item => item !== '').join(', ') || ''
  }

  getPortfolioTags (payload) {
    const trades = payload.trades ?? []

    return trades && trades.map(trade => {
      return trade.assetPair.base.displayName ?? ''
    }).filter(item => item !== '') || []
  }
}
