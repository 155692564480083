import BaseResource                        from '@/lib/data/resource/BaseResource'
import { collect }                         from '@/lib/utils/array'
import RiskForwardSimulationsAssetResource from '@/api/resources/risks/RiskForwardSimulationsAssetResource'
import AssetNameResource                   from '@/api/resources/asset/AssetNameResource'

export default class RiskForwardSimulationsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.simulation || null

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Assets = collect(o?.assets || [], AssetNameResource)
    this.Data = collect(o?.items || [], RiskForwardSimulationsAssetResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
