<template>
  <v-container
    class="fill-height align-start"
    fluid
  >
    <v-row
      class="fill-height"
      dense
    >
      <v-col
        class=""
        cols="12"
        lg="8"
        md="7"
        sm="12"
        xl="10"
      >
        <v-row>
          <section-header :breadcrumb-items="breadcrumbItems" />

          <v-col
            v-if="$vuetify.breakpoint.sm"
            class="py-0"
            cols="12"
          >
            <form-stepper-steps
              :step.sync="step"
              :steps="steps"
            />
          </v-col>

          <v-col
            :class="[{'pa-0': !stepHasCard}]"
            :cols="stepCols"
          >
            <v-card
              :color="stepBgColor"
              class="synthesis-card-border"
              flat
            >
              <v-card-text
                :class="[stepHasCard ? 'pa-sm-4' : 'pa-sm-0']"
                class="pa-0"
              >
                <v-form
                  ref="form"
                  v-model="isFormValid"
                  @submit.prevent
                >
                  <v-window v-model="step">
                    <v-window-item :value="1">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <label class="field-label">{{ $t('Risks.Edit.Form.Fields.Name.Label') }}</label>
                            <v-text-field
                              v-model="model.name"
                              :error-messages="serverErrorMessages['name']"
                              :placeholder="$t('Risks.Edit.Form.Fields.Name.Placeholder')"
                              :rules="model.validator.vuetifyFormFieldRules('name')"
                              hide-details="auto"
                              outlined
                            />
                          </v-col>

                          <v-col cols="12">
                            <select-base-currency
                              v-model="model.baseCurrencyCode"
                              :disabled="model.exposures.length > 0"
                              :error-messages="serverErrorMessages['baseCurrencyCode']"
                              :rules="model.validator.vuetifyFormFieldRules('baseCurrencyCode')"
                              :use-user-base-currency="!item"
                            />
                          </v-col>

                          <v-col
                            v-if="includeDebtProfileEnabled"
                            cols="12"
                          >
                            <v-switch
                              v-model="includeDebtProfile"
                              label="Include Debt Profile"
                              color="synthesis-ui-green-01"
                              class="mx-1"
                              inset
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>

                    <v-window-item :value="2">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <item-list-card
                              :items="model.exposuresList"
                              :menu-items="$t('Risks.Edit.Form.Fields.Exposures.MenuItems')"
                              @list-item-edit-button-click="onExposureListItemEditButtonClick"
                              @list-item-delete-button-click="onExposureListItemDeleteButtonClick"
                            />

                            <item-list-card
                              :items="model.riskAnalysesList"
                              :menu-items="$t('Risks.Edit.Form.Fields.Exposures.MenuItems')"
                              @list-item-edit-button-click="onAnalysesListItemEditButtonClick"
                              @list-item-delete-button-click="onAnalysesListItemDeleteButtonClick"
                            />
                          </v-col>

                          <v-col cols="12">
                            <dashed-action-button
                              :disabled="isAddExposureDisabled"
                              :title="$t('Risks.Edit.Form.Fields.Exposures.Label')"
                              @click="onAddExposureClick"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>

                    <v-window-item :value="3">
                      <v-container fluid>
                        <v-row>
                          <v-col cols="12">
                            <label class="field-label text-size-24">{{ $t('Risks.Edit.Form.Fields.Methodologies.Placeholder') }}</label>
                            <v-item-group
                              v-model="selectedMethodologies"
                              mandatory
                              multiple
                            >
                              <v-container
                                fluid
                                px-0
                              >
                                <v-row>
                                  <template v-for="(item, index) in $t('Risks.Edit.Form.Fields.Methodologies.Items')">
                                    <v-col
                                      :key="`methodology-${index}-${item.Value}`"
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-item
                                        v-slot="{active, toggle}"
                                        :value="item.Value"
                                      >
                                        <item-group-item-card
                                          :active="active"
                                          :item="item"
                                          :min-height="180"
                                          :toggle="toggle"
                                          active-bg-color="synthesis-ui-green-08-base"
                                          active-border-color="synthesis-ui-green-01-base"
                                          avatar-color="transparent"
                                          large
                                        />
                                      </v-item>
                                    </v-col>
                                  </template>
                                </v-row>
                              </v-container>
                            </v-item-group>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>

                    <v-window-item :value="4">
                      <v-container
                        fluid
                        px-0
                      >
                        <v-row>
                          <v-col cols="12">
                            <risks-analysis-show-base
                              v-if="riskAnalysisId"
                              :risk-analysis-id="riskAnalysisId"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-window-item>
                  </v-window>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        class="text-right"
        cols="12"
        lg="4"
        md="5"
        sm="12"
        xl="2"
      >
        <form-stepper
          :step.sync="step"
          :steps="steps"
          class="d-inline-flex"
          @click-btn-cancel="onStepperBtnCancelClick"
          @click-btn-back="onStepperBtnBackClick"
        />
      </v-col>
    </v-row>

    <add-risk-exposure-dialog
      :analyses-list="model.riskAnalyses"
      :base-currency-code="model.baseCurrencyCode"
      :exposures-list="model.exposures"
      :excluded-assets="excludedDebtProfileAssets"
      :include-debt-profile="includeDebtProfileEnabled && includeDebtProfile"
      :multiple-exposures-per-asset.sync="model.multipleExposuresPerAsset"
      :item="selectedEditItem"
      :visible.sync="exposuresDialogVisible"
      component-lang="Risks"
      @form:save:success="onDialogSave"
      @dialog:close="onExposureDialogClose"
    />

    <overlay-loader :value="loaderVisible" />
  </v-container>
</template>

<script>

import API                            from '@/api/Api'
import i18nRouteMeta                  from '@/mixins/i18n/i18nRouteMeta'
import formData                       from '@/mixins/form/formData'
import SectionHeader                  from '@/theme/default/components/common/SectionHeader'
import DashedActionButton             from '@/theme/default/components/common/DashedActionButton'
import ItemGroupItemCard              from '@/theme/default/components/common/ItemGroupItemCard'
import ItemListCard                   from '@/theme/default/components/common/ItemListCard'
import FormStepper                    from '@/theme/default/components/form/formStepper/FormStepper'
import formStepper                    from '@/theme/default/components/form/formStepper/mixins/formStepper'
import SelectBaseCurrency             from '@/theme/default/components/form/SelectBaseCurrency'
import FormStepperSteps               from '@/theme/default/components/form/formStepper/components/FormStepperSteps'
import RiskAnalysisModel              from '@/api/models/risks/RiskAnalysisModel'
import OverlayLoader                  from '@/theme/default/components/common/OverlayLoader'
import RisksAnalysisShowBase          from '@/theme/default/views/risks/RisksAnalysisShowBase'
import RiskForwardSimulationsResource from '@/api/resources/risks/RiskForwardSimulationsResource'
import RiskCorrelationsResource       from '@/api/resources/risks/RiskCorrelationsResource'
import RiskHistoricalSpectrumResource from '@/api/resources/risks/RiskHistoricalSpectrumResource'
import RiskSeasonalityResource        from '@/api/resources/risks/RiskSeasonalityResource'
import RiskValueAtRiskResource        from '@/api/resources/risks/RiskValueAtRiskResource'
import RiskExposuresResource          from '@/api/resources/risks/RiskExposuresResource'
import RiskSummaryResource            from '@/api/resources/risks/RiskSummaryResource'
import AssetModel                     from '@/api/models/asset/AssetModel'
import { clone }                      from '@/lib/utils/helper'
import RiskExtremeEventsBaseResource  from '@/api/resources/risks/RiskExtremeEventsBaseResource'
import assetExposureCommon            from '@/mixins/assetExposureCommon'
import AddRiskExposureDialog          from '@/theme/default/views/risks/AddRiskExposureDialog'
import FeatureFilterEnum              from '@/api/enums/FeatureFilterEnum'
import AssetExposureModel             from '@/api/models/asset/AssetExposureModel'

export default {
  name      : 'RisksAnalysisNew',
  components: {
    AddRiskExposureDialog,
    FormStepperSteps,
    RisksAnalysisShowBase,
    OverlayLoader,
    SelectBaseCurrency,
    ItemListCard,
    ItemGroupItemCard,
    DashedActionButton,
    FormStepper,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, assetExposureCommon, formData, formStepper],
  props     : {},
  enums     : {},
  dataStore : {
    riskSummaryData           : 'Risks.Summary.Data',
    riskCorrelationsData      : 'Risks.Correlations.Data',
    riskSeasonalityData       : 'Risks.Seasonality.Data',
    riskForecastsData         : 'Risks.Forecasts.Data',
    riskExposuresData         : 'Risks.Exposures.Data',
    riskValueAtRiskData       : 'Risks.ValueAtRisk.Data',
    riskHistoricalSpectrumData: 'Risks.HistoricalSpectrum.Data',
    riskForwardSimulationsData: 'Risks.ForwardSimulations.Data',
    riskExtremeEventsData     : 'Risks.ExtremeEvents.Data',
    riskBaseCurrencyData      : 'Risks.BaseCurrency.Data'
  },
  dataModel: RiskAnalysisModel,
  data () {
    return {
      riskAnalysisId         : null,
      loaderVisible          : false,
      selectedEditItem       : null,
      debtProfileExposureData: null,
      includeDebtProfile     : false,
      initialExposures       : [],
      selectedMethodologies  : [],
      breadcrumbItems        : [
        {
          text    : this.$t('Risks.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Risks' }
        },
        {
          text    : this.$t('Risks.New.Title'),
          disabled: true
        }
      ]
    }
  },
  computed: {
    excludedDebtProfileAssets () {
      return this.includeDebtProfileEnabled && this.includeDebtProfile ? this.debtProfileExposureData?.excludedAssets || [] : []
    },
    includeDebtProfileEnabled () {
      return this.userHasDebtProfile && this.model?.baseCurrencyCode === this.debtProfileExposureData?.debtProfileCurrency?.symbol
    },

    steps () {
      return [
        {
          Title   : this.$t('Risks.Edit.Form.Steps.Step1.Title'),
          Complete: this.step > 1,
          HasCard : true,
          BgColor : '#FFFFFF',
          Fields  : ['name', 'baseCurrencyCode']
        },
        {
          Title   : this.$t('Risks.Edit.Form.Steps.Step2.Title'),
          Complete: this.step > 2,
          HasCard : false,
          BgColor : 'transparent',
          Fields  : ['exposures', 'riskAnalyses']
        },
        {
          Title    : this.$t('Risks.Edit.Form.Steps.Step3.Title'),
          Complete : this.step > 3,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : [],
          BtnAction: {
            Title         : this.$t('Risks.Edit.Form.Steps.Step3.Button.Action'),
            CallBack      : this.createOrRerunAnalysis,
            PreventDefault: false
          }
        },
        {
          Title    : this.$t('Risks.Edit.Form.Steps.Step4.Title'),
          Complete : this.step > 4,
          HasCard  : false,
          BgColor  : 'transparent',
          Fields   : [],
          BtnAction: {
            Title         : this.$t('Risks.Edit.Form.Steps.Step4.Button.Action'),
            CallBack      : this.persistAnalysis,
            PreventDefault: true
          }
        }
      ]
    }
  },
  watch: {
    'model.baseCurrencyCode': {
      handler () {
        this.$nextTick(() => {
          // this.includeDebtProfile = this.includeDebtProfileEnabled
        })
      },
      immediate: true
    },

    includeDebtProfile: {
      handler (newVal) {
        if (newVal) {
          const payload = {
            asset       : this.debtProfileExposureData?.asset,
            notional    : this.debtProfileExposureData?.notional,
            label       : this.debtProfileExposureData?.sourceLabel,
            seasonality : [],
            selectedItem: {
              fromDebtProfile: true,
              price          : this.debtProfileExposureData?.price || 0,
              pair           : {
                base : this.debtProfileExposureData?.asset,
                quote: this.debtProfileExposureData?.debtProfileCurrency
              },
              quotation: {
                displayUnit: this.debtProfileExposureData?.debtProfileCurrency?.displayName || ''
              }
            }
          }

          if (this.model) {
            const filteredExposures = this.model.exposures.filter(item => !!item && !this.excludedDebtProfileAssets.includes(item?.asset?.symbol))
            this.model.exposures = [new AssetExposureModel(payload)].concat(filteredExposures)
          }
        } else {
          if (this.model) {
            this.model.exposures = clone(this.initialExposures)
          }
        }
      },
      immediate: true
    },

    selectedMethodologies (newVal) {
      if (Array.isArray(newVal)) {
        this.model.activeVar = false
        this.model.activeHistorical = false
        this.model.activeEvents = false
        this.model.activeSimulation = false
        newVal.forEach(val => { this.model[val] = true })
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    if (this.model.baseCurrencyCode && this.userHasDebtProfile) this.getDebtProfileExposure()
    this.initialExposures = this.model ? clone(this.model.exposures) : []
    this.selectedMethodologies = this.$t('Risks.Edit.Form.Fields.Methodologies.Items').map(methodology => methodology.Value)
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.deleteStorageDataById()
  },
  destroyed () {},
  methods: {
    onStepperBtnBackClick (from, to) {
      if (to < 3) this.model.id = null
      if (from === 4) this.deleteStorageDataById()
    },

    onStepperBtnCancelClick () {
      this.$router.push({ name: 'Risks' })
    },

    /**
     * getDebtProfileExposure
     *
     * Request Request debt profile exposure and latest quotes for passed asset pairs
     *
     * @param assetPairs {Array<Object>}
     */
    getDebtProfileExposure (assetPairs = this.user.getLivePricesAssetPairs(this.model?.baseCurrencyCode)) {
      if (!Array.isArray(assetPairs) || assetPairs.length <= 0 || !this.model.baseCurrencyCode) return

      this.loaderVisible = true

      const resultPairs = []
      assetPairs.forEach(asset => {
        if (this.model.baseCurrencyCode && asset.baseSymbol !== this.model.baseCurrencyCode) {
          resultPairs.push({
            baseSymbol : asset.baseSymbol,
            quoteSymbol: this.model.baseCurrencyCode
          })
        }
      })

      API.Resource.Assets.DebtProfileExposure(this.userSelectedAccountId, resultPairs, FeatureFilterEnum.RiskAnalysis)
        .then(response => {
          this.handleDebtProfileExposureQueryResponse(response)
        })
        .catch(e => {
          this.handleDebtProfileExposureQueryResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleDebtProfileExposureQueryResponse
     *
     * @param response <Object>
     */
    handleDebtProfileExposureQueryResponse (response) {
      this.loaderVisible = false

      const data = API.responseData(response)?.debtProfileExposure || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.debtProfileExposureData = data
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    createOrRerunAnalysis () {
      if (this.model.id) {
        this.rerunAnalysis()
      } else {
        this.createAnalysis()
      }
    },

    createAnalysis () {
      this.loaderVisible = true
      this.model.accountId = this.user.selectedAccountId

      this.model.CreateAnalysis()
        .then(response => {
          this.handleCreateAnalysisResponse(response)
        })
        .catch(e => {
          this.handleCreateAnalysisResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },
    handleCreateAnalysisResponse (response) {
      const data = API.responseData(response)?.createAnalysis || API.responseData(response)?.createGroupAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.setData(data)
      } else {
        this.step--
        this.$bus.$emit('app:error', errors)
      }
    },

    rerunAnalysis () {
      this.loaderVisible = true
      this.model.accountId = this.user.selectedAccountId

      this.model.RerunAnalysis()
        .then(response => {
          this.handleRerunAnalysisResponse(response)
        })
        .catch(e => {
          this.handleRerunAnalysisResponse(e.response)
        })
        .finally(() => {
          this.loaderVisible = false
        })
    },
    handleRerunAnalysisResponse (response) {
      const data = API.responseData(response)?.rerunAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.setData(data)
      } else {
        this.step--
        this.$bus.$emit('app:error', errors)
      }
    },

    persistAnalysis () {
      this.isFormSaving = true

      this.model.PersistAnalysis()
        .then(response => {
          this.handlePersistAnalysisResponse(response)
        })
        .catch(e => {
          this.handlePersistAnalysisResponse(e.response)
        })
        .finally(() => {
          this.isFormSaving = false
        })
    },
    handlePersistAnalysisResponse (response) {
      const data = API.responseData(response)?.persistAnalysis || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.$router.push({ name: 'Risks' })
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    setData (data) {
      // Risk analysis id
      this.riskAnalysisId = data.id

      // Analysis page
      if (data) {
        const o = clone(data)
        const v = o?.result || null

        // Store data to DataStore
        this.$set(this.riskSummaryData, this.riskAnalysisId, new RiskSummaryResource(o))
        this.$set(this.riskBaseCurrencyData, this.riskAnalysisId, new AssetModel(o.baseCurrency ?? null))
        v?.correlations && this.$set(this.riskCorrelationsData, this.riskAnalysisId, new RiskCorrelationsResource(o))
        v?.seasonality && this.$set(this.riskSeasonalityData, this.riskAnalysisId, new RiskSeasonalityResource(o))
        v?.hasExposure && this.$set(this.riskExposuresData, this.riskAnalysisId, new RiskExposuresResource(o))
        v?.hasVar && this.$set(this.riskValueAtRiskData, this.riskAnalysisId, new RiskValueAtRiskResource(o))
        v?.hasHistorical && this.$set(this.riskHistoricalSpectrumData, this.riskAnalysisId, new RiskHistoricalSpectrumResource(o))
        v?.hasSimulation && this.$set(this.riskForwardSimulationsData, this.riskAnalysisId, new RiskForwardSimulationsResource(o))
        v?.hasEvents && this.$set(this.riskExtremeEventsData, this.riskAnalysisId, new RiskExtremeEventsBaseResource(o))
      }

      this.model.id = data.id
    },

    deleteStorageDataById () {
      this.$delete(this.riskBaseCurrencyData, this.riskAnalysisId)
      this.$delete(this.riskSummaryData, this.riskAnalysisId)
      this.$delete(this.riskCorrelationsData, this.riskAnalysisId)
      this.$delete(this.riskSeasonalityData, this.riskAnalysisId)
      this.$delete(this.riskForecastsData, this.riskAnalysisId)
      this.$delete(this.riskExposuresData, this.riskAnalysisId)
      this.$delete(this.riskValueAtRiskData, this.riskAnalysisId)
      this.$delete(this.riskHistoricalSpectrumData, this.riskAnalysisId)
      this.$delete(this.riskForwardSimulationsData, this.riskAnalysisId)
      this.$delete(this.riskExtremeEventsData, this.riskAnalysisId)

      this.riskAnalysisId = null
    },

    onDialogSave (data) {
      if (data.isTypeSummary) {
        this.onSummarySave(data)
      } else {
        this.onExposureSave(data)
      }
    },

    onSummarySave (data) {
      const summaryIndex = this.model.riskAnalyses.findIndex(item => item.riskAnalysisId === data.riskAnalysisId)
      if (summaryIndex > -1) {
        this.model.riskAnalyses[summaryIndex] = data
      } else {
        this.model.riskAnalyses.push(data)
      }
    },

    onAnalysesListItemEditButtonClick (analysisItem, index) {
      this.selectedEditItem = analysisItem.Analysis
      this.exposuresDialogVisible = true
    },

    onAnalysesListItemDeleteButtonClick (analysisItem, index) {
      this.$root.$confirmDialog(
        this.$t('Risks.AddExposure.Form.Confirm.Delete.Title'),
        this.$t('Risks.AddExposure.Form.Confirm.Delete.Body'),
        {
          color : 'synthesis-ui-red-02',
          button: {
            no : { title: this.$t('Common.Button.Cancel') },
            yes: { title: this.$t('Common.Button.Delete') }
          }
        }
      ).then(response => {
        response && this.model.riskAnalyses.splice(index, 1)
      })
    }
  }
}
</script>

<style scoped>

</style>
