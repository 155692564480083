<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'
import baseEChartData   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartData'

import { use }              from 'echarts/core'
import { GraphicComponent } from 'echarts/components'
import { CanvasRenderer }   from 'echarts/renderers'
import baseEChartProps      from '@/theme/default/components/charts/echarts/mixins/common/baseEChartProps'

use([
  GraphicComponent,
  CanvasRenderer
])

export default {
  name    : 'BaseEChartGraphic',
  enums   : {},
  mixins  : [chartsColorMixin, baseEChartProps, baseEChartData],
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 0,
        bottom      : 0,
        containLabel: false
      }
    },

    getChartDataOptions () {
      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        grid              : this.getChartGridMargin,
        graphic           : {
          elements: [
            {
              type : 'text',
              left : 'center',
              top  : 'center',
              style: {
                text          : 'Maven Synthesis',
                fontFamily    : this.fontFamily,
                fontSize      : this.fontSize,
                color         : this.fontColor,
                fontStyle     : this.fontStyle,
                fontWeight    : this.fontWeight,
                lineDash      : [0, 200],
                lineDashOffset: 0,
                fill          : 'transparent',
                stroke        : '#000',
                lineWidth     : 1
              },
              keyframeAnimation: {
                duration : this.chartAnimation,
                loop     : true,
                keyframes: [
                  {
                    percent: 0.7,
                    style  : {
                      fill          : 'transparent',
                      lineDashOffset: 200,
                      lineDash      : [200, 0]
                    }
                  },
                  {
                    // Stop for a while.
                    percent: 0.8,
                    style  : {
                      fill: 'transparent'
                    }
                  },
                  {
                    percent: 1,
                    style  : {
                      fill: 'black'
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
