import BaseResource           from '@/lib/data/resource/BaseResource'
import AlertPriorityEnum      from '@/api/enums/AlertPriorityEnum'
import AlertTypeEnum          from '@/api/enums/AlertTypeEnum'
import { collect }            from '@/lib/utils/array'
import TriggerInputResource   from '@/api/resources/alerts/TriggerInputResource'
import i18n                   from '@/lang/lang'
import { toDecimalMarkRange } from '@/lib/utils/helper'

export default class AlertInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.accountId = payload.accountId ?? ''
    this.name = payload.name ?? ''
    this.ignoreFiring = payload.ignoreFiring ?? false
    this.priority = payload.priority ?? AlertPriorityEnum.Low
    this.baseCurrencyCode = payload.baseCurrencyCode ?? ''
    this.description = payload.description ?? ''
    this.enableEmailNotifications = payload.enableEmailNotifications ?? false
    this.expirationDate = payload.expirationDate || null
    this.type = payload.type ?? AlertTypeEnum.AssetPrice
    this.triggers = collect(payload.triggers ?? [], TriggerInputResource)
  }

  /* PROPERTIES */
  get triggersList () {
    const retVal = []

    this.triggers.forEach(trigger => {
      const asset = trigger?.selectedItem?.pair?.base?.displayName || trigger?.selectedItem?.title || ''
      const triggerLevel = toDecimalMarkRange(parseFloat(trigger?.threshold || '0'), 0, 5) || '0'
      const items = [
        {
          Title: (this.type === AlertTypeEnum.PortfolioMTM) ? i18n.t('Alerts.AddTrigger.Form.Fields.Portfolio.Label') : i18n.t('Alerts.AddTrigger.Form.Fields.Asset.Label'),
          Value: asset,
          Tags : null
        },
        {
          Title: i18n.t('Alerts.AddTrigger.Form.Fields.Threshold.Label'),
          Value: triggerLevel,
          Tags : null
        }
      ]

      if (this.type === AlertTypeEnum.PortfolioMTM) {
        items.splice(1, 0,
          {
            Title: i18n.t('Alerts.AddTrigger.Form.Fields.Assets.Label'),
            Value: trigger?.selectedItem?.assets || '',
            Tags : trigger?.selectedItem?.tags || null
          }
        )
      }

      retVal.push({
        Trigger: trigger,
        Items  : items
      })
    })

    return retVal
  }

  /* METHODS */
}
