import BaseValidator      from '@/lib/data/validator/BaseValidator'
import InstrumentTypeEnum from '@/api/enums/InstrumentTypeEnum'

export default class TradeInputValidator extends BaseValidator {
  validatorRules = {
    assetCode: {
      required: true,
      type    : BaseValidator.Type.String
    },
    'forward.rate': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.FORWARD],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'option.strike': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.OPTION],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'option.type': {
      requiredIf: ['instrument', InstrumentTypeEnum.OPTION],
      type      : BaseValidator.Type.String
    },
    'collar.lowStrike': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.COLLAR],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'collar.highStrike': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.COLLAR],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'crossCurrencySwap.ccsStrike': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.CROSS_CURRENCY_SWAP],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'crossCurrencySwap.assetFixedRate': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.CROSS_CURRENCY_SWAP],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'crossCurrencySwap.counterAssetFixedRate': {
      requiredIf                      : ['instrument', InstrumentTypeEnum.CROSS_CURRENCY_SWAP],
      max                             : Math.pow(10, 6),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    'crossCurrencySwap.ccsFrequency': {
      requiredIf: ['instrument', InstrumentTypeEnum.CROSS_CURRENCY_SWAP],
      type      : BaseValidator.Type.String
    },
    direction: {
      required: true,
      type    : BaseValidator.Type.String
    },
    instrument: {
      required: true,
      type    : BaseValidator.Type.String
    },
    tradeDate: {
      required: true,
      type    : BaseValidator.Type.String
    },
    maturityDate: {
      required: true,
      type    : BaseValidator.Type.String
    },
    notional: {
      required                        : true,
      max                             : Math.pow(10, 16),
      NotEmptyNegativeOrPositiveNumber: (v) => parseFloat(v) !== 0 && v !== ''
    },
    label: {
      required: false,
      type    : BaseValidator.Type.String,
      minLen  : 3
    }
  }

  validatorMessages = {
    el: {
      NotEmptyNegativeOrPositiveNumber: 'Enter at positive or a negative value'
    },
    en: {
      NotEmptyNegativeOrPositiveNumber: 'Enter at positive or a negative value'
    }
  }

  constructor (data = {}) {
    super(data)
  }
}
