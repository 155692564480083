import { themeUtils } from '@/lib/utils'
import { i18n }       from '@/lang/lang'

const useRouteAuth = true

// eslint-disable-next-line no-unused-vars
// const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')
const Login = () => themeUtils.importThemeComponent('views/login/Login')
const ForgotPassword = () => themeUtils.importThemeComponent('views/login/ForgotPassword')
const ResetPassword = () => themeUtils.importThemeComponent('views/login/ResetPassword')
const Dashboard = () => themeUtils.importThemeComponent('views/dashboard/Dashboard')
const Alerts = () => themeUtils.importThemeComponent('views/alerts/Alerts')
const AlertNew = () => themeUtils.importThemeComponent('views/alerts/AlertNew')
const AlertEdit = () => themeUtils.importThemeComponent('views/alerts/AlertEdit')
const AlertShow = () => themeUtils.importThemeComponent('views/alerts/AlertShow')
const Risks = () => themeUtils.importThemeComponent('views/risks/Risks')
const RisksAnalysisShow = () => themeUtils.importThemeComponent('views/risks/RisksAnalysisShow')
const RisksAnalysisNew = () => themeUtils.importThemeComponent('views/risks/RisksAnalysisNew')
const Hedging = () => themeUtils.importThemeComponent('views/hedging/Hedging')
const HedgingStrategyShow = () => themeUtils.importThemeComponent('views/hedging/HedgingStrategyShow')
const HedgingAnalysisNew = () => themeUtils.importThemeComponent('views/hedging/HedgingAnalysisNew')
const Industry = () => themeUtils.importThemeComponent('views/industry/Industry')
const IndustryComparisonShow = () => themeUtils.importThemeComponent('views/industry/IndustryComparisonShow')
const IndustryComparisonNew = () => themeUtils.importThemeComponent('views/industry/IndustryComparisonNew')
const Positions = () => themeUtils.importThemeComponent('views/positions/Positions')
const PositionsNew = () => themeUtils.importThemeComponent('views/positions/PositionsNew')
const PositionsShow = () => themeUtils.importThemeComponent('views/positions/PositionsShow')
const HelpMavenAdvisory = () => themeUtils.importThemeComponent('views/help/HelpMavenAdvisory')
const HelpSynthesisSupport = () => themeUtils.importThemeComponent('views/help/HelpSynthesisSupport')
const AccountSettings = () => themeUtils.importThemeComponent('views/account/AccountSettings')
const KnowledgeCenter = () => themeUtils.importThemeComponent('views/knowledgeCenter/KnowledgeCenter')
const KnowledgeCenterArticleShow = () => themeUtils.importThemeComponent('views/knowledgeCenter/KnowledgeCenterArticleShow')
const TermsAndPrivacy = () => themeUtils.importThemeComponent('views/terms/TermsAndPrivacy')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: '/' + i18n.locale
    }
  ],

  LangRoutes: [
    {
      name     : 'Login',
      path     : '',
      component: Login,
      meta     : {
        layout: 'login'
      }
    },
    {
      name     : 'ForgotPassword',
      path     : 'forgot-password',
      component: ForgotPassword,
      meta     : {
        layout: 'login'
      }
    },
    {
      name     : 'ResetPassword',
      path     : 'reset-password/:token?',
      component: ResetPassword,
      props    : true,
      meta     : {
        layout: 'login'
      }
    },

    {
      name     : 'Dashboard',
      path     : 'dashboard',
      component: Dashboard,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'Alerts',
      path     : 'alerts/:tab?',
      component: Alerts,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'AlertNew',
      path     : 'alerts/new',
      component: AlertNew,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'AlertEdit',
      path     : 'alerts/edit/:id',
      component: AlertEdit,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'AlertShow',
      path     : 'alerts/show/:id',
      component: AlertShow,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'Risks',
      path     : 'risks/:tab?',
      component: Risks,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'RisksAnalysisShow',
      path     : 'risks/analysis/show/:id/:tab?/:subTab?',
      component: RisksAnalysisShow,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'RisksAnalysisNew',
      path     : 'risks/analysis/new',
      component: RisksAnalysisNew,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'Hedging',
      path     : 'hedging',
      component: Hedging,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'HedgingStrategyShow',
      path     : 'hedging/strategy/show/:id/:tab?',
      component: HedgingStrategyShow,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'HedgingStrategyNew',
      path     : 'hedging/strategy/new',
      component: HedgingAnalysisNew,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'Industry',
      path     : 'industry',
      component: Industry,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'IndustryComparisonNew',
      path     : 'industry/comparison/new',
      component: IndustryComparisonNew,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'IndustryComparisonShow',
      path     : 'industry/comparison/show/:id',
      component: IndustryComparisonShow,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'Positions',
      path     : 'positions',
      component: Positions,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'PositionsShow',
      path     : 'positions/show/:id',
      component: PositionsShow,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'PositionsNew',
      path     : 'positions/new',
      component: PositionsNew,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'PositionsEdit',
      path     : 'positions/edit/:id',
      component: PositionsNew,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'KnowledgeCenter',
      path     : 'knowledge-center',
      component: KnowledgeCenter,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },
    {
      name     : 'KnowledgeCenterArticleShow',
      path     : 'knowledge-center/article/:id',
      component: KnowledgeCenterArticleShow,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'MavenAdvisory',
      path     : 'help/maven-advisory',
      component: HelpMavenAdvisory,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'SynthesisSupport',
      path     : 'help/synthesis-support',
      component: HelpSynthesisSupport,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'MyDetails',
      path     : 'my-details',
      component: AccountSettings,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    },

    {
      name     : 'TermsAndPrivacy',
      path     : 'terms-and-privacy',
      component: TermsAndPrivacy,
      props    : true,
      meta     : {
        requiresAuth: useRouteAuth,
        layout      : 'default'
      }
    }
  ]
}

export default AppRoutes
