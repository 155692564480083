import { first }       from '@/lib/utils/array'
import { toTitleCase } from '@/lib/utils/string'

export default {
  name: 'GlobalErrorHandler',

  mounted () {
    this.$root.$errorDialog = this.$refs?.errorDialog?.open ?? null
    this.$bus.$on('app:error', this.handleAppError)
  },

  beforeDestroy () {
    this.$bus.$off('app:error', this.handleAppError)
  },

  methods: {
    handleAppError (errors = [], showDialog = true) {
      if (!Array.isArray(errors) || errors.length <= 0) return

      const error = first(errors)
      let errorTitle = this.$t('Common.Error.Generic.Title')
      let errorMessage = this.$t('Common.Error.Generic.Message')

      // Validation Error
      if (error?.extensions?.classification === 'ValidationError') {
        errorTitle = error?.extensions?.classification ? toTitleCase(error?.extensions?.classification) : this.$t('Common.Error.Generic.Title')

        // Validation Error Multi
        if (error?.extensions['violation.message']) {
          errorMessage = error?.extensions['violation.message']

          // Validation Error Wrong Type
        } else {
          errorMessage = this.$t('Common.Error.Generic.Message')
        }
      } else if (error?.extensions?.classification === 'DataFetchingException') {
        // Authorization / Authentication Error
        if (error?.extensions?.code === 'unauthorized' || error?.extensions?.code === 'authentication-failed') {
          showDialog = false
          window.Vue.user = null
          if (window.Vue.$router.currentRoute.name !== 'Login') {
            window.Vue.$router.push({
              name : 'Login',
              query: {}
            })
          }
          // Business Error
        } else if (error?.message && error?.message !== 'System error') {
          errorMessage = error.message
        } else {
          showDialog = false
        }
      } else if (error?.custom && error?.custom?.message) {
        if (error?.custom?.title) errorTitle = error.custom.title
        errorMessage = error.custom.message
      } else {
        // Unhandled Error
        errorMessage = this.$t('Common.Error.Generic.Message')
      }

      showDialog && this.$refs.errorDialog && this.$refs.errorDialog.open(errorTitle, errorMessage)

      return {
        title  : errorTitle,
        message: errorMessage
      }
    }
  }
}
