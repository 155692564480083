<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartBarProps from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData  from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'
import baseEChartColors   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

import { first } from '@/lib/utils/array'

import { use }                 from 'echarts/core'
import { BarChart }            from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer }      from 'echarts/renderers'

import { GridComponent, LegendComponent, TitleComponent, TooltipComponent, TransformComponent } from 'echarts/components'
import { toDecimalSignificant, toNumberFormatNotationShort }                                    from '@/lib/utils/helper'
import dayjs                                                                                    from 'dayjs'
import { isEmpty }                                                                              from '@/lib/utils/type'

use([
  BarChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  CanvasRenderer,
  UniversalTransition
])

export default {
  name    : 'BaseEChartBar',
  mixins  : [baseEChartColors, baseEChartBarProps, baseEChartBarData],
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 10,
        top         : 15,
        right       : 10,
        bottom      : 20,
        containLabel: false
      }
    },

    getChartDataOptions () {
      const o = this.series

      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          show           : this.tooltipShow,
          trigger        : 'item',
          backgroundColor: this.themeColorRGBA(this.tooltipBackgroundColor, 0.8),
          borderColor    : this.tooltipBorderColor,
          borderWidth    : this.tooltipBorderWidth,
          padding        : this.tooltipPadding,
          showContent    : this.tooltipShowContent,
          textStyle      : {
            color: this.tooltipLabelColor
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        legend   : { show: this.legendShow },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          type     : 'category',
          axisLabel: {
            show      : this.axisXLabelShow,
            inside    : this.axisXLabelInside,
            margin    : this.axisXLabelMargin,
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            fontWeight: this.axisLabelFontWeight,
            color     : this.axisLabelFontColor
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitArea: {
            areaStyle: {
              color     : ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
              shadowBlur: 0.5
            }
          },
          data: o.xAxis
        },
        yAxis: [{
          min      : this.axisYMinValue,
          max      : this.axisYMaxValue,
          type     : 'value',
          position : 'left',
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }
              if (this.axisYLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format('MMM YY')
              }

              if (isEmpty(value)) {
                value = 0
              }

              const prefix = (this.axisYTickPrefix + ' ' || '')
              const suffix = (this.axisYTickSuffix || '')

              return prefix + value + suffix
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : {
            show: false
          },
          axisTick: {
            show: false
          },
          silent: this.silent
        }],
        color : this.colors,
        series: o.series
      }
    },

    series () {
      let o = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      const barMaxWidth = (o.x.length < 4 ? (this.$vuetify.breakpoint.xs ? '100%' : '25%') : '100%')

      const series = o.x.map((name, index) => {
        const values = new Array(o.y.length).fill('')
        values[index] = parseFloat(o.y[index]) || 0

        return {
          name        : name,
          type        : 'bar',
          stack       : 'chart',
          barMinHeight: 1,
          // barCategoryGap: '20%',
          barMaxWidth : barMaxWidth,
          label       : {
            show          : true,
            position      : 'top',
            color         : this.axisLabelFontColor,
            fontFamily    : 'Aeonik, Roboto, sans-serif',
            fontSize      : 12,
            fontWeight    : 500,
            valueAnimation: true,
            formatter     : params => {
              let value = params.value

              if (this.axisXLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(params.value))
              }
              if (this.axisXLabelValueFormat === 'percent') {
                value = (params.value === 0 ? params.value : toDecimalSignificant(parseFloat(params.value)))
              }
              if (this.axisXLabelValueFormat === 'date') {
                value = dayjs(params.value).format('MMM YY')
              }

              if (isEmpty(value)) {
                value = 0
              }

              const prefix = (this.axisXTickPrefix + ' ' || '')
              const suffix = (this.axisXTickSuffix || '')

              return prefix + value + suffix
            }
          },
          showBackground : true,
          backgroundStyle: {
            color: this.barBackgroundColor
          },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius],
            color       : this.barColor
          },
          cursor: this.cursor,
          silent: this.silent,
          data  : values
        }
      })

      return {
        series: series,
        xAxis : o.x
      }
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
