export default {
  AddExposure: {
    Title   : '@:Common.Button.Add Exposure',
    Subtitle: 'Select currency, commodity or interest rate',
    Form    : {
      Fields: {
        Label: {
          Label      : 'Label',
          Placeholder: 'Enter exposure label'
        },

        Notional: {
          Label      : 'Annual exposure',
          Placeholder: '0'
        }
      },
      Confirm: {
        Delete: {
          Title: 'Delete Exposure?',
          Body : 'Are you sure you want to delete this exposure?'
        }
      },
      Button: {
        Save: {
          Title: '@:Common.Button.Save Exposure'
        }
      }
    }
  }
}
