<template>
  <v-item-group
    v-model="model"
    :disabled="disabled"
    :mandatory="mandatory"
  >
    <v-container
      fluid
      pa-0
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-item
            v-slot="{active, toggle}"
            :disabled="disabled"
            :value="CurrentMarketRates.Value"
          >
            <v-card
              :class="[{'item-group-card-active': active}]"
              :disabled="disabled"
              class="item-group-card d-flex align-center"
              flat
              height="56"
              @click="toggle"
            >
              <v-icon
                :color="active ? 'synthesis-ui-blue-04' : 'synthesis-ui-grey-02'"
                class="pa-3"
              >
                {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
              </v-icon>

              <v-card-title class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0 text-no-wrap">
                {{ CurrentMarketRates.Label }}
                <infotip
                  v-if="CurrentMarketRates.InfoTip"
                  icon-class="ml-1"
                  icon-style="margin-top: -12px"
                >
                  {{ CurrentMarketRates.InfoTip }}
                </infotip>
              </v-card-title>
            </v-card>
          </v-item>

          <v-btn
            :disabled="model !== CurrentMarketRates.Value"
            class="px-1 mt-1 button-text-underline synthesis-text text-size-14 font-weight-regular"
            color="synthesis-ui-green-02"
            small
            text
            @click="$emit('click:show-rates')"
          >
            {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.Button.ShowRates') }}
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-item
            v-slot="{active, toggle}"
            :disabled="disabled"
            :value="InputValues.Value"
          >
            <v-card
              :class="[{'item-group-card-active': active}]"
              :disabled="disabled"
              class="item-group-card d-flex align-center"
              flat
              height="56"
              @click="toggle"
            >
              <v-icon
                :color="active ? 'synthesis-ui-blue-04' : 'synthesis-ui-grey-02'"
                class="pa-3"
              >
                {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
              </v-icon>

              <v-card-title class="synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0 text-no-wrap">
                {{ InputValues.Label }}
              </v-card-title>
            </v-card>
          </v-item>

          <v-btn
            v-if="assetParamsButton === 'Add'"
            :disabled="model !== InputValues.Value"
            class="px-1 mt-1 button-text-underline synthesis-text text-size-14 font-weight-regular"
            color="synthesis-ui-green-02"
            small
            text
            @click="$emit('click:add-asset-params')"
          >
            {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.Button.AddAsset') }}
          </v-btn>

          <v-btn
            v-else
            :disabled="model !== InputValues.Value"
            class="px-1 mt-1 button-text-underline synthesis-text text-size-14 font-weight-regular"
            color="synthesis-ui-green-02"
            small
            text
            @click="$emit('click:edit-asset-params')"
          >
            {{ $t('Hedging.Edit.Form.Fields.MarketRatesUsed.Button.EditAsset') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import GroupItemRadio from '@/theme/default/components/form/GroupItemRadio'
import Infotip        from '@/theme/default/components/common/Infotip'

export default {
  name      : 'SelectMarketParameters',
  components: { Infotip },
  directives: {},
  extends   : GroupItemRadio,
  mixins    : [],
  props     : {
    assetParamsButton: {
      type   : String,
      default: 'Add'
    }
  },
  data () {
    return {
      ...this.$t('Hedging.Edit.Form.Fields.MarketRatesUsed.Items')
    }
  },
  computed: {}
}
</script>

<style scoped>
.item-group-card {
  border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  background-color: #FFFFFF;
}

.item-group-card-active {
  border: 1px solid var(--v-synthesis-ui-blue-04-base) !important;
  background-color: var(--v-synthesis-brand-lightest-blue-base);
}
</style>
