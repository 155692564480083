import BaseResource from '@/lib/data/resource/BaseResource'

export default class PositionMtmTriggerInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.tradeId = payload.tradeId ?? ''
    this.threshold = payload.threshold ?? ''
    this.snapshotValue = payload.snapshotValue ?? payload.threshold
  }

  /* PROPERTIES */

  /* METHODS */
}
