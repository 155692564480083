import BaseResource                        from '@/lib/data/resource/BaseResource'
import { collect }                         from '@/lib/utils/array'
import AssetNameResource                   from '@/api/resources/asset/AssetNameResource'
import HedgingCompareAssetStrategyResource from '@/api/resources/hedging/HedgingCompareAssetStrategyResource'

export default class HedgingCompareResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.strategyComparison || null

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Assets = collect(o?.assets || [], AssetNameResource)
    this.Data = collect(o?.assetStrategy || [], HedgingCompareAssetStrategyResource)
    this.CurrentStrategyProvided = o?.currentStrategyProvided ?? false
  }

  /* PROPERTIES */

  /* METHODS */
}
