<template>
  <v-col cols="12">
    <v-row class="mb-5">
      <v-col
        cols="12"
        lg="12"
        md="12"
      >
        <block-item-card
          v-if="exposures && exposures.FinancialExposure"
          ref="financialExposureCard"
          :base-currency-symbol="riskBaseCurrencyDisplayName"
          :export-element="elementFinancialExposure"
          :title="$t('Risks.Section.ValueAtRisk.Block.FinancialExposure.Title')"
          :title-large="false"
          :title-value="exposures.FinancialExposure.Value"
          :tooltip="$t('Risks.Section.ValueAtRisk.Block.FinancialExposure.InfoTip')"
        >
          <v-lazy
            :options="{threshold: .5}"
            transition="fade-transition"
          >
            <base-e-chart-bar
              :axis-label-font-color="themeColorRGBA('synthesis-ui-grey-01')"
              :axis-x-label-show="false"
              :axis-y-label-inside="false"
              :axis-y-label-margin="0"
              :axis-y-label-show="true"
              :axis-y-split-line-dash-offset="4"
              :axis-y-split-line-opacity="1"
              :axis-y-split-line-type="[8, 8]"
              :axis-y-split-number="2"
              :bar-background-color="themeColorRGBA('synthesis-ui-grey-05', 0.1)"
              :chart-data-options="exposures.FinancialExposure.Bar"
              :chart-grid-margin="{left: 10,top: 20,right: 10,bottom: 10,containLabel: true}"
              :tooltip-show="false"
              :tooltip-show-content="false"
              axis-x-label-value-format="percent"
              axis-x-tick-suffix="%"
              axis-y-label-value-format="percent"
              axis-y-tick-suffix="%"
              chart-animation-easing="quarticOut"
            />
          </v-lazy>
          <v-card-text
            v-if="exposures.FinancialExposure.PerAsset"
            class="overflow-y-auto pb-2"
          >
            <v-container
              fluid
              pa-0
              pb-2
            >
              <v-row dense>
                <v-col
                  :class="[{'pr-3': $vuetify.breakpoint.mdAndUp}]"
                  class="py-0"
                  cols="12"
                  :md="$vuetify.breakpoint.mdAndUp && exposuresSingle ? '12' : '6'"
                >
                  <item-chart-legend
                    :key="`financial-exposure-legend-header-tl`"
                    :background-color="getColorBg(12, .1)"
                    title="Exposure"
                    title-value="Base"
                    middle-value="Local"
                    title-class="font-weight-medium"
                    title-value-class="font-weight-medium"
                    title-middle-value-class="font-weight-medium"
                    cols
                  />
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp && !exposuresSingle"
                  class="py-0 pl-3"
                  cols="12"
                  md="6"
                >
                  <item-chart-legend
                    :key="`financial-exposure-legend-header-tr`"
                    :background-color="getColorBg(12, .1)"
                    title="Exposure"
                    title-value="Base"
                    middle-value="Local"
                    title-class="font-weight-medium"
                    title-value-class="font-weight-medium"
                    title-middle-value-class="font-weight-medium"
                    cols
                  />
                </v-col>
                <template
                  v-for="(item, i) in exposures.FinancialExposure.PerAsset"
                >
                  <v-col
                    :key="`item-financial-exposure-legend-${i}`"
                    :class="[{'pr-3': (i % 2 === 0) && $vuetify.breakpoint.mdAndUp},{'pl-3': (i % 2 === 1) && $vuetify.breakpoint.mdAndUp}]"
                    class="py-0"
                    cols="12"
                    :md="$vuetify.breakpoint.mdAndUp && exposuresSingle ? '12' : '6'"
                  >
                    <item-chart-legend
                      :key="`financial-exposure-legend-${i}`"
                      :background-color="getColorBg(i % colors.length, .1)"
                      :base-currency-symbol="riskBaseCurrencyDisplayName"
                      :icon-color="getColor(i % colors.length)"
                      :title="item.name"
                      :title-value="item.value"
                      :expanded="item.aggregate"
                      :icon="expandedFinancialExposure[item.name] ? item.iconExpanded : item.icon"
                      :middle-value="getMiddleValue(item)"
                      cols
                      @item-chart-legend-click="onFinancialExposureClick(item)"
                    />
                    <div
                      v-if="item.aggregate && expandedFinancialExposure[item.name]"
                      :key="`financial-exposure-legend-expanded-${i}`"
                      class="pl-4"
                    >
                      <template
                        v-for="(aggregateItem, i2) in item.aggregateItems"
                      >
                        <item-chart-legend
                          :key="`financial-exposure-legend-expanded-item-${i2}`"
                          :background-color="getColorBg(i % colors.length, .1)"
                          :base-currency-symbol="riskBaseCurrencyDisplayName"
                          :icon-color="getColor(i % colors.length)"
                          :title="aggregateItem.name"
                          :title-value="aggregateItem.value"
                          icon="mdi-circle-outline"
                          :middle-value="getMiddleValue(aggregateItem)"
                          cols
                        />
                      </template>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-text>
        </block-item-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

import BaseEChartBar         from '@/theme/default/components/charts/echarts/BaseEChartBar'
import BlockItemCard         from '@/theme/default/components/common/BlockItemCard'
import chartsColorMixin      from '@/theme/default/components/charts/mixins/chartsColorMixin'
import ItemChartLegend       from '@/theme/default/components/common/ItemChartLegend'
import baseEChartColors      from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import riskAnalysisId        from '@/theme/default/views/risks/mixins/riskAnalysisId'
import API                   from '@/api/Api'
import { clone }             from '@/lib/utils/helper'
import riskBaseCurrency      from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import RiskExposuresResource from '@/api/resources/risks/RiskExposuresResource'

export default {
  name      : 'ExposuresBlock',
  components: {
    ItemChartLegend,
    BlockItemCard,
    BaseEChartBar
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, baseEChartColors, riskAnalysisId],
  props     : {},
  dataStore : {
    riskExposuresData   : 'Risks.Exposures.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      elementFinancialExposure : null,
      expandedFinancialExposure: []
    }
  },
  computed: {
    exposures () {
      return this.riskExposuresData[this.id] || null
    },
    exposuresSingle () {
      return this.exposures.FinancialExposure.PerAsset.length === 1
    }
  },
  watch: {
    exposures () {
      this.setExportElements()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.Exposures(this.id).then(response => {
        this.handleResponse(response)
      }).catch(e => {
        this.handleResponse(e.response)
      })
    },
    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)

        if (o) {
          const v = new RiskExposuresResource(o)

          this.$set(this.riskExposuresData, this.id, v)
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onFinancialExposureClick (item) {
      for (const key in this.expandedFinancialExposure) {
        if (key !== item.name) {
          this.expandedFinancialExposure[key] = false
        }
      }

      this.$set(this.expandedFinancialExposure, item.name, !this.expandedFinancialExposure[item.name])
    },

    getCardTextHeight () {
      const len = this.exposures.FinancialExposure.PerAsset.length

      const value = (52 * len) + 150
      return this.$vuetify.breakpoint.mdAndUp ? value / 2 : value
    },

    setExportElements () {
      this.$nextTick(() => {
        this.elementFinancialExposure = this.$refs?.financialExposureCard?.$el
      })
    },

    getMiddleValue (item) {
      const prefix = item.displayUnitPosition === 'Before' ? item.displayUnit : ''
      const suffix = item.displayUnitPosition === 'After' ? item.displayUnit : ''

      return (prefix + ' ' + item.valueLocal + ' ' + suffix).trim()
    }
  }
}
</script>

<style scoped>
</style>
