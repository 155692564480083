import BaseResource      from '@/lib/data/resource/BaseResource'
import { toDecimalMark } from '@/lib/utils/helper'

export default class HedgingCostsOptionsInterbankRatesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Maturity = payload.maturity ?? ''
    this.Premium = payload.premium ?? ''
    this.PremiumFmt = payload.premium ? `${ toDecimalMark(payload.premium) }%` : '-'
    this.AnnualisedCost = payload.annualisedCost ?? ''
    this.AnnualisedCostFmt = payload.annualisedCost ? `${ toDecimalMark(payload.annualisedCost) }%` : '-'
    this.AnnualisedCostColor = (this.AnnualisedCost > 0 || this.AnnualisedCost === '' ? 'synthesis-ui-green-07' : 'synthesis-ui-red-07')
    this.AnnualisedCostTextColor = (this.AnnualisedCost > 0 || this.AnnualisedCost === '' ? 'synthesis-ui-green-00' : 'synthesis-ui-red-01')
  }

  /* PROPERTIES */

  /* METHODS */
}
