export const Positions = {
  Archive: {
    Data: {}
  },

  Summary: {
    Data: {}
  },

  SummaryMaturedTrades: {
    Data: {}
  },

  CrossCurrencySwapSupportedFrequencies: []
}
