import BaseResource                    from '@/lib/data/resource/BaseResource'
import dayjs                           from 'dayjs'
import { first, take }                 from '@/lib/utils/array'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import AssetModel                      from '@/api/models/asset/AssetModel'

export default class RiskArchiveAnalysisResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.summary?.items || null

    this.Id = payload.id ?? ''
    this.Name = payload.name ?? ''
    this.Active = payload.active ?? false
    this.CreatedAt = payload.createdAt ?? ''
    this.CreatedAtFmt = this.getDateFmt(payload.createdAt ?? '')
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Portfolio = this.getPortfolio(o)
    this.Items = this.getItems(o)
    this.Tags = this.getTags()
  }

  /* PROPERTIES */
  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get TotalRisk () {
    const num = this.Portfolio.annualRisk ?? ''

    return toNumberFormatNotationShort(num)
  }

  get PieValues () {
    if (this.Items) {
      return this.Items.map(item => {
        return {
          name : item.itemName,
          value: item.annualRisk,
          type : 'number'
        }
      })
    }

    return []
  }

  get TagsTrimmed () {
    const showTotal = 4
    const addMore = this.Tags.length > showTotal
    const showTags = take(this.Tags, showTotal)
    addMore && showTags.push('...')

    return showTags
  }

  /* METHODS */
  getPortfolio (o) {
    if (o) {
      return first(o.filter(item => item.itemName === 'portfolio'))
    }

    return []
  }

  getItems (o) {
    if (o) {
      return o.filter(item => item.itemName !== 'portfolio')
    }

    return []
  }

  getTags () {
    if (this.Items) {
      return this.Items.map(item => item.itemName)
    }

    return []
  }

  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
