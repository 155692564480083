import BaseModel         from '@/lib/data/model/BaseModel'
import HedgingCurrentStrategyValidator from '@/api/validations/hedging/HedgingCurrentStrategyValidator'
import HedgingCurrentStrategyResource  from '@/api/resources/hedging/HedgingCurrentStrategyResource'

export default class HedgingCurrentStrategyModel extends BaseModel {
  constructor (payload = {}, resource = HedgingCurrentStrategyResource, validator = HedgingCurrentStrategyValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
