import BaseResource from '@/lib/data/resource/BaseResource'
import AssetModel   from '@/api/models/asset/AssetModel'

export default class AssetPairResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Base = new AssetModel(payload.base ?? null)
    this.Quote = new AssetModel(payload.quote ?? null)
    this.Symbol = payload.symbol ?? ''
    this.BaseAsset = this.Base?.displayName || ''
    this.QuoteAsset = this.Quote?.displayName || ''
    this.QuoteSymbol = this.Quote?.displaySymbol || ''
    this.LatestQuote = payload.latestQuote ?? null
  }

  /* PROPERTIES */
  get LatestQuotePrice () {
    return this.LatestQuote?.price ?? ''
  }

  get LatestQuoteDisplayPrice () {
    return this.LatestQuote?.displayPrice ?? ''
  }

  get QuotationDescription () {
    return this.LatestQuote?.quotation?.description ?? ''
  }

  get Pair () {
    return `${ this.QuoteAsset }_${ this.BaseAsset }`
  }

  get Asset () {
    return `${ this.QuoteAsset }-${ this.BaseAsset }`
  }

  /* METHODS */
}
