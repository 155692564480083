import Vue     from 'vue'
import VueGtag from 'vue-gtag'

export const setupGoogleAnalytics = (GAConfig, Router) => {
  const config = GAConfig?.key && GAConfig?.enabled ? {
    id    : GAConfig?.key ?? '',
    params: { send_page_view: true }
  } : null

  const environments = GAConfig?.environments || ['production']

  if (config && environments.includes(process.env.VUE_APP_ENV)) {
    Vue.use(VueGtag, {
      config  : config,
      includes: [],
      enabled : !!config
    }, Router)
  }
}
