import BaseResource from '@/lib/data/resource/BaseResource'

export default class IndustryListResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload?.name || ''
    this.Value = payload?.id || ''
  }

  /* PROPERTIES */

  /* METHODS */
}
