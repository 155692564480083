import BaseResource                     from '@/lib/data/resource/BaseResource'
import i18n                             from '@/lang/lang'
import { collect }                      from '@/lib/utils/array'
import RiskSeasonalityAssetItemResource from '@/api/resources/risks/RiskSeasonalityAssetItemResource'
import { toNumberFormatNotationShort }  from '@/lib/utils/helper'
import Common                           from '@/lang/locale/en/Common'
import AssetNameResource                from '@/api/resources/asset/AssetNameResource'
import AssetCodeNameResource            from '@/api/resources/asset/AssetCodeNameResource'

export default class RiskSeasonalityAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const assetValue = payload?.itemCode || payload?.asset || ''

    this.Asset = payload.asset ? (payload.asset === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.asset) : ''
    this.AssetValue = assetValue.toLocaleLowerCase(i18n.code)
    this.CashFlowProfile = payload.cashFlowProfile ?? []
    this.ImpactPercent = payload.impactPercent ?? ''
    this.ImpactLabel = payload.impactLabel ?? ''
    this.DisplayUnit = payload.quotation?.displayUnit || ''

    this.Values = [{
      x: ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10, 11, 12],
      y: this.CashFlowProfile
    }]

    this.PerSeasonality = collect(payload.perSeasonality ?? [], RiskSeasonalityAssetItemResource)
    this.Seasonality = {
      no  : [],
      max : [],
      user: []
    }
    this.TableValues = []

    if (this.PerSeasonality) {
      if (this.PerSeasonality.length) {
        this.PerSeasonality.forEach(item => {
          const seasonalityName = Common.Seasonality.Map[item.Name]
          const values = {
            Priority       : Common.Seasonality.Items.find(item => seasonalityName === item.Value).PriorityTable,
            Method         : i18n.t('Risks.Table.Seasonality.Data.' + item.Name),
            VaR_Pct_1      : item.OnePercentRisk,
            Impact         : this.getImpact(item.Impact),
            ImpactColor    : this.getColor(item.ImpactRaw),
            ImpactTextColor: this.getTextColor(item.ImpactRaw)
          }
          this.Seasonality[seasonalityName] = values
          this.TableValues.push(values)
        })

        this.TableValues.sort((a, b) => (a.Priority > b.Priority) ? 1 : -1)
      }
    }
    this.AggregateItem = payload.aggregateItem || false
    this.PerCashflowCodes = collect(payload?.perCashflowCodes || [], AssetNameResource)
    this.PerCashflowItems = collect(payload?.perCashflowItems || [], RiskSeasonalityAssetResource)

    this.PerCashflowNames = payload.perCashflowNames ?? []
    this.PerCashflowCodeNames = collect(payload?.perCashflowCodeNames || [], AssetCodeNameResource)
  }

  /* PROPERTIES */

  /* METHODS */
  getImpact (num) {
    if (num) {
      return num === 0 ? '-' : toNumberFormatNotationShort(num)
    }

    return '-'
  }

  getColor (num) {
    if (num < 0) {
      return window.Vue.$vuetify.theme.themes.light['synthesis-ui-red-07']
    }

    return window.Vue.$vuetify.theme.themes.light['synthesis-ui-green-07']
  }

  getTextColor (num) {
    if (num < 0) {
      return window.Vue.$vuetify.theme.themes.light['synthesis-ui-red-01']
    }

    return window.Vue.$vuetify.theme.themes.light['synthesis-ui-green-00']
  }
}
