<template>
  <v-container fluid>
    <v-row>
      <section-header :breadcrumb-items="breadcrumb" />
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="activeTab"
          active-class="active-tab"
          background-color="transparent"
          height="30"
          hide-slider
          show-arrows
        >
          <v-tab :href="`#${$t('Positions.Tab.OpenTrades.Slug')}`">
            {{ $t('Positions.Tab.OpenTrades.Title') }}
          </v-tab>

          <v-tab :href="`#${$t('Positions.Tab.MaturedTrades.Slug')}`">
            {{ $t('Positions.Tab.MaturedTrades.Title') }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pt-0"
        cols="12"
      >
        <v-tabs-items v-model="activeTab">
          <v-tab-item :value="$t('Positions.Tab.OpenTrades.Slug')">
            <positions-show-open-trades v-if="activeTab === $t('Positions.Tab.OpenTrades.Slug')" />
          </v-tab-item>

          <v-tab-item :value="$t('Positions.Tab.MaturedTrades.Slug')">
            <positions-show-matured-trades v-if="activeTab === $t('Positions.Tab.MaturedTrades.Slug')" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta              from '@/mixins/i18n/i18nRouteMeta'
import SectionHeader              from '@/theme/default/components/common/SectionHeader'
import positionId                 from '@/theme/default/views/positions/mixins/positionId'
import routerTabState             from '@/mixins/routerTabState'
import PositionsShowOpenTrades    from '@/theme/default/views/positions/PositionsShowOpenTrades'
import PositionsShowMaturedTrades from '@/theme/default/views/positions/PositionsShowMaturedTrades'

export default {
  name      : 'PositionsShow',
  components: {
    PositionsShowMaturedTrades,
    PositionsShowOpenTrades,
    SectionHeader
  },
  directives: {},
  mixins    : [i18nRouteMeta, positionId, routerTabState],
  props     : {},
  dataStore : {
    positionsSummaryData: 'Positions.Summary.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      showPriceAnimation                    : true,
      historicalMTMValueDialogData          : null,
      historicalMTMValueDialogVisible       : false,
      historicalMTMValueDialogSelectedPeriod: this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      table: {
        headers: [
          {
            text     : this.$t('Positions.Table.Headers.Asset'),
            align    : 'left',
            value    : 'Asset',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Instrument'),
            align    : 'left',
            value    : 'Instrument',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.TradeDate'),
            align    : 'left',
            value    : 'TradeDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.MaturityDate'),
            align    : 'left',
            value    : 'MaturityDate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Rate'),
            align    : 'left',
            value    : 'DisplayRate',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.Notional'),
            align    : 'left',
            value    : 'DisplayNotional',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          },
          {
            text     : this.$t('Positions.Table.Headers.DailyChange'),
            align    : 'left',
            value    : 'DailyChange',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text', 'font-weight-medium']
          },
          {
            text     : this.$t('Positions.Table.Headers.Direction'),
            align    : 'left',
            value    : 'Direction',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text', 'font-weight-medium']
          },
          {
            text     : this.$t('Positions.Table.Headers.MTMValue'),
            align    : 'left',
            value    : 'MTMValue',
            class    : ['synthesis-inter-text', 'text-size-12', 'synthesis-brand-dark-blue--text'],
            cellClass: ['text-size-14', 'synthesis-brand-dark-blue--text']
          }
        ]
      }
    }
  },
  computed: {
    accountId () {
      return this.user?.selectedAccountId || null
    },

    loading () {
      return !this.position
    },

    position () {
      return this.positionsSummaryData[this.id] || null
    },

    title () {
      return this.position?.Name || ''
    },

    createdAt () {
      return this.position?.CreatedAtFmt || ''
    },

    tableValues () {
      return this.position?.TableValues || []
    },

    breadcrumb () {
      return [
        {
          text    : this.$t('Positions.Title'),
          disabled: false,
          exact   : true,
          to      : { name: 'Positions' }
        },
        {
          text    : this.$t('Positions.Summary.Title'),
          disabled: true
        }
      ]
    }
  },
  watch: {
    tableValues: {
      handler: function (after, before) {
        const changed = after.filter(function (p, idx) {
          return Object.keys(p).some(function (prop) {
            return p?.DailyChange !== before[idx]?.DailyChange
          })
        })

        if (changed) {
          changed.forEach(item => {
            this.animatePriceText(item)
          })
        }
      },
      deep: true
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    animatePriceText (item) {
      if (this.showPriceAnimation) {
        const ref = this.$refs[`live-daily-text-${ item.Id }`]
        const mtm = this.$refs[`live-mtm-text-${ item.Id }`]
        const all = ['blinking', ...item.DailyChangeBgColors]
        const cls = ['blinking', item.DailyChangeBgColor]

        ref && ref.classList.add(...cls)
        mtm && mtm.classList.add(...cls)

        clearTimeout(item.AnimatePriceTimeout)
        item.AnimatePriceTimeout = setTimeout(() => {
          ref && ref.classList.remove(...all)
          mtm && mtm.classList.remove(...all)
        }, 400)
      }
    },

    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    },

    getDailyChangeColor (v, text = '') {
      if (v === 0 || v === '') {
        return 'synthesis-brand-dark-blue' + text
      } else {
        return (v > 0 ? 'synthesis-ui-green-01' : 'synthesis-ui-red-02') + text
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar__content, .v-toolbar__extension {
  padding: 4px 0 !important;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.blinking {
  -webkit-animation: 1s blink ease-in-out infinite;
  -moz-animation: 1s blink ease-in-out infinite;
  -ms-animation: 1s blink ease-in-out infinite;
  -o-animation: 1s blink ease-in-out infinite;
  animation: 1s blink ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  50% {
    opacity: 1;
  }
}

</style>
