import BaseResource                        from '@/lib/data/resource/BaseResource'
import RiskForecastFlowRuglSummaryResource from '@/api/resources/risks/RiskForecastFlowRuglSummaryResource'
import { isString }                        from '@/lib/utils/type'
// import ruglTable                           from '@/ruglTable.json'

export default class RiskForecastFlowResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload.id ?? null
    this.CreatedByUserId = payload.createdByUserId ?? null
    this.OwnedByAccountId = payload.ownedByAccountId ?? null
    this.CreatedAt = payload.createdAt ?? null
    this.ReferenceDate = payload.referenceDate ?? null
    this.RuglSummary = new RiskForecastFlowRuglSummaryResource(payload.ruglSummary ?? null)

    this.RuglTable = {
      headers: [],
      values : [],
      group  : {}
    }

    const table = payload.ruglTable.result ?? null
    if (isString(table)) {
      this.RuglTable = JSON.parse(table)
      // this.RuglTable = ruglTable
    }
  }

  /* PROPERTIES */

  /* METHODS */
}
