<template>
  <v-col cols="12">
    <block-item-card
      :min-height="80"
    >
      <v-container
        fill-height
        pa-5
        style="min-height: 80px;"
      >
        <v-row>
          <template v-for="(item, i) in items">
            <v-col
              :key="`list-item-${i}-content-${i}`"
              class="shrink justify-center align-start"
            >
              <div
                class="d-inline-block text-no-wrap"
                style="min-width: 150px;"
              >
                <div class="synthesis-inter-text text-size-12 synthesis-ui-grey-01--text">
                  {{ item.Title }}
                </div>
                <template
                  v-if="item.Tags && !!item.Tags.length"
                >
                  <v-chip
                    v-for="(tag, index) in item.Tags"
                    :key="`tag-${index}`"
                    :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
                    class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
                    label
                    x-small
                  >
                    {{ tag }}
                  </v-chip>
                </template>
                <div
                  v-else
                  class="text-size-16 font-weight-medium synthesis-brand-dark-blue--text"
                >
                  {{ item.Value }}
                </div>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </block-item-card>
  </v-col>
</template>

<script>
import BlockItemCard from '@/theme/default/components/common/BlockItemCard'

export default {
  name      : 'TriggerListCard',
  components: { BlockItemCard },
  directives: {},
  mixins    : [],
  props     : {
    items: {
      type    : Array,
      required: true
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
/deep/ .synthesis-card-border {
  border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  border-radius: 8px !important;
}
</style>
