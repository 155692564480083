<template>
  <e-chart
    :ref="`echart${chartRefId}`"
    :autoresize="true"
    :option="getChartOptions"
    :style="`height: ${getChartHeight}px;`"
    class="chart"
  />
</template>

<script>
import baseEChartBarProps from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData  from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'
import baseEChartColors   from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'

import { first } from '@/lib/utils/array'

import { use }                 from 'echarts/core'
import { BarChart }            from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer }      from 'echarts/renderers'

import { GridComponent, LegendComponent, TitleComponent, TooltipComponent, TransformComponent } from 'echarts/components'
import { toDecimalSignificant, toNumberFormatNotationShort }                                    from '@/lib/utils/helper'
import dayjs                                                                                    from 'dayjs'

use([
  BarChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
  CanvasRenderer,
  UniversalTransition
])

export default {
  name    : 'EChartDistributionBar',
  mixins  : [baseEChartColors, baseEChartBarProps, baseEChartBarData],
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 10,
        top         : 15,
        right       : 10,
        bottom      : 20,
        containLabel: false
      }
    },

    getChartDataOptions () {
      return {
        animation         : this.chartAnimation,
        animationThreshold: this.chartAnimationThreshold,
        animationDuration : this.chartAnimationDuration,
        animationEasing   : this.chartAnimationEasing,
        tooltip           : {
          show: false
        },
        legend   : { show: this.legendShow },
        textStyle: {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          type     : 'category',
          axisLabel: {
            show: true
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitNumber: this.axisXSplitNumber
        },
        yAxis: {
          z        : 10,
          max      : this.axisYMaxValue,
          type     : 'value',
          axisLabel: {
            show      : this.axisYLabelShow,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }
              if (this.axisYLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format('MMM YY')
              }
              return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
            },
            interval     : this.axisYLabelInterval,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber,
          axisLine   : {
            show: false
          },
          axisTick: {
            show: false
          },
          silent: true
        },
        color : [null, ...[this.themeColorRGBA('synthesis-ui-blue-04')]],
        series: this.series
      }
    },

    series () {
      let o = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
      }

      return Object.keys(o.y).map((key) => {
        const name = o.x[key] || ''
        const values = new Array(o.y.length).fill('')

        values[key] = parseFloat(o.y[key]) || 0

        return {
          name          : name,
          type          : 'bar',
          stack         : 'chart',
          barMinHeight  : 10,
          barCategoryGap: 0,
          barWidth      : '100%',
          barGap        : 0,
          label         : {
            show          : false,
            position      : 'top',
            valueAnimation: true,
            formatter     : params => {
              return (this.axisYTickPrefix + ' ' || '') + toNumberFormatNotationShort(params.value) + (this.axisYTickSuffix || '')
            }
          },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, this.barBorderRadius, this.barBorderRadius]
          },
          showBackground : !!name,
          backgroundStyle: { color: this.barBackgroundColor },
          data           : values
        }
      })
    }
  }
}
</script>

<style scoped>
.chart {
  width: 100%;
}
</style>
