import BaseResource                          from '@/lib/data/resource/BaseResource'
import { collect }                           from '@/lib/utils/array'
import RiskDebtFixedFloatingMixAssetResource from '@/api/resources/risks/RiskDebtFixedFloatingMixAssetResource'
import RiskDebtFixedFloatingMixItemsResource from '@/api/resources/risks/RiskDebtFixedFloatingMixItemsResource'

export default class RiskDebtFixedFloatingMixResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Assets = collect(payload?.years ?? [], RiskDebtFixedFloatingMixAssetResource)
    this.Items = collect(payload?.items ?? [], RiskDebtFixedFloatingMixItemsResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
