<template>
  <v-item-group
    v-model="model"
    :disabled="disabled"
    :mandatory="mandatory"
  >
    <v-container
      fluid
      pa-0
    >
      <v-row>
        <template v-for="(item, index) in items">
          <v-col
            :key="`item-${index}`"
            cols="12"
            md="4"
          >
            <v-item
              v-slot="{active, toggle}"
              :disabled="disabled"
              :value="item.Value"
            >
              <v-card
                :class="[{'item-group-card-active': active}]"
                :disabled="disabled"
                class="item-group-card align-center"
                flat
                @click="toggle"
              >
                <div>
                  <v-icon
                    :color="active ? 'synthesis-ui-blue-04' : 'synthesis-ui-grey-02'"
                    class="px-3 mb-1"
                  >
                    {{ active ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                  </v-icon>

                  <v-card-title class="d-inline-block synthesis-text synthesis-brand-dark-blue--text text-size-14 font-weight-regular pl-0 text-no-wrap">
                    {{ item.Label }}
                    <infotip
                      v-if="item.InfoTip"
                      icon-style="margin-top: -12px"
                    >
                      {{ item.InfoTip }}
                    </infotip>
                  </v-card-title>
                </div>

                <v-card-text class="text-center pa-0">
                  <img
                    :alt="item.Label"
                    :src="item.Image"
                    class="pa-4"
                    width="100%"
                  >
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </v-item-group>
</template>

<script>
import GroupItemRadio from '@/theme/default/components/form/GroupItemRadio'
import Infotip        from '@/theme/default/components/common/Infotip'

export default {
  name      : 'SelectHedgingInstrument',
  components: { Infotip },
  directives: {},
  extends   : GroupItemRadio,
  mixins    : [],
  props     : {},
  data () {
    return {}
  },
  computed: {}
}
</script>

<style scoped>
.item-group-card {
  border: 1px solid var(--v-synthesis-ui-grey-02-base) !important;
  background-color: #FFFFFF;
}

.item-group-card-active {
  border: 1px solid var(--v-synthesis-ui-blue-04-base) !important;
  background-color: var(--v-synthesis-brand-lightest-blue-base);
}
</style>
