<template>
  <v-container
    v-if="table"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="6"
      >
        <v-text-field
          v-model="searchTerms"
          :placeholder="$t('Common.Search.Title')"
          background-color="white"
          dense
          height="42"
          hide-details="auto"
          outlined
          prepend-inner-icon="mdi-magnify"
        />
      </v-col>
      <v-col
        class="pt-0 pt-sm-3"
        cols="12"
        md="4"
        sm="6"
      >
        <v-select
          v-model="industryId"
          :items="industryListData"
          :placeholder="$t('Industry.Search.Category.Title')"
          background-color="#fff"
          clearable
          dense
          hide-details="auto"
          item-text="Title"
          item-value="Value"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <table-header-gradient-blue>
          <template #table>
            <v-data-table
              v-model="chips"
              :footer-props="{
                itemsPerPageOptions: [5, 10, 15, 25],
                disablePagination: loading
              }"
              :headers="headers"
              :items="values"
              :items-per-page.sync="options.itemsPerPage"
              :loading="loading"
              :multi-sort="options.multiSort"
              :page.sync="options.page"
              :server-items-length="options.total"
              :single-select="false"
              :sort-by.sync="options.sortBy"
              :sort-desc.sync="options.sortDesc"
              class="font-weight-medium synthesis-card-border"
              item-key="Id"
              show-select
            />
          </template>
        </table-header-gradient-blue>
      </v-col>

      <v-col
        v-if="chips.length"
        cols="12"
      >
        <block-item-card
          :min-height="60"
          :title="`${$t('Industry.Label.SelectedCompanies.Title')} (${chips.length}/${IndustryEnum.MaxSelectedCompanies})`"
        >
          <v-card-text class="pt-0">
            <template v-for="(item, i) in chips">
              <v-chip
                :key="`chip-${i}`"
                class="ma-2 font-weight-medium"
                close
                color="synthesis-ui-blue-08"
                text-color="synthesis-ui-blue-02"
                @click:close="removeSelectedItem(item)"
              >
                {{ item.Name }}
              </v-chip>
            </template>
          </v-card-text>
        </block-item-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import API                     from '@/api/Api'
import { clone }               from '@/lib/utils/helper'
import TableHeaderGradientBlue from '@/theme/default/components/common/TableHeaderGradientBlue'
import ComparisonTableResource from '@/api/resources/industry/ComparisonTableResource'
import CompanySortResource     from '@/api/resources/industry/CompanySortResource'
import BlockItemCard           from '@/theme/default/components/common/BlockItemCard'
import IndustryEnum            from '@/api/enums/IndustryEnum'
import IndustryListResource    from '@/api/resources/industry/IndustryListResource'
import { collect }             from '@/lib/utils/array'

export default {
  name      : 'ComparisonTable',
  components: {
    BlockItemCard,
    TableHeaderGradientBlue
  },
  directives: {},
  mixins    : [],
  props     : {
    value: {
      type   : Array,
      default: () => []
    }
  },
  dataStore: {
    industryTableData: 'Industry.Table.Data',
    industryListData : 'Industry.List.Data'
  },
  enums    : { IndustryEnum },
  dataModel: null,
  data () {
    return {
      loading              : true,
      industryId           : null,
      searchTerms          : '',
      searchDebounceTimeout: null,
      options              : {
        itemsPerPage: 10,
        page        : 1,
        total       : 0,
        sortBy      : [],
        sortDesc    : [],
        multiSort   : false
      },
      chips: []
    }
  },
  computed: {
    model: {
      get () {
        return this.getModelValueId(this.value)
      },
      set (val) {
        this.$emit('input', this.setModelValueId(val))
      }
    },

    table () {
      return this.industryTableData ?? []
    },

    headers () {
      return this.table.Headers ?? []
    },

    values () {
      return this.table.Values ?? []
    }

  },
  watch: {
    chips (val, oldVal) {
      if (val.length > IndustryEnum.MaxSelectedCompanies) {
        this.$nextTick(() => {
          this.chips = oldVal
        })
      }

      this.$nextTick(() => {
        this.model = this.chips
      })
    },

    industryId () {
      this.getData()
    },

    searchTerms () {
      clearTimeout(this.searchDebounceTimeout)
      this.searchDebounceTimeout = setTimeout(() => {
        this.options.page = 1
        this.getData()
      }, 500)
    },

    'options.page': {
      handler () {
        this.getData()
      },
      immediate: true
    },

    'options.sortDesc': {
      handler () {
        this.getData()
      }
    }

  },
  beforeCreate () {},
  created () {
    this.getIndustries()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    removeSelectedItem (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
    },

    getModelValueId (val) {
      return val.map(v => { return { Id: v } })
    },

    setModelValueId (val) {
      return val.map(v => v?.Id || v)
    },

    getIndustries () {
      API.Resource.Industry.IndustriesList()
        .then(response => {
          this.handleIndustriesListResponse(response)
        })
        .catch(e => {
          this.handleIndustriesListResponse(e.response)
        })
        .finally(() => {})
    },

    handleIndustriesListResponse (response) {
      const data = API.responseData(response)?.listIndustries || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        this.industryListData = collect(o, IndustryListResource)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getData () {
      this.loading = true

      const sort = new CompanySortResource({
        sortBy  : clone(this.options.sortBy),
        sortDesc: clone(this.options.sortDesc)
      })

      API.Resource.Industry.Companies({
        industryId  : this.industryId || null,
        keyword     : this.searchTerms && this.searchTerms.trim() || null,
        page        : (this.options.page - 1),
        itemsPerPage: this.options.itemsPerPage,
        sort        : sort.Sortable
      })
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.loading = false
        })
    },

    handleResponse (response) {
      const data = API.responseData(response)?.findCompaniesByKeyword || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        const o = clone(data)
        const v = new ComparisonTableResource(o)

        this.industryTableData = v
        this.options.total = v.Total
      } else {
        this.$bus.$emit('app:error', errors)
      }
    }
  }
}
</script>

<style scoped>
/deep/ .v-data-footer__select {
  visibility: hidden;
}

/deep/ .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 42px;
}
</style>
