import BaseResource               from '@/lib/data/resource/BaseResource'
import { collect }                from '@/lib/utils/array'
import i18n                       from '@/lang/lang'
import { clone }                  from '@/lib/utils/helper'
import RiskExposuresAssetResource from '@/api/resources/risks/RiskExposuresAssetResource'

export default class RiskExposuresResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.exposure || null

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Assets = o?.assets || []
    this.Items = collect(o?.items || [], RiskExposuresAssetResource)
  }

  /* PROPERTIES */
  get FinancialExposure () {
    return this.getFinancialExposureBarChart()
  }

  /* METHODS */
  getItemsWithoutPortfolio () {
    const v = clone(this.Items)

    if (v) {
      return v.filter(item => item.Asset !== i18n.t('Common.Asset.Portfolio'))
    }

    return []
  }

  getFinancialExposureBarChart () {
    const o = this.getPercentageFinancialExposurePerAsset()

    if (o) {
      return {
        Bar: [{
          x: o.map(item => item.name),
          y: o.map(item => item.value)
        }],
        Value   : this.getFinancialExposureValue(),
        PerAsset: this.getFinancialExposurePerAsset()
      }
    }

    return {
      Bar     : [],
      Value   : '',
      PerAsset: []
    }
  }

  getFinancialExposurePerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name                : item.Asset ?? '',
        value               : item.Exposure ?? '',
        valueLocal          : item.LocalExposure ?? '',
        valueLocalSymbol    : item.DetailAsset.displaySymbol ?? '',
        displayLocalExposure: item.DisplayLocalExposure ?? false,
        displayUnit         : item.DisplayUnit ?? '',
        displayUnitPosition : item.DisplayUnitPosition ?? '',
        icon                : item.AggregateItem ? 'mdi-plus-circle' : 'mdi-checkbox-blank-circle',
        iconExpanded        : 'mdi-minus-circle-outline',
        aggregate           : item.AggregateItem ?? false,
        aggregateItems      : this.getFinancialExposurePerAssetAggregate(item)
      }
    })
  }

  getFinancialExposurePerAssetAggregate (aggregateItem) {
    const items = aggregateItem.PerCashflowItems ?? []

    return items.map(item => {
      return {
        name                : item.Asset ?? '',
        value               : item.Exposure ?? '',
        valueLocal          : item.LocalExposure ?? '',
        valueLocalSymbol    : item.DetailAsset.displaySymbol ?? '',
        displayLocalExposure: item.DisplayLocalExposure ?? false,
        displayUnit         : item.DisplayUnit ?? '',
        displayUnitPosition : item.DisplayUnitPosition ?? ''
      }
    })
  }

  getPercentageFinancialExposurePerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name : item.Asset ?? '',
        value: item.ExposurePercent ?? ''
      }
    })
  }

  getVolatilityPerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name : item.Asset ?? '',
        value: item.Volatility ?? ''
      }
    })
  }

  getPortfolioPriceValue () {
    const v = clone(this.Items)

    if (v) {
      const index = v.findIndex(item => item.Asset === i18n.t('Common.Asset.Portfolio'))
      const portfolio = v[index] ?? null

      if (portfolio) {
        return portfolio?.Exposure || ''
      }
    }

    return ''
  }

  getFinancialExposureValue () {
    return this.getPortfolioPriceValue(false)
  }
}
