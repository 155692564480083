/**
 * Truncates a string up to a specified length.
 *
 * Determine if the string's length is greater than num. Return the string truncated to the desired length, with '...' appended to the end or the original string.
 *
 * @example truncate('boomerang', 7); // 'boom...'
 *
 * @param str
 * @param num
 * @returns {string}
 */
export const truncate = (str, num) => str.length > num ? str.slice(0, num > 3 ? num - 3 : num) + '...' : str
