<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-btn
              v-if="model.selectedItem && !isEditMode"
              class="pa-2"
              text
              @click="onBackToListClick"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              {{ $t('Common.Button.Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <template v-if="!model.selectedItem">
              <v-icon
                color="orange"
                size="24"
              >
                mdi-poll
              </v-icon>

              <div class="mt-4">
                <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                  {{ $t(`${ componentLang }.AddExposure.Title`) }}
                </div>
                <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                  {{ $t(`${ componentLang }.AddExposure.Subtitle`) }}
                </div>
              </div>

              <div class="mt-4">
                <v-text-field
                  v-model="searchTerms"
                  :placeholder="$t('Common.Search.Title')"
                  append-icon="mdi-magnify"
                  outlined
                  single-line
                />
              </div>
            </template>

            <template v-else>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                      {{ model.selectedItem.pair.base.displayName }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                      {{ model.selectedItem.pair.quote.displayName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action-text class="text-right">
                    <v-chip
                      class="font-weight-medium"
                      color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                      label
                      small
                    >
                      {{ model.selectedItem.displayPrice }}
                    </v-chip>
                    <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                      {{ model.selectedItem.quotation.description }}
                    </div>
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </template>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          :class="[{'pa-0': !model.selectedItem}]"
          class="pt-5"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <template v-if="!model.selectedItem">
              <v-list>
                <template v-for="(item, index) in items">
                  <v-list-item
                    :key="`list-item-${index}`"
                    :class="[{'grey lighten-4': isAssetInExposuresList(item.pair.base.symbol)}]"
                    :disabled="isAssetInExposuresList(item.pair.base.symbol)"
                    class="px-6 py-1"
                    @click="onListItemClick(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        :class="isAssetInExposuresList(item.pair.base.symbol) ? 'synthesis-ui-grey-01--text' : 'synthesis-brand-dark-blue--text'"
                        class="synthesis-text text-size-16 font-weight-medium"
                      >
                        {{ item.pair.base.displayName }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium"
                      >
                        {{ item.pair.quote.displayName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action-text class="text-right">
                      <v-chip
                        :color="isAssetInExposuresList(item.pair.base.symbol) ? 'synthesis-ui-grey-07 synthesis-ui-grey-00--text' : 'synthesis-ui-green-07 synthesis-ui-green-00--text'"
                        class="font-weight-medium"
                        label
                        small
                      >
                        {{ item.displayPrice }}
                      </v-chip>
                      <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                        {{ item.quotation.description }}
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-divider :key="`list-item-divider-${index}`" />
                </template>
              </v-list>
            </template>

            <template v-else>
              <div class="text-center mb-2">
                <label class="field-label">{{ $t(`${ componentLang }.AddExposure.Form.Fields.Notional.Label`) }}
                  <infotip
                    v-if="$t(`${ componentLang }.AddExposure.Form.Fields.Notional.InfoTip`)"
                    icon-style="margin-top: -16px"
                  >{{ $t(`${ componentLang }.AddExposure.Form.Fields.Notional.InfoTip`) }}
                  </infotip>
                </label>
                <v-text-field-currency-directive
                  v-model="model.notional"
                  :class="{'negative-value-input-warning': isNegativeValue(model.notional), 'notional-large-value': isLargeValue(model.notional), 'notional-extra-large-value': isExtraLargeValue(model.notional), 'notional-extra-extra-large-value': isExtraExtraLargeValue(model.notional)}"
                  :options="{precision: 0, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                  :placeholder="$t(`${componentLang}.AddExposure.Form.Fields.Notional.Placeholder`)"
                  :readonly="model.userProvidedSeasonality"
                  autofocus
                  class="annual-exposure"
                  flat
                  height="70"
                  hide-details="auto"
                  solo
                >
                  <template #prepend-inner>
                    <span
                      ref="notionalPrepend"
                      :style="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? `width: ${$refs.notionalAppend && $refs.notionalAppend.clientWidth}px` : ''"
                      class="text-no-wrap"
                      v-html="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? model.selectedItem.pair.base.displayName : ''"
                    />
                  </template>

                  <template #append>
                    <span
                      ref="notionalAppend"
                      :style="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? `width: ${$refs.notionalPrepend && $refs.notionalPrepend.clientWidth}px` : ''"
                      class="text-no-wrap"
                      v-html="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? model.selectedItem.quotation.displayUnit : ''"
                    />
                  </template>
                </v-text-field-currency-directive>

                <div
                  :class="[isNegativeValue(model.notional) ? 'negative-value-input-warning' : 'synthesis-ui-grey-01--text']"
                  class="synthesis-text text-size-14 font-weight-medium pa-2"
                >
                  {{ notionalInBaseCurrency }}
                </div>
              </div>

              <v-divider />

              <div class="mt-4">
                <div class="text-left mb-4">
                  <label class="field-label">{{ $t(`${ componentLang }.AddExposure.Form.Fields.Label.Label`) }}</label>
                  <v-text-field
                    v-model="model.label"
                    :error-messages="serverErrorMessages['label']"
                    :placeholder="$t(`${componentLang}.AddExposure.Form.Fields.Label.Placeholder`)"
                    :rules="model.validator.vuetifyFormFieldRules('label')"
                    hide-details="auto"
                    outlined
                  />
                </div>

                <v-container
                  v-if="model.userProvidedSeasonality"
                  fluid
                  pa-0
                >
                  <v-row>
                    <v-col cols="12">
                      <v-messages
                        v-model="seasonalityValidationMessages"
                        class="text-size-14 error--text text-center"
                      />
                    </v-col>

                    <template v-for="(month, index) in 12">
                      <v-col
                        :key="`seasonality-${month}`"
                        cols="6"
                      >
                        <label class="field-label">{{ $tc('Common.Date.Month', 1) }} {{ month }}</label>
                        <v-text-field-currency-directive
                          v-model="model.seasonality[index]"
                          :class="{'negative-value-input-warning': isNegativeValue(model.seasonality[index])}"
                          :options="{precision: 0, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                          :prefix="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? model.selectedItem.pair.base.displayName : ''"
                          :rules="model.validator.vuetifyFormFieldRules(`seasonality.${index}`)"
                          :suffix="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? model.selectedItem.quotation.displayUnit : ''"
                          class="add-seasonality"
                          dense
                          hide-details="auto"
                          outlined
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-container>

                <v-btn
                  block
                  class="my-4 text-size-14 font-weight-medium"
                  color="synthesis-ui-green-01"
                  text
                  @click="toggleUserProvidedSeasonality"
                >
                  {{ model.userProvidedSeasonality ? 'Remove seasonality' : 'Add seasonality' }}
                </v-btn>
              </div>
            </template>
          </v-form>
        </v-card-text>

        <template v-if="model.selectedItem">
          <v-divider />

          <v-card-actions class="py-3">
            <v-btn
              :disabled="isFormSaving || !isModelValid"
              :loading="isFormSaving"
              block
              class="action-button white--text"
              color="synthesis-ui-green-02"
              depressed
              large
              @click="saveFromData"
            >
              {{ isEditMode ? $t(`${ componentLang }.AddExposure.Form.Button.Update.Title`) : $t(`${ componentLang }.AddExposure.Form.Button.Save.Title`) }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                  from '@/mixins/dialog/dialogData'
import dialogVisible               from '@/mixins/dialog/dialogVisible'
import API                         from '@/api/Api'
import AssetExposureModel          from '@/api/models/asset/AssetExposureModel'
import { clone, toDecimalMark }    from '@/lib/utils/helper'
import VTextFieldCurrencyDirective from '@/theme/default/components/form/VTextFieldCurrencyDirective'
import AssetEnum                   from '@/api/enums/AssetEnum'
import Infotip                     from '@/theme/default/components/common/Infotip'
import FeatureFilterEnum           from '@/api/enums/FeatureFilterEnum'

export default {
  name      : 'AddHedgingExposureDialog',
  components: {
    Infotip,
    VTextFieldCurrencyDirective
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    componentLang: {
      type   : String,
      default: 'Assets'
    },

    baseCurrencyCode: {
      type   : String,
      default: ''
    },

    exposuresList: {
      type   : Array,
      default: () => []
    }
  },
  dataStore: { SupportedAssets: 'Assets.All' },
  enums    : { AssetEnum },
  dataModel: AssetExposureModel,
  data () {
    return {
      items                : [],
      searchTerms          : '',
      searchDebounceTimeout: null
    }
  },
  computed: {
    notionalInBaseCurrency () {
      return `${ this.model?.selectedItem?.pair?.quote?.displaySymbol } ${ toDecimalMark(parseFloat(this.model.notional || 0) * this.model.selectedItem.price, 0) }`
    },

    seasonalityValidationMessages () {
      return this.model.errors?.seasonality || []
    },

    isEditMode () {
      return !!this.item
    }
  },
  watch: {
    'model.seasonality': function () {
      this.model.sumSeasonality()
    },

    baseCurrencyCode () {
      this.getData()
    },

    searchTerms (searchTerm) {
      this.searchAssets(searchTerm)
    },

    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
        this.model.selectedItem = null
      } else {
        this.searchTerms = ''
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toDecimalMark,

    /**
     * getData
     *
     * Get latest quotes from api
     */
    getData () {
      this.getLatestQuotes()
    },

    /**
     * toggleUserProvidedSeasonality
     *
     * Toggle user provided seasonality fields
     */
    toggleUserProvidedSeasonality () {
      this.model.userProvidedSeasonality = !this.model.userProvidedSeasonality

      if (this.model.userProvidedSeasonality) {
        this.model.sumSeasonality()
      } else {
        this.model.resetSeasonality()
      }
    },

    /**
     * searchAssets
     *
     * Search in supported assets locally, create pairs
     * for the result and request latest quotes with
     * 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchAssets (searchTerm) {
      clearTimeout(this.searchDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      if (!searchTerm) {
        this.getLatestQuotes()
        return
      }

      this.searchDebounceTimeout = setTimeout(() => {
        const results = this.SupportedAssets.filter(asset => asset.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.symbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.displaySymbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm))
        const resultPairs = []

        results.forEach(asset => {
          if (this.baseCurrencyCode && asset.symbol !== this.baseCurrencyCode) {
            resultPairs.push({
              baseSymbol : asset.symbol,
              quoteSymbol: this.baseCurrencyCode
            })
          }
        })

        this.getLatestQuotes(resultPairs)
      }, 500)
    },

    /**
     * getLatestQuotes
     *
     * Request latest quotes for passed asset pairs
     *
     * @param assetPairs {Array<Object>}
     */
    getLatestQuotes (assetPairs = this.user.getLivePricesAssetPairs(this.baseCurrencyCode)) {
      if (!Array.isArray(assetPairs) || assetPairs.length <= 0 || !this.baseCurrencyCode) return

      const resultPairs = []
      assetPairs.forEach(asset => {
        if (this.baseCurrencyCode && asset.baseSymbol !== this.baseCurrencyCode) {
          resultPairs.push({
            baseSymbol : asset.baseSymbol,
            quoteSymbol: this.baseCurrencyCode
          })
        }
      })

      API.Resource.Assets.LatestQuotes(resultPairs, FeatureFilterEnum.Hedging)
        .then(response => {
          this.handleLatestQuotesResponse(response)
        })
        .catch(e => {
          this.handleLatestQuotesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleLatestQuotesResponse
     *
     * @param response <Object>
     */
    handleLatestQuotesResponse (response) {
      const data = API.responseData(response)?.latestQuotes || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.items = data.filter(pair => !!pair)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * onListItemClick
     *
     * @param item {Object}
     */
    onListItemClick (item) {
      this.model.selectedItem = item
      this.model.assetCode = item?.pair?.base?.symbol || ''
      this.model.code = this.model.assetCode || ''
      this.model.name = this.model.code
    },

    /**
     * onBackToListClick
     *
     * Handle back to asset list button click
     */
    onBackToListClick () {
      this.model.selectedItem = null
      this.model.assetCode = ''
      this.model.code = ''
      this.model.name = ''
      this.model.label = ''
      this.model.userProvidedSeasonality = false
      this.model.resetSeasonality()
    },

    /**
     * saveFromData
     *
     * Locally persist entered exposures
     */
    saveFromData () {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const data = new AssetExposureModel(clone(this.model))
      if (data.userProvidedSeasonality) {
        data.seasonality = data.seasonality.map(item => item === '' ? '0' : item)
      } else {
        data.seasonality = []
      }
      this.validateForm() && this.formSaveSuccess(data)
    },

    isAssetInExposuresList (symbol) {
      return !!this.exposuresList.find(e => e.assetCode === symbol)
    },

    isNegativeValue (val) {
      return parseInt(val) < 0
    },
    isLargeValue (val) {
      return parseInt(val) >= 1_000_000_000
    },
    isExtraLargeValue (val) {
      return parseInt(val) >= 10_000_000_000
    },
    isExtraExtraLargeValue (val) {
      return parseInt(val) >= 1_000_000_000_000
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items     : start;
  justify-content : end;
  height          : 100%;
}

/deep/ .v-dialog {
  border-radius : 4px;
  margin        : 0px !important;
  max-height    : 100% !important;
}

/deep/ .annual-exposure .v-input__prepend-inner, /deep/ .annual-exposure .v-input__append-inner {
  font-size   : 20px;
  font-weight : 500;
}

/deep/ .annual-exposure .v-text-field__slot input {
  text-align    : center;
  font-size     : 46px;
  font-weight   : 500;
  padding-right : 4px;
  padding-left  : 4px;
  min-height    : 70px;
}

/deep/ .notional-large-value .v-text-field__slot input {
  font-size : 38px;
}

/deep/ .notional-extra-large-value .v-text-field__slot input {
  font-size : 32px;
}

/deep/ .notional-extra-extra-large-value .v-text-field__slot input {
  font-size : 25px;
}

/deep/ .add-seasonality .v-input__slot {
  padding : 0 !important;
}

/deep/ .add-seasonality .v-text-field__slot .v-text-field__prefix, /deep/ .add-seasonality .v-text-field__slot .v-text-field__suffix {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
  display       : flex;
  align-items   : center;
  font-weight   : 400;
}

/deep/ .add-seasonality .v-text-field__slot input {
  padding-left  : 4px;
  padding-right : 4px;
  margin-left   : 2px;
  margin-right  : 2px;
}
</style>
