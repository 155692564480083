<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-btn
              v-if="model.selectedItem && !isEditMode"
              class="pa-2"
              text
              @click="onBackToListClick"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              {{ $t('Common.Button.Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <template v-if="!model.selectedItem">
              <v-icon
                color="orange"
                size="24"
              >
                mdi-poll
              </v-icon>

              <div class="mt-4">
                <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                  {{ $t(`${ componentLang }.AddPosition.Title`) }}
                  <infotip
                    v-if="$t(`${ componentLang }.AddPosition.InfoTip`)"
                    icon-style="margin-top: -26px"
                  >
                    {{ $t(`${ componentLang }.AddPosition.InfoTip`) }}
                  </infotip>
                </div>
                <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                  {{ $t(`${ componentLang }.AddPosition.Subtitle`) }}
                </div>
              </div>

              <div class="mt-4">
                <v-text-field
                  v-model="searchTerms"
                  :placeholder="$t('Common.Search.Title')"
                  append-icon="mdi-magnify"
                  outlined
                  single-line
                />
              </div>
            </template>

            <template v-else>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                      {{ model.selectedItem.pair.base.displayName }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                      {{ model.selectedItem.pair.quote.displayName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action-text class="text-right">
                    <v-chip
                      class="font-weight-medium"
                      color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                      label
                      small
                    >
                      {{ model.selectedItem.displayPrice }}
                    </v-chip>
                    <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                      {{ model.selectedItem.quotation.description }}
                    </div>
                  </v-list-item-action-text>
                </v-list-item>
              </v-list>
            </template>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          :class="[{'pa-0': !model.selectedItem}]"
          class="pt-5"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <template v-if="!model.selectedItem">
              <v-list>
                <template v-for="(item, index) in items">
                  <v-list-item
                    :key="`list-item-${index}`"
                    class="px-6 py-1"
                    @click="onListItemClick(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="synthesis-text text-size-16 font-weight-medium synthesis-brand-dark-blue--text"
                      >
                        {{ item.pair.base.displayName }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium"
                      >
                        {{ item.pair.quote.displayName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action-text class="text-right">
                      <v-chip
                        class="font-weight-medium"
                        color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                        label
                        small
                      >
                        {{ item.displayPrice }}
                      </v-chip>
                      <div class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium pt-1">
                        {{ item.quotation.description }}
                      </div>
                    </v-list-item-action-text>
                  </v-list-item>

                  <v-divider :key="`list-item-divider-${index}`" />
                </template>
              </v-list>
            </template>

            <template v-else>
              <v-container
                fluid
                pa-0
              >
                <v-row>
                  <v-col cols="12">
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddPosition.Form.Fields.Instrument.Label`) }}
                    </label>

                    <v-select
                      v-model="selectedInstrumentItem"
                      return-object
                      :items="instrumentList"
                      :placeholder="$t(`${ componentLang }.AddPosition.Form.Fields.Instrument.Placeholder`)"
                      hide-details="auto"
                      item-text="displayInstrument"
                      item-value="instrument"
                      outlined
                      @change="changeInstrument"
                    />
                  </v-col>

                  <v-col
                    v-if="model.instrument === InstrumentTypeEnum.FORWARD"
                    cols="12"
                  >
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddPosition.Form.Fields.ForwardRate.Label`) }}
                      <span
                        v-if="model.selectedItem"
                        class="grey--text text-size-12"
                      >
                        {{ model.selectedItem.quotation.description }}
                      </span>
                    </label>

                    <v-text-field-currency-directive
                      v-model="model.forward.rate"
                      :class="{'negative-value-input-warning': isNegativeValue(model.forward.rate)}"
                      :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: Math.pow(10, 6)}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                      :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.ForwardRate.Placeholder`)"
                      hide-details="auto"
                      outlined
                    />
                  </v-col>

                  <v-col
                    v-if="model.instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP"
                    cols="12"
                  >
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddPosition.Form.Fields.InterestRateSwap.Label`) }}
                      <span
                        v-if="model.selectedItem"
                        class="grey--text text-size-12"
                      >
                        {{ model.selectedItem.quotation.description }}
                      </span>
                    </label>

                    <v-text-field-currency-directive
                      v-model="model.interestRateSwap.rate"
                      :class="{'negative-value-input-warning': isNegativeValue(model.interestRateSwap.rate)}"
                      :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: Math.pow(10, 6)}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                      :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.InterestRateSwap.Placeholder`)"
                      hide-details="auto"
                      outlined
                    />
                  </v-col>

                  <template v-if="model.instrument === InstrumentTypeEnum.OPTION">
                    <v-col cols="12">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.OptionStrike.Label`) }}
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.option.strike"
                        :class="{'negative-value-input-warning': isNegativeValue(model.option.strike)}"
                        :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: Math.pow(10, 6)}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.OptionStrike.Placeholder`)"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.OptionType.Label`) }}
                      </label>

                      <group-item-radio
                        v-model="model.option.type"
                        :items="$t(`${ componentLang }.AddPosition.Form.Fields.OptionType.Items`)"
                        mandatory
                      />
                    </v-col>
                  </template>

                  <template v-if="model.instrument === InstrumentTypeEnum.COLLAR">
                    <v-col cols="6">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.LowStrike.Label`) }}
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.collar.lowStrike"
                        :class="{'negative-value-input-warning': isNegativeValue(model.collar.lowStrike)}"
                        :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: Math.pow(10, 6)}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.LowStrike.Placeholder`)"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>

                    <v-col cols="6">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.HighStrike.Label`) }}
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.collar.highStrike"
                        :class="{'negative-value-input-warning': isNegativeValue(model.collar.highStrike)}"
                        :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: Math.pow(10, 6)}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.HighStrike.Placeholder`)"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>
                  </template>

                  <template v-if="model.instrument === InstrumentTypeEnum.CROSS_CURRENCY_SWAP">
                    <v-col cols="12">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.CrossCurrencySwapStrike.Label`) }}
                        <span
                          v-if="model.selectedItem"
                          class="grey--text text-size-12"
                        >
                          {{ model.selectedItem.quotation.description }}
                        </span>
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.crossCurrencySwap.ccsStrike"
                        :class="{'negative-value-input-warning': isNegativeValue(model.crossCurrencySwap.strike)}"
                        :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: Math.pow(10, 6)}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.CrossCurrencySwapStrike.Placeholder`)"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>

                    <v-col cols="6">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.CrossCurrencySwapFixedRate.Label`) }}
                        <span
                          v-if="model.selectedItem"
                          class="grey--text text-size-12"
                        >
                          {{ model.selectedItem.pair.quote.symbol }} Leg
                        </span>
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.crossCurrencySwap.assetFixedRate"
                        :class="{'negative-value-input-warning': isNegativeValue(model.crossCurrencySwap.assetFixedRate)}"
                        :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: 1}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.CrossCurrencySwapFixedRate.Placeholder`)"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>

                    <v-col cols="6">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.CrossCurrencySwapCounterFixedRate.Label`) }}
                        <span
                          v-if="model.selectedItem"
                          class="grey--text text-size-12"
                        >
                          {{ model.selectedItem.pair.base.symbol }} Leg
                        </span>
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.crossCurrencySwap.counterAssetFixedRate"
                        :class="{'negative-value-input-warning': isNegativeValue(model.crossCurrencySwap.counterAssetFixedRate)}"
                        :options="{precision: {min: 0, max: 5}, valueRange: {min: 0, max: 1}, valueScaling: undefined, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.CrossCurrencySwapCounterFixedRate.Placeholder`)"
                        hide-details="auto"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.CrossCurrencySwapFrequency.Label`) }}
                      </label>

                      <v-select
                        v-model="model.crossCurrencySwap.ccsFrequency"
                        :items="ccsSupportedFrequenciesList"
                        :placeholder="$t(`${ componentLang }.AddPosition.Form.Fields.CrossCurrencySwapFrequency.Placeholder`)"
                        hide-details="auto"
                        item-text="displayFrequency"
                        item-value="frequency"
                        outlined
                      />
                    </v-col>
                  </template>

                  <v-col cols="6">
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddPosition.Form.Fields.TradeDate.Label`) }}
                    </label>

                    <select-date
                      v-model="model.tradeDate"
                      :max="$dayjs().format('YYYY-MM-DD')"
                      :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.TradeDate.Placeholder`)"
                      @input="onTradeDateChange"
                    />
                  </v-col>

                  <v-col cols="6">
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddPosition.Form.Fields.MaturityDate.Label`) }}
                    </label>

                    <select-date
                      v-model="model.maturityDate"
                      :disabled="!model.tradeDate"
                      :min="$dayjs(model.tradeDate).add(1, 'day').format('YYYY-MM-DD')"
                      :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.MaturityDate.Placeholder`)"
                    />
                  </v-col>

                  <v-col cols="12">
                    <div class="text-center mb-">
                      <label class="field-label">
                        {{ $t(`${ componentLang }.AddPosition.Form.Fields.Notional.Label`) }}
                      </label>

                      <v-text-field-currency-directive
                        v-model="model.notional"
                        :class="{'negative-value-input-warning': isNegativeValue(model.notional), 'notional-large-value': isLargeValue(model.notional), 'notional-extra-large-value': isExtraLargeValue(model.notional), 'notional-extra-extra-large-value': isExtraExtraLargeValue(model.notional)}"
                        :options="{precision: 0, valueRange: {min: 0, max: Math.pow(10, 16)}, locale: $i18n.code, currency: model.selectedItem.pair.quote.symbol}"
                        class="annual-exposure"
                        flat
                        height="70"
                        hide-details="auto"
                        placeholder="0"
                        solo
                      >
                        <template #prepend-inner>
                          <span
                            ref="notionalPrepend"
                            :style="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? `width: ${$refs.notionalAppend && $refs.notionalAppend.clientWidth}px` : ''"
                            class="text-no-wrap"
                            v-html="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? model.selectedItem.pair.base.displayName : ''"
                          />
                        </template>

                        <template #append>
                          <span
                            ref="notionalAppend"
                            :style="model.selectedItem.pair.base.type === AssetEnum.CURRENCY ? `width: ${$refs.notionalPrepend && $refs.notionalPrepend.clientWidth}px` : ''"
                            class="text-no-wrap"
                            v-html="model.selectedItem.pair.base.type === AssetEnum.COMMODITY ? model.selectedItem.quotation.displayUnit : ''"
                          />
                        </template>
                      </v-text-field-currency-directive>

                      <div
                        v-if="model.instrument !== InstrumentTypeEnum.INTEREST_RATE_SWAP"
                        :class="[isNegativeValue(model.notional) ? 'negative-value-input-warning' : 'synthesis-ui-grey-01--text']"
                        class="synthesis-text text-size-14 font-weight-medium pa-2"
                      >
                        {{ notionalInBaseCurrency }}
                      </div>
                    </div>
                  </v-col>

                  <v-col
                    v-if="model.instrument !== InstrumentTypeEnum.CROSS_CURRENCY_SWAP"
                    cols="12"
                  >
                    <label class="field-label">
                      {{ $t(`${ componentLang }.AddPosition.Form.Fields.Direction.Label`) }}
                      <span class="grey--text text-size-12">
                        {{ directionDescription }}
                      </span>
                    </label>

                    <group-item-radio
                      v-model="model.direction"
                      :items="directionItems"
                      mandatory
                    />
                  </v-col>

                  <v-col cols="12">
                    <div class="text-left mb-4">
                      <label class="field-label">{{ $t(`${ componentLang }.AddPosition.Form.Fields.Label.Label`) }}</label>
                      <v-text-field
                        v-model="model.label"
                        :error-messages="serverErrorMessages['label']"
                        :placeholder="$t(`${componentLang}.AddPosition.Form.Fields.Label.Placeholder`)"
                        :rules="model.validator.vuetifyFormFieldRules('label')"
                        hide-details="auto"
                        outlined
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </v-form>
        </v-card-text>

        <template v-if="model.selectedItem">
          <v-divider />

          <v-card-actions class="py-3">
            <v-btn
              :disabled="isFormSaving || !isModelValid"
              :loading="isFormSaving"
              block
              class="action-button white--text"
              color="synthesis-ui-green-02"
              depressed
              large
              @click="saveFromData"
            >
              {{ isEditMode ? $t(`${ componentLang }.AddPosition.Form.Button.Update.Title`) : $t(`${ componentLang }.AddPosition.Form.Button.Save.Title`) }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                  from '@/mixins/dialog/dialogData'
import dialogVisible               from '@/mixins/dialog/dialogVisible'
import API                         from '@/api/Api'
import { clone, toDecimalMark }    from '@/lib/utils/helper'
import AssetEnum                   from '@/api/enums/AssetEnum'
import InstrumentTypeEnum          from '@/api/enums/InstrumentTypeEnum'
import GroupItemRadio              from '@/theme/default/components/form/GroupItemRadio'
import DirectionEnum               from '@/api/enums/DirectionEnum'
import TradeInputModel             from '@/api/models/positions/TradeInputModel'
import VTextFieldCurrencyDirective from '@/theme/default/components/form/VTextFieldCurrencyDirective'
import SelectDate                  from '@/theme/default/components/form/SelectDate'
import Infotip                     from '@/theme/default/components/common/Infotip'
import FeatureFilterEnum           from '@/api/enums/FeatureFilterEnum'
import CrossCurrencySwapOptionItemResource from '@/api/resources/positions/CrossCurrencySwapOptionItemResource'
import { collect } from '@/lib/utils/array'

export default {
  name      : 'AddPositionDialog',
  components: {
    Infotip,
    SelectDate,
    VTextFieldCurrencyDirective,
    GroupItemRadio
  },
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    componentLang: {
      type   : String,
      default: 'Assets'
    },

    baseCurrencyCode: {
      type   : String,
      default: ''
    },

    tradesList: {
      type   : Array,
      default: () => []
    }
  },
  dataStore: {
    SupportedAssets                      : 'Assets.All',
    SupportedCrossCurrencySwapFrequencies: 'Positions.CrossCurrencySwapSupportedFrequencies'
  },
  enums: {
    AssetEnum,
    DirectionEnum,
    InstrumentTypeEnum
  },
  dataModel: TradeInputModel,
  data () {
    return {
      items                 : [],
      searchTerms           : '',
      searchDebounceTimeout : null,
      selectedInstrumentItem: null
    }
  },
  computed: {
    instrumentList () {
      return this.model?.selectedItem?.pair?.supportedTradeInstruments || []
    },

    ccsSupportedFrequenciesList () {
      const supported = this.SupportedCrossCurrencySwapFrequencies.filter(ccsSupported =>
        ccsSupported.key.Base.symbol === this.model?.selectedItem?.pair?.base.symbol &&
        ccsSupported.key.Quote.symbol === this.model?.selectedItem?.pair?.quote.symbol)
      return (supported && supported.length > 0) ? supported[0]?.options || [] : []
    },

    directionItems () {
      return clone(this.$t(`${ this.componentLang }.AddPosition.Form.Fields.Direction.Items`))
    },

    directionDescription () {
      const items = clone(this.$t(`${ this.componentLang }.AddPosition.Form.Fields.Direction.Items`))
      const itemLong = items.find(v => v.Value === 'Long')
      const itemShort = items.find(v => v.Value === 'Short')
      let description = ''

      if (this.model.direction === 'Long') {
        if (this.model.instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP) {
          const payFixed = this.$t(`${ this.componentLang }.AddPosition.Form.Fields.Direction.PayFixed.Label`)
          const receiveFloating = this.$t(`${ this.componentLang }.AddPosition.Form.Fields.Direction.ReceiveFloating.Label`)

          description = `${ itemLong.Label } ${ payFixed } - ${ itemShort.Label } ${ receiveFloating }`
        } else {
          description = `${ itemLong.Label } ${ this.model.selectedItem.pair.quote.name } - ${ itemShort.Label } ${ this.model.selectedItem.pair.base.name }`
        }
      } else {
        if (this.model.instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP) {
          const payFloating = this.$t(`${ this.componentLang }.AddPosition.Form.Fields.Direction.PayFloating.Label`)
          const receiveFixed = this.$t(`${ this.componentLang }.AddPosition.Form.Fields.Direction.ReceiveFixed.Label`)

          description = `${ itemLong.Label } ${ receiveFixed } - ${ itemShort.Label } ${ payFloating }`
        } else {
          description = `${ itemLong.Label } ${ this.model.selectedItem.pair.base.name } - ${ itemShort.Label } ${ this.model.selectedItem.pair.quote.name }`
        }
      }

      return description
    },

    notionalInBaseCurrency () {
      let rate = 0
      if (this.model.instrument === InstrumentTypeEnum.INTEREST_RATE_SWAP) rate = this.model.interestRateSwap.rate
      if (this.model.instrument === InstrumentTypeEnum.FORWARD) rate = this.model.forward.rate
      if (this.model.instrument === InstrumentTypeEnum.COLLAR) rate = this.model.collar.highStrike
      if (this.model.instrument === InstrumentTypeEnum.OPTION) rate = this.model.option.strike
      if (this.model.instrument === InstrumentTypeEnum.CROSS_CURRENCY_SWAP) rate = this.model.crossCurrencySwap.ccsStrike

      if (parseFloat(this.model.selectedItem.price) !== parseFloat(this.model.selectedItem.displayPrice)) {
        return `${ this.model?.selectedItem?.pair?.quote?.displaySymbol } ${ toDecimalMark(parseFloat(this.model.notional || 0) / rate, 0) }`
      } else {
        return `${ this.model?.selectedItem?.pair?.quote?.displaySymbol } ${ toDecimalMark(parseFloat(this.model.notional || 0) * rate, 0) }`
      }
    },

    isEditMode () {
      return !!this.item
    }
  },
  watch: {
    'model.instrument': function () {
      this.model.interestRateSwap.rate = 0
      this.model.forward.rate = 0
      this.model.option.strike = 0
      this.model.collar.lowStrike = 0
      this.model.collar.highStrike = 0
      this.model.crossCurrencySwap.ccsStrike = 0
      this.model.crossCurrencySwap.assetFixedRate = 0
      this.model.crossCurrencySwap.counterAssetFixedRate = 0
      this.model.notional = 0
    },

    baseCurrencyCode: {
      handler () {
        this.getData()
      },
      immediate: true
    },

    searchTerms (searchTerm) {
      this.searchAssets(searchTerm)
    },

    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
        this.model.selectedItem = null
      } else {
        this.searchTerms = ''
      }
    },

    instrumentList: {
      handler (val) {
        if (val.length > 0 && !this.isEditMode) {
          this.selectedInstrumentItem = val[0]
          this.model.instrument = val[0].instrument
          this.model.displayInstrument = val[0].displayInstrument
        }
      },
      immediate: true
    }
  },
  beforeCreate () {},
  async created () {
    await this.getCrossCurrencySwapSupportedAssets()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    /**
     * getData
     *
     * Get latest quotes from api
     */
    changeInstrument (selectedItem) {
      this.selectedInstrumentItem = selectedItem
      this.model.displayInstrument = selectedItem.displayInstrument
      this.model.instrument = selectedItem.instrument
      if (this.model.instrument === InstrumentTypeEnum.CROSS_CURRENCY_SWAP) {
        this.getCrossCurrencySwapSupportedAssets().then(() => {
          this.model.crossCurrencySwap.ccsFrequency = (this.ccsSupportedFrequenciesList && this.ccsSupportedFrequenciesList.length > 0) ? this.ccsSupportedFrequenciesList[0].frequency : ''
        })
      }
    },

    getData () {
      this.getLatestQuotes()
    },

    /**
     * searchAssets
     *
     * Search in supported assets locally, create pairs
     * for the result and request latest quotes with
     * 500ms debounce / delay
     *
     * @param searchTerm {String}
     */
    searchAssets (searchTerm) {
      clearTimeout(this.searchDebounceTimeout)
      searchTerm = searchTerm.trim().toLocaleLowerCase(this.$i18n.locale)

      if (!searchTerm) {
        this.getLatestQuotes()
        return
      }

      this.searchDebounceTimeout = setTimeout(() => {
        const results = this.SupportedAssets.filter(asset => asset.name.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.symbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm) || asset.displaySymbol.toLocaleLowerCase(this.$i18n.locale).includes(searchTerm))
        const resultPairs = []

        results.forEach(asset => {
          if (this.baseCurrencyCode && asset.symbol !== this.baseCurrencyCode) {
            resultPairs.push({
              baseSymbol : asset.symbol,
              quoteSymbol: this.baseCurrencyCode
            })
          }
        })

        this.getLatestQuotes(resultPairs)
      }, 500)
    },

    /**
     * getCcsSupportedFrequencies
     *
     * Request supported cross currency swap frequencies for passed asset pairs
     */
    getCrossCurrencySwapSupportedAssets () {
      if (this.SupportedCrossCurrencySwapFrequencies && this.SupportedCrossCurrencySwapFrequencies.length > 0) {
        return Promise.resolve()
      }
      return new Promise((resolve, reject) => {
        API.Resource.Positions.GetCrossCurrencySwapSupportedAssets()
          .then(response => {
            this.handleCrossCurrencySwapSupportedAssetsResponse(response)
            return resolve()
          })
          .catch(e => {
            this.handleCrossCurrencySwapSupportedAssetsResponse(e.response)
            return reject(e.message)
          })
          .finally(() => {})
      })
    },

    /**
     * handleCcsSupportedFrequenciesResponse
     *
     * @param response <Object>
     */
    handleCrossCurrencySwapSupportedAssetsResponse (response) {
      const data = API.responseData(response)?.crossCurrencySwapSupportedAssets || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.SupportedCrossCurrencySwapFrequencies = collect(data ?? [], CrossCurrencySwapOptionItemResource)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * getLatestQuotes
     *
     * Request latest quotes for passed asset pairs
     *
     * @param assetPairs {Array<Object>}
     */
    getLatestQuotes (assetPairs = this.user.getLivePricesAssetPairs(this.baseCurrencyCode)) {
      if (!Array.isArray(assetPairs) || assetPairs.length <= 0 || !this.baseCurrencyCode) return

      const resultPairs = []
      assetPairs.forEach(asset => {
        if (this.baseCurrencyCode && asset.baseSymbol !== this.baseCurrencyCode) {
          resultPairs.push({
            baseSymbol : asset.baseSymbol,
            quoteSymbol: this.baseCurrencyCode
          })
        }
      })

      API.Resource.Assets.LatestQuotes(resultPairs, FeatureFilterEnum.Position)
        .then(response => {
          this.handleLatestQuotesResponse(response)
        })
        .catch(e => {
          this.handleLatestQuotesResponse(e.response)
        })
        .finally(() => {})
    },

    /**
     * handleLatestQuotesResponse
     *
     * @param response <Object>
     */
    handleLatestQuotesResponse (response) {
      const data = API.responseData(response)?.latestQuotes || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response) && data) {
        this.items = data.filter(pair => !!pair)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    /**
     * onListItemClick
     *
     * @param item {Object}
     */
    onListItemClick (item) {
      this.model.selectedItem = item
      this.model.assetCode = item?.pair?.base?.symbol || ''
    },

    /**
     * onBackToListClick
     *
     * Handle back to asset list button click
     */
    onBackToListClick () {
      this.model.selectedItem = null
      this.model.assetCode = ''
    },

    /**
     * saveFromData
     *
     * Locally persist entered trades
     */
    saveFromData () {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const data = new TradeInputModel(clone(this.model))
      this.validateForm() && this.formSaveSuccess(data)
    },

    /*
    isAssetInTradesList (symbol) {
      return !!this.tradesList.find(e => e.assetCode === symbol)
    },
    */

    isNegativeValue (val) {
      return parseInt(val) < 0
    },

    onTradeDateChange () {
      if (this.$dayjs(this.model.tradeDate).isSameOrAfter(this.$dayjs(this.model.maturityDate))) {
        this.model.maturityDate = ''
      }
    },

    isLargeValue (val) {
      return parseInt(val) >= 1_000_000_000
    },
    isExtraLargeValue (val) {
      return parseInt(val) >= 10_000_000_000
    },
    isExtraExtraLargeValue (val) {
      return parseInt(val) >= 1_000_000_000_000
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items: start;
  justify-content: end;
  height: 100%;
}

/deep/ .v-dialog {
  border-radius: 4px;
  margin: 0px !important;
  max-height: 100% !important;
}

/deep/ .annual-exposure .v-input__prepend-inner, /deep/ .annual-exposure .v-input__append-inner {
  font-size: 20px;
  font-weight: 500;
}

/deep/ .annual-exposure .v-text-field__slot input {
  text-align: center;
  font-size: 46px;
  font-weight: 500;
  padding-right: 4px;
  padding-left: 4px;
  min-height: 70px;
}

/deep/ .notional-large-value .v-text-field__slot input {
  font-size: 38px;
}

/deep/ .notional-extra-large-value .v-text-field__slot input {
  font-size: 32px;
}

/deep/ .notional-extra-extra-large-value .v-text-field__slot input {
  font-size: 25px;
}

/deep/ .textfield-prefix .v-input__slot {
  padding: 0 !important;
}

/deep/ .textfield-prefix .v-text-field__slot .v-text-field__prefix, /deep/ .textfield-prefix .v-text-field__slot .v-text-field__suffix {
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  font-weight: 400;
}

/deep/ .textfield-prefix .v-text-field__slot input {
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 2px;
  margin-right: 2px;
}
</style>
