import BaseResource                     from '@/lib/data/resource/BaseResource'
import { collect }                      from '@/lib/utils/array'
import RiskDebtAmortisationInputModel   from '@/api/models/risks/RiskDebtAmortisationInputModel'
import RiskDebtAmortisationTypeEnumEnum from '@/api/enums/RiskDebtAmortisationTypeEnumEnum'

export default class RiskDebtAmortisationResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.notional = payload.notional ?? 0
    this.maturity = payload.maturity ?? null
    this.amortisationType = payload.amortisationType ?? RiskDebtAmortisationTypeEnumEnum.ANNUALLY
    this.amortisationAnnually = payload.amortisationAnnually ?? []
    this.amortisationQuarterly = payload.amortisationQuarterly ?? []
    this.amortisation = collect(payload.amortisation ?? [], RiskDebtAmortisationInputModel)
  }

  /* PROPERTIES */

  /* METHODS */
}
