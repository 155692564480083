import BaseResource                                       from '@/lib/data/resource/BaseResource'
import RiskDebtOptimalFixedRatioAssetResource             from '@/api/resources/risks/RiskDebtOptimalFixedRatioAssetResource'
import RiskDebtOptimalFixedRatioValueResource             from '@/api/resources/risks/RiskDebtOptimalFixedRatioValueResource'
import RiskDebtOptimalFixedRatioRatesViewAssetResource    from '@/api/resources/risks/RiskDebtOptimalFixedRatioRatesViewAssetResource'
import RiskDebtOptimalFixedRatioRiskAversionAssetResource from '@/api/resources/risks/RiskDebtOptimalFixedRatioRiskAversionAssetResource'
import { collect }                                        from '@/lib/utils/array'
import i18n                                               from '@/lang/lang'

export default class RiskDebtOptimalFixedRatioResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Items = collect(payload?.items ?? [], RiskDebtOptimalFixedRatioValueResource)
    this.RatesView = collect(payload?.ratesViews ?? [], RiskDebtOptimalFixedRatioRatesViewAssetResource)
    this.RiskAversions = collect(payload?.riskAversions ?? [], RiskDebtOptimalFixedRatioRiskAversionAssetResource)

    this.RatesViewAssets = collect(i18n.t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.RatesView.Items'), RiskDebtOptimalFixedRatioAssetResource)
    this.RiskAversionsAssets = collect(i18n.t('Risks.Section.DebtProfile.Block.OptimalFixedRatio.Buttons.RiskAversion.Items'), RiskDebtOptimalFixedRatioAssetResource)
  }

  /* PROPERTIES */

  /* METHODS */
}
