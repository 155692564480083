import { API } from '@/api/Api'

import {
  ActiveAnalysesQuery,
  CalculateCorrelationsMutation,
  CalculateExtremePeriod,
  CreateAnalysisMutation,
  CreateGroupAnalysisMutation,
  CurrentRiskAssetsQuery,
  DebtDerivativeMarketRate,
  DebtProfileSupportedCurrenciesQuery,
  DeleteAnalysisMutation,
  NewRiskAnalysisFromExistingQuery,
  PersistAnalysisMutation,
  RerunAnalysisMutation,
  RiskActiveQuery,
  RiskArchiveAllQuery,
  RiskArchiveQuery,
  RiskArchivePaginatedQuery,
  RiskCorrelationsQuery,
  RiskDebtQuery,
  RiskExposuresQuery,
  RiskExtremeEventsQuery,
  RiskForecastFlowQuery,
  RiskForecastsQuery,
  RiskHistoricalExposureQuery,
  RiskSeasonalityQuery,
  RiskSummaryDashboardQuery,
  RiskSummaryQuery,
  RiskValueAtRiskQuery,
  RiskValueAtRiskSimulationQuery,
  SaveDebtProfileMutation,
  SetActiveAnalysisMutation,
  UpdateExtremeEventsMutation
} from '@/api/query/Risks.graphql'

export default {
  // Queries
  async SummaryDashboard (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(RiskSummaryDashboardQuery, Data)
  },

  async CurrentAssets (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(CurrentRiskAssetsQuery, Data)
  },

  async Active (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(RiskActiveQuery, Data)
  },

  async Archive (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(RiskArchiveQuery, Data)
  },

  async ArchivePaginated (data) {
    const Data = { ...data }

    return await API.GQLRequest(RiskArchivePaginatedQuery, Data)
  },

  async ArchiveAll (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(RiskArchiveAllQuery, Data)
  },

  async Summary (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskSummaryQuery, Data)
  },

  async Exposures (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskExposuresQuery, Data)
  },

  async ValueAtRisk (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskValueAtRiskQuery, Data)
  },

  async HistoricalExposure (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskHistoricalExposureQuery, Data)
  },

  async ValueAtRiskSimulation (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskValueAtRiskSimulationQuery, Data)
  },

  async ExtremeEvents (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskExtremeEventsQuery, Data)
  },

  async RiskCorrelations (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskCorrelationsQuery, Data)
  },

  async RiskSeasonality (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskSeasonalityQuery, Data)
  },

  async RiskForecasts (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskForecastsQuery, Data)
  },

  async RiskDebt (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(RiskDebtQuery, Data)
  },

  async RiskForecastFlow (accountId) {
    const Data = { accountId: accountId }

    return await API.GQLRequest(RiskForecastFlowQuery, Data)
  },

  async ActiveAnalyses (accountIds) {
    const Data = { accountIds: accountIds }

    return await API.GQLRequest(ActiveAnalysesQuery, Data)
  },

  async NewRiskAnalysisFromExisting (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(NewRiskAnalysisFromExistingQuery, Data)
  },

  async GetDebtDerivativeMarketRate (accountId, maturity) {
    const Data = {
      accountId: accountId,
      maturity : maturity
    }

    return await API.GQLRequest(DebtDerivativeMarketRate, Data)
  },

  async DebtProfileSupportedCurrencies () {
    const Data = {}

    return await API.GQLRequest(DebtProfileSupportedCurrenciesQuery, Data)
  },

  // Mutations
  async CreateAnalysis (data) {
    const Data = { ...data }

    return await API.GQLRequest(CreateAnalysisMutation, Data)
  },

  async CreateGroupAnalysis (data) {
    const Data = { ...data }

    return await API.GQLRequest(CreateGroupAnalysisMutation, Data)
  },

  async RerunAnalysis (data) {
    const Data = { ...data }

    return await API.GQLRequest(RerunAnalysisMutation, Data)
  },

  async PersistAnalysis (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(PersistAnalysisMutation, Data)
  },

  async CalculateCorrelations (data) {
    const Data = { ...data }

    return await API.GQLRequest(CalculateCorrelationsMutation, Data)
  },

  async SetActiveAnalysis (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(SetActiveAnalysisMutation, Data)
  },

  async DeleteAnalysis (riskAnalysisId) {
    const Data = { riskAnalysisId: riskAnalysisId }

    return await API.GQLRequest(DeleteAnalysisMutation, Data)
  },

  async UpdateExtremeEvents (riskAnalysisId, selectedEvents) {
    const Data = {
      riskAnalysisId: riskAnalysisId,
      selectedEvents: selectedEvents
    }

    return await API.GQLRequest(UpdateExtremeEventsMutation, Data)
  },

  async CalculateExtremePeriod (riskAnalysisId, periodType) {
    const Data = {
      riskAnalysisId: riskAnalysisId,
      periodType    : periodType
    }

    return await API.GQLRequest(CalculateExtremePeriod, Data)
  },

  async SaveDebtProfile (DebtProfileInput) {
    const Data = { ...DebtProfileInput }

    return await API.GQLRequest(SaveDebtProfileMutation, Data)
  }
}
