import { collect }                          from '@/lib/utils/array'
import { toNumberFormatNotationShort }      from '@/lib/utils/helper'
import BaseResource                         from '@/lib/data/resource/BaseResource'
import AssetModel                           from '@/api/models/asset/AssetModel'
import DashboardHedgingPositionItemResource from '@/api/resources/dashboard/DashboardHedgingPositionItemResource'

export default class DashboardHedgingPositionsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.TitleValue = payload.totalMtmValue ?? ''
    this.TitleValueChange = payload.totalDailyChange ?? ''
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.Items = payload.positionsItems ? collect(payload.positionsItems, DashboardHedgingPositionItemResource) : []
  }

  /* PROPERTIES */
  get BaseCurrencyTitle () {
    return this.baseCurrencySymbolSingle() ? this.getBaseCurrencySymbolSingle() : this.getBaseCurrencySymbolSingle() + ' '
  }

  get BaseCurrencyDisplayName () {
    return this.BaseCurrency?.displayName || ''
  }

  get BaseCurrencyDisplaySymbol () {
    return this.BaseCurrency?.displaySymbol || ''
  }

  get TitleValueFmt () {
    return this.TitleValue ? toNumberFormatNotationShort(this.TitleValue) : ''
  }

  get TitleValueChangeFmt () {
    return this.TitleValueChange ? this.BaseCurrencyTitle + toNumberFormatNotationShort(this.TitleValueChange) : ''
  }

  /* METHODS */
  baseCurrencySymbolSingle () {
    return this.BaseCurrencyDisplaySymbol.length === 1
  }

  getBaseCurrencySymbolSingle () {
    return this.baseCurrencySymbolSingle() ? this.BaseCurrencyDisplaySymbol : this.BaseCurrencyDisplayName
  }
}
