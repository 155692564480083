<template>
  <view-data-container
    :loading="isLoadingData"
    :style="`${hasForecasts ? '' : 'min-height: calc(100vh - 160px);'}`"
    class="pa-0"
  >
    <v-row
      v-if="hasForecasts"
    >
      <v-col cols="12">
        <template v-for="(item, i) in forecasts">
          <v-row
            :key="`forecasts-${i}`"
            class="ma-0"
          >
            <v-col>
              <block-item-card
                :key="`block-item-card-forecasts-${i}`"
                :title="item.Name"
              >
                <v-row
                  class="ma-0 flex-md-nowrap"
                >
                  <v-col
                    class="pa-0 pl-4 pr-md-0 pr-4 flex-shrink-1"
                    cols="12"
                    md="9"
                    xl="10"
                  >
                    <v-lazy
                      :key="`lazy-block-item-card-forecasts-${i}`"
                      :options="{threshold: .5}"
                      :value="disableLazy"
                      transition="fade-transition"
                    >
                      <e-chart-forecasts
                        :axis-pointer-label-background-color="lineAreaColors.lineColor"
                        :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-grey-01', 0.4)"
                        :axis-pointer-line-style-width="2"
                        :axis-x-label-hide-overlap="true"
                        :axis-x-label-interval="60"
                        :axis-x-label-margin="10"
                        :axis-x-pointer-show="true"
                        :axis-y-label-inside="false"
                        :axis-y-label-margin="5"
                        :axis-y-pointer-show="false"
                        :axis-y-split-line-color="themeColorRGBA('synthesis-ui-grey-03', 1)"
                        :axis-y-split-line-dash-offset="4"
                        :axis-y-split-line-opacity="1"
                        :axis-y-split-line-type="[8, 8]"
                        :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
                        :chart-animation="chartAnimation"
                        :chart-area-fill-color="[themeColorRGBA('synthesis-ui-grey-01', 0.4), themeColorRGBA('synthesis-ui-grey-01', 0.03)]"
                        :chart-data-options="item.Values"
                        :chart-grid-margin="[{left: 5,top: 10,right: '49.88%',bottom: 10,containLabel: true, show: true, borderWidth: 0}, {left: '50%',top: 10,right: 0,bottom: 10,containLabel: true, show: true, borderWidth: 0, backgroundColor: themeColorRGBA('synthesis-ui-grey-06', .8)}]"
                        :chart-height="300"
                        :chart-line-color="themeColorRGBA('synthesis-ui-grey-01')"
                        :chart-line-show-symbol="false"
                        :chart-line-smooth="false"
                        :chart-scale="false"
                        axis-y-label-value-format="number"
                      />
                    </v-lazy>
                  </v-col>

                  <v-col
                    class="pa-0 pt-md-0"
                    cols="12"
                    md="3"
                    style="min-width: 285px;width: 285px"
                    xl="2"
                  >
                    <v-row
                      class="ma-0"
                    >
                      <v-col
                        class="ma-0 pa-0 px-4 py-4"
                        cols="12"
                      >
                        <v-lazy
                          :options="{threshold: .5}"
                          :value="disableLazy"
                          transition="fade-transition"
                        >
                          <block-item-card
                            :min-height="280"
                          >
                            <v-card-text
                              v-if="item.Banks"
                              class="pb-2 overflow-y-auto"
                              style="height: 280px"
                            >
                              <template
                                v-for="(bank, k) in item.Banks"
                              >
                                <item-chart-legend
                                  :key="`forecasts-per-asset-legend-${i}-${k}`"
                                  :background-color="bank.Color ? themeColorRGBA(bank.Color, .1) : ''"
                                  :base-currency-symbol="riskBaseCurrencyDisplayName"
                                  :icon-color="bank.Icon ? themeColorRGBA(bank.Color) : ''"
                                  :title="bank.Name"
                                  :title-value="bank.Value"
                                />
                              </template>
                            </v-card-text>
                          </block-item-card>
                        </v-lazy>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </block-item-card>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </view-data-container>
</template>

<script>

import i18nRouteMeta         from '@/mixins/i18n/i18nRouteMeta'
import riskAnalysisId        from '@/theme/default/views/risks/mixins/riskAnalysisId'
import API                   from '@/api/Api'
import { clone }             from '@/lib/utils/helper'
import { collect }           from '@/lib/utils/array'
import RiskForecastsResource from '@/api/resources/risks/RiskForecastsResource'
import BlockItemCard         from '@/theme/default/components/common/BlockItemCard'
import EChartForecasts       from '@/theme/default/components/charts/echarts/EChartForecasts'
import chartsColorMixin      from '@/theme/default/components/charts/mixins/chartsColorMixin'
import ItemChartLegend       from '@/theme/default/components/common/ItemChartLegend'
import baseEChartColors      from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import riskBaseCurrency      from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import ViewDataContainer     from '@/theme/default/components/common/ViewDataContainer'
import useExportAdditional   from '@/mixins/useExportAdditional'

export default {
  name      : 'RiskForecastsBlock',
  components: {
    ViewDataContainer,
    ItemChartLegend,
    EChartForecasts,
    BlockItemCard
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, baseEChartColors, i18nRouteMeta, riskAnalysisId, useExportAdditional],
  props     : {},
  dataStore : {
    riskForecastsData   : 'Risks.Forecasts.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      isLoadingData: true,
      lineAreaColors () {
        return {
          lineColor: this.themeColorRGBA('synthesis-ui-grey-01'),
          fillColor: [this.themeColorRGBA('synthesis-ui-grey-01', 0.4), this.themeColorRGBA('synthesis-ui-grey-01', 0.03)]
        }
      }
    }
  },
  computed: {
    forecasts () {
      return this.riskForecastsData[this.id] || null
    },

    hasForecasts () {
      return this.forecasts && !!this.forecasts.length
    }
  },
  watch: {
    forecasts: {
      handler () {
        this.setExportElements()
      },
      immediate: true
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Risks.RiskForecasts(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {
          this.isLoadingData = false
        })
    },
    handleResponse (response) {
      const data = API.responseData(response)
      const errors = API.responseErrors(response) || []
      const result = data?.analysisAssetsForecasts || null

      if (API.isResponseSuccess(response)) {
        const o = clone(result)
        const v = collect(o, RiskForecastsResource)

        this.$set(this.riskForecastsData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    setExportElements () {
      this.$nextTick(() => {
        this.$emit('export-element-updated', { el: this.$el })
      })
    }
  }
}
</script>

<style scoped>

</style>
