import BaseResource                 from '@/lib/data/resource/BaseResource'
import { collect }                  from '@/lib/utils/array'
import RiskValueAtRiskAssetResource from '@/api/resources/risks/RiskValueAtRiskAssetResource'
import i18n                         from '@/lang/lang'
import { clone }                    from '@/lib/utils/helper'

export default class RiskValueAtRiskResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const o = payload?.result?.valueAtRisk || null

    this.Id = payload?.id ?? ''
    this.Name = payload?.name ?? ''
    this.Assets = o?.assets || []
    this.Items = collect(o?.items || [], RiskValueAtRiskAssetResource)
  }

  /* PROPERTIES */
  get ValueAtRisk () {
    return this.getValueAtRiskBarChart()
  }

  get FinancialExposure () {
    return this.getFinancialExposureBarChart()
  }

  get VolatilityPerAsset () {
    return this.getVolatilityPerAsset()
  }

  /* METHODS */
  getItemsWithoutPortfolio () {
    const v = clone(this.Items)

    if (v) {
      return v.filter(item => item.Asset !== i18n.t('Common.Asset.Portfolio'))
    }

    return []
  }

  getPercentageTotalRiskPerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name : item.Asset ?? '',
        value: item.ValueAtRiskPercentRaw ?? ''
      }
    })
  }

  getValueRiskPerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name          : item.Asset ?? '',
        value         : item.ValueAtRisk ?? '',
        icon          : item.AggregateItem ? 'mdi-plus-circle' : 'mdi-checkbox-blank-circle',
        iconExpanded  : 'mdi-minus-circle-outline',
        aggregate     : item.AggregateItem ?? false,
        aggregateItems: this.getValueRiskPerAssetAggregate(item)
      }
    })
  }

  getValueRiskPerAssetAggregate (aggregateItem) {
    const items = aggregateItem.PerCashflowItems ?? []

    return items.map(item => {
      return {
        name : item.Asset ?? '',
        value: item.ValueAtRisk ?? ''
      }
    })
  }

  getValueAtRiskBarChart () {
    const o = this.getPercentageTotalRiskPerAsset()

    if (o) {
      return {
        Bar: [{
          x: o.map(item => item.name),
          y: o.map(item => item.value)
        }],
        Value   : this.getValueAtRiskValue(),
        PerAsset: this.getValueRiskPerAsset()
      }
    }

    return {
      Bar     : [],
      Value   : '',
      PerAsset: []
    }
  }

  getFinancialExposureBarChart () {
    const o = this.getPercentageFinancialExposurePerAsset()

    if (o) {
      return {
        Bar: [{
          x: o.map(item => item.name),
          y: o.map(item => item.value)
        }],
        Value   : this.getFinancialExposureValue(),
        PerAsset: this.getFinancialExposurePerAsset()
      }
    }

    return {
      Bar     : [],
      Value   : '',
      PerAsset: []
    }
  }

  getFinancialExposurePerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name          : item.Asset ?? '',
        value         : item.Exposure ?? '',
        icon          : item.AggregateItem ? 'mdi-plus-circle' : 'mdi-checkbox-blank-circle',
        iconExpanded  : 'mdi-minus-circle-outline',
        aggregate     : item.AggregateItem ?? false,
        aggregateItems: this.getFinancialExposurePerAssetAggregate(item)
      }
    })
  }

  getFinancialExposurePerAssetAggregate (aggregateItem) {
    const items = aggregateItem.PerCashflowItems ?? []

    return items.map(item => {
      return {
        name : item.Asset ?? '',
        value: item.Exposure ?? ''
      }
    })
  }

  getPercentageFinancialExposurePerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name : item.Asset ?? '',
        value: item.ExposurePercent ?? ''
      }
    })
  }

  getVolatilityPerAsset () {
    return this.getItemsWithoutPortfolio().map(item => {
      return {
        name : item.Asset ?? '',
        value: item.Volatility ?? ''
      }
    })
  }

  getPortfolioPriceValue (valueAtRisk = true) {
    const v = clone(this.Items)

    if (v) {
      const index = v.findIndex(item => item.Asset === i18n.t('Common.Asset.Portfolio'))
      const portfolio = v[index] ?? null

      if (portfolio) {
        return valueAtRisk ? (portfolio?.ValueAtRisk || '') : (portfolio?.Exposure || '')
      }
    }

    return ''
  }

  getValueAtRiskValue () {
    return this.getPortfolioPriceValue()
  }

  getFinancialExposureValue () {
    return this.getPortfolioPriceValue(false)
  }
}
