<template>
  <v-row>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="550px"
      persistent
      scrollable
      transition="slide-x-reverse-transition"
    >
      <v-card min-height="100vh">
        <div class="">
          <v-toolbar
            color="section-bg-gradient-yellow"
            flat
          >
            <v-btn
              v-if="selectedItem"
              class="pa-2"
              text
              @click="onBackToListClick"
            >
              <v-icon left>
                mdi-arrow-left
              </v-icon>
              {{ $t('Common.Button.Back') }}
            </v-btn>

            <v-spacer />

            <v-btn
              :disabled="isFormSaving"
              icon
              @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="px-5">
            <template v-if="!selectedItem">
              <v-icon
                color="orange"
                size="24"
              >
                mdi-poll
              </v-icon>

              <div class="my-4">
                <div class="synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-medium">
                  {{ $t(`${ componentLang }.AddCurrentStrategy.Title`) }}
                </div>
                <div class="synthesis-text text-size-14 synthesis-ui-grey-01--text">
                  {{ $t(`${ componentLang }.AddCurrentStrategy.Subtitle`) }}
                </div>
              </div>
            </template>

            <template v-else>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium">
                      {{ selectedItem.asset.displayName }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium">
                      {{ selectedItem.baseCurrency.displayName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </div>

          <v-divider />
        </div>

        <v-card-text
          ref="formContainer"
          :class="[{'pa-0': !selectedItem}]"
          class="pt-5"
          style="overflow-y: scroll;"
        >
          <v-form
            ref="form"
            v-model="isFormValid"
            @submit.prevent
          >
            <template v-if="!selectedItem">
              <v-list>
                <template v-for="(item, index) in model.currentAssetStrategy">
                  <v-list-item
                    :key="`list-item-${index}`"
                    class="px-6 py-1"
                    @click="onListItemClick(item)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        class="synthesis-text text-size-16 synthesis-brand-dark-blue--text font-weight-medium"
                      >
                        {{ item.asset.displayName }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="synthesis-text text-size-10 synthesis-ui-grey-01--text font-weight-medium"
                      >
                        {{ item.baseCurrency.displayName }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action-text class="text-right">
                      <v-chip
                        v-if="!item.currentStrategyProvided"
                        class="font-weight-medium"
                        color="synthesis-ui-grey-04 synthesis-ui-grey-01--text"
                        small
                      >
                        {{ $t(`${ componentLang }.AddCurrentStrategy.Message.NoStrategyAdded`) }}
                      </v-chip>

                      <v-chip
                        v-else
                        class="font-weight-medium"
                        color="synthesis-ui-green-07 synthesis-ui-green-00--text"
                        small
                      >
                        {{ item.currentInstrument }} • {{ item.currentHedgeRatio }}%
                      </v-chip>
                    </v-list-item-action-text>

                    <v-list-item-action
                      v-if="model.currentAssetStrategy.some(asset => asset.currentStrategyProvided)"
                      class="ma-0"
                    >
                      <v-btn
                        v-if="item.currentStrategyProvided"
                        icon
                        small
                        @click.stop="clearItem(item)"
                      >
                        <v-icon small>
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider :key="`list-item-divider-${index}`" />
                </template>
              </v-list>
            </template>

            <template v-else>
              <div class="mt-4">
                <v-container
                  fluid
                  pa-0
                >
                  <v-row>
                    <v-col cols="12">
                      <label class="field-label">{{ $t(`${ componentLang }.AddCurrentStrategy.Form.Fields.Instrument.Label`) }}</label>
                      <v-select
                        v-model="selectedItem.currentInstrument"
                        :items="selectedItem.instrumentOptions"
                        :placeholder="$t(`${componentLang}.AddCurrentStrategy.Form.Fields.Instrument.Placeholder`)"
                        flat
                        hide-details="auto"
                        item-text="Label"
                        item-value="Value"
                        outlined
                      />
                    </v-col>

                    <v-col cols="12">
                      <label class="field-label">{{ $t(`${ componentLang }.AddCurrentStrategy.Form.Fields.HedgeRatio.Label`) }}</label>
                      <v-text-field
                        v-model="selectedItem.currentHedgeRatio"
                        v-mask="'###'"
                        :class="{'over-percent-limit-warning': isOverPercentLimit(selectedItem.currentHedgeRatio)}"
                        :placeholder="$t(`${componentLang}.AddCurrentStrategy.Form.Fields.HedgeRatio.Placeholder`)"
                        flat
                        hide-details="auto"
                        outlined
                        suffix="%"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </template>
          </v-form>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-btn
            v-if="selectedItem"
            block
            class="action-button white--text"
            color="synthesis-ui-green-02"
            depressed
            large
            @click="saveAsset"
          >
            {{ $t(`${ componentLang }.AddCurrentStrategy.Form.Button.SaveAsset.Title`) }}
          </v-btn>

          <v-btn
            v-else
            :disabled="isFormSaving || !isModelValid"
            :loading="isFormSaving"
            block
            class="action-button white--text"
            color="synthesis-ui-green-02"
            depressed
            large
            @click="saveFromData"
          >
            {{ $t(`${ componentLang }.AddCurrentStrategy.Form.Button.SaveStrategy.Title`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dialogData                  from '@/mixins/dialog/dialogData'
import dialogVisible               from '@/mixins/dialog/dialogVisible'
import HedgingCurrentStrategyModel from '@/api/models/hedging/HedgingCurrentStrategyModel'
import { clone }                   from '@/lib/utils/helper'
import AssetStrategyInputModel     from '@/api/models/asset/AssetStrategyInputModel'

export default {
  name      : 'AddCurrentStrategyDialog',
  components: {},
  directives: {},
  mixins    : [dialogVisible, dialogData],
  props     : {
    componentLang: {
      type   : String,
      default: 'Hedging'
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: HedgingCurrentStrategyModel,
  data () {
    return {
      selectedItem: null
    }
  },
  computed: {},
  watch   : {
    isVisible (newVal) {
      if (newVal) {
        this.isFormSaving = false
        this.selectedItem = null
      } else {
        this.searchTerms = ''
      }
    }
  },
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    /**
     * onListItemClick
     *
     * @param item {Object}
     */
    onListItemClick (item) {
      this.selectedItem = clone(item)
    },

    /**
     * onBackToListClick
     *
     * Handle back to asset list button click
     */
    onBackToListClick () {
      this.selectedItem = null
    },

    /**
     * saveAsset
     *
     * Locally persist asset
     */
    saveAsset () {
      const itemIndex = this.model.currentAssetStrategy.findIndex(asset => asset.assetCode === this.selectedItem.assetCode)
      this.selectedItem.currentStrategyProvided = !!(this.selectedItem.currentInstrument && this.selectedItem.currentHedgeRatio)
      if (itemIndex > -1) {
        this.$set(this.model.currentAssetStrategy, itemIndex, new AssetStrategyInputModel(this.selectedItem))
      }
      this.onBackToListClick()
    },

    clearItem (asset) {
      if (!asset) return

      asset.currentInstrument = null
      asset.currentHedgeRatio = ''
      asset.currentStrategyProvided = false
    },

    /**
     * saveFromData
     *
     * Locally persist entered exposures
     */
    saveFromData () {
      if (this.isFormSaving) return

      this.isFormSaving = true
      const currentAssetStrategy = this.model.currentAssetStrategy.map(item => item.clone())
      const data = this.model.clone()
      data.currentAssetStrategy = currentAssetStrategy
      this.validateForm() && this.formSaveSuccess(data)
    },

    isOverPercentLimit (val) {
      return parseInt(val) > 100
    }
  }
}
</script>

<style scoped>
/* v-dialog overrides */
.v-dialog__content {
  align-items: start;
  justify-content: end;
  height: 100%;
}

/deep/ .v-dialog {
  border-radius: 4px;
  margin: 0px !important;
  max-height: 100% !important;
}
</style>
