<template>
  <v-card
    :height="310"
    :min-height="310"
    color="synthesis-ui-purple-02"
    flat
  >
    <v-card-title
      class="synthesis-text text-size-32 font-weight-medium synthesis-ui-purple-07--text"
      v-text="$t('Dashboard.Block.FunFacts.Title')"
    />
    <v-window
      v-model="index"
    >
      <v-window-item
        v-for="(v, i) in items"
        :key="`card-fun-facts-${i}`"
      >
        <v-card-text
          ref="fanFacts"
          class="overflow-y-auto synthesis-text text-size-24 font-weight-regular synthesis-brand-white--text pt-0"
          style="height:185px"
        >
          {{ v.Title }}
        </v-card-text>
      </v-window-item>
    </v-window>
    <v-card-actions class="pa-4 pt-2">
      <v-spacer />

      <v-progress-circular
        :rotate="-90"
        :size="30"
        :value="value"
        :width="3"
        color="synthesis-brand-white"
      />
    </v-card-actions>
  </v-card>
</template>

<script>

import API                  from '@/api/Api'
import { clone }            from '@/lib/utils/helper'
import { collect }          from '@/lib/utils/array'
import FunFactsItemResource from '@/api/resources/dashboard/FunFactsItemResource'

export default {
  name      : 'FunFactsBlock',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {
    items: 'Dashboard.FunFacts.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      timeout : 1000,
      interval: null,
      index   : 0,
      value   : 0,
      maxValue: 100
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.getData()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    clearInterval(this.interval)
  },
  destroyed () {},
  methods: {
    getData () {
      API.Resource.Dashboard.FunFacts()
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
        .finally(() => {})
    },
    handleResponse (response) {
      const data = API.responseData(response)?.latestFunFacts || null
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        if (data) {
          const o = clone(data)
          this.items = collect(o, FunFactsItemResource)

          this.$nextTick(() => {
            this.startLoader()
          })
        }
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    startLoader () {
      clearInterval(this.interval)

      const value = (this.timeout / this.maxValue)

      this.interval = setInterval(() => {
        if (this.value >= this.maxValue) {
          this.value = 0

          this.index++
          if (this.index > (this.items.length - 1)) {
            this.index = 0
          }
          if (this.$refs && this.$refs.fanFacts) this.$refs.fanFacts.scrollTop = 0
        } else {
          this.value += value
        }
      }, this.timeout)
    }
  }
}
</script>

<style scoped>

</style>
