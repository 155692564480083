import { UUIDGeneratorBrowser } from '@/lib/utils/browser'
import chartsColorMixin         from '@/theme/default/components/charts/mixins/chartsColorMixin'
import { hexToRGBA }            from '@/lib/utils/color'

export default {
  mixins : [chartsColorMixin],
  methods: {},
  props  : {
    chartRefId: {
      type   : String,
      default: function () { return UUIDGeneratorBrowser() }
    },
    chartColors: {
      type   : Array,
      default: undefined
    },
    fontFamily: {
      type   : String,
      default: 'Inter, Aeonik, Roboto, sans-serif'
    },
    fontSize: {
      type   : Number,
      default: 10
    },
    fontStyle: {
      type   : String,
      default: 'normal'
    },
    fontWeight: {
      type   : [String, Number],
      default: 'normal'
    },
    fontColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-01'] }
    },
    axisLabelFontSize: {
      type   : Number,
      default: 10
    },
    axisLabelFontWeight: {
      type   : [String, Number],
      default: 'normal'
    },
    axisLabelFontColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-01'] }
    },
    legendShow: {
      type   : Boolean,
      default: false
    },
    legendTop: {
      type   : String,
      default: 'auto'
    },
    legendLeft: {
      type   : String,
      default: 'auto'
    },
    legendBottom: {
      type   : String,
      default: 'auto'
    },
    legendRight: {
      type   : String,
      default: 'auto'
    },
    legendOrient: {
      type   : String,
      default: 'horizontal'
    },
    legendAlign: {
      type   : String,
      default: 'auto'
    },
    legendPadding: {
      type   : [Number, Array],
      default: () => [0, 0, 0, 0] // [top, right, bottom, left]
    },
    legendData: {
      type   : Array,
      default: null
    },
    chartAnimation: {
      type   : Boolean,
      default: true
    },
    chartAnimationThreshold: {
      type   : Number,
      default: 2000
    },
    chartAnimationEasing: {
      type   : String,
      default: 'cubicOut'
    },
    chartAnimationDuration: {
      type   : Number,
      default: 2000
    },
    chartGridMargin: {
      type   : [Object, Array],
      default: () => {}
    },
    chartOptions: {
      type   : Object,
      default: () => {}
    },
    chartOptionsUpdated: {
      type   : Object,
      default: () => {}
    },
    chartDataOptionsUpdated: {
      type   : Array,
      default: () => []
    },
    chartDataOptions: {
      type    : Array,
      required: true
    },
    chartHeight: {
      type   : [Number, String],
      default: 300
    },
    axisXTickPrefix: {
      type   : String,
      default: ''
    },
    axisYTickPrefix: {
      type   : String,
      default: ''
    },
    axisXTickSuffix: {
      type   : String,
      default: ''
    },
    axisYTickSuffix: {
      type   : String,
      default: ''
    },
    axisXLabelShow: {
      type   : Boolean,
      default: true
    },
    axisYLabelShow: {
      type   : Boolean,
      default: true
    },
    axisXLabelInterval: {
      type   : [String, Number],
      default: 'auto'
    },
    axisYLabelInterval: {
      type   : [String, Number],
      default: 'auto'
    },
    axisXLabelHideOverlap: {
      type   : Boolean,
      default: true
    },
    axisYLabelHideOverlap: {
      type   : Boolean,
      default: true
    },
    axisYLabelVerticalAlign: {
      type   : String,
      default: 'bottom' // 'top', 'middle', 'bottom'
    },
    axisXLabelMargin: {
      type   : Number,
      default: 8
    },
    axisXLabelPadding: {
      type   : Array,
      default: () => [0, 0, 0, 5] // [top, right, bottom, left]
    },
    axisYLabelMargin: {
      type   : Number,
      default: 0
    },
    axisXLabelAlign: {
      type   : String,
      default: 'center'
    },
    axisXLabelInside: {
      type   : Boolean,
      default: false
    },
    axisYLabelInside: {
      type   : Boolean,
      default: true
    },
    axisXGridLineShow: {
      type   : Boolean,
      default: false
    },
    axisYGridLineShow: {
      type   : Boolean,
      default: false
    },
    chartGridLinesStyleType: {
      type   : String,
      default: 'dashed'
    },
    chartGridLinesOpacity: {
      type   : Number,
      default: 1
    },
    axisXMinValue: {
      type   : [Number, String],
      default: null // 'dataMin'
    },
    axisXMaxValue: {
      type   : [Number, String],
      default: null // 'dataMax'
    },
    axisXBoundaryGap: {
      type   : [Boolean, Array],
      default: false
    },
    axisYMinValue: {
      type   : [Number, String],
      default: null // 'dataMin'
    },
    axisYMaxValue: {
      type   : [Number, String],
      default: null // 'dataMax'
    },
    axisYBoundaryGap: {
      type   : [Boolean, Array],
      default: false
    },
    axisXSplitNumber: {
      type   : Number,
      default: 3
    },
    axisXSplitLineShow: {
      type   : Boolean,
      default: false
    },
    axisXSplitLineType: {
      type   : [String, Array],
      default: 'dashed'
    },
    axisXSplitLineDashOffset: {
      type   : Number,
      default: 4
    },
    axisXSplitLineColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-02'] }
    },
    axisXSplitLineOpacity: {
      type   : Number,
      default: 1
    },
    axisXSplitLineWidth: {
      type   : Number,
      default: 1
    },
    axisXTickColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-02'] }
    },
    axisXTickWidth: {
      type   : Number,
      default: 1
    },
    axisXGridLineStyleColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-02'] }
    },
    axisYSplitNumber: {
      type   : Number,
      default: 3
    },
    axisYSplitLineShow: {
      type   : Boolean,
      default: true
    },
    axisYSplitLineType: {
      type   : [String, Array],
      default: 'dashed'
    },
    axisYSplitLineDashOffset: {
      type   : Number,
      default: 4
    },
    axisYSplitLineColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-02'] }
    },
    axisYSplitLineOpacity: {
      type   : Number,
      default: 1
    },
    axisYSplitLineWidth: {
      type   : Number,
      default: 1
    },
    axisYGridLineStyleColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-02'] }
    },
    axisXLabelValueFormat: {
      type   : String,
      default: 'date' // 'number', 'date', 'percent'
    },
    axisXLabelValueDateFormat: {
      type   : String,
      default: 'MMM YYYY' // https://day.js.org/docs/en/display/format
    },
    axisXLabelValueDateFormatTooltip: {
      type   : String,
      default: 'DD MMM YY' // https://day.js.org/docs/en/display/format
    },
    axisYLabelValueFormat: {
      type   : String,
      default: 'number' // 'number', 'date', 'percent'
    },
    axisYLabelValueDateFormat: {
      type   : String,
      default: 'MMM YYYY' // https://day.js.org/docs/en/display/format
    },
    markLineValue: {
      type   : [String, Number, Array],
      default: ''
    },
    markLineValueLabel: {
      type   : [String, Number],
      default: undefined
    },
    markLineValueLabelFormat: {
      type   : String,
      default: 'number' // 'number', 'date', 'percent'
    },
    markLineHorizontal: {
      type   : Boolean,
      default: true
    },
    markLineLabelShow: {
      type   : Boolean,
      default: false
    },
    graphLineShow: {
      type   : Boolean,
      default: false
    },
    /**
     * It can also be a number or a number array
     * to specify the dash array of the line, with dashOffset.
     */
    markLineLineStyleType: {
      type   : [String, Array],
      default: () => [8, 8] // 'solid', 'dashed', 'dotted'
    },
    markLineLineStyleDashOffset: {
      type   : Number,
      default: 4
    },
    markLineLineStyleWidth: {
      type   : Number,
      default: 1
    },
    markLineLineStyleColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-red-01'] }
    },
    markLineLabelFontFamily: {
      type   : String,
      default: 'Inter, Aeonik, Roboto, sans-serif'
    },
    markLineLabelFontSize: {
      type   : Number,
      default: 10
    },
    markLineLabelFontWeight: {
      type   : [String, Number],
      default: 'normal'
    },
    markLineLabelColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-lightest-blue'] }
    },
    markLineLabelBackgroundColor: {
      type   : String,
      default: function () { return hexToRGBA(this.$vuetify.theme.themes.light['synthesis-ui-red-08'], 0.5) }
    },
    markLineLabelBorderWidth: {
      type   : Number,
      default: 1
    },
    markLineLabelBorderRadius: {
      type   : Number,
      default: 30
    },
    markLineLabelPadding: {
      type   : [Number, Array],
      default: () => [6, 10, 4, 10] // [top, right, bottom, left]
    },
    markLineLabelDistance: {
      type   : [Number, Array],
      default: () => [0, 0] // [horizontal, vertical]
    },
    markLineLabelBorderColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-red-01'] }
    },
    markLineLabelPrefix: {
      type   : String,
      default: ''
    },
    markLineLabelSuffix: {
      type   : String,
      default: ''
    },
    markLineAnimation: {
      type   : Boolean,
      default: true
    },
    markLineAnimationThreshold: {
      type   : Number,
      default: 2000
    },
    markLineAnimationEasing: {
      type   : String,
      default: 'cubicOut'
    },
    markLineAnimationDuration: {
      type   : Number,
      default: 300
    },
    markLineAnimationDurationUpdate: {
      type   : Number,
      default: 300
    },
    tooltipShow: {
      type   : Boolean,
      default: true
    },
    tooltipConfine: {
      type   : Boolean,
      default: false
    },
    tooltipShowContent: {
      type   : Boolean,
      default: true
    },
    tooltipBackgroundColor: {
      type   : String,
      default: function () { return 'synthesis-brand-dark-blue' }
    },
    tooltipBorderColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-dark-blue'] }
    },
    tooltipBorderWidth: {
      type   : Number,
      default: 0
    },
    tooltipPadding: {
      type   : [Number, Array],
      default: () => 12 // [up, right, down, left]
    },
    tooltipLabelColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-white'] }
    },
    axisYShow: {
      type   : Boolean,
      default: true
    },
    axisXShow: {
      type   : Boolean,
      default: true
    },
    axisXType: {
      type   : String,
      default: 'category' // https://echarts.apache.org/en/option.html#xAxis.type
    },
    axisXPointerShow: {
      type   : Boolean,
      default: true
    },
    axisYPointerShow: {
      type   : Boolean,
      default: false
    },
    axisPointerLineStyleType: {
      type   : String,
      default: 'dashed'
    },
    axisPointerLineDashOffset: {
      type   : Number,
      default: 4
    },
    axisPointerLineStyleWidth: {
      type   : Number,
      default: 0.5
    },
    axisPointerLineStyleOpacity: {
      type   : Number,
      default: 1
    },
    axisPointerLineStyleColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-dark-blue'] }
    },
    axisPointerLabelFontSize: {
      type   : Number,
      default: 11
    },
    axisPointerLabelFontColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-lightest-blue'] }
    },
    axisPointerLabelBackgroundColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-dark-blue'] }
    },
    axisXPointerLabelShow: {
      type   : Boolean,
      default: false
    },
    axisYPointerLabelShow: {
      type   : Boolean,
      default: false
    },
    cursor: {
      type   : String,
      default: 'default'
    },
    silent: {
      type   : Boolean,
      default: true
    },
    defaultColors: {
      type   : Array,
      default: undefined
    },
    labelShow: {
      type   : Boolean,
      default: false
    },
    labelPosition: {
      type   : [String, Array],
      default: 'bottom'
    },
    labelLineShow: {
      type   : Boolean,
      default: false
    },
    labelPieAsset: {
      type   : Boolean,
      default: true
    },
    pieAssetValueFontFamily: {
      type   : String,
      default: 'Inter, Aeonik, Roboto, sans-serif'
    },
    pieAssetValueFontColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-01'] }
    },
    pieAssetValueFontSize: {
      type   : Number,
      default: 13
    },
    pieAssetValueFontStyle: {
      type   : String,
      default: 'normal'
    },
    pieAssetValueFontWeight: {
      type   : [String, Number],
      default: 600
    },
    pieAssetValueAlign: {
      type   : String,
      default: 'left'
    }
  }
}
