import commonEChartLineProps from '@/theme/default/components/charts/echarts/mixins/common/commonEChartLineProps'
import { hexToRGBA }         from '@/lib/utils/color'

export default {
  mixins: [commonEChartLineProps],
  props : {
    chartAreaFillColor: {
      type   : Array,
      default: function () {
        return [hexToRGBA(this.$vuetify.theme.themes.light['synthesis-ui-blue-04'], 0.4), hexToRGBA(this.$vuetify.theme.themes.light['synthesis-ui-blue-04'], 0.03)]
      }
    }
  }
}
