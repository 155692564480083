import BaseResource from '@/lib/data/resource/BaseResource'

export default class AnalyticsSettingsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.enabled = payload?.enabled ?? false
    this.bsEnabled = payload?.bsEnabled ?? false
    this.bsId = payload?.bsId ?? ''
    this.gaEnabled = payload?.gaEnabled ?? false
    this.gaId = payload?.gaId ?? ''
    this.hjEnabled = payload?.hjEnabled ?? false
    this.hjId = payload?.hjId ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
