import BaseResource               from '@/lib/data/resource/BaseResource'
import { collect }                from '@/lib/utils/array'
import SurveyAnswerEnum           from '@/api/enums/SurveyAnswerEnum'
import SurveyResponseRateResource from '@/api/resources/dashboard/SurveyResponseRateResource'

export default class SurveyItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Id = payload?.survey?.id ?? ''
    this.Title = payload?.survey?.question ?? ''
    this.ResponseRates = payload?.responseRates ? collect(payload.responseRates, SurveyResponseRateResource) : []
  }

  /* PROPERTIES */
  get Result () {
    const result = ['', '', '']

    if (this.ResponseRates && !!this.ResponseRates.length) {
      this.ResponseRates.forEach(item => {
        if (item.Response === SurveyAnswerEnum.Yes) {
          result[0] = {
            value: item.Value,
            name : SurveyAnswerEnum.Yes
          }
        }
        if (item.Response === SurveyAnswerEnum.No) {
          result[1] = {
            value: item.Value,
            name : SurveyAnswerEnum.No
          }
        }
        if (item.Response === SurveyAnswerEnum.Unsure) {
          result[2] = {
            value: item.Value,
            name : SurveyAnswerEnum.Unsure
          }
        }
      })
    } else {
      return []
    }

    return result
  }

  /* METHODS */
}
