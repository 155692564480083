import BaseResource                      from '@/lib/data/resource/BaseResource'
import { toNumberFormatNotationShort }   from '@/lib/utils/helper'
import i18n                              from '@/lang/lang'
import { collect }                       from '@/lib/utils/array'
import RiskSummaryAssetAggregateResource from '@/api/resources/risks/RiskSummaryAssetAggregateResource'
import AssetCodeNameResource             from '@/api/resources/asset/AssetCodeNameResource'

export default class RiskSummaryAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Asset = payload.itemName ? (payload.itemName === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.itemName) : ''

    this.Exposure = payload.exposure ? toNumberFormatNotationShort(payload.exposure) : ''
    this.ExposureRaw = payload.exposure ?? ''

    this.ValueAtRisk = payload.valueAtRisk ? toNumberFormatNotationShort(payload.valueAtRisk) : ''
    this.ValueAtRiskRaw = payload.valueAtRisk ?? ''

    this.Historical = payload.historicalRisk ? toNumberFormatNotationShort(payload.historicalRisk) : ''
    this.HistoricalRaw = payload.historicalRisk ?? ''

    this.ExtremeEvent = payload.eventsRisk ? toNumberFormatNotationShort(payload.eventsRisk) : ''
    this.ExtremeEventRaw = payload.eventsRisk ?? ''

    this.Simulation = payload.simulationRisk ? toNumberFormatNotationShort(payload.simulationRisk) : ''
    this.SimulationRaw = payload.simulationRisk ?? ''

    this.AnnualRisk = payload.annualRisk ? toNumberFormatNotationShort(payload.annualRisk) : ''
    this.AnnualRiskRaw = payload.annualRisk ?? ''

    this.AggregateItem = payload?.aggregateItem || false
    this.PerCashflowCodes = payload?.perCashflowCodes || []
    this.PerCashflowItems = collect(payload?.perCashflowItems || [], RiskSummaryAssetAggregateResource)

    this.PerCashflowNames = payload.perCashflowNames ?? []
    this.PerCashflowCodeNames = collect(payload?.perCashflowCodeNames || [], AssetCodeNameResource)

    this.Icon = this.AggregateItem ? 'mdi-plus-circle' : 'mdi-checkbox-blank-circle'
    this.IconExpanded = 'mdi-minus-circle-outline'
  }

  /* PROPERTIES */

  /* METHODS */
}
