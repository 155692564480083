import BaseModel         from '@/lib/data/model/BaseModel'
import RiskDebtAmortisationValidator from '@/api/validations/risks/RiskDebtAmortisationValidator'
import RiskDebtAmortisationResource  from '@/api/resources/risks/RiskDebtAmortisationResource'

export default class RiskDebtAmortisationModel extends BaseModel {
  constructor (payload = {}, resource = RiskDebtAmortisationResource, validator = RiskDebtAmortisationValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = []
  }

  /* PROPERTIES */

  /* METHODS */
}
