<template>
  <v-radio-group
    v-model="model"
    :disabled="disabled"
    :readonly="readonly"
  >
    <v-row class="ma-0">
      <template v-for="(item, i) in $t('Common.Seasonality.Items')">
        <v-col
          :key="`seasonality-switch-${i})`"
          class="pa-1"
          cols="12"
          sm="4"
        >
          <v-card
            class="pa-0 ma-0"
            flat
          >
            <v-radio
              :key="`seasonality-radio-button-${i})`"
              :style="`border: ${themeColorRGBA('synthesis-ui-grey-02')} 1px solid;border-radius: ${CommonEnum.Components.SeasonalitySwitch.BorderRadius}px !important;`"
              :value="item.Value"
              class="ma-0 pa-0 v-input--reverse v-input--expand pa-2 mb-2 rounded d-flex synthesis-ui-grey-01--text"
              color="synthesis-ui-blue-04"
            >
              <template #label>
                {{ item.Title }}
                <infotip
                  v-if="item.InfoTip"
                  icon-style="margin-top: -16px"
                >
                  {{ item.InfoTip }}
                </infotip>
              </template>
            </v-radio>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-radio-group>
</template>

<script>

import chartsColorMixin from '@/theme/default/components/charts/mixins/chartsColorMixin'
import CommonEnum       from '@/api/enums/CommonEnum'
import Infotip          from '@/theme/default/components/common/Infotip'

export default {
  name      : 'SeasonalityRadio',
  components: { Infotip },
  directives: {},
  mixins    : [chartsColorMixin],
  props     : {
    selected: {
      type   : String,
      default: 'user'
    },
    readonly: {
      type   : Boolean,
      default: false
    },
    disabled: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : { CommonEnum },
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    model: {
      get () {
        return this.selected
      },
      set (val) {
        this.$emit('update:selected', val)
      }
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>
/deep/ .v-input--expand {
  padding: 12px !important;
}

/deep/ .v-input--reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
}

/deep/ .v-input--reverse .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 8px;
}

/deep/ .v-input--reverse .v-input--selection-controls__input {
  margin-left: 0;
  margin-right: 8px;
}

/deep/ .v-input--expand .v-label {
  display: block;
  flex: 1;
}
</style>
