<script>
import BaseEChartLine                                                       from '@/theme/default/components/charts/echarts/BaseEChartLine'
import baseEChartLineAreaProps                                              from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaProps'
import baseEChartLineAreaData                                               from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaData'
import { first, last }                                                      from '@/lib/utils/array'
import { toDecimalMark, toDecimalSignificant, toNumberFormatNotationShort } from '@/lib/utils/helper'
import dayjs                                                                from 'dayjs'
import baseEChartColors                                                     from '@/theme/default/components/charts/echarts/mixins/common/baseEChartColors'
import chartsColorMixin                                                     from '@/theme/default/components/charts/mixins/chartsColorMixin'

import { use }            from 'echarts/core'
import { BarChart }       from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import baseEChartBarProps from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarProps'
import baseEChartBarData  from '@/theme/default/components/charts/echarts/mixins/bar/baseEChartBarData'

use([
  BarChart,
  CanvasRenderer
])

export default {
  name    : 'EChartHedgingHistogram',
  extends : BaseEChartLine,
  mixins  : [baseEChartColors, chartsColorMixin, baseEChartLineAreaProps, baseEChartLineAreaData, baseEChartBarProps, baseEChartBarData],
  props   : {},
  computed: {
    getChartGridMargin () {
      return this.chartGridMargin || {
        left        : 0,
        top         : 0,
        right       : 0,
        bottom      : 0,
        containLabel: true
      }
    },

    setChartDataOptions () {
      return this.getChartDataOptions
    },

    getChartDataOptions () {
      const o = this.series

      return {
        animation              : this.chartAnimation,
        animationThreshold     : this.chartAnimationThreshold,
        animationDuration      : this.chartAnimationDuration,
        animationEasing        : this.chartAnimationEasing,
        animationDurationUpdate: this.chartAnimationDuration,
        animationEasingUpdate  : this.chartAnimationEasing,
        tooltip                : { show: false },
        legend                 : { show: false },
        textStyle              : {
          fontFamily: this.fontFamily,
          fontSize  : this.fontSize,
          color     : this.fontColor,
          fontStyle : this.fontStyle,
          fontWeight: this.fontWeight
        },
        grid : this.getChartGridMargin,
        xAxis: {
          data     : o.xAxis,
          type     : 'category',
          axisLabel: {
            visible   : true,
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisXLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }
              if (this.axisXLabelValueFormat === 'percent') {
                value = toDecimalMark(parseFloat(value))
              }
              if (this.axisXLabelValueFormat === 'date') {
                value = dayjs(value).format(this.axisXLabelValueDateFormat)
              }

              return (this.axisXTickPrefix + ' ' || '') + value + (this.axisXTickSuffix || '')
            },
            align      : 'center',
            inside     : this.axisXLabelInside,
            margin     : this.axisXLabelMargin,
            padding    : [0, 0, 0, 0], // [top, right, bottom, left]
            interval   : this.axisXLabelInterval,
            hideOverlap: this.axisXLabelHideOverlap
          },
          axisLine: {
            show     : this.axisXGridLineShow,
            lineStyle: {
              color: this.axisXGridLineStyleColor
            }
          },
          axisTick   : { show: false },
          splitLine  : { show: false },
          min        : this.axisXMinValue,
          max        : this.axisXMaxValue,
          boundaryGap: this.axisXBoundaryGap
        },
        yAxis: {
          type     : 'value',
          scale    : this.chartScale,
          axisLabel: {
            visible   : true,
            inside    : this.axisYLabelInside,
            margin    : this.axisYLabelMargin,
            padding   : [0, 0, 0, 0], // [top, right, bottom, left]
            fontFamily: this.fontFamily,
            fontSize  : this.axisLabelFontSize,
            color     : this.axisLabelFontColor,
            formatter : value => {
              if (this.axisYLabelValueFormat === 'number') {
                value = toNumberFormatNotationShort(parseFloat(value))
              }
              if (this.axisYLabelValueFormat === 'percent') {
                value = (value === 0 ? value : toDecimalSignificant(parseFloat(value)))
              }
              if (this.axisYLabelValueFormat === 'date') {
                value = dayjs(value).format(this.axisYLabelValueDateFormat)
              }

              return (this.axisYTickPrefix + ' ' || '') + value + (this.axisYTickSuffix || '')
            },
            interval     : this.axisYLabelInterval,
            hideOverlap  : this.axisYLabelHideOverlap,
            verticalAlign: this.axisYLabelVerticalAlign
          },
          axisLine: {
            onZero   : false,
            show     : this.axisYGridLineShow,
            lineStyle: {
              color: this.axisYGridLineStyleColor
            }
          },
          axisTick : { show: false },
          splitLine: {
            show     : this.axisYSplitLineShow,
            lineStyle: {
              type   : this.axisYSplitLineType,
              color  : this.axisYSplitLineColor,
              opacity: this.axisYSplitLineOpacity,
              width  : this.axisYSplitLineWidth
            }
          },
          splitNumber: this.axisYSplitNumber
        },
        series: o.series
      }
    },

    series () {
      let o = {
        x: [],
        y: []
      }
      let o2 = {
        x: [],
        y: []
      }

      if (this.chartDataOptions.length) {
        o = first(this.chartDataOptions)
        o2 = last(this.chartDataOptions)
      }

      const series = [
        {
          type      : 'line',
          smooth    : 0.6,
          showSymbol: false,
          lineStyle : {
            opacity: 0
          },
          areaStyle: {
            color: this.themeColorRGBA('synthesis-ui-grey-02', 0.8)
          },
          silent: this.silent,
          data  : o.y
        },
        {
          z        : 10,
          type     : 'bar',
          barWidth : 2,
          // barCategoryGap: '100%',
          stack    : 'hedged',
          label    : { show: false },
          itemStyle: {
            // (clockwise upper left, upper right, bottom right and bottom left)
            borderRadius: [this.barBorderRadius, this.barBorderRadius, 0, 0],
            color       : this.themeColorRGBA('synthesis-ui-purple-02', 0.8)
          },
          animationDelay: function (idx) {
            return idx * 10
          },
          silent: this.silent,
          data  : o2.y
        }
      ]

      return {
        xAxis : o.x,
        series: series
      }
    }
  }
}
</script>
