<template>
  <table-header-gradient-blue
    v-if="tableValues"
    :export-data="exportData"
    :export-file-name="exportFileName"
    :title="title"
    :tooltip="$t('Hedging.Table.Costs.Titles.EstimatedBankFees.InfoTip')"
  >
    <template #table>
      <v-data-table
        :headers="table.headers"
        :items="tableValues"
        class="font-weight-medium synthesis-card-border"
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template #item.Maturity="{item}">
          <span :class="`${showOverlay ? 'text-blur' : ''}`">{{ item.Maturity }}</span>
        </template>

        <template #item.ForwardFees="{item}">
          <span :class="`${showOverlay ? 'text-blur' : ''}`">{{ item.ForwardFeesFmt }}</span>
        </template>

        <template #item.OptionPremiumFees="{item}">
          <span :class="`${showOverlay ? 'text-blur' : ''}`">{{ item.OptionPremiumFeesFmt }}</span>
        </template>

        <template #item.CollarFees="{item}">
          <span :class="`${showOverlay ? 'text-blur' : ''}`">{{ item.CollarFeesFmt }}</span>
        </template>
      </v-data-table>
    </template>
    <template
      v-if="showOverlay"
      #toolbarActions
    >
      <button-unlock
        :title="$t('Hedging.Table.Costs.Titles.EstimatedBankFees.Title')"
        @button-unlock-click="$router.push({name: 'SynthesisSupport', params: {item: supportModel}})"
      />
    </template>
  </table-header-gradient-blue>
</template>

<script>

import policyId                   from '@/theme/default/views/hedging/mixins/policyId'
import TableHeaderGradientBlue    from '@/theme/default/components/common/TableHeaderGradientBlue'
import { toPascalCase }           from '@/lib/utils/string'
import ButtonUnlock               from '@/theme/default/components/common/ButtonUnlock'
import SynthesisSupportInputModel from '@/api/models/help/SynthesisSupportInputModel'

export default {
  name      : 'HedgingCostsEstimatedBankFeesTableBlock',
  components: {
    ButtonUnlock,
    TableHeaderGradientBlue
  },
  mixins: [policyId],
  props : {
    selectedAsset: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    hedgingCostsData: 'Hedging.Costs.Data'
  },
  data () {
    return {
      table: {
        headers: [
          {
            text : this.$t('Hedging.Table.Costs.Headers.Maturity'),
            align: 'start',
            value: 'Maturity',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Costs.Headers.ForwardFees'),
            value: 'ForwardFees',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Costs.Headers.OptionPremiumFees'),
            value: 'OptionPremiumFees',
            class: 'grey lighten-5'
          },
          {
            text : this.$t('Hedging.Table.Costs.Headers.CollarFees'),
            value: 'CollarFees',
            class: 'grey lighten-5'
          }
        ]
      }
    }
  },
  computed: {
    title () {
      return this.$t('Hedging.Table.Costs.Titles.EstimatedBankFees.Title')
    },

    asset () {
      return this.selectedAsset || ''
    },

    costs () {
      return this.hedgingCostsData[this.id] ?? null
    },

    assets () {
      return this.costs?.Assets || []
    },

    showOverlay () {
      return this.assetCost?.IsStandard ?? true
    },

    assetName () {
      const asset = this.assets.find(item => item.Value === this.asset) || null

      return asset?.Title || ''
    },

    assetCost () {
      const v = this.costs?.Costs

      if (v && !!v.length) {
        return v.find(item => item.AssetValue === this.asset) || null
      }
      return null
    },

    tableValues () {
      return this.assetCost?.EstimatedBankFees || []
    },

    exportData () {
      return (!this.showOverlay && this.assetCost?.ExportEstimatedBankFeesData) || []
    },

    exportFileName () {
      const asset = this.assetName.toLocaleUpperCase(this.$i18n.locale)
      const title = toPascalCase(this.title)

      return `${ asset }-${ title }`
    },

    supportModel () {
      return new SynthesisSupportInputModel({
        email  : (this.user?.email ?? ''),
        subject: this.$t('Common.Infotip.Unlock', { title: this.$t('Hedging.Table.Costs.Titles.EstimatedBankFees.Title') })
      })
    }
  }
}
</script>

<style scoped>
.text-blur {
  filter: blur(4px);
}
</style>
