<script>
import BaseEChartLine          from '@/theme/default/components/charts/echarts/BaseEChartLine'
import baseEChartLineAreaProps from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaProps'
import baseEChartLineAreaData  from '@/theme/default/components/charts/echarts/mixins/lineArea/baseEChartLineAreaData'
import { graphic }             from 'echarts/core'

export default {
  name    : 'BaseEChartLineArea',
  extends : BaseEChartLine,
  mixins  : [baseEChartLineAreaProps, baseEChartLineAreaData],
  computed: {
    getChartOptions () {
      const options = this.getChartDataOptions

      let chartAreaFillColor = this.chartAreaFillColor

      if (this.chartAreaFillColor?.length && this.chartAreaFillColor?.length < 2) {
        chartAreaFillColor = [this.chartAreaFillColor[0], '']
      }

      options.series[0].areaStyle = {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color : chartAreaFillColor[0]
          },
          {
            offset: 0.9,
            color : chartAreaFillColor[1]
          },
          {
            offset: 1,
            color : chartAreaFillColor[1]
          }
        ])
      }

      options.series[0].universalTransition = true
      options.series[0].animationDurationUpdate = 800
      options.series[0].animationEasingUpdate = 'exponentialOut'

      return options
    }

  }
}
</script>
