<template>
  <v-col
    ref="extremeEvents"
    cols="12"
  >
    <v-row>
      <v-col
        v-if="coreEventsFirstItem"
        cols="12"
        lg="6"
        md="6"
      >
        <block-item-card
          :base-currency-symbol="riskBaseCurrencyDisplayName"
          :export-element="elementExtremeEvents"
          :export-file-name="$t('Risks.Tab.Summary.Tab.ExtremeEvent.Title')"
          :min-height="150"
          :title="coreEventsFirstItem.Name"
          :title-large="false"
          :title-value="coreEventsFirstItem.PortfolioLoss"
          :title-value-color-green="coreEventsFirstItem.PortfolioChangePositive"
          :title-value-color-red="coreEventsFirstItem.PortfolioChangeNegative"
          :tooltip="$t('Risks.Section.ExtremeEvents.InfoTip')"
          @export-to-image="onExportToImageClick"
          @export-to-image-completed="onExportToImageCompleted"
        >
          <v-lazy
            :options="{threshold: .5}"
            :value="disableLazy"
            transition="fade-transition"
          >
            <base-e-chart-line-area
              :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-blue-06')"
              :axis-pointer-line-style-width="2"
              :axis-x-label-interval="30"
              :axis-x-max-value="coreEventsFirstItem.EndDate"
              :axis-x-min-value="coreEventsFirstItem.StartDate"
              :axis-x-split-number="2"
              :axis-y-label-inside="false"
              :axis-y-label-margin="5"
              :axis-y-split-number="1"
              :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
              :chart-animation="chartAnimation"
              :chart-animation-duration="600"
              :chart-data-options="coreEventsFirstItem.ChartData"
              :chart-grid-margin="{left: 20,top: 10,right: 20,bottom: 20,containLabel: true}"
              :chart-height="280"
              :chart-scale="true"
            />
          </v-lazy>

          <v-card-text
            v-if="coreEventsFirstItem.PerAssetRates && coreEventsFirstItem.PerAssetRates.length"
            class="pt-0 pb-2 overflow-y-auto"
            style="max-height: 220px"
          >
            <template
              v-for="(asset, k) in coreEventsFirstItem.PerAssetRates"
            >
              <item-chart-legend
                :key="`extreme-events-per-asset-legend-first-${k}`"
                :background-color="themeColorRGBA(asset.ColorBg)"
                :title="asset.Asset"
                :title-value="asset.PercentageChange"
                :title-value-color="asset.Color"
              />
            </template>
          </v-card-text>
        </block-item-card>
      </v-col>

      <v-col
        v-if="extraEventsFirstItem"
        cols="12"
        lg="6"
        md="6"
      >
        <block-item-card
          :base-currency-symbol="riskBaseCurrencyDisplayName"
          :min-height="150"
          :title="extraEventsFirstItem.Name"
          :title-large="false"
          :title-value="extraEventsFirstItem.PortfolioLoss"
          :title-value-color-green="extraEventsFirstItem.PortfolioChangePositive"
          :title-value-color-red="extraEventsFirstItem.PortfolioChangeNegative"
        >
          <template #title>
            <extreme-event-select
              :key="`extra-event-select-first`"
              v-model="extremeEventSelected[0]"
              :disabled="disabledExtraEventsSelect"
              :items="allSupportedEvents"
              :name="extraEventsFirstItem.Name"
            />
          </template>

          <v-lazy
            :options="{threshold: .5}"
            :value="disableLazy"
            transition="fade-transition"
          >
            <base-e-chart-line-area
              :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-blue-06')"
              :axis-pointer-line-style-width="2"
              :axis-x-label-interval="30"
              :axis-x-max-value="extraEventsFirstItem.EndDate"
              :axis-x-min-value="extraEventsFirstItem.StartDate"
              :axis-x-split-number="2"
              :axis-y-label-inside="false"
              :axis-y-label-margin="5"
              :axis-y-split-number="1"
              :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
              :chart-animation="chartAnimation"
              :chart-animation-duration="600"
              :chart-data-options="extraEventsFirstItem.ChartData"
              :chart-grid-margin="{left: 20,top: 10,right: 20,bottom: 20,containLabel: true}"
              :chart-height="280"
              :chart-scale="true"
            />
          </v-lazy>

          <v-card-text
            v-if="extraEventsFirstItem.PerAssetRates && extraEventsFirstItem.PerAssetRates.length"
            class="pt-0 pb-2 overflow-y-auto"
            style="max-height: 220px"
          >
            <template
              v-for="(asset, k) in extraEventsFirstItem.PerAssetRates"
            >
              <item-chart-legend
                :key="`extra-events-per-asset-legend-${k}`"
                :background-color="themeColorRGBA(asset.ColorBg)"
                :title="asset.Asset"
                :title-value="asset.PercentageChange"
                :title-value-color="asset.Color"
              />
            </template>
          </v-card-text>
        </block-item-card>
      </v-col>
    </v-row>

    <v-row
      v-if="extremeEventSelectedPeriod"
      justify="center"
      align="center"
    >
      <v-col
        v-if="$t('Risks.Section.ExtremeEvents.ExtremePeriod.Buttons.Right')"
        cols="12"
        lg="3"
        md="3"
        :class="$vuetify.breakpoint.mdAndUp ? 'pb-0 text-right' : 'pb-0'"
      >
        <template v-for="(button, b) in $t('Risks.Section.ExtremeEvents.ExtremePeriod.Buttons.Left')">
          <v-row
            :key="`extra-events-button-left-${b}`"
            class="ma-0 d-inline-flex align-content-start"
          >
            <v-col
              class="pa-1"
            >
              <v-btn
                :color="button.Color"
                class="synthesis-text text-size-14 white--text"
                depressed
                height="42"
                :width="buttonPeriodWidth"
                large
                @click="updateExtremeEvent(button)"
              >
                {{ button.Title }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>

      <v-col
        v-if="extremeEventSelectedPeriod"
        cols="12"
        lg="6"
        md="6"
      >
        <block-item-card
          :base-currency-symbol="riskBaseCurrencyDisplayName"
          :export-file-name="$t('Risks.Tab.Summary.Tab.ExtremeEvent.Title')"
          :min-height="150"
          :title="extremeEventSelectedPeriod.Name"
          :title-large="false"
          :title-value="extremeEventSelectedPeriod.PortfolioLoss"
          :title-value-color-green="extremeEventSelectedPeriod.PortfolioChangePositive"
          :title-value-color-red="extremeEventSelectedPeriod.PortfolioChangeNegative"
        >
          <template
            v-if="$vuetify.breakpoint.mdAndUp"
            #actions
          >
            <v-spacer />
            <v-card-title
              v-if="selectedPeriod"
              class="pr-0 text-size-14 font-weight-medium"
              :class="selectedPeriod.Color"
            >
              {{ selectedPeriod.Title }}
            </v-card-title>
          </template>

          <v-lazy
            :options="{threshold: .5}"
            :value="disableLazy"
            transition="fade-transition"
          >
            <base-e-chart-line-area
              :axis-pointer-line-style-color="themeColorRGBA('synthesis-ui-blue-06')"
              :axis-pointer-line-style-width="2"
              :axis-x-label-interval="30"
              :axis-x-max-value="extremeEventSelectedPeriod.EndDate"
              :axis-x-min-value="extremeEventSelectedPeriod.StartDate"
              :axis-x-split-number="2"
              :axis-y-label-inside="false"
              :axis-y-label-margin="5"
              :axis-y-split-number="1"
              :axis-y-tick-prefix="riskBaseCurrencyDisplayName"
              :chart-animation="chartAnimation"
              :chart-animation-duration="600"
              :chart-data-options="extremeEventSelectedPeriod.ChartData"
              :chart-grid-margin="{left: 20,top: 10,right: 20,bottom: 20,containLabel: true}"
              :chart-height="280"
              :chart-scale="true"
            />
          </v-lazy>

          <v-card-text
            v-if="extremeEventSelectedPeriod.PerAssetRates && extremeEventSelectedPeriod.PerAssetRates.length"
            class="pt-0 pb-2 overflow-y-auto"
            style="max-height: 220px"
          >
            <template
              v-for="(asset, k) in extremeEventSelectedPeriod.PerAssetRates"
            >
              <item-chart-legend
                :key="`extreme-events-per-asset-legend-first-${k}`"
                :background-color="themeColorRGBA(asset.ColorBg)"
                :title="asset.Asset"
                :title-value="asset.PercentageChange"
                :title-value-color="asset.Color"
              />
            </template>
          </v-card-text>
        </block-item-card>
      </v-col>
      <v-col
        v-if="$t('Risks.Section.ExtremeEvents.ExtremePeriod.Buttons.Right')"
        cols="12"
        lg="3"
        md="3"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'pt-0'"
      >
        <template v-for="(button, b) in $t('Risks.Section.ExtremeEvents.ExtremePeriod.Buttons.Right')">
          <v-row
            :key="`extra-events-button-right-${b}`"
            class="ma-0 d-inline-flex align-content-start"
          >
            <v-col
              class="pa-1"
            >
              <v-btn
                :color="button.Color"
                class="synthesis-text text-size-14 white--text"
                depressed
                height="42"
                :width="buttonPeriodWidth"
                large
                @click="updateExtremeEvent(button)"
              >
                {{ button.Title }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import chartsColorMixin              from '@/theme/default/components/charts/mixins/chartsColorMixin'
import BaseEChartLineArea            from '@/theme/default/components/charts/echarts/BaseEChartLineArea'
import API                           from '@/api/Api'
import { clone }                     from '@/lib/utils/helper'
import BlockItemCard                 from '@/theme/default/components/common/BlockItemCard'
import ItemChartLegend               from '@/theme/default/components/common/ItemChartLegend'
import riskAnalysisId                from '@/theme/default/views/risks/mixins/riskAnalysisId'
import riskBaseCurrency              from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import RiskExtremeEventsBaseResource from '@/api/resources/risks/RiskExtremeEventsBaseResource'
import ExtremeEventSelect            from '@/theme/default/components/common/ExtremeEventSelect'
import RiskExtremeEventsResource     from '@/api/resources/risks/RiskExtremeEventsResource'
import { first }                     from '@/lib/utils/array'

export default {
  name      : 'MethodFourBlock',
  components: {
    ExtremeEventSelect,
    ItemChartLegend,
    BlockItemCard,
    BaseEChartLineArea
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, riskAnalysisId],
  props     : {},
  dataStore : {
    appToolbarExtended   : 'App.Config.AppToolbarExtended',
    riskExtremeEventsData: 'Risks.ExtremeEvents.Data',
    riskBaseCurrencyData : 'Risks.BaseCurrency.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {
      onInit                    : true,
      elementExtremeEvents      : null,
      hideExtraEventsSelect     : false,
      disableLazy               : false,
      chartAnimation            : true,
      extremeEventSelected      : [],
      extremeEventSelectedPeriod: null,
      selectedPeriod            : null
    }
  },
  computed: {
    buttonPeriodWidth () {
      return this.$vuetify.breakpoint.mdAndUp ? (this.appToolbarExtended ? 200 : (this.$vuetify.breakpoint.xl ? 290 : 210)) : 150
    },

    extremeEvents () {
      return this.riskExtremeEventsData[this.id] || null
    },

    riskAnalysisName () {
      return this.extremeEvents?.Name || ''
    },

    events () {
      return this.extremeEvents?.Events || null
    },

    coreEvents () {
      return this.extremeEvents?.CoreEvents || null
    },

    coreEventsFirstItem () {
      if (this.coreEvents && !!this.coreEvents.length) {
        return first(this.coreEvents)
      }

      return null
    },

    extraEventsFirstItem () {
      if (this.extraEvents && !!this.extraEvents.length) {
        return first(this.extraEvents)
      } else {
        return (this.events && !!this.events.length ? this.events[1] : null)
      }
    },

    extraEvents () {
      return this.extremeEvents?.ExtraEvents || null
    },

    allSupportedEvents () {
      return this.extremeEvents?.AllSupportedEvents || null
    },

    selectedEvents () {
      return this.extremeEvents?.SelectedEvents || null
    },

    visibleExtraEvents () {
      return this.extremeEvents?.Visible || false
    },

    disabledExtraEventsSelect () {
      if (this.hideExtraEventsSelect) {
        return true
      }

      return this.extremeEvents?.Disabled || false
    }
  },
  watch: {
    extremeEventSelected () {
      if (this.onInit) {
        this.onInit = false
        return
      }
      this.updateExtremeEvents()
    }
  },
  beforeCreate () {},
  created () {
    this.getData()
  },
  beforeMount () {},
  mounted () {
    this.$nextTick(() => {
      this.elementExtremeEvents = this.$el
    })
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    updateExtremeEvent (button) {
      const period = button.Value

      const bestValues = this.$t('Risks.Section.ExtremeEvents.ExtremePeriod.BestValues')

      const color = bestValues.includes(period) ? 'synthesis-ui-green-01--text' : 'synthesis-ui-red-02--text'

      this.selectedPeriod = {
        Title: button.Title,
        Color: color
      }

      API.Resource.Risks.CalculateExtremePeriod(this.id, period)
        .then(response => {
          this.handleUpdateExtremeEventResponse(response)
        })
        .catch(e => {
          this.handleUpdateExtremeEventResponse(e.response)
        })
    },
    handleUpdateExtremeEventResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []
      const result = data?.calculateExtremePeriod?.selectedPeriod || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)

        this.extremeEventSelectedPeriod = new RiskExtremeEventsResource(o)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    updateExtremeEvents () {
      API.Resource.Risks.UpdateExtremeEvents(this.id, this.extremeEventSelected)
        .then(response => {
          this.handleUpdateExtremeEventsResponse(response)
        })
        .catch(e => {
          this.handleUpdateExtremeEventsResponse(e.response)
        })
    },
    handleUpdateExtremeEventsResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []
      const result = data?.selectExtremeEvents || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)

        o.id = this.id
        o.name = this.riskAnalysisName

        const v = new RiskExtremeEventsBaseResource(o)

        this.$set(this.riskExtremeEventsData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    getData () {
      API.Resource.Risks.ExtremeEvents(this.id)
        .then(response => {
          this.handleResponse(response)
        })
        .catch(e => {
          this.handleResponse(e.response)
        })
    },
    handleResponse (response) {
      const data = API.responseData(response) || null
      const errors = API.responseErrors(response) || []
      const result = data?.findAnalysis || null

      if (API.isResponseSuccess(response) && result) {
        const o = clone(result)
        const v = new RiskExtremeEventsBaseResource(o)

        if (v) {
          if (v.SelectedEvents && !!v.SelectedEvents.length) {
            const selected = first(v.SelectedEvents)

            if (selected) {
              this.extremeEventSelected = [
                selected.Value
              ]
            }
          }

          if (v.SelectedPeriod) {
            this.extremeEventSelectedPeriod = v.SelectedPeriod
          }
        }

        this.$set(this.riskExtremeEventsData, this.id, v)
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    onExportToImageClick () {
      this.chartAnimation = false
      this.disableLazy = true
      this.hideExtraEventsSelect = true
    },

    onExportToImageCompleted () {
      this.hideExtraEventsSelect = false
    }
  }
}
</script>

<style scoped>

</style>
