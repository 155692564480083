import BaseResource                    from '@/lib/data/resource/BaseResource'
import AssetModel                      from '@/api/models/asset/AssetModel'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'
import i18n                            from '@/lang/lang'

export default class HedgingInstrumentHistogramResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.itemName ?? ''
    this.AssetValue = payload.itemName ? payload.itemName.toLocaleLowerCase(i18n.code) : ''
    this.Asset = new AssetModel(payload.detailedAsset ?? {})
    this.BaseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.BucketEdges = payload.bucketEdges ?? []
    this.UnhedgedFrequencies = payload.unhedgedFrequencies ?? []
    this.UnhedgedPercentiles = payload.unhedgedPercentiles ?? []
    this.Frequencies = payload.frequencies ?? []
    this.Percentiles = payload.percentiles ?? []
    this.UnhedgedRisk = payload.unhedgedRisk ?? ''
    this.UnhedgedRiskFmt = payload.unhedgedRisk ? toNumberFormatNotationShort(payload.unhedgedRisk) : ''
    this.HedgedRisk = payload.hedgedRisk ?? ''
    this.HedgedRiskFmt = payload.hedgedRisk ? toNumberFormatNotationShort(payload.hedgedRisk) : ''
    this.PotentialSaving = payload.potentialSaving ?? ''
    this.PotentialSavingFmt = payload.potentialSaving ? toNumberFormatNotationShort(payload.potentialSaving) : ''
    this.DistributionType = payload.distributionType ?? ''
    this.Min = payload.min ?? ''
    this.MinFmt = payload.min ? toNumberFormatNotationShort(payload.min) : ''
    this.Max = payload.max ?? ''
    this.MaxFmt = payload.max ? toNumberFormatNotationShort(payload.max) : ''
    this.Mean = payload.mean ?? ''
    this.MeanFmt = payload.mean ? toNumberFormatNotationShort(payload.mean) : ''

    this.MeanBucketIndex = payload.meanBucketIndex ?? ''
    this.UnhedgedMeanBucketIndex = payload.unhedgedMeanBucketIndex ?? ''

    this.OnePercentileBucketIndex = payload.onePercentileBucketIndex ?? ''
    this.UnhedgedOnePercentileBucketIndex = payload.unhedgedOnePercentileBucketIndex ?? ''
  }

  /* PROPERTIES */
  get BaseCurrencyDisplayName () {
    return this.BaseCurrency.displaySymbol ?? ''
  }

  get ChartValues () {
    return [
      {
        x                       : this.BucketEdges,
        y                       : this.UnhedgedFrequencies,
        meanBucketIndex         : this.UnhedgedMeanBucketIndex,
        onePercentileBucketIndex: this.UnhedgedOnePercentileBucketIndex
      },
      {
        x                       : this.BucketEdges,
        y                       : this.Frequencies,
        meanBucketIndex         : this.MeanBucketIndex,
        onePercentileBucketIndex: this.OnePercentileBucketIndex
      }
    ]
  }

  /* METHODS */
}
