<template>
  <v-row>
    <v-col>
      <slot name="toolbar">
        <v-skeleton-loader
          v-if="hasSkeleton ? loading : false"
          :height="35"
          :loading="loading"
          :width="300"
          type="heading"
        />
        <v-toolbar
          v-if="hasSkeleton ? !loading : title"
          color="transparent"
          dense
          flat
        >
          <v-toolbar-title
            class="pl-1 synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text fill-width"
          >
            {{ title }}
            <slot name="tooltip">
              <infotip
                v-if="tooltip"
                icon-style="margin-top: -16px"
              >
                {{ tooltip }}
              </infotip>
            </slot>
          </v-toolbar-title>
          <slot name="toolbarActions">
            <template v-if="exportData">
              <v-spacer />
              <div>
                <export-to-csv
                  :export-data="exportData"
                  :export-file-name="exportFileName || title"
                  :text="$t('Common.Button.Export')"
                  :to-pascal-case-title="false"
                  color="synthesis-ui-blue-04"
                  color-hover="synthesis-ui-blue-03"
                  dark
                  large
                  no-container-padding
                  no-padding
                />
              </div>
            </template>
          </slot>
        </v-toolbar>
      </slot>
      <slot name="table" />
    </v-col>
  </v-row>
</template>

<script>

import Infotip     from '@/theme/default/components/common/Infotip'
import ExportToCsv from '@/theme/default/components/common/ExportToCsv'

export default {
  name      : 'TableHeaderGradientBlue',
  components: {
    ExportToCsv,
    Infotip
  },
  props: {
    title: {
      type   : String,
      default: ''
    },
    tooltip: {
      type   : String,
      default: ''
    },
    loading: {
      type   : Boolean,
      default: false
    },
    hasSkeleton: {
      type   : Boolean,
      default: false
    },
    exportFileName: {
      type   : String,
      default: ''
    },
    exportData: {
      type   : Array,
      default: undefined
    }
  }
}
</script>

<style scoped>
/deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 16px;
  background: linear-gradient(180deg, var(--v-synthesis-ui-blue-02-base) -190.91%, rgba(28, 56, 128, 0) 100%), #FFFFFF;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: thin solid var(--v-synthesis-ui-grey-02-base) !important;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:first-child {
  border-top-left-radius: 8px;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
  border-top-right-radius: 8px;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-of-type(even) {
  background-color: var(--v-synthesis-ui-grey-06-base);
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 8px;
}

/deep/ .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 8px;
}

/deep/ .v-toolbar--dense .v-toolbar__content {
  padding-left: 4px;
}
</style>
