import BaseResource      from '@/lib/data/resource/BaseResource'
import { toDecimalMark } from '@/lib/utils/helper'

export default class HedgingCostsEstimatedBankFeesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Maturity = payload.maturity ?? ''
    this.ForwardFees = payload.forwardFees ?? ''
    this.ForwardFeesFmt = payload.forwardFees ? `${ toDecimalMark(payload.forwardFees) }%` : ''
    this.OptionPremiumFees = payload.optionPremiumFees ?? ''
    this.OptionPremiumFeesFmt = payload.optionPremiumFees ? `${ toDecimalMark(payload.optionPremiumFees) }%` : ''
    this.CollarFees = payload.collarFees ?? ''
    this.CollarFeesFmt = payload.collarFees ? `${ toDecimalMark(payload.collarFees) }%` : ''
  }

  /* PROPERTIES */

  /* METHODS */
}
