<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-tabs
          v-model="activeTab"
          active-class="active-tab"
          background-color="transparent"
          height="30"
          hide-slider
          show-arrows
        >
          <v-tab :href="`#${$t('Risks.Tab.Summary.Slug')}`">
            {{ $t('Risks.Tab.Summary.Title') }}
          </v-tab>
          <v-tab :href="`#${$t('Risks.Tab.Correlations.Slug')}`">
            {{ $t('Risks.Tab.Correlations.Title') }}
          </v-tab>
          <v-tab :href="`#${$t('Risks.Tab.Seasonality.Slug')}`">
            {{ $t('Risks.Tab.Seasonality.Title') }}
          </v-tab>
          <v-tab
            :disabled="userHasStandardSubscriptionTier"
            :href="`#${$t('Risks.Tab.Forecasts.Slug')}`"
          >
            {{ $t('Risks.Tab.Forecasts.Title') }}
          </v-tab>
          <infotip
            v-if="userHasStandardSubscriptionTier"
            icon-class="ml-1"
            icon-style="margin-top: -12px; left: -18px;"
          >
            {{ $t('Risks.Tab.Forecasts.InfoTip') }}
          </infotip>
          <v-tab
            v-if="userHasDebtProfile"
            :href="`#${$t('Risks.Tab.Debt.Slug')}`"
          >
            {{ $t('Risks.Tab.Debt.Title') }}
          </v-tab>
          <v-tab
            v-if="hasForecastFlow"
            :href="`#${$t('Risks.Tab.ForecastFlow.Slug')}`"
          >
            {{ $t('Risks.Tab.ForecastFlow.Title') }}
          </v-tab>
        </v-tabs>
      </v-col>

      <v-col
        v-if="activeTab === $t('Risks.Tab.Correlations.Slug')"
        class="pb-0"
        cols="12"
        md="6"
      >
        <v-container
          ma-0
          pa-0
        >
          <v-row
            class="ma-0"
          >
            <v-col class="text-right">
              <export-to-image
                v-if="elementCorrelations"
                key="export-to-image-correlations"
                :export-element="elementCorrelations"
                :export-file-name="$t('Risks.Tab.Correlations.Title')"
                :text="$t('Common.Button.Export')"
                color="synthesis-ui-blue-04"
                color-hover="synthesis-ui-blue-03"
                dark
                large
                no-container-padding
                no-padding
                @export-to-image="exportToImage"
                @export-to-image-completed="exportToImageCompleted"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        v-if="activeTab === $t('Risks.Tab.Forecasts.Slug')"
        class="pb-0"
        cols="12"
        md="6"
      >
        <v-container
          ma-0
          pa-0
        >
          <v-row
            class="ma-0"
          >
            <v-col class="text-right">
              <export-to-image
                v-if="elementForecasts"
                key="export-to-image-forecasts"
                :export-element="elementForecasts"
                :export-file-name="$t('Risks.Tab.Forecasts.Title')"
                :text="$t('Common.Button.Export')"
                color="synthesis-ui-blue-04"
                color-hover="synthesis-ui-blue-03"
                dark
                large
                no-container-padding
                no-padding
                @export-to-image="exportToImage"
                @export-to-image-completed="exportToImageCompleted"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-tabs-items
          v-model="activeTab"
        >
          <v-tab-item :value="$t('Risks.Tab.Summary.Slug')">
            <risk-summary-block :risk-analysis-id="id" />
          </v-tab-item>

          <v-tab-item
            :class="fitContentWidth"
            :value="$t('Risks.Tab.Correlations.Slug')"
          >
            <risk-correlations-block
              :risk-analysis-id="id"
              @export-element-updated="exportCorrelationsElementUpdated"
            />
          </v-tab-item>

          <v-tab-item :value="$t('Risks.Tab.Seasonality.Slug')">
            <risk-seasonality-block :risk-analysis-id="id" />
          </v-tab-item>

          <v-tab-item
            :value="$t('Risks.Tab.Forecasts.Slug')"
          >
            <risk-forecasts-block
              :chart-animation="chartAnimation"
              :disable-lazy="disableLazy"
              :risk-analysis-id="id"
              @export-element-updated="exportForecastsElementUpdated"
            />
          </v-tab-item>

          <v-tab-item
            :value="$t('Risks.Tab.Debt.Slug')"
          >
            <risk-debt-block :risk-analysis-id="id" />
          </v-tab-item>

          <v-tab-item
            :value="$t('Risks.Tab.ForecastFlow.Slug')"
          >
            <risk-forecast-flow-block :risk-analysis-id="id" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import RiskSummaryBlock      from '@/theme/default/views/risks/blocks/RiskSummaryBlock'
import RiskCorrelationsBlock from '@/theme/default/views/risks/blocks/RiskCorrelationsBlock'
import RiskSeasonalityBlock  from '@/theme/default/views/risks/blocks/RiskSeasonalityBlock'
import RiskForecastsBlock    from '@/theme/default/views/risks/blocks/RiskForecastsBlock'
import riskAnalysisId        from '@/theme/default/views/risks/mixins/riskAnalysisId'
import routerTabState        from '@/mixins/routerTabState'
import ExportToImage         from '@/theme/default/components/common/ExportToImage'
import Infotip               from '@/theme/default/components/common/Infotip'
import RiskDebtBlock         from '@/theme/default/views/risks/blocks/RiskDebtBlock.vue'
import RiskForecastFlowBlock from '@/theme/default/views/risks/blocks/RiskForecastFlowBlock.vue'

export default {
  name      : 'RisksAnalysisShowBase',
  components: {
    RiskForecastFlowBlock,
    RiskDebtBlock,
    Infotip,
    ExportToImage,
    RiskCorrelationsBlock,
    RiskSummaryBlock,
    RiskSeasonalityBlock,
    RiskForecastsBlock
  },
  directives: {},
  mixins    : [riskAnalysisId, routerTabState],
  props     : {},
  dataStore : { riskSummaryData: 'Risks.Summary.Data' },
  enums     : {},
  dataModel : null,
  data () {
    return {
      fitContentWidth    : '',
      elementCorrelations: null,
      elementForecasts   : null,
      chartAnimation     : true,
      disableLazy        : false
    }
  },
  computed: {
    summary () {
      return this.riskSummaryData[this.id] || null
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    exportCorrelationsElementUpdated (params) {
      this.elementCorrelations = params?.el
    },

    exportForecastsElementUpdated (params) {
      this.elementForecasts = params?.el
    },

    exportToImage () {
      this.fitContentWidth = 'fit-content-width'
      this.chartAnimation = false
      this.disableLazy = true
    },

    exportToImageCompleted () {
      this.fitContentWidth = ''
      this.chartAnimation = true
    }
  }
}
</script>

<style scoped>

</style>
