import baseEChartProps from '@/theme/default/components/charts/echarts/mixins/common/baseEChartProps'

export default {
  mixins: [baseEChartProps],
  props : {
    pieTop: {
      type   : [String, Number],
      default: ''
    },
    pieLeft: {
      type   : [String, Number],
      default: ''
    },
    pieRight: {
      type   : [String, Number],
      default: ''
    },
    pieBottom: {
      type   : [String, Number],
      default: ''
    },
    pieWidth: {
      type   : [String, Number],
      default: 'auto'
    },
    pieHeight: {
      type   : [String, Number],
      default: 'auto'
    },
    pieCenter: {
      type   : [String, Number, Array],
      default: () => ['50%', '50%']
    },
    pieRadius: {
      type   : [String, Number, Array],
      default: () => ['50%', '90%']
    },
    pieBorderColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-brand-white'] }
    },
    pieBorderWidth: {
      type   : Number,
      default: 2
    },
    pieBorderRadius: {
      type   : Number,
      default: 4
    },
    pieLegendFontFamily: {
      type   : String,
      default: 'Inter, Aeonik, Roboto, sans-serif'
    },
    pieLegendFontSize: {
      type   : Number,
      default: 13
    },
    pieLegendFontColor: {
      type   : String,
      default: function () { return this.$vuetify.theme.themes.light['synthesis-ui-grey-01'] }
    },
    pieLegendFontStyle: {
      type   : String,
      default: 'normal'
    },
    pieLegendFontWeight: {
      type   : [String, Number],
      default: 600
    }
  }
}
