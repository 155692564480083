<template>
  <v-col
    v-if="triggers && !!triggers.length"
    cols="12"
    md="6"
  >
    <v-toolbar
      color="transparent"
      dense
      flat
    >
      <v-toolbar-title
        class="pl-1 synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text"
        v-text="$t('Alerts.Section.Summary.Triggers.Title')"
      />
    </v-toolbar>
    <v-container
      fluid
      pa-0
    >
      <v-row>
        <template v-for="(item, i) in triggers">
          <trigger-list-card
            :key="`trigger-list-card-${i}`"
            :items="item"
          />
        </template>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>

import alertId         from '@/theme/default/views/alerts/mixins/alertId'
import TriggerListCard from '@/theme/default/components/common/TriggerListCard'

export default {
  name      : 'AlertTriggers',
  components: { TriggerListCard },
  directives: {},
  mixins    : [alertId],
  props     : {},
  dataStore : {
    alertsSummaryData: 'Alerts.Summary.Data'
  },
  data () {
    return {}
  },
  computed: {
    alert () {
      return this.alertsSummaryData[this.id] || null
    },

    triggers () {
      return this.alert?.TriggerItems
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
