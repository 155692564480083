import { API } from '@/api/Api'

import { LatestNewsQuery, NewsQuery } from '@/api/query/News.graphql'

export default {
  // Queries
  async News () {
    const Data = {}

    return await API.GQLRequest(NewsQuery, Data)
  },

  async LatestNews () {
    const Data = {}

    return await API.GQLRequest(LatestNewsQuery, Data)
  }

  // Mutations
}
