<template>
  <v-row v-if="instrumentData">
    <v-col cols="12">
      <instrument-optimal-hedge-ratio-block
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
        :instrument-data="instrumentData"
        :instrument-type="instrumentType"
        :tooltip-show="false"
      />
      <instrument-histogram-block
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
        :instrument-data="instrumentData"
        :instrument-type="instrumentType"
        :policy-id="id"
      />
    </v-col>
  </v-row>
</template>

<script>

import policyId                         from '@/theme/default/views/hedging/mixins/policyId'
import InstrumentChartTypeEnum          from '@/api/enums/InstrumentChartTypeEnum'
import InstrumentHistogramBlock         from '@/theme/default/views/hedging/blocks/instrument/InstrumentHistogramBlock'
import InstrumentOptimalHedgeRatioBlock from '@/theme/default/views/hedging/blocks/instrument/InstrumentOptimalHedgeRatioBlock'
import useExportAdditional              from '@/mixins/useExportAdditional'

export default {
  name      : 'HedgingInstrumentBlock',
  components: {
    InstrumentOptimalHedgeRatioBlock,
    InstrumentHistogramBlock
  },
  directives: {},
  mixins    : [policyId, useExportAdditional],
  props     : {
    instrumentType: {
      type    : String,
      required: true
    }
  },
  dataStore: {
    hedgingInstrument: 'Hedging.Instrument'
  },
  enums: {
    InstrumentChartTypeEnum
  },
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    instrumentData () {
      return this.hedgingInstrument[this.instrumentType].Data[this.id] ?? null
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
