import BaseResource                    from '@/lib/data/resource/BaseResource'
import { collect }                     from '@/lib/utils/array'
import RiskExtremeEventsAssetsResource from '@/api/resources/risks/RiskExtremeEventsAssetsResource'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskExtremeEventsResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.eventName ?? payload.periodName ?? ''
    this.StartDate = payload.startDate ?? ''
    this.EndDate = payload.endDate ?? ''
    this.PortfolioChange = payload.portfolioChange ?? ''

    this.PortfolioLoss = toNumberFormatNotationShort(payload.portfolioLoss ?? '')
    this.PortfolioLossRaw = payload.portfolioLoss ?? ''

    this.PortfolioValues = payload.portfolioValues ?? []
    this.IntermediateDates = payload.intermediateDates ?? []
    this.PerAssetRates = collect(payload.assetRates ?? [], RiskExtremeEventsAssetsResource)
    this.ChartData = [{
      x: this.IntermediateDates,
      y: this.PortfolioValues
    }]
  }

  /* PROPERTIES */
  get PortfolioChangeNegative () {
    return this.PortfolioChange < 0
  }

  get PortfolioChangePositive () {
    return this.PortfolioChange >= 0
  }

  /* METHODS */
}
