import BaseResource    from '@/lib/data/resource/BaseResource'
import { clone }       from '@/lib/utils/helper'
import i18n            from '@/lang/lang'
import { first, last } from '@/lib/utils/array'

export default class RiskDebtMaturitiesResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Dates = payload?.dates ?? []

    this.FloatingValues = payload?.floating ?? []
    this.FixedValues = payload?.fixed ?? []
    this.TotalValues = payload?.total ?? []

    this.Floating = this.getFloating()
    this.Fixed = this.getFixed()
    this.Total = this.getTotal()
    this.TotalStack = this.getTotalValues()
  }

  /* PROPERTIES */

  /* METHODS */
  getFloating () {
    return [{
      x: this.Dates,
      y: this.FloatingValues
    }]
  }

  getFixed () {
    return [{
      x: this.Dates,
      y: this.FixedValues
    }]
  }

  getTotal () {
    return [{
      x: this.Dates,
      y: this.TotalValues
    }]
  }

  getTotalValues () {
    const dates = clone(this.Dates)
    const total = clone(this.TotalValues)

    const series = [{
      xAxis    : dates,
      data     : [],
      portfolio: total
    }]

    if (total && total.length) {
      const labelFloating = i18n.t('Risks.Section.DebtProfile.Block.Maturities.Floating.Title')
      const labelFixed = i18n.t('Risks.Section.DebtProfile.Block.Maturities.Fixed.Title')

      const data = {}
      data[labelFloating] = []
      data[labelFixed] = []

      const floating = clone(this.FloatingValues)
      const fixed = clone(this.FixedValues)

      const dataUp = []
      const dataDown = []

      dates.forEach(item => { dataUp[item] = [] })
      dates.forEach(item => { dataDown[item] = [] })

      dates.forEach((item, index) => {
        const floatingValue = floating[index] ?? 0
        const fixedValue = fixed[index] ?? 0

        data[labelFloating].push(floatingValue)
        data[labelFixed].push(fixedValue)

        if (floatingValue > 0) {
          dataUp[item].push(labelFloating)
        } else {
          dataDown[item].push(labelFloating)
        }

        if (fixedValue > 0) {
          dataUp[item].push(labelFixed)
        } else {
          dataDown[item].push(labelFixed)
        }
      })

      // Asset labels for portfolio
      const labelKeys = []
      for (const [key, value] of Object.entries(dataUp)) {
        if (value.length) {
          labelKeys.push(last(value))
        } else {
          labelKeys.push(first(dataDown[key]))
        }
      }

      series[0].data = data
      series[0].labels = labelKeys
    }

    return series
  }
}
