<template>
  <v-container :class="[{'ma-0': noMargin}, {'pa-0': noContainerPadding}, ExportEnum.NO_EXPORT_CLASS, containerPadding]">
    <v-row :class="[{'ma-0': noMargin}, {'pa-0': noPadding}, rowPadding]">
      <v-col :class="[{'ma-0': noMargin}, {'pa-0': noPadding}, colPadding]">
        <infotip>
          <template #content="{on, attrs}">
            <v-hover v-slot="{hover}">
              <v-btn
                :color="hover ? colorHover : (color)"
                :dark="disable ? false : dark"
                :disabled="disable"
                :height="large ? height: '100%'"
                :icon="text.length ? false : icon"
                :large="large"
                :loading="disable"
                depressed
                v-bind="attrs"
                @click="exportImage"
                v-on="on"
              >
                {{ text }}
                <v-icon :class="[{'pl-2': text.length}]">
                  mdi-tray-arrow-down
                </v-icon>
              </v-btn>
            </v-hover>
          </template>
          {{ $t('Common.Infotip.Export', {title: title}) }}
        </infotip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import dayjs            from 'dayjs'
import domToImage       from 'dom-to-image-more'
import { clone }        from '@/lib/utils/helper'
import { toPascalCase } from '@/lib/utils/string'
import Infotip          from '@/theme/default/components/common/Infotip'
import ExportEnum       from '@/api/enums/ExportEnum'
import useExport        from '@/mixins/useExport'
import { saveAs }       from 'file-saver'

let timeoutId

export default {
  name      : 'ExportToImage',
  components: { Infotip },
  directives: {},
  mixins    : [useExport],
  props     : {
    noMargin: {
      type   : Boolean,
      default: false
    },
    noPadding: {
      type   : Boolean,
      default: false
    },
    noContainerPadding: {
      type   : Boolean,
      default: false
    },
    containerPadding: {
      type   : String,
      default: ''
    },
    rowPadding: {
      type   : String,
      default: ''
    },
    colPadding: {
      type   : String,
      default: ''
    },
    large: {
      type   : Boolean,
      default: false
    },
    dark: {
      type   : Boolean,
      default: false
    },
    icon: {
      type   : Boolean,
      default: true
    },
    color: {
      type   : String,
      default: 'synthesis-ui-grey-02'
    },
    colorHover: {
      type   : String,
      default: 'synthesis-ui-grey-01'
    },
    text: {
      type   : String,
      default: ''
    },
    height: {
      type   : Number,
      default: 42
    }
  },
  enums: { ExportEnum },
  data () {
    return {
      disable: false,
      nodes  : []
    }
  },
  computed: {
    title () {
      return this.exportFileName ? `${ this.exportFileName }` : `${ this.$route.name }`
    },

    fileName () {
      const prefixTitle = toPascalCase(this.$t('Common.App.Title'))
      const dateTime = dayjs().toISOString()

      return `${ prefixTitle }-${ toPascalCase(this.title) }-${ dateTime }`
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {
    timeoutId = null
  },
  methods: {
    filter (node) {
      if (node.className) {
        /**
         * Remove scrollbar and
         * set the full height for screenshot
         */
        if (node.className.includes('overflow-y-auto')) {
          this.nodes.push({
            node : node,
            style: clone(node.style.cssText)
          })

          node.style = 'height: 100%;'
        }

        /**
         * Filter excluded elements by selected class
         */
        return !(node.className.includes(ExportEnum.NO_EXPORT_CLASS))
      }

      return true
    },

    exportImage () {
      /**
       * Disable the button
       * @type {boolean}
       */
      this.disable = true

      this.$emit('export-to-image')

      this.$nextTick(() => {
        if (this.exportElement) {
          clearTimeout(timeoutId)

          timeoutId = setTimeout(() => {
            try {
              const isFileSaverSupported = !!new Blob()

              if (isFileSaverSupported) {
                domToImage.toBlob(this.exportElement, {
                  filter: this.filter
                }).then((blob) => {
                  saveAs(blob, `${ this.fileName }.png`)
                }).finally(() => {
                  this.$emit('export-to-image-completed')

                  /**
                   * Restore height on elements
                   */
                  if (this.nodes && this.nodes.length) {
                    this.nodes.map(item => {
                      item.node.style.cssText = item.style
                    })
                  }

                  /**
                   * Enable button
                   * @type {boolean}
                   */
                  this.disable = false
                })
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error('ExportToImage', e)
            }
          }, 300)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
