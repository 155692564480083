<template>
  <v-row>
    <v-col
      xs="12"
      md="6"
    >
      <forecast-flow-rugl-summary-card
        :title="uglHedgesFlows.label"
        :title-value="uglHedgesFlows.value"
        title-value-color="synthesis-ui-blue-02--text"
        :items="ugl.Values"
      />
    </v-col>
    <v-col
      xs="12"
      md="6"
    >
      <forecast-flow-rugl-summary-card
        :title="rglHedgesFlows.label"
        :title-value="rglHedgesFlows.value"
        title-value-color="synthesis-ui-blue-05--text"
        :items="rgl.Values"
      />
    </v-col>
  </v-row>
</template>

<script>

import riskAnalysisId              from '@/theme/default/views/risks/mixins/riskAnalysisId'
import ForecastFlowRuglSummaryCard from '@/theme/default/views/risks/blocks/forecastFlow/ForecastFlowRuglSummaryCard.vue'

export default {
  name      : 'ForecastFlowRuglSummary',
  components: {
    ForecastFlowRuglSummaryCard
  },
  directives: {},
  mixins    : [riskAnalysisId],
  props     : {},
  dataStore : {
    riskForecastFlowData: 'Risks.ForecastFlow.Data'
  },
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    forecastFlow () {
      return this.riskForecastFlowData[this.id]
    },

    summary () {
      return this.forecastFlow?.RuglSummary || null
    },

    ugl () {
      return this.summary?.Ugl || []
    },

    rgl () {
      return this.summary?.Rgl || []
    },

    uglHedgesFlows () {
      return this.summary?.UglHedgesFlows || []
    },

    rglHedgesFlows () {
      return this.summary?.RglHedgesFlows || []
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>
