import AutoUpdateApiData                               from '@/mixins/api/autoUpdateApiData'
import API                                             from '@/api/Api'
import AlertPriorityEnum, { AlertPriorityEnumHelper }  from '@/api/enums/AlertPriorityEnum'
import SnotifyService                                  from '@/theme/default/components/common/vue-snotify/SnotifyService'
import { SnotifyAction, SnotifyPosition, SnotifyType } from '@/theme/default/components/common/vue-snotify'
import { SnotifyProgressBarPosition }                  from '@/theme/default/components/common/vue-snotify/enums'
import { collect }                                     from '@/lib/utils/array'
import AlertActiveIncidentResource                     from '@/api/resources/alerts/AlertActiveIncidentResource'

export default {
  data: function () {
    return {
      autoUpdateApiDataOptions: {
        timeout  : 60_000,
        autoStart: false
      }
    }
  },

  mixins: [AutoUpdateApiData],

  created () {
    this.$bus.$on('app::show-alert-notification', this.showAlertNotification)
    SnotifyService.$on(SnotifyAction.beforeHide, this.onAlertClose)
    this.startAutoUpdateApiData(this.pollActiveIncidents)
    this.pollActiveIncidents()
  },

  beforeDestroy () {
    this.$bus.$off('app::show-alert-notification', this.showAlertNotification)
    SnotifyService.$off(SnotifyAction.onClick, this.onAlertClose)
    this.$snotify.clear()
  },

  methods: {
    initAlerts (data) {
      data.forEach(alertIncident => {
        if (!this.$dataStore.Alerts.ActiveIncidents.Data.find(incident => incident.incidentId === alertIncident.incidentId)) {
          this.$dataStore.Alerts.ActiveIncidents.Data.push(alertIncident)
        }
      })

      this.$dataStore.Alerts.ActiveIncidents.Data.forEach(alertIncident => {
        if (!alertIncident.shownToUser) {
          alertIncident.shownToUser = true
          this.showAlertNotification(`<strong>'${ alertIncident.alertName }'</strong> ${ alertIncident.message }`, alertIncident, alertIncident.priority)
        }
      })
    },

    onAlertView (toastId, toast) {
      this.$router.push({
        name  : 'AlertShow',
        params: {
          id     : toast.config.dataItem.alertId,
          alertId: toast.config.dataItem.alertId
        }
      })
    },

    onAlertClose (toast) {
      const incidentId = toast.config.dataItem.incidentId
      const alertIncidentIndex = this.$dataStore.Alerts.ActiveIncidents.Data.findIndex(incident => incident.incidentId === incidentId)
      if (alertIncidentIndex > -1) {
        this.$dataStore.Alerts.ActiveIncidents.Data.splice(alertIncidentIndex, 1)
        this.acknowledgeIncident(incidentId)
      }
    },

    pollActiveIncidents () {
      if (!this.user?.selectedAccountId) return

      API.Resource.Alerts.ActiveIncidents(this.user.selectedAccountId)
        .then(response => {
          this.handleActiveIncidentsResponse(response)
        })
        .catch(e => {
          this.handleActiveIncidentsResponse(e.response)
        })
        .finally(() => {})
    },

    handleActiveIncidentsResponse (response) {
      const data = API.responseData(response)?.activeAlertIncidents || {}
      const errors = API.responseErrors(response) || []

      if (API.isResponseSuccess(response)) {
        this.initAlerts(collect(data, AlertActiveIncidentResource))
      } else {
        this.$bus.$emit('app:error', errors)
      }
    },

    acknowledgeIncident (incidentId) {
      API.Resource.Alerts.Acknowledge(incidentId)
        .then(response => {})
        .catch(e => {})
    },

    showAlertNotification (message, dataItem = null, alertType = AlertPriorityEnum.Low) {
      const data = {
        body         : message || '',
        bodyHtml     : true,
        type         : SnotifyType.SIMPLE,
        timeout      : -1,
        icon         : 'mdi-bell',
        iconColor    : `synthesis-ui-${ AlertPriorityEnumHelper.getColor(alertType) }-01`,
        avatarColor  : `synthesis-ui-${ AlertPriorityEnumHelper.getColor(alertType) }-07`,
        dataItem     : dataItem,
        inlineButtons: [
          {
            text  : this.$t('Common.Button.View') + ' ' + 'Alert',
            class : 'button-text-underline synthesis-ui-green-02--text',
            action: this.onAlertView
          }
        ]
      }
      this.showNotification(data)
    },

    showNotification (data) {
      this.$snotify[data.type](data.body, data.title, {
        timeout        : data.timeout ?? 3000,
        showProgressBar: data.showProgressBar ?? true,
        progressBarPos : data.progressBarPos ?? SnotifyProgressBarPosition.Bottom,
        closeOnClick   : data.closeOnClick ?? false,
        hasCloseButton : data.hasCloseButton ?? true,
        pauseOnHover   : data.pauseOnHover ?? true,
        position       : data.position ?? SnotifyPosition.leftBottom,
        buttons        : data.buttons ?? null,
        inlineButtons  : data.inlineButtons ?? null,
        icon           : data.icon ?? null,
        iconColor      : data.iconColor ?? null,
        iconSize       : data.iconSize ?? 35,
        avatar         : data.avatar ?? true,
        avatarColor    : data.avatarColor ?? null,
        dataItem       : data.dataItem ?? null,
        bodyHtml       : data.bodyHtml ?? false
      })
    }
  }
}
