<template>
  <v-container
    class="align-content-start"
    fill-height
    fluid
  >
    <slot>
      <div class="center-vertical-horizontal text-center fill-width pa-2">
        <template v-if="loading">
          <div class="text-center d-inline-block">
            <div class="text-center pa-4">
              <img
                class="animate-logo"
                src="/img/logo/logo-symbol.png"
                width="56"
                alt=""
              >
            </div>

            <div
              class="text-center text-size-16 font-weight-medium synthesis-ui-grey-01--text"
              style="max-width: 300px;"
            >
              {{ $t('Common.Message.Loading.Wait') }}
            </div>
          </div>
        </template>

        <template v-else>
          <v-icon
            v-if="icon"
            :color="noDataIconColor"
            :size="noDataIconSize"
          >
            {{ noDataIcon }}
          </v-icon>

          <div
            :class="[noDataTitleClass, !noDataTitleColor.startsWith('#') && noDataTitleColor]"
            :style="noDataTitleColor.startsWith('#') && noDataTitleColor ? `color: ${noDataTitleColor};`: ''"
            class="synthesis-text text-size-20 font-weight-medium mt-5 "
          >
            {{ noDataTitle }}
          </div>

          <v-btn
            v-if="button"
            :to="buttonTo ? {name: buttonTo} : {}"
            class="white--text mt-5"
            color="synthesis-ui-green-01"
            depressed
            large
          >
            <v-icon left>
              {{ buttonIcon }}
            </v-icon>
            {{ buttonTitle }}
          </v-btn>
        </template>
      </div>

      <div
        v-if="imageBg"
        style="position: absolute; bottom: 0; right: 0;"
      >
        <img
          src="/img/ui/no-data.png"
          style="max-width: 85vw;"
          alt=""
        >
      </div>
    </slot>
  </v-container>
</template>

<script>
export default {
  name      : 'ViewDataContainer',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    icon: {
      type   : Boolean,
      default: false
    },
    noDataIcon: {
      type   : String,
      default: function () {
        return this.$te(`Route.${ this.$route.name }.Icon`) ? this.$t(`Route.${ this.$route.name }.Icon`) : 'mdi-bell'
      }
    },
    noDataIconColor: {
      type   : String,
      default: '#d6dce0'
    },
    noDataIconSize: {
      type   : [Number, String],
      default: 96
    },
    noDataTitle: {
      type   : String,
      default: function () { return this.$t('Common.Message.NoData.Title') }
    },
    noDataTitleClass: {
      type   : String,
      default: ''
    },
    noDataTitleColor: {
      type   : String,
      default: 'synthesis-ui-grey-01--text'
    },
    button: {
      type   : Boolean,
      default: false
    },
    buttonTitle: {
      type   : String,
      default: function () { return this.$t('Common.Button.New') }
    },
    buttonIcon: {
      type   : String,
      default: 'mdi-plus-circle-outline'
    },
    buttonColor: {
      type   : String,
      default: 'synthesis-ui-green-01'
    },
    buttonTo: {
      type   : String,
      default: ''
    },
    imageBg: {
      type   : Boolean,
      default: true
    },
    loading: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {},
  watch   : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods : {}
}
</script>

<style scoped>
.center-vertical-horizontal {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -55%);
}
</style>
