import BaseResource              from '@/lib/data/resource/BaseResource'
import { collect }               from '@/lib/utils/array'
import AccountModel              from '@/api/models/user/AccountModel'
import PreferencesResource       from '@/api/resources/user/PreferencesResource'
import AnalyticsSettingsResource from '@/api/resources/user/AnalyticsSettingsResource'

export default class UserResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? null
    this.name = payload.name ?? ''
    this.firstName = payload.firstName ?? ''
    this.lastName = payload.lastName ?? ''
    this.userName = payload.userName ?? ''
    this.email = payload.email ?? ''
    this.roles = payload.roles ?? []
    this.accounts = collect(payload.accounts ?? [], AccountModel)
    this.preferences = new PreferencesResource(payload?.preferences)
    this.userAnalyticsSettings = new AnalyticsSettingsResource(payload?.userAnalyticsSettings)

    /* PROPERTIES */

    /* METHODS */
  }
}
