export default {
  computed: {
    hedgingBaseCurrency () {
      return this.hedgingBaseCurrencyData[this.id] ?? null
    },

    hedgingBaseCurrencyDisplayName () {
      return this.hedgingBaseCurrency?.displaySymbol || ''
    }
  },
  methods: {
    isBaseCurrencySymbolSingle (baseCurrency) {
      return baseCurrency.length === 1
    },

    baseCurrencySymbolSingle (baseCurrency) {
      return this.isBaseCurrencySymbolSingle(baseCurrency) ? baseCurrency : ''
    }
  }
}
