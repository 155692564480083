<template>
  <v-card
    :class="{'active': activeAnalysis}"
    :height="210"
    class="pa-2 synthesis-card-border"
    flat
    v-on="enableCardClick ? {click: onCardClick} : {}"
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="synthesis-inter-text text-size-12 synthesis-ui-grey-01--text pb-0">
          {{ title }}
        </div>
        <v-list-item-title
          class="synthesis-text text-size-24 font-weight-medium synthesis-brand-dark-blue--text pt-0 pb-0"
        >
          {{ titleValue }}
        </v-list-item-title>

        <v-list-item-subtitle class="pt-4">
          <template v-for="(tag, index) in tags">
            <v-chip
              :key="`tag-${index}`"
              :color="$vuetify.theme.themes.light['synthesis-ui-grey-05']"
              class="pa-1 mr-1 mb-1 synthesis-inter-text text-size-10 synthesis-ui-grey-01--text text-uppercase"
              label
              x-small
            >
              {{ tag }}
            </v-chip>
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        v-if="pieValues && pieValues.length"
        :size="120"
        class="ma-2"
        tile
      >
        <base-e-chart-pie
          :axis-y-tick-prefix="baseCurrencySymbol"
          :chart-data-options="pieValues"
          :chart-height="120"
          :tooltip-show="true"
          chart-animation-easing="elasticOut"
          tooltip-confine
        />
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions class="pa-4">
      <span class="synthesis-inter-text text-size-10 synthesis-ui-grey-01--text">{{ dateCreated }}</span>

      <v-spacer />

      <v-menu
        v-if="$te('Hedging.Tab.Archive.Card.MenuItems') && Array.isArray($t('Hedging.Tab.Archive.Card.MenuItems')) && $t('Hedging.Tab.Archive.Card.MenuItems').length"
        bottom
        offset-y
      >
        <template #activator="{on, attrs}">
          <v-btn
            color="grey"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-dots-horizontal
            </v-icon>
          </v-btn>
        </template>

        <v-list class="pa-0 ma-0">
          <v-list-item
            v-for="(menuItem, menuIndex) in $t('Hedging.Tab.Archive.Card.MenuItems')"
            :key="`menu-item-${menuIndex}`"
            :disabled="!menuItem.Active"
            @click="onCardMenuItemClick(menuItem)"
          >
            <v-list-item-title class="text-size-14">
              {{ menuItem.Title }}
            </v-list-item-title>

            <v-list-item-action>
              <v-icon :color="menuItem.Active ? menuItem.Color : 'grey lighten-2'">
                {{ menuItem.Icon }}
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-else
        icon
        @click.stop="onCardEditClick"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import BaseEChartPie from '@/theme/default/components/charts/echarts/BaseEChartPie'

export default {
  name      : 'HedgingArchiveItemCard',
  components: { BaseEChartPie },
  directives: {},
  mixins    : [],
  props     : {
    baseCurrencySymbol: {
      type   : String,
      default: ''
    },
    title: {
      type   : String,
      default: ''
    },
    titleValue: {
      type   : String,
      default: ''
    },
    dateCreated: {
      type   : String,
      default: ''
    },
    pieValues: {
      type   : Array,
      default: () => []
    },
    tags: {
      type   : Array,
      default: () => []
    },
    cardEvent: {
      type   : Boolean,
      default: false
    },
    activeAnalysis: {
      type   : Boolean,
      default: false
    }
  },
  dataStore: {},
  enums    : {},
  dataModel: null,
  data () {
    return {}
  },
  computed: {
    enableCardClick () {
      return this.cardEvent
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    onCardClick () {
      this.$emit('card-click')
    },
    onCardEditClick () {
      this.$emit('card-edit-click')
    },
    onCardMenuItemClick (menuItem) {
      menuItem?.Event && this.$emit(menuItem.Event)
    }
  }
}
</script>

<style scoped>
/deep/ .v-list-item--three-line .v-list-item__content {
  align-self: initial;
  padding-top: 0;
}
</style>
