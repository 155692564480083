import HedgingModeEnum from '@/api/enums/HedgingModeEnum'

export default {
  Title: 'Hedging',

  Rolling: 'Rolling',
  Strip  : 'Strip',

  Mode: {
    Historical: 'Historical',
    MonteCarlo: 'Forward Looking'
  },

  Message: {
    NoData: {
      Title: 'No Analysis currently setup'
    }
  },

  Section: {
    SuggestedStrategy: {
      Title            : 'Suggested Strategy',
      ToolbarTitle     : '{Mode} Analysis Summary',
      OptimalHedgeRatio: {
        Title   : 'Optimal Hedge Ratio',
        Subtitle: '{Type}',
        InfoTip : 'This is the optimal hedge ratio produced by Synthesis taking into account multiple factors including the cost of hedging and the volatility reduction.'
      },
      HedgeAmount: {
        Title  : 'Hedge Amount',
        InfoTip: 'This is the optimal amount to hedge. It is calculated simply by applying the optimal hedge ratio to the original exposure and shown in base currency terms.'
      },
      HedgedVsUnhedged: {
        Title   : 'Risk Reduction through Hedging',
        InfoTip : 'The grey shaded distribution show the unhedged risk: the far left part of the distribution shows what is the potential loss per asset (or the overall portfolio) for a full year. The purple lines show the risk after the hedging instruments have been applied. Similarly, the far left of the purple lines show the maximum loss post hedging.',
        Subtitle: '{Type} Strip',
        Tooltip : 'Placing a strip of 12 forwards at the beginning of the period fully immunises the risk: Once you enter the 12 forwards, the company knows with certainty how much the local exposures will materialise in base currency amounts. Zero cost instrument but sometimes credit intensive. | Purchasing a strip of 12 put options at the beginning of the period fully immunises the risk on the downside: Once you enter the 12 options, the company knows the maximum it can lose is the premium paid upfront. The benefit that comes with purchased options is that the upside of favourable asset moves is still there (unlike the forwards). | Placing a strip of 12 collars at the beginning of the period “bounds” the risk: Once you enter the 12 collars, the company knows the minimum and maximum it can receive in base currency terms. Zero cost instrument but sometimes credit intensive.'
      },
      RollingTradeVsStripTrade: {
        Title   : 'Rolling Trade vs Strip of Trades',
        InfoTip : '',
        Subtitle: 'Rolling {Type}',
        Tooltip : 'In this scenario, a single 1-month forward (with notional equal to the annual exposure) is placed at the beginning of the period. Subsequently, every month the forward is renewed (or “rolled”). Even though the FX risk is fully immunised, the company is still subject to forward points risk, thus this strategy is more risky than the strip of forwards. Often deployed when timing of cashflows is less uniform or the company has a view of forward points moving in their favour in the coming months. | In this scenario, a single 1-month option (with notional equal to the annual exposure) is purchased at the beginning of the period. Subsequently, every month the option is renewed (or “rolled”). | In this scenario, a single 1-month collar (with notional equal to the annual exposure) is placed at the beginning of the period. Subsequently, every month the collar is renewed (or “rolled”).'
      }
    },
    Compare: {
      Title      : 'Benchmarking',
      ExportTitle: 'Hedging Benchmarking'
    },
    Results: {
      InstrumentResults: 'Instrument Results'
    }
  },

  Tab: {
    Archive: {
      Title  : 'My Strategies',
      Confirm: {
        Delete: {
          Title: 'Delete Analysis?',
          Body : 'Are you sure you want to delete <span class="grey--text">"{Name}"</span> analysis?'
        }
      },
      Card: {
        MenuItems: [
          {
            Event : 'archive-item-delete-button-click',
            Title : 'Delete',
            Icon  : 'mdi-trash-can',
            Color : 'synthesis-ui-red-05',
            Active: true
          }
        ]
      }
    },
    Summary: {
      Title: 'Suggested Strategy',
      Slug : 'suggested-strategy'
    },
    Compare: {
      Title: 'Benchmarking',
      Slug : 'benchmarking'
    }
  },

  Button: {
    New: {
      Title: '@:Common.Button.New Analysis'
    },
    View: {
      Title: '@:Common.Button.View Analysis'
    },
    AddCurrentStrategy: {
      Title: '@:Common.Button.Add Current Strategy'
    },
    EditCurrentStrategy: {
      Title: '@:Common.Button.Edit Current Strategy'
    }
  },

  Table: {
    StrategySummary: {
      Headers: {
        Asset       : 'ASSET',
        Instrument  : 'INSTRUMENT',
        HedgedRatio : 'HEDGED RATIO',
        UnHedgedRisk: '1% UNHEDGED RISK',
        HedgedRisk  : 'HEDGED RISK'
      }
    },
    Compare: {
      Headers: {
        Asset     : '',
        MyStrategy: 'MY STRATEGY',
        Suggested : 'SUGGESTED',
        Industry  : 'INDUSTRY'
      }
    },
    Results: {
      Headers: {
        Asset  : 'Asset',
        Forward: {
          Title  : 'Forward',
          InfoTip: 'Risk (1%) of the hedged distribution if exposures had been hedged with Forwards.'
        },
        Option: {
          Title  : 'Option',
          InfoTip: 'Risk (1%) of the hedged distribution if exposures had been hedged with Options.'
        },
        Collar: {
          Title  : 'Collar',
          InfoTip: 'Risk (1%) of the hedged distribution if exposures had been hedged with Collars.'
        },
        BestInstrument: {
          Title  : 'Best Instrument',
          InfoTip: 'Optimal instrument on the basis of lower risk among hedging strategies.'
        }
      },
      Instrument: {
        Forward: 'Forward',
        Option : 'Option',
        Collar : 'Collar'
      }
    },
    Costs: {
      Titles: {
        Asset                 : 'Asset',
        SelectAsset           : 'Select an asset to see its hedging costs',
        ForwardsInterbankRates: {
          Title  : 'Forwards Interbank Rates',
          InfoTip: 'Market indications of the interbank forward rates (no transaction costs).'
        },
        OptionsInterbankRates: {
          Title  : 'Options Interbank Rates',
          InfoTip: 'Market indications of the interbank ATMF put premia (no transaction costs).'
        },
        CollarInterbankRates: {
          Title  : 'Collar Interbank Rates',
          InfoTip: 'Market indications of the interbank collar strikes with the lower strike at 5% (no transaction costs).'
        },
        EstimatedBankFees: {
          Title  : 'Estimated Bank Fees',
          InfoTip: 'Synthesis estimates about approximate bank costs for a company between $50m-$200m of revenues. Please contact Synthesis if you want a more detailed estimate.'
        }
      },
      Headers: {
        Maturity         : 'Maturity',
        ForwardRate      : 'Forward Rate',
        CollarStrike     : 'Collar Strike',
        Carry            : 'Cost of Carry (p.a.)',
        Premium          : 'Premium',
        AnnualisedCost   : 'Annualised Cost',
        ForwardFees      : 'Forward Fees',
        OptionPremiumFees: 'Option Premium Fees',
        CollarFees       : 'Collar Fees',

        ForwardsInterbankRates: {
          Maturity   : 'Maturity',
          ForwardRate: 'Forward Rate',
          Carry      : 'Cost of Carry (p.a.)'
        },

        OptionsInterbankRates: {
          Maturity      : 'Maturity',
          Premium       : 'Premium',
          AnnualisedCost: 'Annualised Cost'
        },

        CollarInterbankRates: {
          Maturity    : 'Maturity',
          CollarStrike: 'Collar Strike',
          Carry       : 'Cost of Carry (p.a.)'
        },

        EstimatedBankFees: {
          Maturity         : 'Maturity',
          ForwardFees      : 'Forward Fees',
          OptionPremiumFees: 'Option Premium Fees',
          CollarFees       : 'Collar Fees'
        }

      }
    }
  },

  Card: {
    UnhedgedRisk: {
      Title  : 'Unhedged Risk',
      InfoTip: '1% Value-at-Risk for the sum of the 12 cash flows per each asset exposure with no hedging.'
    },
    HedgedRisk: {
      Title  : 'Hedged Risk',
      InfoTip: '1% Value-at-Risk for the sum of the 12 cash flows per each asset exposure after a hedging strategy is applied.'
    },
    Instrument: {
      Title  : 'Instrument',
      InfoTip: 'Optimal instrument to hedge the risk of this particular asset based on back/forward testing.'
    },
    HedgeRatio: {
      Title  : 'Hedge Ratio',
      InfoTip: 'This is the optimal hedge ratio produced by Synthesis taking into account multiple factors including the cost of hedging and the volatility reduction.'
    },
    Cost: {
      Title: 'Cost'
    },
    PotentialSaving: {
      Title             : 'Potential Saving',
      InfoTip           : 'This number denotes the potential loss avoided by mitigating risk via hedging.',
      InfoTipChartLegend: 'Simply the difference between Hedged and Unhedged Risk. This denotes how much potential loss the company has avoided.'
    },
    Current: {
      Title  : 'Current',
      InfoTip: 'Current Hedging Strategy deployed by the company. Can be added by pressing the green button “Add Current Strategy” in the top right of the page.'
    },
    Optimal: {
      Title  : 'Optimal',
      InfoTip: 'Optimal Hedge Ratio and Hedge Instrument based on Synthesis analysis.'
    },
    Industry: {
      Title  : 'Industry',
      InfoTip: 'Typical Hedge Ratios and Hedge Instruments used by companies in the same industry based on public sources.'
    }
  },

  Legend: {
    Unhedged: {
      Title: 'Unhedged Risk'
    },
    Hedged: {
      Title: 'Hedged Risk'
    }
  },

  Edit: {
    Title: '@:Common.Button.Edit Analysis',
    Form : {
      Steps: {
        Step1: {
          Title: 'Inputs'
        },
        Step2: {
          Title: 'Hedge Ratio'
        },
        Step3: {
          Title: 'Instruments'
        },
        Step4: {
          Title : 'Hedging Costs',
          Button: {
            Action: 'Run Analysis'
          }
        },
        Step5: {
          Title : 'Strategy Summary',
          Button: {
            Action: 'Save Analysis'
          }
        }
      },

      Fields: {
        Name: {
          Label      : 'New Analysis Name',
          Placeholder: 'Enter analysis name'
        },
        BaseCurrency: {
          Label      : 'Base Currency',
          SelectLabel: 'Select @:Risks.Edit.Form.Fields.BaseCurrency.Label',
          Placeholder: 'Please Select'
        },
        Mode: {
          Label  : 'Analysis Type',
          InfoTip: 'User can choose whether Synthesis will deploy back testing methodologies (for data going back to 2000) or forward looking simulations. For more robust strategy design, both methodologies should be run and compared.',
          Items  : [
            {
              Value: HedgingModeEnum.MonteCarlo,
              Label: 'Forward Looking'
            }
          ]
        },
        Exposures: {
          Label                       : 'Add Asset',
          Placeholder                 : 'Select currency, commodity or interest rate',
          Asset                       : 'Asset',
          AnnualExposure              : 'Annual Exposure (Local)',
          AnnualExposureInBaseCurrency: 'Annual Exposure (Base)',
          Seasonality                 : 'Seasonality',
          MenuItems                   : [
            {
              Event : 'list-item-edit-button-click',
              Title : 'Edit',
              Icon  : 'mdi-pencil',
              Color : 'synthesis-ui-grey-01',
              Active: true
            },
            {
              Event : 'list-item-delete-button-click',
              Title : 'Delete',
              Icon  : 'mdi-trash-can',
              Color : 'synthesis-ui-red-05',
              Active: true
            }
          ]
        },
        MarketRatesUsed: {
          Label  : 'Select Market Parameters',
          InfoTip: 'The key parameters here include the Forward Premium p.a. (or Cost of Carry), the “out-of-the-moneyness” of the hedging put options, and the lower strike of the collars (the higher strike being automatically calculated to make the collar zero cost).',
          Items  : {
            CurrentMarketRates: {
              Label  : 'Current Market Rates',
              Value  : true,
              InfoTip: 'This assumes the prevailing market rates for 1-year cost of carry, option put strikes set at the forward level (ATMF) and 5% distance between the forward and the lower strike of the collar. For alternative parameter inputs, please select “Input Values”.'
            },
            InputValues: {
              Label: 'Input Values',
              Value: false
            }
          },
          Button: {
            AddAsset : '@:Common.Button.Add Asset Parameters',
            EditAsset: '@:Common.Button.Edit Asset Parameters',
            ShowRates: 'Show rates'
          }
        },
        NaturalHedging: {
          Label  : 'Natural Hedging',
          Include: {
            Label  : 'Include',
            InfoTip: ''
          },
          Exclude: {
            Label  : 'Exclude',
            InfoTip: ''
          },
          InfoTip: 'Observes how the optimal hedging ratio is affected when accounting for natural hedging (correlations between exposures).'
        },
        HedgingInstrument: {
          Label: 'Select Hedging Instrument',
          Items: [
            {
              Label  : 'Forward',
              Value  : 'activeForwards',
              Image  : '/img/hedging/instrument/forward.svg',
              InfoTip: 'This hedging strategy assumes hedging via OTC forwards. The default scenario is hedging the 12 monthly cash flows with 12 forwards on the first day of the full period. For rolling strategies please press the “Rolling” button at the bottom box.'
            },
            {
              Label  : 'Option',
              Value  : 'activeOptions',
              Image  : '/img/hedging/instrument/option.svg',
              InfoTip: 'This hedging strategy assumes hedging via OTC Put Options. The default scenario is hedging the 12 monthly cash flows by purchasing 12 put options on the first day of the full period. For rolling strategies please press the “Rolling” button at the bottom box.'
            },
            {
              Label  : 'Collar',
              Value  : 'activeRiskReversal',
              Image  : '/img/hedging/instrument/collar.svg',
              InfoTip: 'This hedging strategy assumes hedging via OTC Collars (or “Risk Reversals”). Typically they consist of a purchased put and a sold call in the base currency. The strikes of the call and the put are chosen so that the net premium of the strategy is zero. The default scenario is hedging the 12 monthly cash flows with 12 collars on the first day of the full period. For rolling strategies please press the “Rolling” button at the bottom box.'
            }
          ]
        }
      },

      Button: {
        RunAnalysis: 'Run Analysis'
      }
    }
  },

  AddExposure: {
    Title   : '@:Common.Button.Add Asset',
    Subtitle: 'Select currency, commodity or interest rate',
    Form    : {
      Fields: {
        Label: {
          Label      : 'Source name',
          Placeholder: 'Enter asset source name e.g. exports receivables'
        },

        Notional: {
          Label      : 'Annual Exposure',
          InfoTip    : 'Annual exposure in local units. Positive numbers in black denote revenues/inflows and negative numbers in red denote expenses/outflows. If this field is populated, each month will be allocated the total exposure divided by twelve (no seasonality). If different exposures per month, please click “Add Seasonality” link below.',
          Placeholder: '0'
        }
      },
      Confirm: {
        Delete: {
          Title: 'Delete Asset?',
          Body : 'Are you sure you want to delete this asset?'
        }
      },
      Button: {
        Save: {
          Title: '@:Common.Button.Save Asset'
        },
        Update: {
          Title: '@:Common.Button.Update Asset'
        }
      }
    }
  },

  AssetParameters: {
    Add: {
      Title   : 'Add Asset Parameters',
      Subtitle: 'Add your own parameters to the inputs below',
      InfoTip : 'The “Cost of Carry” per asset is defined as [ spot rate - forward rate ] / [spot rate]. The spot and forward rates follow the quote convention of x units of base currency per 1 unit of asset (base currency has been chosen by the user at the beginning of the section).',
      Form    : {
        Button: {
          Save: {
            Title: 'Save Parameters'
          }
        },
        Fields: {
          CostOfCarry: {
            Label      : 'Cost of Carry',
            Placeholder: 'Enter percentage'
          },
          Option: {
            Label      : 'Option Strike',
            Placeholder: 'Enter value'
          },
          Collar: {
            Label      : 'Collar Lower Strike for Zero Premium',
            Placeholder: 'Enter value'
          }
        }
      }
    },
    Edit: {
      Title   : 'Edit Asset Parameters',
      Subtitle: 'Edit your own parameters to the inputs below',
      InfoTip : 'The “Cost of Carry” per asset is defined as [ spot rate - forward rate ] / [spot rate]. The spot and forward rates follow the quote convention of x units of base currency per 1 unit of asset (base currency has been chosen by the user at the beginning of the section).',
      Form    : {
        Button: {
          Save: {
            Title: 'Save Parameters'
          }
        },
        Fields: {
          CostOfCarry: {
            Label      : 'Cost of Carry',
            Placeholder: 'Enter percentage'
          },
          Option: {
            Label      : 'Option Strike',
            Placeholder: 'Enter value'
          },
          Collar: {
            Label      : 'Collar Lower Strike for Zero Premium',
            Placeholder: 'Enter value'
          }
        }
      }
    },
    Show: {
      Title   : 'Current Market Rates',
      Subtitle: 'The current market rates for your chosen assets',
      InfoTip : 'The “Cost of Carry” per asset is defined as [ spot rate - forward rate ] / [spot rate]. The spot and forward rates follow the quote convention of x units of base currency per 1 unit of asset (base currency has been chosen by the user at the beginning of the section).',
      Form    : {
        Button: {
          Save: {
            Title: 'Save Parameters'
          }
        },
        Fields: {
          CostOfCarry: {
            Label      : 'Cost of Carry',
            Placeholder: 'Enter percentage'
          },
          Option: {
            Label      : 'Option Strike',
            Placeholder: 'Enter value'
          },
          Collar: {
            Label      : 'Collar Lower Strike for Zero Premium',
            Placeholder: 'Enter value'
          }
        }
      }
    },
    Confirm: {
      Save: {
        Title: 'Save Parameters?',
        Body : 'Different parameters used for Hedge Ratio and Instruments Optimizations! Are you sure you want to continue?'
      }
    }
  },

  AddCurrentStrategy: {
    Title   : 'Current Hedging Strategy',
    Subtitle: 'Add your current hedge ratios and instruments below',
    Message : {
      NoStrategyAdded: 'No Strategy Added'
    },
    Form: {
      Fields: {
        Instrument: {
          Label      : 'Instrument',
          Placeholder: 'Please select'
        },

        HedgeRatio: {
          Label      : 'Hedge Ratio',
          Placeholder: '0'
        }
      },
      Button: {
        SaveAsset: {
          Title: '@:Common.Button.Save Asset'
        },
        SaveStrategy: {
          Title: '@:Common.Button.Save Current Strategy'
        }
      }
    }
  },

  New: {
    Title: '@:Common.Button.New Analysis'
  }

}
