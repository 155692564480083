import Common from '@/api/enums/ChartsEnum'
import dayjs  from 'dayjs'

export default {

  App: {
    Title: 'Maven Synthesis',
    Desc : '',
    Img  : ''
  },

  Search: {
    Title: 'Search'
  },

  Message: {
    Loading: {
      Title   : 'Loading',
      Wait    : 'Synthesis is working its magic! Your results will appear shortly.',
      Progress: '%{progress}%'
    },

    Sort: {
      Title : 'Sort',
      SortBy: 'Sort by'
    },

    Group: {
      Title  : 'Group',
      GroupBy: 'Group by'
    },

    Filter: {
      Title   : 'Filter',
      FilterBy: 'Filter by'
    },

    NoData: {
      Title: 'No data are currently setup'
    },

    Select: {
      Title: 'Please Select'
    }
  },

  Date: {
    Title  : 'Date | Dates',
    Today  : 'Today',
    Day    : 'Day | Days',
    Week   : 'Week | Weeks',
    Month  : 'Month | Months',
    Year   : 'Year | Years',
    Period : 'Period | Periods',
    Periods: {
      Day  : '1D',
      Week : '1W',
      Month: '1M',
      Year : '1Y',
      All  : 'All'
    },
    PeriodItemsWithDaily: [{
      Title: '1D',
      Value: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    },
    {
      Title: '1W',
      Value: dayjs().subtract(1, 'week').format('YYYY-MM-DD')
    },
    {
      Title: '1M',
      Value: dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    },
    {
      Title: '1Y',
      Value: dayjs().subtract(1, 'year').format('YYYY-MM-DD')
    },
    {
      Title: 'All',
      Value: null
    }],
    PeriodItems: [{
      Title: '1W',
      Value: dayjs().subtract(1, 'week').format('YYYY-MM-DD')
    },
    {
      Title: '1M',
      Value: dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    },
    {
      Title: '1Y',
      Value: dayjs().subtract(1, 'year').format('YYYY-MM-DD')
    },
    {
      Title: 'All',
      Value: null
    }],
    PeriodFrom: 'From',
    PeriodTo  : 'To',
    Expire    : 'Expire',
    MonthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    Preset    : [
      {
        Title    : 'Last month',
        Value    : -1,
        ValueType: 'month'
      },
      {
        Title    : 'Last 3 months',
        Value    : -3,
        ValueType: 'month'
      },
      {
        Title    : 'Last 6 months',
        Value    : -6,
        ValueType: 'month'
      },
      {
        Title    : 'Last year',
        Value    : -1,
        ValueType: 'year'
      },
      {
        Title    : 'Last 3 years',
        Value    : -3,
        ValueType: 'year'
      },
      {
        Title    : 'Last 5 years',
        Value    : -5,
        ValueType: 'year'
      }
    ]
  },

  Infotip: {
    Export: 'Export “{title}”',
    Unlock: 'Unlock “{title}”'
  },

  Button: {
    All     : 'All',
    Save    : 'Save',
    Update  : 'Update',
    Copy    : 'Copy',
    Clear   : 'Clear',
    View    : 'View',
    ViewAll : 'View All',
    Back    : 'Back',
    New     : 'New',
    Create  : 'Create',
    Continue: 'Continue',
    Edit    : 'Edit',
    Add     : 'Add',
    Export  : 'Export',
    Import  : 'Import',
    Delete  : 'Delete',
    Remove  : 'Remove',
    Reset   : 'Reset',
    Login   : 'Login',
    Logout  : 'Sign out',
    Cancel  : 'Cancel',
    Close   : 'Close',
    Send    : 'Send',
    Yes     : 'Yes',
    No      : 'No',
    Ok      : 'Ok',
    Next    : 'Next',
    Prev    : 'Prev',
    Select  : 'Select',
    Submit  : 'Submit',
    Hide    : 'Hide',
    Show    : 'Show',
    Expand  : 'Expand',
    Retract : 'Retract',
    Unsure  : 'Unsure',
    Ignore  : 'Ignore',
    Proceed : 'Proceed',
    Toggle  : {
      Active  : 'Active',
      Inactive: 'Inactive'
    }
  },

  Error: {
    Generic: {
      Title  : 'An Error Occurred',
      Message: 'An unexpected error has occurred'
    },
    Staging    : 'Attention! This is a DEMO environment.',
    Development: 'Attention! This is a DEV environment.'
  },

  Misc: {
    From     : 'From',
    To       : 'To',
    Locale   : 'Language',
    CreatedAt: 'Created',
    UpdatedAt: 'Updated',
    DeletedAt: 'Deleted'
  },

  Asset: {
    Portfolio: 'All Risk'
  },

  Hedging: {
    Instruments: {
      Forward: 'Forward',
      Option : 'Option',
      Collar : 'Collar'
    }
  },

  Seasonality: {
    Title: 'Seasonality',
    Map  : {
      UserSeasonality: 'user',
      MaxSeasonality : 'max',
      NoSeasonality  : 'no'
    },
    Items: [
      {
        Priority     : 1,
        PriorityTable: 1,
        Title        : 'No Seasonality',
        InfoTip      : 'Regardless of the seasonality input by the user, this button takes the total annual exposure and divides it equally between the 12 months.',
        Value        : 'no',
        Color        : Common.Colors.LineChartSeasonality.no
      },
      {
        Priority     : 2,
        PriorityTable: 3,
        Title        : 'Max Seasonality',
        InfoTip      : 'Regardless of the seasonality input by the user, this button takes the total annual exposure and allocates fully in a single month.',
        Value        : 'max',
        Color        : Common.Colors.LineChartSeasonality.max
      },
      {
        Priority     : 3,
        PriorityTable: 2,
        Title        : 'User Seasonality',
        InfoTip      : 'This button takes into account the seasonality profile input by the user for the 12 months per each asset.',
        Value        : 'user',
        Color        : Common.Colors.LineChartSeasonality.user
      }
    ]
  }
}
