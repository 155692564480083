<template>
  <block-item-card
    v-if="hasDebt"
    ref="debtProfileCard"
    class="pt-0"
    :show-background="false"
    :show-border="false"
  >
    <v-toolbar
      :class="toolbarClassForExport()"
      :style="toolbarStyleForExport()"
      flat
    >
      <v-skeleton-loader
        v-if="loading"
        :height="35"
        :loading="loading"
        :width="300"
        type="heading"
      />
      <v-toolbar-title
        class="synthesis-text text-size-28 font-weight-medium synthesis-brand-dark-blue--text"
        v-text="$t('Risks.Section.DebtProfile.Block.Scenario.Title', {name: title})"
      />
      <infotip
        v-if="$t('Risks.Section.DebtProfile.Block.Scenario.InfoTip')"
        icon-style="margin-top: -20px"
      >
        {{ $t('Risks.Section.DebtProfile.Block.Scenario.InfoTip') }}
      </infotip>
      <v-row>
        <v-col>
          <export-to-image
            v-if="elementDebtProfile"
            row-padding="pt-3"
            :export-element="elementDebtProfile"
            :export-file-name="title"
            large
            @export-to-image="exportToImage"
            @export-to-image-completed="exportToImageCompleted"
          />
        </v-col>
      </v-row>
      <v-spacer />
      <v-btn
        class="mr-1 button-text-underline synthesis-text text-size-14 font-weight-400"
        :class="ExportEnum.NO_EXPORT_CLASS"
        color="synthesis-ui-green-02"
        height="42"
        text
        @click="onEditDebtProfileClick"
      >
        {{ $t('Risks.Section.DebtProfile.Edit.Title') }}
      </v-btn>
    </v-toolbar>

    <v-row>
      <maturities-block
        :risk-analysis-id="id"
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
      />
      <fixed-vs-floating-block
        :risk-analysis-id="id"
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
      />
      <optimal-fixed-ratio-block
        :risk-analysis-id="id"
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
      />
      <interest-expense-block
        :risk-analysis-id="id"
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
      />
      <credit-metrics-block
        :risk-analysis-id="id"
        :chart-animation="chartAnimation"
        :disable-lazy="disableLazy"
      />
    </v-row>
  </block-item-card>
</template>

<script>

import i18nRouteMeta          from '@/mixins/i18n/i18nRouteMeta'
import chartsColorMixin       from '@/theme/default/components/charts/mixins/chartsColorMixin'
import riskBaseCurrency       from '@/theme/default/views/risks/mixins/riskBaseCurrency'
import MaturitiesBlock        from '@/theme/default/views/risks/blocks/debt/MaturitiesBlock.vue'
import FixedVsFloatingBlock   from '@/theme/default/views/risks/blocks/debt/FixedVsFloatingBlock.vue'
import InterestExpenseBlock   from '@/theme/default/views/risks/blocks/debt/InterestExpenseBlock.vue'
import CreditMetricsBlock     from '@/theme/default/views/risks/blocks/debt/CreditMetricsBlock.vue'
import viewHasData            from '@/mixins/viewHasData'
import BlockItemCard          from '@/theme/default/components/common/BlockItemCard.vue'
import ExportToImage          from '@/theme/default/components/common/ExportToImage.vue'
import ExportEnum             from '@/api/enums/ExportEnum'
import Infotip                from '@/theme/default/components/common/Infotip.vue'
import OptimalFixedRatioBlock from '@/theme/default/views/risks/blocks/debt/OptimalFixedRatioBlock.vue'

export default {
  name      : 'RiskDebtShowBlock',
  components: {
    OptimalFixedRatioBlock,
    Infotip,
    ExportToImage,
    BlockItemCard,
    CreditMetricsBlock,
    InterestExpenseBlock,
    FixedVsFloatingBlock,
    MaturitiesBlock
  },
  directives: {},
  mixins    : [riskBaseCurrency, chartsColorMixin, i18nRouteMeta, viewHasData],
  props     : {
    id: {
      type   : String,
      default: ''
    }
  },
  dataStore: {
    riskDebtData        : 'Risks.Debt.Data',
    riskBaseCurrencyData: 'Risks.BaseCurrency.Data'
  },
  enums    : { ExportEnum },
  dataModel: null,
  data () {
    return {
      chartAnimation    : true,
      disableLazy       : false,
      export            : false,
      elementDebtProfile: null
    }
  },
  computed: {
    debt () {
      return this.riskDebtData[this.id] || null
    },

    hasDebt () {
      return !!this.debt
    },

    loading () {
      return !this.hasDebt
    },

    title () {
      return this.debt?.Name || 'Debt Profile Test'
    }
  },
  watch: {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {
    this.setExportElements()
  },
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {
    toolbarClassForExport () {
      return this.export ? 'my-2 rounded' : 'transparent'
    },

    toolbarStyleForExport () {
      return this.export ? `border-radius: 8px !important; border: ${ this.themeColorRGBA('synthesis-ui-grey-02') } 1px solid` : ''
    },

    exportToImage () {
      this.export = true
      this.chartAnimation = false
      this.disableLazy = true
    },

    exportToImageCompleted () {
      this.export = false
      this.chartAnimation = true
    },

    setExportElements () {
      this.$nextTick(() => {
        this.elementDebtProfile = this.$refs?.debtProfileCard?.$el
      })
    },

    onEditDebtProfileClick () {
      this.$emit('click:button:edit')
    }
  }
}
</script>

<style scoped>
/deep/ .v-toolbar .v-toolbar__content {
  padding-right: 0;
}
</style>
