import BaseResource                                 from '@/lib/data/resource/BaseResource'
import i18n                                         from '@/lang/lang'
import { collect }                                  from '@/lib/utils/array'
import RiskValueAtRiskSimulationSeasonalityResource from '@/api/resources/risks/RiskValueAtRiskSimulationSeasonalityResource'
import Common                                       from '@/lang/locale/en/Common'
import ChartsStyleEnum                              from '@/api/enums/ChartsEnum'
import AssetNameResource                            from '@/api/resources/asset/AssetNameResource'
import AssetCodeNameResource                        from '@/api/resources/asset/AssetCodeNameResource'

export default class RiskForwardSimulationsAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    const assetValue = payload?.itemCode || payload?.itemName || ''

    this.Asset = payload.itemName ? (payload.itemName === 'portfolio' ? i18n.t('Common.Asset.Portfolio') : payload.itemName) : ''
    this.AssetValue = assetValue.toLocaleLowerCase(i18n.code)

    this.BucketEdges = payload.bucketEdges ?? []
    this.CashFlowPaths = payload.cashFlowPaths ?? []
    this.CashFlowPathsValues = this.getCashFlowPaths()

    const v = this.getSeasonalityHistogramsWithBucketEdges(payload.seasonalityHistograms ?? [])

    this.SeasonalityHistograms = collect(v, RiskValueAtRiskSimulationSeasonalityResource)
    this.Seasonality = {
      user: [],
      max : [],
      no  : []
    }
    if (this.SeasonalityHistograms) {
      if (this.SeasonalityHistograms.length) {
        this.SeasonalityHistograms.forEach(item => {
          const seasonalityName = Common.Seasonality.Map[item.Name]
          this.Seasonality[seasonalityName] = {
            Color: ChartsStyleEnum.Colors.LineChartSeasonality[seasonalityName],
            ...item
          }
        })
      }
    }
    this.AggregateItem = payload.aggregateItem || false
    this.PerCashflowCodes = collect(payload?.perCashflowCodes || [], AssetNameResource)
    this.PerCashflowItems = collect(payload?.perCashflowItems || [], RiskForwardSimulationsAssetResource)

    this.PerCashflowNames = payload.perCashflowNames ?? []
    this.PerCashflowCodeNames = collect(payload?.perCashflowCodeNames || [], AssetCodeNameResource)
  }

  /* PROPERTIES */

  /* METHODS */
  getCashFlowPaths () {
    return this.CashFlowPaths.map(item => { return { y: item } })
  }

  getSeasonalityHistogramsWithBucketEdges (v) {
    if (v && !!v.length) {
      return v.map(item => {
        item.bucketEdges = this.BucketEdges
        return item
      })
    }

    return []
  }
}
