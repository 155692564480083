<template>
  <v-card
    :height="310"
    :min-height="310"
    color="synthesis-ui-orange-01"
    flat
  >
    <v-card-text
      class="synthesis-text text-size-32 font-weight-regular synthesis-brand-white--text"
      style="height:170px"
    >
      {{ title }}
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-container
        fluid
        pa-0
      >
        <v-row class="pa-0">
          <v-col cols="6">
            <div class="d-flex fill-height align-end">
              <v-btn
                :to="$t('Dashboard.Block.KnowledgeCenter.Link.To')"
                class="px-2 button-text-underline synthesis-text text-size-12 font-weight-400"
                color="synthesis-brand-white"
                height="30"
                text
              >
                {{ $t('Dashboard.Block.KnowledgeCenter.Link.Title') }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            class="text-right"
            cols="6"
          >
            <v-avatar
              class="d-inline-block"
              color="synthesis-ui-orange-02"
              size="106"
            >
              <v-icon
                color="synthesis-ui-orange-08"
                size="57"
              >
                mdi-school-outline
              </v-icon>
            </v-avatar>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>

import { truncate } from '@/filters/string'

export default {
  name      : 'KnowledgeCenterBlock',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {},
  enums     : {},
  dataModel : null,
  data () {
    return {}
  },
  computed: {
    title () {
      return truncate(this.$t('Dashboard.Block.KnowledgeCenter.Title'), this.$vuetify.breakpoint.smAndUp ? 80 : 60)
    }
  },
  watch  : {},
  beforeCreate () {},
  created () {},
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {},
  destroyed () {},
  methods: {}
}
</script>

<style scoped>

</style>
