import BaseResource from '@/lib/data/resource/BaseResource'

export default class CrossCurrencySwapInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.ccsStrike = payload.ccsStrike ?? ''
    this.assetFixedRate = payload.assetFixedRate ?? ''
    this.counterAssetFixedRate = payload.counterAssetFixedRate ?? ''
    this.ccsFrequency = payload.ccsFrequency ?? ''
  }

  /* PROPERTIES */

  /* METHODS */
}
