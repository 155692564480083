export default {
  Title      : 'Dashboard',
  HeaderTitle: 'Welcome back, {Name}',

  Block: {
    LiveRisk: {
      Title: 'Risk Summary',
      Label: {
        Risk        : 'Risk',
        UnhedgedRisk: 'Unhedged Risk',
        HedgedRisk  : 'Risk after Hedging'
      },
      InfoTip: {
        Risk: 'Annual risk in base currency expressed as the average of the 4 independent methodologies. It assumes monthly cashflows per the seasonality profile inserted by the user.'
      }
    },
    LiveHedging: {
      Title: 'Hedging Positions',
      Label: {
        MTM: 'MTM'
      },
      InfoTip: {
        MTM: 'Current “Mark-to-Market” value of all the positions chosen to be displayed on the dashboard. It can also be thought as the liquidation value of these positions assuming no transaction costs.'
      },
      Button: {
        ViewAll: {
          Title: 'View all'
        }
      }
    },
    LivePrices: {
      Title : 'Live Prices',
      Button: {
        AddNewPrices: {
          Title: 'Add new prices'
        }
      }
    },
    LiveNews: {
      Title: 'Market News'
    },
    Alerts: {
      Title : 'Alerts',
      Button: {
        ViewAll: {
          Title: 'View all'
        }
      }
    },
    KnowledgeCenter: {
      Title: 'Learn more about protecting your business',
      Link : {
        Title: 'Visit Knowledge Centre',
        Icon : '',
        To   : { name: 'KnowledgeCenter' }
      }
    },
    Chart: {
      Title: 'Forward Looking Methodologies'
    },
    Survey: {
      Title: 'Monthly Synthesis Survey'
    },
    FunFacts: {
      Title: 'Interesting Facts'
    }
  },

  TrackLivePrices: {
    Title   : 'Track Live Prices',
    Subtitle: 'Select a currency, commodity, interest rate or stock to track',
    Form    : {
      Button: {
        Save: {
          Title: '@:Common.Button.Save Selections'
        }
      }
    }
  }
}
