<template>
  <div class="">
    <div class="text-center">
      <img
        src="/img/logo/logo-symbol.png"
        width="56"
      >
    </div>

    <v-card-title class="justify-center pt-6 pb-8 synthesis-text text-size-24 synthesis-brand-dark-blue--text font-weight-bold">
      {{ $t('Users.Login.Title') }}
    </v-card-title>

    <v-card-text class="text-left">
      <v-form
        ref="form"
        v-model="isFormValid"
        @submit.prevent
      >
        <label class="field-label">{{ $t('Users.Login.Email.Label') }}</label>
        <v-text-field
          v-model="model.Username"
          :disabled="isAuthLoading"
          :placeholder="$t('Users.Login.Email.Placeholder')"
          :rules="model.validator.vuetifyFormFieldRules('Username')"
          name="username"
          outlined
          type="text"
        />

        <div class="my-2" />

        <div class="text-no-wrap d-flex">
          <div class="d-inline-flex">
            <label class="field-label">{{ $t('Users.Login.Password.Label') }}</label>
          </div>

          <v-spacer />

          <div class="d-inline-flex">
            <v-btn
              :large="$vuetify.breakpoint.smAndUp"
              :small="$vuetify.breakpoint.xs"
              :to="{name: 'ForgotPassword'}"
              class="pa-0 ma-0 pb-1"
              color="synthesis-ui-blue-04"
              height="20"
              plain
              text
            >
              <span>{{ $t('Users.ForgotPassword.Title') }}?</span>
            </v-btn>
          </div>
        </div>

        <v-text-field
          v-model="model.Password"
          :append-icon="passwordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          :disabled="isAuthLoading"
          :placeholder="$t('Users.Login.Password.Placeholder')"
          :rules="model.validator.vuetifyFormFieldRules('Password')"
          :type="passwordVisible ? 'text' : 'password'"
          name="password"
          outlined
          @click:append="togglePassword"
        />

        <v-alert
          v-model="loginError"
          class="v-alert--opel ma-0"
          dense
          outlined
          type="error"
        >
          {{ loginErrorMessage }}
        </v-alert>
      </v-form>
    </v-card-text>

    <v-card-actions class="d-block justify-center px-4">
      <v-btn
        :disabled="isAuthLoading"
        :loading="isAuthLoading"
        block
        class="px-4 px-sm-8 px-md-12 white--text action-button"
        color="synthesis-ui-green-02"
        depressed
        x-large
        @click="authenticate"
        v-text="$t('Common.Button.Login')"
      />
    </v-card-actions>
  </div>
</template>

<script>
import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import LoginModel    from '@/api/models/user/LoginModel'

export default {
  name      : 'Login',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  data () {
    return {
      isFormValid    : false,
      passwordVisible: false,
      componentData  : new LoginModel()
    }
  },
  computed: {
    model: {
      get () {
        return this.componentData
      },
      set (val) {
        this.componentData = val
      }
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    window.addEventListener('keydown', this.onKeyDown)
    if (this.$refs.form) this.$refs.form.reset()
    this.user = null
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  destroyed () {},
  methods: {
    togglePassword () {
      this.passwordVisible = !this.passwordVisible
    },

    onKeyDown (e) {
      if (e.keyCode === 13 && !this.isAuthLoading) {
        e.preventDefault()
        this.authenticate()
      }
    },

    authenticate () {
      this.validateForm()

      if (this.isFormValid) {
        this.login(this.model.Username, this.model.Password)
      }
    },

    validateForm (deep = false) {
      this.isFormValid = true
      if (this.$refs?.form) this.isFormValid = this.$refs.form.validate()
      if (this.model?.validator) {
        this.isFormValid = (deep ? this.model.validate() : this.model.validator.validate())
        // eslint-disable-next-line no-console
        console.log(this.model.validator.constructor.name, this.model.errors)
      }
      return this.isFormValid
    },

    async ping () {

    }
  }
}

</script>

<style scoped>

</style>
