import BaseResource                    from '@/lib/data/resource/BaseResource'
import { toNumberFormatNotationShort } from '@/lib/utils/helper'

export default class RiskValueAtRiskSimulationSeasonalityResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Name = payload.seasonality ?? ''
    this.XAxis = payload.bucketEdges ?? []
    this.YAxis = payload.frequencies ?? []
    this.Min = payload.min ?? ''
    this.Max = payload.max ?? []
    this.Mean = payload.mean ?? ''
    this.Percentiles = payload.percentiles ?? []
    this.OnePercentile = payload.onePercentile ?? ''
    this.FivePercentile = payload.fivePercentile ?? ''
    this.OnePercentRisk = payload.onePercentRisk ?? ''
    this.FivePercentRisk = payload.fivePercentRisk ?? ''
    this.OnePercentileBucketIndex = payload.onePercentileBucketIndex ?? ''
    this.FivePercentileBucketIndex = payload.fivePercentileBucketIndex ?? ''
    this.MeanBucketIndex = payload.meanBucketIndex ?? ''

    this.MeanFmt = this.Mean ? toNumberFormatNotationShort(this.Mean) : ''
    this.OnePercentileFmt = this.OnePercentile ? toNumberFormatNotationShort(this.OnePercentile) : ''
    this.FivePercentileFmt = this.FivePercentile ? toNumberFormatNotationShort(this.FivePercentile) : ''
    this.OnePercentRiskFmt = this.OnePercentRisk ? toNumberFormatNotationShort(this.OnePercentRisk) : ''
    this.FivePercentRiskFmt = this.FivePercentRisk ? toNumberFormatNotationShort(this.FivePercentRisk) : ''

    this.Values = [{
      x                        : this.XAxis,
      y                        : this.YAxis,
      onePercentRisk           : this.OnePercentRiskFmt,
      meanBucketIndex          : this.MeanBucketIndex,
      onePercentileBucketIndex : this.OnePercentileBucketIndex,
      fivePercentileBucketIndex: this.FivePercentileBucketIndex
    }]
  }

  /* PROPERTIES */

  /* METHODS */
}
