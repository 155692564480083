import BaseResource from '@/lib/data/resource/BaseResource'

export default class PortfolioMtmTriggerInputResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.threshold = payload.threshold ?? ''
    this.portfolioId = payload.portfolioId ?? ''
    this.snapshotValue = payload.snapshotValue
  }

  /* PROPERTIES */

  /* METHODS */
}
