import BaseResource from '@/lib/data/resource/BaseResource'
import dayjs        from 'dayjs'
import AssetModel   from '@/api/models/asset/AssetModel'

export default class AddTriggerAssetResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.id = payload.id ?? null
    this.pair = payload.assetPair ?? payload.pair ?? ''
    this.value = payload.displayPrice ?? payload.mtmValue ?? payload.aggregateMtm ?? ''
    this.description = payload.quotation?.description ?? payload.displayInstrument ?? ''
    this.title = payload.positionName ?? payload.name ?? ''
    this.assets = this.getPortfolioAssets(payload)
    this.tags = this.getPortfolioTags(payload)
    this.baseCurrency = new AssetModel(payload.baseCurrency ?? null)
    this.createdAt = this.getDateFmt(payload.createdAt ?? null)
  }

  /* PROPERTIES */

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }

  getPortfolioAssets (payload) {
    const trades = payload.trades ?? []

    return trades && trades.map(trade => {
      return trade.assetPair.base.displayName ?? ''
    }).filter(item => item !== '').join(', ') || ''
  }

  getPortfolioTags (payload) {
    const trades = payload.trades ?? []

    return trades && trades.map(trade => {
      return trade.assetPair.base.displayName ?? ''
    }).filter(item => item !== '') || []
  }
}
