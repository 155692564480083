import BaseModel              from '@/lib/data/model/BaseModel'
import AlertInputValidator    from '@/api/validations/alerts/AlertInputValidator'
import API                    from '@/api/Api'
import AlertEditInputResource from '@/api/resources/alerts/AlertEditInputResource'
import AlertTypeEnum          from '@/api/enums/AlertTypeEnum'

export default class AlertEditInputModel extends BaseModel {
  constructor (payload = {}, resource = AlertEditInputResource, validator = AlertInputValidator) {
    super(payload, resource, validator)
  }

  boot () {
    super.boot()

    this.guarded = ['type', 'triggersList']
  }

  /* PROPERTIES */

  /* METHODS */
  clone (includeGuardedFields = false, Resource = null) {
    const data = super.clone(includeGuardedFields, Resource)

    if (data.alertType === AlertTypeEnum.AssetPrice) {
      data.assetPriceTriggers = data.triggers.map(trigger => {
        return {
          assetCode    : trigger.assetCode,
          snapshotValue: trigger.snapshotValue,
          threshold    : trigger.threshold,
          triggerId    : null // trigger.id
        }
      })
    }

    if (data.alertType === AlertTypeEnum.PositionMTM) {
      data.positionMTMTriggers = data.triggers.map(trigger => {
        return {
          snapshotValue: trigger.snapshotValue,
          threshold    : trigger.threshold,
          triggerId    : null, // trigger.id
          tradeId      : trigger.tradeId
        }
      })
    }

    if (data.alertType === AlertTypeEnum.PortfolioMTM) {
      data.portfolioMTMTriggers = data.triggers.map(trigger => {
        return {
          snapshotValue: trigger.snapshotValue,
          threshold    : trigger.threshold,
          portfolioId  : trigger.portfolioId,
          triggerId    : null
        }
      })
    }

    delete data.triggers

    return data
  }

  async UpdateAlert () {
    return await API.Resource.Alerts.UpdateAlertDefinition(this.clone())
  }
}
