import BaseResource from '@/lib/data/resource/BaseResource'
import dayjs        from 'dayjs'

export default class NewsItemResource extends BaseResource {
  constructor (payload = {}) {
    super(payload)

    this.Title = payload.title ?? ''
    this.Description = payload.description ?? ''
    this.Link = payload.link ?? ''
    this.Source = payload.source ?? ''
    this.Date = this.getDateFmt(payload.publicationDate ?? null)
  }

  /* PROPERTIES */

  /* METHODS */
  getDateFmt (date) {
    return date ? dayjs(date).format('DD MMM YYYY') : ''
  }
}
